/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import CustomHeader from 'components/CustomHeader'
import { roleSelector } from 'store/roleManagement/selectors'
import { getPermission } from 'utils/helpers/systemHelpers'
import { PERMISSIONS } from 'model/auth'
import { getGenres } from 'store/basicManagement/slice'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { updateTenant, deleteTenant } from 'store/roleManagement/slice'
import { basicSelector } from 'store/basicManagement/selector'

const createTenantSchema = Yup.object().shape({
  name: Yup.string().required('Genre name is required'),
})

const Genres = () => {
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const genreCloseRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const basic = useSelector(basicSelector)

  useEffect(() => {
    dispatch(getGenres({}))
  }, [])

  let genreData = basic.genres

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Genre Name', field: 'contact', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const genresData = useMemo(() => {
    let computedTenants: any = genreData

    if (search) {
      computedTenants = computedTenants.filter(
        (user: {
          firstName: string
          lastName: string
          phoneNumber: string
          email: string
        }) =>
          user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          user.lastName.toLowerCase().includes(search.toLowerCase()) ||
          user.phoneNumber.toLowerCase().includes(search.toLowerCase()) ||
          user.email.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedTenants.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedTenants.slice(firstPageIndex, lastPageIndex)
  }, [genreData, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
  })

  const clearInputs = async () => {
    // setTimeout(() => innerModalClear(), 100)
    // const innerModalClear = () => {
    setModalOpenType('add')
    initialValues.name = ''
    // }
  }
  const setSelectedTenant = async (value: any) => {
    await clearInputs()
    setModalOpenType('edit')
    initialValues.name = value.name
    setSelectedTenantId(value._id)
  }

  const handleSubmit = async (value: { name: string }, resetForm: any) => {
    baseAxios.post(apiEndPoints.cms.genres, value).then((resp: any) => {
      dispatch(getGenres({}))
      genreData = basic.genres
      genreCloseRef.current?.click()
      resetForm()
    })
  }

  const handleUpdate = async (
    value: { name: string },
    genreId: string,
    resetForm: any,
  ) => {
    baseAxios
      .put(apiEndPoints.cms.genresWithId(genreId), value)
      .then((res: any) => {
        dispatch(getGenres({}))
        genreData = basic.genres
        genreCloseRef.current?.click()
        resetForm()
      })
  }

  const handleDelete = (value: string) => {
    baseAxios.delete(apiEndPoints.cms.genresWithId(value)).then((res: any) => {
      dispatch(getGenres({}))
      genreData = basic.genres
    })
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Genre Lists</h4>
                  </div>
                  <div className="sign-info">
                    <a
                      data-toggle="modal"
                      data-target="#ModalCenter"
                      className="btn btn-primary"
                      onClick={() => clearInputs()}
                    >
                      {modalOpenType == 'add' ? 'Add Genre' : 'Edit Genre'}
                    </a>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                      handleSearchValue={(searchVal: any) =>
                        setSearch(searchVal)
                      }
                    />
                    <table
                      className="data-tables table movie_table"
                      style={{ width: '100%' }}
                    >
                      <TableHeader
                        headers={headers}
                        onSorting={(field: any, order: any) =>
                          setSorting({ field, order })
                        }
                      />
                      <tbody>
                        {genresData.map((genre: any, index: number) => (
                          <tr key={genre.name}>
                            <td>{index + 1}</td>
                            <td>{genre.name}</td>
                            <td>
                              <div className="flex align-items-center list-user-action">
                                <a
                                  className="iq-bg-success"
                                  data-placement="top"
                                  title=""
                                  data-toggle="modal"
                                  data-target="#ModalCenter"
                                  data-original-title="Edit"
                                  onClick={() => setSelectedTenant(genre)}
                                >
                                  <i className="ri-pencil-line" />
                                </a>

                                <a
                                  className="iq-bg-primary"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Delete"
                                  onClick={() => handleDelete(genre._id)}
                                >
                                  <i className="ri-delete-bin-line" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={createTenantSchema}
            onSubmit={(values: { name: string }, { resetForm }: any) => {
              modalOpenType == 'add'
                ? handleSubmit(values, resetForm)
                : handleUpdate(values, selectedTenantId, resetForm)
            }}
          >
            {(formik: any) => {
              const { errors, touched } = formik
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add' ? 'Add Genre' : 'Edit Genre'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={genreCloseRef}
                      onClick={() => {
                        setModalOpenType('add')
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control  mb-0"
                                placeholder="Enter Genre Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                          <button type="submit" className="btn btn-primary">
                            {modalOpenType == 'add' ? 'Submit' : 'Update'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default Genres
