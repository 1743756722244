import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createVideos,
  deleteVideos,
  getVideos,
  updateVideos,
  getVideoById,
} from './slice'

function* getAllVideos(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.cms.videos)
    yield put(actions.setVideos(response))
  } catch (err) {
    console.log(err)
  }
}
function* getVideosById({ payload }: ReturnType<typeof getVideoById>): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.videoWithId(payload.videoId),
    )
    yield put(actions.setVideo(response))
  } catch (err) {
    console.log(err)
  }
}

function* deleteVideo({ payload }: ReturnType<typeof deleteVideos>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.cms.videoWithId(payload.videoId))
    toast.success(message.SINGLE_VIDEO_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateVideo({ payload }: ReturnType<typeof updateVideos>): any {
  try {
    yield call(baseAxios.put, apiEndPoints.cms.videoWithId(payload.videoId))
  } catch (err) {
    console.log(err)
  }
}

function* createVideo() {
  try {
    yield call(baseAxios.post, apiEndPoints.cms.videos)
  } catch (err) {
    console.log(err)
  }
}

export function* videoSaga(): any {
  yield all([yield takeLatest(getVideos, getAllVideos)])
  yield all([yield takeLatest(getVideoById, getVideosById)])
  yield all([yield takeLatest(deleteVideos, deleteVideo)])
  yield all([yield takeLatest(updateVideos, updateVideo)])
  yield all([yield takeLatest(createVideos, createVideo)])
}
