import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { actions, getVideoPlaylist, getVideoPlaylistById } from './slice'

function* getAllVideoPlaylist(): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.cms.videoPlaylistList,
    )
    yield put(actions.setVideoPlaylists(response))
  } catch (err) {
    console.log(err)
  }
}

function* getVideoPlaylistWithVideoById({
  payload,
}: ReturnType<typeof getVideoPlaylistById>): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.videoPlaylistWithId(payload.videoPlaylistId),
    )
    yield put(actions.setVideoPlaylistData(response))
  } catch (err) {
    console.log(err)
  }
}

export function* videoPlaylistSaga(): any {
  yield all([yield takeLatest(getVideoPlaylist, getAllVideoPlaylist)])
  yield all([
    yield takeLatest(getVideoPlaylistById, getVideoPlaylistWithVideoById),
  ])
}
