import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectBannersState = (state: RootState) => state.Banner || initialState

export const bannerSelector = createSelector(
  [selectBannersState],
  Banner => Banner,
)
