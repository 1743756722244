import React from 'react'
import { Navigate } from 'react-router-dom'
import AccessDenied from 'components/AccessDenied'
import { PERMISSIONS } from 'model/auth'
import { getLoggedInUser } from 'utils/helpers/authUtils'

interface Props {
  component: React.ComponentType
  path?: string
  permissions: Array<PERMISSIONS>
  rootOnlyAccess?: boolean
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  permissions,
  rootOnlyAccess = false,
}) => {
  const isAuthenticated = getLoggedInUser()
  const userHasRequiredRole = isAuthenticated.roleId.permission.some(
    (data: any) => permissions.includes(data.name),
  )
  const rootAccess = isAuthenticated && isAuthenticated.tenant.isRoot
  if (isAuthenticated && permissions.length > 0 && userHasRequiredRole) {
    if (rootOnlyAccess) {
      if (rootAccess) {
        return <RouteComponent />
      } else {
        return <AccessDenied />
      }
    }
    return <RouteComponent />
  } else if (isAuthenticated) {
    return <RouteComponent />
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <AccessDenied />
  }

  return <Navigate to="/login" />
}
