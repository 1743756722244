import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VideoPlaylist } from './types'

// eslint-disable-next-line
const getVideoPlaylist = createAction<any>('cms/getVideoPlaylist')
const getVideoPlaylistById = createAction<any>('cms/getVideoPlaylistById')

export const initialState: VideoPlaylist = {
  videoPlaylists: [],
  videoPlaylistData: {},
}

const VideoPlaylistSlice = createSlice({
  name: 'VideoPlaylist',
  initialState: initialState,
  reducers: {
    setVideoPlaylists(state, action: PayloadAction<any>) {
      state.videoPlaylists = action.payload
    },
    setVideoPlaylistData(state, action: PayloadAction<any>) {
      state.videoPlaylistData = action.payload
    },
  },
})

export { getVideoPlaylist, getVideoPlaylistById }

export const { actions, reducer, name: sliceKey } = VideoPlaylistSlice
