/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Pagination, TableHeader } from 'components/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import CustomHeader from 'components/CustomHeader'
import {
  formatWebCategories,
  formattedBannerTypes,
} from 'utils/helpers/systemHelpers'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import DropDown from 'components/DropDown'
import AddBanner from './AddBanner'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getCategories } from 'store/categoryManagement/slice'
import {
  changeBannerPublishStatus,
  createBannerForCategory,
  deleteBannerForCategory,
  getBannersByCategoryId,
  manageActiveCategory,
  manageActiveTab,
  manageModalOpenType,
  manageSelectedBanner,
  updateBannerForCategory,
} from 'store/bannnerManagement/slice'
import { categorySelector } from 'store/categoryManagement/selector'
import { bannerSelector } from 'store/bannnerManagement/selector'
import { MediaContentStatusType } from 'utils/enum'
import NoData from 'components/NoData/nodata'

const Banners = () => {
  const headers = [
    {},
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Image', field: 'image', sortable: false },
    { name: 'Order', field: 'order', sortable: false },
    { name: 'Type', field: 'type', sortable: false },
    { name: 'Status', field: 'type', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategories({}))
  }, [dispatch])

  const { webCategories } = useSelector(categorySelector)
  const { banners, activeCategory, modalOpenType, bannersLoading, activeTab } =
    useSelector(bannerSelector)

  useEffect(() => {
    // const category: any = webCategories?.find((e: any) => e.name === 'All')

    dispatch(manageActiveCategory({ label: 'All Banners', value: 'no_select' }))
    webCategories &&
      webCategories.length &&
      dispatch(getBannersByCategoryId({ type: 'no_select' }))
  }, [dispatch, webCategories])

  useEffect(() => {
    if (activeCategory?.value == 'no_select') {
      dispatch(getBannersByCategoryId({ type: 'no_select' }))
    } else {
      dispatch(getBannersByCategoryId({}))
    }
  }, [dispatch, activeTab, activeCategory])

  const reArrangeData = (e: any) => {
    if (!e.destination) return
    const tempData: any = Array.from(banners)
    const [source_data]: any = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
  }

  const onSubmit = (payload: any) => {
    payload.currentActiveTab = activeTab
    modalOpenType == 'edit'
      ? dispatch(updateBannerForCategory(payload))
      : dispatch(createBannerForCategory(payload))
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        dispatch(deleteBannerForCategory(value))
      }
    })
  }

  const changeWorkFlow = (bannerId: any, status: any) => {
    dispatch(changeBannerPublishStatus({ status, bannerId }))
  }

  return (
    <CustomHeader pageName={'Banner List'}>
      <AddBanner onSubmit={onSubmit} />
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <ul className="nav nav-tabs" id="myTab-two" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                onClick={() => {
                  dispatch(manageActiveTab(MediaContentStatusType.Draft))
                }}
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                In Review
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                onClick={() => {
                  dispatch(manageActiveTab(MediaContentStatusType.Approved))
                }}
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => {
                  dispatch(manageActiveTab(MediaContentStatusType.Publish))
                }}
                data-toggle="tab"
                role="tab"
                aria-controls="permission"
                aria-selected="false"
              >
                Published
              </a>
            </li>
          </ul>
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Banner Lists</h4>
                  </div>
                  <div className="sign-info">
                    <a
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#ModalCenter"
                    >
                      Add Banner
                    </a>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    {webCategories?.length && (
                      <DropDown
                        handleLengthChange={(activeCategory: any) => {
                          dispatch(getBannersByCategoryId({}))
                          dispatch(
                            manageActiveCategory(
                              formatWebCategories(webCategories).find(
                                (category: any) =>
                                  category.value == activeCategory?.value,
                              ) || null,
                            ),
                          )
                        }}
                        handleSearchValue={(searchVal: any) => {
                          return null
                        }}
                        searchValue={''}
                        activeCategory={activeCategory}
                        lengthOptions={formatWebCategories(webCategories)}
                        showSearchInput={false}
                      />
                    )}
                    {/* {bannersLoading && (
                      <div
                        className="container d-flex justify-content-center align-items-center"
                        style={{ height: '200px' }}
                      >
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#e20e02"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                    )} */}
                    {banners.length > 0 && (
                      <DragDropContext onDragEnd={reArrangeData}>
                        <table
                          className="data-tables table movie_table"
                          style={{ width: '100%' }}
                        >
                          <TableHeader
                            headers={
                              activeCategory?.value === 'no_select'
                                ? [
                                    ...headers.slice(0, 5),
                                    {
                                      name: 'Category',
                                      field: 'category',
                                      sortable: false,
                                    },
                                    ...headers.slice(5),
                                  ]
                                : headers
                            }
                          />

                          <Droppable droppableId="droppable-1">
                            {(provider: any) => (
                              <tbody
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                              >
                                {banners.map((banner: any, index: number) => (
                                  <Draggable
                                    key={banner.category}
                                    draggableId={banner.category}
                                    index={index}
                                  >
                                    {(provider: any) => (
                                      <tr
                                        key={`artist-${index}`}
                                        {...provider.draggableProps}
                                        ref={provider.innerRef}
                                      >
                                        <td {...provider.dragHandleProps}>
                                          {' '}
                                          ={' '}
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>
                                          <img
                                            src={banner.imageUrl}
                                            width={50}
                                            height={50}
                                          />
                                        </td>
                                        <td>{banner.order}</td>
                                        <td>
                                          {formattedBannerTypes(banner.type)}
                                        </td>
                                        {activeCategory?.value ===
                                          'no_select' && (
                                          <td>
                                            {webCategories[
                                              webCategories?.findIndex(
                                                (x: any) =>
                                                  x._id ===
                                                  banner?.webCategoryId,
                                              )
                                            ]?.name || '--'}
                                          </td>
                                        )}
                                        <td>
                                          {banner.status !=
                                          MediaContentStatusType.Publish ? (
                                            <button
                                              type="submit"
                                              className="btn btn-primary"
                                            >
                                              <span
                                                className="indicator-label"
                                                onClick={() =>
                                                  changeWorkFlow(
                                                    banner?._id,
                                                    banner?.status ===
                                                      MediaContentStatusType.Draft
                                                      ? MediaContentStatusType.Approved
                                                      : MediaContentStatusType.Publish,
                                                  )
                                                }
                                              >
                                                {banner.status ===
                                                  MediaContentStatusType.Draft &&
                                                !banner?.loading
                                                  ? 'Approve'
                                                  : banner.status ===
                                                      MediaContentStatusType.Approved &&
                                                    !banner?.loading
                                                  ? 'Publish'
                                                  : ''}
                                              </span>
                                              {banner?.loading && (
                                                <span className="indicator-progress">
                                                  Please wait...
                                                  <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                                </span>
                                              )}
                                            </button>
                                          ) : (
                                            <p>Live</p>
                                          )}
                                        </td>
                                        <td>
                                          <div className="flex align-items-center list-user-action">
                                            <a
                                              className="iq-bg-success"
                                              data-placement="top"
                                              title=""
                                              data-toggle="modal"
                                              data-target="#ModalCenter"
                                              data-original-title="Edit"
                                              onClick={() => {
                                                dispatch(
                                                  manageModalOpenType('edit'),
                                                )
                                                dispatch(
                                                  manageSelectedBanner(banner),
                                                )
                                              }}
                                            >
                                              <i className="ri-pencil-line" />
                                            </a>

                                            <a
                                              className="iq-bg-primary"
                                              data-toggle="tooltip"
                                              data-original-title="Delete"
                                              onClick={() =>
                                                handleDelete(banner?._id)
                                              }
                                            >
                                              <i className="ri-delete-bin-line" />
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                                {provider.placeholder}
                              </tbody>
                            )}
                          </Droppable>
                        </table>
                      </DragDropContext>
                    )}

                    {!bannersLoading &&
                      !banners?.length &&
                      activeCategory?.value != 'no_select' && <NoData />}

                    {/* {!pageLoading && !dummyData.length && <NoData />}
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddBanner />
    </CustomHeader>
  )
}

export default Banners
