/* eslint-disable react-hooks/exhaustive-deps */
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import NoData from 'components/NoData/nodata'
import { PERMISSIONS } from 'model/auth'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAuthors } from 'store/magazineManagement/slice'
import { MediaContentStatusType } from 'utils/enum'
import { getPermission } from 'utils/helpers/systemHelpers'

const Published = (props: any) => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const dispatch = useDispatch()
  // const magazine = useSelector(magazineSelector)
  const [magazine, setMagazine]: any = useState(null)

  const magazineData = props.data

  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.magazineByStatus(MediaContentStatusType.Publish),
    )
    if (response) {
      setMagazine(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [magazineData])

  useEffect(() => {
    dispatch(getAuthors({}))
  }, [])
  // const magazineData = magazine.magazines

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Type', field: 'type', sortable: false },
    { name: 'Release Date', field: 'release_date', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const magazinesData = useMemo(() => {
    const tempDataDraft: any = []
    magazine &&
      magazine.length > 0 &&
      magazine.map((videoPo: any) => {
        tempDataDraft.push(videoPo)
      })
    let computedMagazines: any = tempDataDraft

    if (search) {
      computedMagazines = computedMagazines.filter(
        (magazine: {
          name: string
          description: string
          //   rating: string
        }) =>
          magazine.name.toLowerCase().includes(search.toLowerCase()) ||
          magazine.description.toLowerCase().includes(search.toLowerCase()),
        //   magazine.rating.includes(search),
      )
    }

    setTotalItems(computedMagazines.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedMagazines.slice(firstPageIndex, lastPageIndex)
  }, [magazine, search, currentPage, pageSize])

  return (
    <div
      className="tab-pane fade active show"
      role="tabpanel"
      aria-labelledby="profile-tab-two"
      id="role-two"
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Published Magazines List</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="table-view">
                <TableLength
                  handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                  handleSearchValue={(searchVal: any) => setSearch(searchVal)}
                />
                {pageLoading && (
                  <div
                    className="container d-flex justify-content-center align-items-center"
                    style={{ height: '200px' }}
                  >
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#e20e02"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      visible={true}
                    />
                  </div>
                )}
                {!pageLoading && magazine && magazine.length > 0 && (
                  <table
                    className="data-tables table movie_table"
                    style={{ width: '100%' }}
                  >
                    <TableHeader
                      headers={headers}
                      onSorting={(field: any, order: any) =>
                        setSorting({ field, order })
                      }
                    />
                    <tbody>
                      {magazinesData.map((magazine: any, index: number) => (
                        <tr key={`published_magazine-${index}`}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={magazine.imageUrl}
                              width={70}
                              height={100}
                            />
                          </td>
                          <td>{magazine.name}</td>
                          <td>
                            {magazine.type.charAt(0).toUpperCase() +
                              magazine.type.slice(1)}
                          </td>
                          <td>
                            {moment(magazine.releaseDate).format('YYYY-MM-DD')}
                          </td>
                          <td>
                            <div className="flex align-items-center list-user-action">
                              {getPermission(PERMISSIONS.VIEW_MAGAZINE) && (
                                <Link
                                  className="iq-bg-warning"
                                  data-placement="top"
                                  to="/magazine/view"
                                  state={{ data: magazine }}
                                >
                                  <i className="ri-eye-line" />
                                </Link>
                              )}
                              {getPermission(PERMISSIONS.UPDATE_MAGAZINE) && (
                                <a
                                  className="iq-bg-success"
                                  data-placement="top"
                                  title=""
                                  data-toggle="modal"
                                  data-target="#AddMagazineModel"
                                  data-original-title="Edit"
                                  onClick={() =>
                                    props.handleUpdatePress(magazine)
                                  }
                                >
                                  <i className="ri-pencil-line" />
                                </a>
                              )}
                              {getPermission(PERMISSIONS.DELETE_MAGAZINE) && (
                                <a
                                  className="iq-bg-primary"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Delete"
                                  onClick={() =>
                                    props.handleDeletePress(magazine._id)
                                  }
                                >
                                  <i className="ri-delete-bin-line" />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {!pageLoading && magazine && magazine.length == 0 && <NoData />}
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalItems}
                  pageSize={pageSize}
                  onPageChange={(page: React.SetStateAction<number>) =>
                    setCurrentPage(page)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Published
