/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { userSelector } from 'store/userManagement/selectors'
import {
  adminCreateUser,
  adminDeleteUser,
  adminUpdateUser,
  getUsersList,
} from 'store/userManagement/slice'
import CustomHeader from '../../../components/CustomHeader'
import { getRoles, getTenants } from 'store/roleManagement/slice'
import { roleSelector } from 'store/roleManagement/selectors'
import {
  getIsRoot,
  getPermission,
  getTenantId,
} from 'utils/helpers/systemHelpers'
import { PERMISSIONS } from 'model/auth'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'

const createUserSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  firstName: Yup.string().required('FirstName is required'),
  lastName: Yup.string().required('LastName is required'),
  gender: Yup.string().required('Gender is required'),
  tenant: Yup.string().required('Tenant is required'),
  role: Yup.string().required('Role is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
})

const Users = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const dispatch = useDispatch()
  const [user, setUser]: any = useState(null)
  const role = useSelector(roleSelector)
  const userModalRef = useRef<HTMLButtonElement>(null)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedRole, setSelectedRole] = useState({})
  const [selectedTenant, setSelectedTenant] = useState({})
  const [loading, setLoading] = useState(false)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const [tenantID, setTenantID] = useState(getTenantId())

  const fetchData = async () => {
    setPageLoading(true)
    const response = await baseAxios.get(apiEndPoints.users.users)
    if (response) {
      setUser(response)
    }
    setPageLoading(false)
  }
  useEffect(() => {
    // dispatch(getUsersList({}))
    dispatch(getTenants({}))
    dispatch(getRoles({}))
    fetchData()
  }, [])

  // let userData = user.users
  const roleData = role.roles
  const tenants = role.tenants

  const tenantList: any = []
  const roleList: any = []

  const headers = [
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Contact', field: 'contact', sortable: false },
    { name: 'Email', field: 'email', sortable: false },
    { name: 'Tenent', field: 'tenent', sortable: false },
    { name: 'Role', field: 'role', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const usersData = useMemo(() => {
    const tempDataDraft: any = []
    user &&
      user.length > 0 &&
      user.map((videoPo: any) => {
        tempDataDraft.push(videoPo)
      })
    let computedUsers: any = tempDataDraft

    if (search) {
      computedUsers = computedUsers.filter(
        (user: {
          firstName: string
          lastName: string
          phoneNumber: string
          email: string
        }) =>
          user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          user.lastName.toLowerCase().includes(search.toLowerCase()) ||
          user.phoneNumber.toLowerCase().includes(search.toLowerCase()) ||
          user.email.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedUsers.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedUsers.slice(firstPageIndex, lastPageIndex)
  }, [user, search, currentPage, pageSize])

  const [initialValues] = useState({
    email: '',
    tenant: tenantID,
    role: '',
    phoneNumber: '',
    gender: '',
    firstName: '',
    lastName: '',
  })

  const handleSubmit = (
    value: {
      email: string
      firstName: string
      lastName: string
      gender: string
      phoneNumber: string
      role: string
      tenant: string
    },
    resetForm: any,
  ) => {
    //Enable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Disable indicator
    setLoading(true)
    const createUserData = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      gender: value.gender,
      phoneNumber: value.phoneNumber,
      tenant: value.tenant,
      roleId: value.role,
    }
    baseAxios
      .post(apiEndPoints.users.adminUser, createUserData)
      .then(async resp => {
        await fetchData()
        //Enable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoading(false)
        // dispatch(getUsersList({}))
        // userData = user.users
        userModalRef.current?.click()
        resetForm()
      })
      .catch(err => {
        //Enable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoading(false)
      })
    // dispatch(adminCreateUser({ data: createUserData }))
    // dispatch(getUsersList({}))
  }

  roleData.forEach((roleItem: any) => {
    const roleTempPush: object = { value: roleItem._id, label: roleItem.name }
    roleList.push(roleTempPush)
  })

  // tenantData.forEach((tenantItem: any) => {
  //   const tenantTempPush: object = {
  //     value: tenantItem._id,
  //     label: tenantItem.name,
  //   }
  //   tenantList.push(tenantTempPush)
  // })

  const handleDeleteUser = (email: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.users.adminUser, { data: { email: email } })
            .then(async resp => {
              await fetchData()
              // dispatch(getUsersList({}))
              // userData = user.users
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  // const handleDeleteUser = (email: string) => {
  //   dispatch(adminDeleteUser({ data: { data: { email: email } } }))
  //   dispatch(getUsersList({}))
  //   userData = user.users
  // }

  const handleUpdateUser = async (value: {
    email: string
    firstName: string
    lastName: string
    gender: string
    phoneNumber: string
    role: string
    tenant: string
  }) => {
    //Enable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Disable indicator
    setLoading(true)
    const updateUserData = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      gender: value.gender,
      phoneNumber: value.phoneNumber,
      tenant: value.tenant,
      roleId: value.role,
    }
    const response = await baseAxios.put(
      apiEndPoints.users.updateUser,
      updateUserData,
    )
    if (response) {
      await fetchData()
    }
    //Enable button
    submitButtonRef.current?.removeAttribute('disabled')
    // Disable indicator
    setLoading(false)
    userModalRef.current?.click()
    toast.success('User updated successfully! ')
  }

  const setSelectedUser = async (value: any) => {
    await clearModal()

    initialValues.email = value.email
    initialValues.firstName = value.firstName
    initialValues.lastName = value.lastName
    initialValues.gender = value.gender
    initialValues.phoneNumber = value.phoneNumber
    initialValues.role = value.roleId._id
    initialValues.tenant = value.tenant._id
    setModalOpenType('edit')
    setSelectedRole({ label: value.roleId.name, value: value.roleId._id })
    setSelectedTenant({ label: value.tenant.name, value: value.tenant._id })
  }

  const clearModal = async () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {

    initialValues.email = ''
    initialValues.firstName = ''
    initialValues.lastName = ''
    initialValues.gender = ''
    initialValues.phoneNumber = ''
    initialValues.role = ''
    initialValues.tenant = tenantID
    setModalOpenType('add')
    setSelectedRole({})
    setSelectedTenant({})
    // }
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">User Lists</h4>
                  </div>
                  {getPermission(PERMISSIONS.CREATE_USER) && (
                    <div className="sign-info">
                      <a
                        data-toggle="modal"
                        data-target="#ModalCenter"
                        className="btn btn-primary"
                        onClick={() => clearModal()}
                      >
                        Add User
                      </a>
                    </div>
                  )}
                </div>
                <div className="iq-card-body">
                  <div className="table-view" style={{ overflowX: 'scroll' }}>
                    <TableLength
                      handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                      handleSearchValue={(searchVal: any) =>
                        setSearch(searchVal)
                      }
                    />
                    {pageLoading && (
                      <div
                        className="container d-flex justify-content-center align-items-center"
                        style={{ height: '200px' }}
                      >
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#e20e02"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                    )}
                    {!pageLoading && user && user.length > 0 && (
                      <table
                        className="data-tables table movie_table"
                        style={{ width: '100%' }}
                      >
                        <TableHeader
                          headers={headers}
                          onSorting={(field: any, order: any) =>
                            setSorting({ field, order })
                          }
                        />
                        <tbody>
                          {usersData.map((user: any, index: any) => (
                            <tr key={`user-${index}`}>
                              <td>
                                {user.firstName} {user.lastName}
                              </td>
                              <td>{user.phoneNumber}</td>
                              <td>{user.email}</td>
                              <td>{user.tenant.name}</td>
                              <td>{user.roleName}</td>
                              <td>
                                <span className="badge iq-bg-success">
                                  {user.userStatus}
                                </span>
                              </td>
                              <td>
                                <div className="flex align-items-center list-user-action">
                                  {getPermission(PERMISSIONS.UPDATE_USER) && (
                                    <a
                                      className="iq-bg-success"
                                      data-toggle="modal"
                                      data-target="#ModalCenter"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Edit"
                                      onClick={() => setSelectedUser(user)}
                                    >
                                      <i className="ri-pencil-line" />
                                    </a>
                                  )}
                                  {getPermission(PERMISSIONS.DELETE_USER) && (
                                    <a
                                      className="iq-bg-primary"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Delete"
                                      onClick={() =>
                                        handleDeleteUser(user.email)
                                      }
                                    >
                                      <i className="ri-delete-bin-line" />
                                    </a>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {!pageLoading && user && user.length == 0 && <NoData />}
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={createUserSchema}
            onSubmit={(
              values: {
                email: string
                firstName: string
                lastName: string
                gender: string
                phoneNumber: string
                role: string
                tenant: string
              },
              { resetForm }: any,
            ) => {
              {
                modalOpenType == 'add'
                  ? handleSubmit(values, resetForm)
                  : handleUpdateUser(values)
              }
            }}
          >
            {(formik: any) => {
              const { errors, touched } = formik
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add' ? 'Add Users' : 'Edit Users'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={userModalRef}
                      onClick={() => formik.resetForm()}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <Field
                                type="text"
                                name="firstName"
                                id="firstName"
                                className="form-control  mb-0"
                                placeholder="Enter FirstName"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                type="text"
                                name="lastName"
                                id="lastName"
                                className="form-control  mb-0"
                                placeholder="Enter LastName"
                              />
                              <ErrorMessage
                                name="lastName"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="email"
                                id="email"
                                className="form-control  mb-0"
                                placeholder="Enter Email"
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                as="select"
                                name="gender"
                                className="form-control season-select"
                              >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </Field>
                              <ErrorMessage
                                name="gender"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                className="form-control  mb-0"
                                placeholder="Enter Phone Number"
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              {/* <Field
                                name="role"
                                id="role"
                                className="form-control season-select"
                                component={SelectBox}
                                options={roleList}
                                placeholder={'Select Role'}
                                isMulti={false}
                              /> */}
                              <Field
                                as="select"
                                name="role"
                                className="form-control season-select"
                              >
                                <option value="">Select Role</option>
                                {roleList.map((role: any, index: any) => {
                                  return (
                                    <option
                                      value={role.value}
                                      key={`role-${index}`}
                                    >
                                      {role.label}
                                    </option>
                                  )
                                })}
                              </Field>
                              <ErrorMessage
                                name="role"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            {getIsRoot() && (
                              <div className="col-md-12 form-group mt-1">
                                {/* <Field
                                name="tenant"
                                id="tenant"
                                className="form-control season-select"
                                component={SelectBox}
                                options={tenantList}
                                placeholder={'Select Tenant'}
                                // value={selectedTenant}
                                isMulti={false}
                              /> */}
                                <Field
                                  as="select"
                                  name="tenant"
                                  className="form-control season-select"
                                >
                                  <option value="">Select Tenant</option>
                                  {tenants.length > 0 &&
                                    tenants.map((tenant: any) => {
                                      return (
                                        <option
                                          value={tenant._id}
                                          key={tenant._id}
                                        >
                                          {tenant.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="tenant"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="sign-info">
                        <button type="submit" className="btn btn-primary">
                          continue
                        </button>
                      </div> */}
                      <div className="sign-info">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default Users
