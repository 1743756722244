/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import TableLength from 'components/DataTable/TableLength'
import InfiniteScroll from 'react-infinite-scroll-component'
import CustomHeader from 'components/CustomHeader'
import { TableHeader } from 'components/DataTable'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import { City, Country, State } from 'country-state-city'
import NoData from 'components/NoData/nodata'
import {
  createJobs,
  deleteJobs,
  getJobs,
  searchJobs,
  updateJobs,
} from 'store/careerManagement/slice'
import { careerSelector } from 'store/careerManagement/selector'
import { debounce, isEmpty } from 'lodash'
import moment, { duration } from 'moment'
import { Link } from 'react-router-dom'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { JobDurationType } from 'utils/enum'

const createJobSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  experience: Yup.string().required('Experience is required'),
  locationType: Yup.string().required('Work Location is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  jobType: Yup.string().required('Job Type is required'),
  durationType: Yup.string().optional(),
  duration: Yup.number().optional(),
})

const DEFAULT_LIMIT = 10

const Career = () => {
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedJobId, setSelectedJobId] = useState('')
  const modalCloseRef = useRef<any>(null)
  const dispatch = useDispatch()
  const [skip, setSkip] = useState(0)
  const [search, setSearch] = useState('')
  const [countries, setCountries]: any = useState([])
  const [states, setStates]: any = useState([])
  const [cities, setCities]: any = useState([])
  const [selectedCountry, setSelectedCountry]: any = useState(null)
  const [countryName, setCountryName]: any = useState(null)
  const [stateName, setStateName]: any = useState(null)
  const [cityName, setCityName]: any = useState(null)
  const [loading, setLoading] = useState(false)
  const formikRef = useRef<any>(null)
  const { jobs, jobsLoading, jobsSearchData } = useSelector(careerSelector)
  const [responsibilities, setResponsibilities] = useState('')
  const [responsibilitiesErrorMessage, setResponsibilitiesErrorMessage] =
    useState(false)
  const [qualification, setQualification] = useState('')
  const [qualificationErrorMessage, setQualificationErrorMessage] =
    useState(false)
  const [benefits, setBenefits] = useState('')
  const [benefitsErrorMessage, setBenefitsErrorMessage] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [initialValues] = useState({
    title: '',
    jobType: '',
    experience: '',
    role: '',
    duration: 0,
    durationType: '',
    description: '',
    locationType: '',
    country: '',
    state: '',
    city: '',
    responsibilities: '',
    qualification: '',
    benefits: '',
    additionalData: '',
  })

  const headers = [
    { name: 'Created Date', field: 'createdDate', sortable: false },
    { name: 'Title', field: 'title', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Location Type', field: 'location', sortable: false },
    { name: 'City', field: 'city', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  // Debounce the setSearch function
  const debouncedSetSearch = debounce((searchValue: string) => {
    setSearch(searchValue)
  }, 500) // You can adjust the debounce delay here (e.g., 500ms).

  useEffect(() => {
    const countries: any = Country.getAllCountries()
    setCountries(countries)
    if (search) {
      dispatch(
        searchJobs({
          skip: skip,
          limit: DEFAULT_LIMIT,
          searchValue: search,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search])

  useEffect(() => {
    dispatch(
      getJobs({
        skip,
        limit: DEFAULT_LIMIT,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const setSelectedJob = async (value: any) => {
    await clearModal()
    setModalOpenType('edit')

    // set initial values
    initialValues.title = value.title
    initialValues.jobType = value.jobType
    initialValues.role = value.role
    initialValues.experience = value.experience
    initialValues.duration = value.duration
    initialValues.durationType = value.durationType
    initialValues.description = value.description
    initialValues.locationType = value.locationType
    initialValues.responsibilities = value.responsibilities
    initialValues.qualification = value.qualification
    initialValues.benefits = value.benefits

    // set countries
    const allCountries = Country.getAllCountries()
    const currentCountryInfo = allCountries.find(e => e.name == value.country)
    initialValues.country = currentCountryInfo?.isoCode || ''
    setCountries(allCountries)
    setSelectedCountry(currentCountryInfo?.isoCode || '')
    setCountryName(value.country)

    // set states
    const currentCountryAllStates = State.getStatesOfCountry(
      initialValues.country,
    )
    const currentSateInfo = currentCountryAllStates.find(
      e => e.name == value.state,
    )
    initialValues.state = currentSateInfo?.isoCode || ''
    setStates(currentCountryAllStates)
    setStateName(value.state)

    // set cicties
    const currentCities = City.getCitiesOfState(
      currentCountryInfo?.isoCode || '',
      currentSateInfo?.isoCode || '',
    )
    initialValues.city = value.city
    setCities(currentCities)
    setCityName(value.city)

    setSelectedJobId(value._id)
    setQualification(value.qualification)
    setResponsibilities(value.responsibilities)
    setBenefits(value.benefits)
    setAdditionalInfo(value.additionalData || '')
  }

  const handleSubmit = async (values: any, resetForm: any) => {
    const JobPostData = { ...values, buttonRef: modalCloseRef }
    JobPostData.qualification = qualification
    JobPostData.responsibilities = responsibilities
    JobPostData.benefits = benefits
    JobPostData.additionalData = additionalInfo
    JobPostData.country = countryName
    JobPostData.city = cityName
    JobPostData.state = stateName

    // TODO: validate submitted fields
    dispatch(createJobs(JobPostData))
    toast.success('Job added successfully! ')
    resetForm()
  }

  const handleStateSelect = (event: any) => {
    const stateInfo = State.getStateByCodeAndCountry(
      event.target.value,
      selectedCountry,
    )
    const currentCities = City.getCitiesOfState(
      selectedCountry,
      stateInfo?.isoCode || '',
    )
    formikRef.current.setFieldValue('state', event.target.value)
    setStateName(stateInfo?.name)
    setCities(currentCities)
  }

  const handleCountrySelect = (event: any) => {
    const countryInfo = Country.getCountryByCode(event.target.value)
    const statesArr: any = State.getStatesOfCountry(event.target.value)
    formikRef.current.setFieldValue('country', event.target.value)
    setSelectedCountry(event.target.value)
    setStates(statesArr)
    setCountryName(countryInfo?.name)
  }

  const handleCityState = (event: any) => {
    formikRef.current.setFieldValue('city', event.target.value)
    setCityName(event.target.value)
  }

  const handleUpdate = async (values: any, jobId: string, resetForm: any) => {
    if (
      (values?.jobType === 'duration' || values?.jobType === 'contract') &&
      isEmpty(values?.durationType) &&
      (parseInt(values?.duration) <= 0 || isEmpty(duration))
    ) {
      toast.error('Please fill contract and duration details')
      return
    }
    const JobPutData: any = { ...values, buttonRef: modalCloseRef }
    JobPutData.qualification = qualification
    JobPutData.responsibilities = responsibilities
    JobPutData.benefits = benefits
    JobPutData.country = countryName
    JobPutData.city = cityName
    JobPutData.state = stateName
    JobPutData.jobId = jobId

    // TODO: validate submitted fields

    dispatch(updateJobs(JobPutData))
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        dispatch(deleteJobs(value))
      }
    })
  }

  const clearModal = async () => {
    initialValues.title = ''
    initialValues.jobType = ''
    initialValues.role = ''
    initialValues.experience = ''
    initialValues.duration = 0
    initialValues.durationType = ''
    initialValues.description = ''
    initialValues.locationType = ''
    initialValues.state = ''
    initialValues.country = ''
    initialValues.city = ''
    initialValues.responsibilities = ''
    initialValues.qualification = ''
    initialValues.benefits = ''
    initialValues.additionalData = ''
    initialValues.duration = 0
    initialValues.durationType = ''
    setSelectedJobId('')
    setModalOpenType('add')
    setSelectedCountry(null)
    setQualification('')
    setResponsibilities('')
    setBenefits('')
    setAdditionalInfo('')
  }

  const jobsDataToMap = !isEmpty(search) ? jobsSearchData : jobs

  return (
    <CustomHeader pageName={'Job List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Jobs List</h4>
                  </div>

                  <div className="sign-info">
                    <a
                      data-toggle="modal"
                      data-target="#ModalCenter"
                      className="btn btn-primary"
                      onClick={() => clearModal()}
                    >
                      Add Job
                    </a>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => {
                        return null
                      }}
                      handleSearchValue={(searchVal: any) =>
                        debouncedSetSearch(searchVal)
                      }
                      disableSelectBox={true}
                    />
                    {jobsLoading && (
                      <div
                        className="container d-flex justify-content-center align-items-center"
                        style={{ height: '200px' }}
                      >
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#e20e02"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                    )}
                    {!jobsLoading &&
                      jobsDataToMap &&
                      jobsDataToMap.length > 0 && (
                        <InfiniteScroll
                          dataLength={jobsDataToMap.length}
                          next={() => {
                            setSkip(skip + 10)
                            dispatch(
                              getJobs({
                                skip: skip + 10,
                                limit: DEFAULT_LIMIT,
                              }),
                            )
                          }}
                          hasMore={true} // Replace with a condition based on your data source
                          loader={
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{ marginLeft: '45%' }}
                              visible={jobsLoading}
                            />
                          }
                          // endMessage={<p>No more data to load.</p>}
                          scrollThreshold={1}
                        >
                          <table
                            className="data-tables table movie_table"
                            style={{ width: '100%' }}
                          >
                            <TableHeader headers={headers} />
                            <tbody>
                              {jobsDataToMap.map((job: any, index: number) => (
                                <tr key={`artist-${index}`}>
                                  {/* <td>{index + 1}</td> */}
                                  <td>
                                    {moment(job.createdDate).format(
                                      'DD-MM-YYYY',
                                    )}
                                  </td>
                                  <td>{job.title}</td>
                                  <td>{job.status}</td>
                                  <td>{job.locationType}</td>
                                  <td>{job.city}</td>
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      <Link
                                        className="iq-bg-warning"
                                        data-placement="top"
                                        to={`/jobs/view/${job?._id}`}
                                        state={{ data: job }}
                                      >
                                        <i className="ri-eye-line" />
                                      </Link>
                                      <a
                                        className="iq-bg-success"
                                        data-placement="top"
                                        title=""
                                        data-toggle="modal"
                                        data-target="#ModalCenter"
                                        data-original-title="Edit"
                                        onClick={() => setSelectedJob(job)}
                                      >
                                        <i className="ri-pencil-line" />
                                      </a>
                                      <a
                                        className="iq-bg-primary"
                                        data-toggle="tooltip"
                                        data-original-title="Delete"
                                        onClick={() => handleDelete(job._id)}
                                      >
                                        <i className="ri-delete-bin-line" />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      )}
                    {!jobsLoading && jobs.length == 0 && <NoData />}
                    {!jobsLoading &&
                      !jobsDataToMap.length &&
                      !isEmpty(search) && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <p>{`No Jobs found with the name ${search}`}</p>
                        </div>
                      )}
                    {/* <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={createJobSchema}
            onSubmit={(values: { name: string }, { resetForm }: any) => {
              modalOpenType == 'add'
                ? handleSubmit(values, resetForm)
                : handleUpdate(values, selectedJobId, resetForm)
            }}
            innerRef={formikRef}
          >
            {(formik: any) => {
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add' ? 'Add Job' : 'Edit Job'}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={modalCloseRef}
                      onClick={() => {
                        setModalOpenType('add')
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <Field
                              type="text"
                              name="title"
                              id="title"
                              className="form-control  mb-0"
                              placeholder="Enter Job Title"
                            />
                            <ErrorMessage
                              name="title"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="textarea"
                              id="text"
                              name="description"
                              rows={5}
                              className="form-control"
                              placeholder="Enter Job Description"
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              type="text"
                              name="role"
                              id="role"
                              className="form-control  mb-0"
                              placeholder="Enter Role: ex: Senior, Executive, Fresher, Intern, etc.."
                            />
                            <ErrorMessage
                              name="role"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              type="text"
                              name="experience"
                              id="experience"
                              className="form-control  mb-0"
                              placeholder="Enter Experience: 2+ years, 3-5 years"
                            />
                            <ErrorMessage
                              name="experience"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="select"
                              className="form-control"
                              name="locationType"
                            >
                              <option value="">Work Location Type</option>
                              <option value="remote">Remote</option>
                              <option value="office">Office</option>
                              <option value="hybrid">Hybrid</option>
                            </Field>
                            <ErrorMessage
                              name="locationType"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="country"
                                  className="form-control"
                                  onChange={(e: any) => handleCountrySelect(e)}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country: any) => {
                                    return (
                                      <option
                                        value={country.isoCode}
                                        key={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="country"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="state"
                                  className="form-control"
                                  onChange={(e: any) => handleStateSelect(e)}
                                >
                                  <option value="">Select State</option>
                                  {states.length > 0 &&
                                    states.map((state: any) => {
                                      return (
                                        <option
                                          value={state.isoCode}
                                          key={state.isoCode}
                                        >
                                          {state.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="state"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="city"
                                  className="form-control"
                                  onChange={(e: any) => handleCityState(e)}
                                >
                                  <option value="">Select City</option>
                                  {cities.map((city: any) => {
                                    return (
                                      <option
                                        value={city.name}
                                        key={
                                          city.name +
                                          city.stateCode +
                                          city.countryCode
                                        }
                                      >
                                        {city.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  className="form-control"
                                  name="jobType"
                                >
                                  <option value="">Job Type</option>
                                  <option value="permenant">Permenant</option>
                                  <option value="contract">Contract</option>
                                  <option value="duration">Duration</option>
                                </Field>
                                <ErrorMessage
                                  name="jobType"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                          {(formik?.values.jobType === 'contract' ||
                            formik?.values.jobType === 'duration') && (
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6 form-group">
                                  <Field
                                    as="select"
                                    className="form-control"
                                    name="durationType"
                                  >
                                    <option value="">Job Duration Type</option>
                                    <option value={JobDurationType.Day}>
                                      Day
                                    </option>
                                    <option value={JobDurationType.Week}>
                                      Week
                                    </option>
                                    <option value={JobDurationType.Month}>
                                      Month
                                    </option>
                                    <option value={JobDurationType.Year}>
                                      Year
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="durationType"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-6 form-group">
                                  <Field
                                    type="number"
                                    name="duration"
                                    className="form-control"
                                    placeholder="Enter duration"
                                  />

                                  <ErrorMessage
                                    name="duration"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <div className="form-group">
                            <Field
                              type="text"
                              name="city"
                              id="city"
                              className="form-control  mb-0"
                              placeholder="Enter City Name"
                            />
                            <ErrorMessage
                              name="title"
                              component="span"
                              className="text-primary"
                            />
                          </div> */}
                          {/* <div className="form-group">
                            <Field
                              as="select"
                              className="form-control"
                              name="jobType"
                            >
                              <option value="">Job Type</option>
                              <option value="permenant">Permenant</option>
                              <option value="contract">Contract</option>
                              <option value="duration">Duration</option>
                            </Field>
                            <ErrorMessage
                              name="jobType"
                              component="span"
                              className="text-primary"
                            />
                          </div> */}
                        </div>
                        <div className="col-md-6">
                          <p className="mt-2">
                            <b>Responsibilities</b>{' '}
                            <b style={{ color: 'red', marginLeft: '1%' }}>*</b>
                          </p>
                          <ReactQuill
                            theme="snow"
                            value={responsibilities}
                            onChange={setResponsibilities}
                          />
                          {/* TODO: write generic error handler for quill component */}
                          {responsibilitiesErrorMessage ? (
                            <p>Responsibilities is Required</p>
                          ) : (
                            <></>
                          )}
                          <p className="mt-2">
                            {' '}
                            <b>Qualification</b>
                            <b style={{ color: 'red', marginLeft: '1%' }}>*</b>
                          </p>
                          <ReactQuill
                            theme="snow"
                            value={qualification}
                            onChange={setQualification}
                          />
                          {/* TODO: write generic error handler for quill component */}
                          {qualificationErrorMessage ? (
                            <p>Qualifications is Required</p>
                          ) : (
                            <></>
                          )}
                          <p className="mt-2">
                            {' '}
                            <b>Benifits</b>
                            <b style={{ color: 'red', marginLeft: '1%' }}>*</b>
                          </p>
                          <ReactQuill
                            theme="snow"
                            value={benefits}
                            onChange={setBenefits}
                          />
                          {/* TODO: write generic error handler for quill component */}
                          {benefitsErrorMessage ? (
                            <p>Benefits is Required</p>
                          ) : (
                            <></>
                          )}
                          <p className="mt-2">
                            {' '}
                            <b>Additional Information</b>
                          </p>
                          <ReactQuill
                            theme="snow"
                            value={additionalInfo}
                            onChange={setAdditionalInfo}
                          />
                        </div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                          <button type="submit" className="btn btn-primary">
                            {!jobsLoading && (
                              <span className="indicator-label">
                                {modalOpenType == 'add' ? 'Submit' : 'Update'}
                              </span>
                            )}
                            {jobsLoading && (
                              <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default Career
