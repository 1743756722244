import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetUser } from 'store/userManagement/slice'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
})

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [initialValues] = useState({
    email: '',
  })

  const handleSubmit = (values: { email: string }, resetForm: any) => {
    dispatch(resetUser({ data: values, navigate }))
    resetForm()
  }
  return (
    <section className="sign-in-page">
      <div className="container h-100">
        <div className="row justify-content-center align-items-center height-self-center">
          <div className="col-md-6 col-sm-12 col-12 ">
            <div className="sign-user_card ">
              <div className="sign-in-page-data">
                <div className="sign-in-from w-100 m-auto">
                  <h3 className="mb-0">Reset Password</h3>
                  <p className="text-grey">
                    Enter your email address and we will send you an email with
                    instructions to reset your password.
                  </p>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={(
                      values: { email: string },
                      { resetForm }: any,
                    ) => {
                      handleSubmit(values, resetForm)
                    }}
                  >
                    {() => {
                      return (
                        <Form className="mt-4">
                          <div className="form-group">
                            <Field
                              type="email"
                              name="email"
                              id="email"
                              className="form-control  mb-0"
                              placeholder="Enter email"
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="sign-info">
                            <button type="submit" className="btn btn-primary">
                              Reset Password
                            </button>
                          </div>
                          <div className="mt-3">
                            <div className="d-flex justify-content-center links">
                              <Link to="/" className="f-link">
                                Back to login
                              </Link>
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ForgotPassword
