import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Video } from './types'

// eslint-disable-next-line
const getVideos = createAction<any>('cms/getVideos')
const getVideoById = createAction<any>('cms/getVideoById')
const deleteVideos = createAction<any>('cms/deleteVideos')
const updateVideos = createAction<any>('cms/updateVideos')
const createVideos = createAction<any>('cms/createVideos')

export const initialState: Video = {
  videos: [],
  video: {},
}

const VideoSlice = createSlice({
  name: 'Video',
  initialState: initialState,
  reducers: {
    setVideos(state, action: PayloadAction<any>) {
      state.videos = action.payload
    },
    setVideo(state, action: PayloadAction<any>) {
      state.video = action.payload
    },
  },
})

export { getVideos, deleteVideos, updateVideos, createVideos, getVideoById }

export const { actions, reducer, name: sliceKey } = VideoSlice
