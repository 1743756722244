import axios, { AxiosInstance } from 'axios'
import { config } from 'api/variables'
import { getLoggedInUser, logOutUser } from 'utils/helpers/authUtils'
import { toast } from 'react-toastify'
import storeInstance from 'store/configureStore'
import { actions } from 'store/common/slice'
import { ExpirationMessage } from 'utils/helpers/constants'

let authenticated = true

const baseAxios: AxiosInstance = axios.create({
  baseURL: `${config.baseUrl}`,
  timeout: 15000,
})

baseAxios.interceptors.request.use(
  function (request: any) {
    storeInstance.store.dispatch(
      actions.setNetworkCallRequestConfig({ loading: true }),
    )

    if (!authenticated) {
      // check authentication status before making API call
      return Promise.reject(new Error('User is not authenticated'))
    }

    if (getLoggedInUser()?.token) {
      request.headers['X-Api-Key'] = `${getLoggedInUser().token}`
    }
    return request
  },
  function (error) {
    console.log(error)
    throw error
  },
)

baseAxios.interceptors.response.use(
  function (response: any) {
    // File download
    if (response.data instanceof Blob) {
      storeInstance.store.dispatch(
        actions.setNetworkCallResponseConfig({ loading: false, error: null }),
      )
      return response
    }

    // Success response
    if (response.status === 200 || response.status === 201) {
      storeInstance.store.dispatch(
        actions.setNetworkCallResponseConfig({ loading: false, error: null }),
      )
      return response.data
    }

    // Failure response
    storeInstance.store.dispatch(
      actions.setNetworkCallResponseConfig({ loading: false, error: response }),
    )
    // toast.error(`${response.data.error}`)

    throw response
  },
  function (error) {
    const errorMessage: string =
      error?.response?.data?.error || error?.response?.data?.errorMessage
    // if (errorMessage) {
    if (error?.response?.data?.errorMeta?.code == 'NotAuthorizedException') {
      authenticated = false
      toast.error(error?.response.data?.errorMeta.message)
      if (
        error?.response.data?.errorMeta.message ===
          ExpirationMessage.AccessTokenExpired ||
        error?.response.data?.errorMeta.message ===
          ExpirationMessage.AccessTokenRevoked
      ) {
        logOutUser()
        window.location.replace('/')
      }
    } else {
      const errorMessages: string[] = []
      if (error?.response?.data?.errorMeta.length > 0) {
        error?.response?.data?.errorMeta?.forEach((er: any) => {
          errorMessages.push(`${er.message}`)
        })
      } else {
        errorMessages.push(error?.response?.data?.errorMessage)
      }
      if (errorMessages.length > 0) {
        toast.error(errorMessages.join(', '))
      } else {
        toast.error(errorMessages)
      }
    }
    // }
    storeInstance.store.dispatch(
      actions.setNetworkCallResponseConfig({
        loading: false,
        error: errorMessage,
      }),
    )
    throw error
  },
)

export { baseAxios }
