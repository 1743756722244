import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import {
  actions,
  getActiveUserAnalytics,
  getAudiosTrend,
  getContentAnalytics,
  getUserSignupsTrend,
  getVideosTrend,
} from './slice'

function* getAllActiveUserAnalytics(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.analytics.getActiveUsers,
    )
    yield put(actions.setActiveUsersAnalytics(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllContentAnalytics(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.analytics.getContentAnalytics,
    )
    yield put(actions.setContentAnalytics(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllUsersSignupTrendByYear(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.analytics.getUserSignupsTrend(),
    )
    yield put(actions.setUserSignupsTrend(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllVideoTrendByYear(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.analytics.getVideosTrend(),
    )
    yield put(actions.setVideosTrend(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllAudioTrendByYear(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.analytics.getAudiosTrend(),
    )
    yield put(actions.setAudiosTrend(response))
  } catch (err) {
    console.log(err)
  }
}

export function* dashboardSaga(): any {
  yield all([
    yield takeLatest(getActiveUserAnalytics, getAllActiveUserAnalytics),
    yield takeLatest(getContentAnalytics, getAllContentAnalytics),
    yield takeLatest(getUserSignupsTrend, getAllUsersSignupTrendByYear),
    yield takeLatest(getVideosTrend, getAllVideoTrendByYear),
    yield takeLatest(getAudiosTrend, getAllAudioTrendByYear),
  ])
}
