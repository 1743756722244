/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Pagination, TableHeader } from 'components/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import CustomHeader from 'components/CustomHeader'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import AddRadioShow from './AddRadioShow'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import InfiniteScroll from 'react-infinite-scroll-component'
import TableLength from 'components/DataTable/TableLength'
import {
  launchRadioShows,
  createRadioShows,
  deleteRadioShows,
  updateRadioShows,
  manageModalOpenType,
  manageSelectedRadioShow,
  searchRadioShows,
} from 'store/radioManagement/slice'
import NoData from 'components/NoData/nodata'
import { getHosts } from 'store/hostManagement/slice'
import { getRadioShows } from 'store/radioManagement/slice'
import { radioSelector } from 'store/radioManagement/selector'
import { hostSelector } from 'store/hostManagement/selector'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { RadioShowStatus } from 'utils/enum'
import { debounce, isEmpty } from 'lodash'

const RadioShow = () => {
  const [pageLoading, setPageLoading]: any = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState<any>('')
  const [skip, setSkip] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRadioShows({ skip }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    dispatch(getHosts({ skip: 0, limit: 200 }))
  }, [dispatch])

  const {
    modalOpenType,
    radioShows,
    radioShowsLoading,
    radioShowInfo,
    radioShowsSearchData,
  } = useSelector(radioSelector)

  const { hosts } = useSelector(hostSelector)

  // useEffect(() => {

  // }, [dispatch])
  // Debounce the setSearch function
  const debouncedSetSearch = debounce((searchValue: string) => {
    setSearch(searchValue)
  }, 500) // You can adjust the debounce delay here (e.g., 500ms).

  useEffect(() => {
    if (search) {
      dispatch(searchRadioShows({ skip: skip, limit: 10, searchValue: search }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search])

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Description', field: 'order', sortable: false },
    { name: 'Hosts', field: 'order', sortable: false },
    { name: 'Occurence', field: 'order', sortable: false },
    { name: 'Date', field: 'type', sortable: false },
    { name: 'Status', field: 'type', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const reArrangeData = (e: any) => {
    if (!e.destination) return
    const tempData: any = Array.from(radioShows)
    const [source_data]: any = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    // setDummyData(tempData)
  }

  const onSubmit = (payload: any) => {
    if (radioShowInfo) {
      payload.radioShowId = radioShowInfo._id
    }
    modalOpenType == 'edit'
      ? dispatch(updateRadioShows(payload))
      : dispatch(createRadioShows(payload))
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        dispatch(deleteRadioShows(value))
      }
    })
  }

  const launchRadioShow = (radioShowId: any) => {
    dispatch(launchRadioShows(radioShowId))
  }

  const getHostNames = (hostIds: any) => {
    const hostNames = hostIds.map((id: any) => {
      const item = hosts.find((element: any) => element._id === id)
      return item ? item.name : ''
    })

    return hostNames.length > 0 ? hostNames.join(', ') : '--'
  }

  const formatedDates = (
    startDateAndTime: any,
    endDateAndTime: any,
    weekDays: any,
  ) => {
    const startDate = moment(startDateAndTime, 'YYYY/MM/DD HH:mm')
    const endDate = moment(endDateAndTime, 'YYYY/MM/DD HH:mm')

    const formattedStartDate = startDate.format('MMMM Do YYYY hh:mm A')
    const formattedEndDate = endDate.format('MMMM Do YYYY hh:mm A')
    if (weekDays && weekDays?.length) {
      const weekdaysString = weekDays.join(', ')

      const result = `${formattedStartDate} to ${formattedEndDate}, Occurs every ${weekdaysString}`

      return result
    }
    return `${formattedStartDate} to ${formattedEndDate}`
  }

  const formattedOccurence = (occurrence: any) => {
    return occurrence === 'dont_repeat' ? 'Once' : occurrence
  }

  const radioShowsDataToMap = !isEmpty(search)
    ? radioShowsSearchData
    : radioShows

  return (
    <CustomHeader pageName={'Radio Show List'}>
      <AddRadioShow onSubmit={onSubmit} />
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Radio Show Lists</h4>
                  </div>
                  <div className="sign-info">
                    <a
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#ModalCenter"
                    >
                      Add Radio Show
                    </a>
                  </div>
                </div>
                {radioShowsLoading && (
                  <div
                    className="container d-flex justify-content-center align-items-center"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '200px',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      position: 'absolute',
                    }}
                  >
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#e20e02"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      visible={true}
                    />
                  </div>
                )}
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => {
                        return null
                      }}
                      handleSearchValue={(searchVal: any) =>
                        debouncedSetSearch(searchVal)
                      }
                      disableSelectBox={true}
                    />
                    {radioShowsDataToMap.length > 0 && (
                      <InfiniteScroll
                        dataLength={radioShowsDataToMap.length}
                        next={() => {
                          setSkip(skip + 10)
                          dispatch(getRadioShows({ skip: skip + 10 }))
                        }}
                        hasMore={true} // Replace with a condition based on your data source
                        loader={
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#e20e02"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{ marginLeft: '45%' }}
                            visible={radioShowsLoading}
                          />
                        }
                        // endMessage={<p>No more data to load.</p>}
                        scrollThreshold={1}
                      >
                        <table
                          className="data-tables table movie_table"
                          style={{ width: '100%' }}
                        >
                          <TableHeader headers={headers} />

                          <tbody>
                            {radioShowsDataToMap.map(
                              (radioShow: any, index: number) => (
                                <tr key={`artist-${index}`}>
                                  <td>{index + 1}</td>
                                  <td>{radioShow.name}</td>
                                  <td>{radioShow.description}</td>
                                  <td>
                                    {hosts.length && radioShow?.radioHostIds
                                      ? getHostNames(radioShow?.radioHostIds)
                                      : '--'}
                                  </td>
                                  <td
                                    style={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {formattedOccurence(radioShow.occurencType)}
                                  </td>
                                  <td>
                                    {formatedDates(
                                      radioShow.startDate +
                                        ' ' +
                                        radioShow.startTime,
                                      radioShow.endDate +
                                        ' ' +
                                        radioShow.endTime,
                                      radioShow.dayOccurences,
                                    )}
                                  </td>
                                  <td>
                                    {radioShow.status !=
                                      RadioShowStatus.Launched &&
                                    !radioShowsLoading ? (
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        <span
                                          className="indicator-label"
                                          onClick={() =>
                                            launchRadioShow(radioShow?._id)
                                          }
                                        >
                                          Launch
                                        </span>
                                      </button>
                                    ) : (
                                      <p>Live</p>
                                    )}
                                  </td>

                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      <Link
                                        className="iq-bg-warning"
                                        data-placement="top"
                                        to={`/radio-shows/view/${radioShow?._id}`} // state={{ data: album }}
                                      >
                                        <i className="ri-eye-line" />
                                      </Link>

                                      {radioShow?.status !=
                                        RadioShowStatus.Launched && (
                                        <a
                                          className="iq-bg-success"
                                          data-placement="top"
                                          title=""
                                          data-toggle="modal"
                                          data-target="#ModalCenter"
                                          data-original-title="Edit"
                                          onClick={() => {
                                            dispatch(
                                              manageModalOpenType('edit'),
                                            )
                                            dispatch(
                                              manageSelectedRadioShow(
                                                radioShow,
                                              ),
                                            )
                                          }}
                                        >
                                          <i className="ri-pencil-line" />
                                        </a>
                                      )}

                                      <a
                                        className="iq-bg-primary"
                                        data-toggle="tooltip"
                                        data-original-title="Delete"
                                        onClick={() =>
                                          handleDelete(radioShow?._id)
                                        }
                                      >
                                        <i className="ri-delete-bin-line" />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    )}
                    {!radioShowsLoading &&
                      !radioShowsDataToMap.length &&
                      !isEmpty(search) && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <p>{`No Radio Shows found with the name ${search}`}</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddRadioShow />
    </CustomHeader>
  )
}

export default RadioShow
