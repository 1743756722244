import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectCareerState = (state: RootState) => state.Career || initialState

export const careerSelector = createSelector(
  [selectCareerState],
  Career => Career,
)
