import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createRadioShows,
  updateRadioShows,
  deleteRadioShows,
  getRadioShows,
  launchRadioShows,
  manageSelectedRadioShow,
  manageModalOpenType,
  manageRadioShowErrorMessage,
  manageRadioShowLoading,
  getRadioEvents,
  getRadioShowInfo,
  searchRadioShows,
} from './slice'

function* getAllRadioShows({ payload }: any): any {
  try {
    yield put(actions.setRadioShowLoader(true))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.radio.getRadioShows(payload?.skip),
    )
    yield put(actions.setRadioShows({ data: response, skip: payload?.skip }))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(actions.setRadioShowLoader(false))
  }
}

function* getAllRadioEventsForShow({ payload }: any): any {
  try {
    yield put(actions.setRadioShowLoader(true))

    const response = yield call(
      baseAxios.get,
      apiEndPoints.radio.getRadioEvents(payload),
    )
    yield put(actions.setRadioEvents(response))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(actions.setRadioShowLoader(false))
  }
}

function* getRadioShowDetails({ payload }: any): any {
  try {
    yield put(actions.setRadioShowLoader(true))

    const response = yield call(
      baseAxios.get,
      apiEndPoints.radio.radioShowInfo(payload),
    )
    yield put(actions.setSelectedRadioShowInfo(response))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(actions.setRadioShowLoader(false))
  }
}

function* deleteRadioShow({
  payload,
}: ReturnType<typeof deleteRadioShows>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.radio.deleteRadioShow(payload))
    yield put(actions.setDeleteRadioShow(payload))
    toast.success(message.RADIO_SHOW_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateRadioShow({
  payload,
}: ReturnType<typeof updateRadioShows>): any {
  const buttonRef: any = payload.buttonRef

  try {
    delete payload.buttonRef
    yield put(actions.setRadioShowLoader(true))
    const response = yield call(
      baseAxios.put,
      apiEndPoints.radio.updateRadioShow(payload.radioShowId),
      payload,
    )
    yield put(actions.setUpdateRadioShow(response))
    buttonRef?.current?.click()
    yield put(actions.setRadioShowLoader(false))
  } catch (err) {
    yield put(actions.setRadioShowLoader(false))
    console.log(err)
  }
}

function* createRadioShow({ payload }: any): any {
  try {
    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    yield put(actions.setRadioShowLoader(true))
    const response = yield call(
      baseAxios.post,
      apiEndPoints.radio.createRadioShow,
      payload,
    )
    buttonRef?.current?.click()
    yield put(actions.setCreateRadioShow(response))
    yield put(actions.setRadioShowLoader(false))
  } catch (err) {
    yield put(actions.setRadioShowLoader(false))
    console.log(err)
  }
}

function* launchRadioShow({ payload }: any): any {
  try {
    yield put(actions.setRadioShowLoader(true))
    const response = yield call(
      baseAxios.patch,
      apiEndPoints.radio.launchRadioShow(payload),
    )
    yield put(actions.setUpdateRadioShow(response))
    yield put(actions.setRadioShowLoader(false))
  } catch (err) {
    yield put(actions.setRadioShowLoader(false))
    console.log(err)
  }
}

function* setSelectedRadioShowInfo({ payload }: any): any {
  try {
    yield put(actions.setSelectedRadioShowInfo(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setModalOpenType({ payload }: any): any {
  try {
    yield put(actions.setModalOpenType(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setRadioShowErrorMessages({ payload }: any): any {
  try {
    yield put(actions.setRadioShowErrorMessage(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setRadioShowLoading({ payload }: any): any {
  try {
    yield put(actions.setRadioShowLoader(payload))
  } catch (err) {
    console.log(err)
  }
}

function* searchRadioShow({ payload }: any): any {
  try {
    yield put(actions.setRadioShowLoader(true))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.radio.searchRadioShows(payload?.searchValue),
    )
    yield put(actions.setRadioShowLoader(false))
    yield put(actions.setRadioShowSearch(response))
  } catch (err) {
    yield put(actions.setRadioShowLoader(true))

    console.log(err)
  }
}

export function* radioSaga(): any {
  yield all([yield takeLatest(getRadioShows, getAllRadioShows)])
  yield all([yield takeLatest(deleteRadioShows, deleteRadioShow)])
  yield all([yield takeLatest(updateRadioShows, updateRadioShow)])
  yield all([yield takeLatest(createRadioShows, createRadioShow)])
  yield all([yield takeLatest(launchRadioShows, launchRadioShow)])
  yield all([yield takeLatest(manageModalOpenType, setModalOpenType)])
  yield all([
    yield takeLatest(manageRadioShowErrorMessage, setRadioShowErrorMessages),
  ])
  yield all([yield takeLatest(manageRadioShowLoading, setRadioShowLoading)])
  yield all([
    yield takeLatest(manageSelectedRadioShow, setSelectedRadioShowInfo),
  ])
  yield all([yield takeLatest(getRadioEvents, getAllRadioEventsForShow)])
  yield all([yield takeLatest(getRadioShowInfo, getRadioShowDetails)])
  yield all([yield takeLatest(searchRadioShows, searchRadioShow)])
}
