import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { logoutUser } from 'store/userManagement/slice'
import { getLoggedInUser } from 'utils/helpers/authUtils'
import Logo from './../../assets/images/logo.png'
import User from './../../assets/images/user/1.jpg'

type LayoutProps = {
  pageName: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Header = (props: LayoutProps) => {
  const dispatch = useDispatch()
  const [userDetail, setUserDetail]: any = useState(null)

  const handleSignOut = () => {
    const user = getLoggedInUser()
    dispatch(logoutUser({ data: user }))
  }

  useEffect(() => {
    const user = getLoggedInUser()
    setUserDetail(user)
  }, [])
  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="iq-menu-bt d-flex align-items-center">
            <div className="wrapper-menu">
              <div
                className="main-circle"
                onClick={() => document.body.classList.toggle('sidebar-main')}
              >
                <i className="las la-bars" />
              </div>
            </div>
            <div className="iq-navbar-logo d-flex justify-content-between">
              <Link to="/dashboard" className="header-logo">
                <img
                  src={Logo}
                  className="img-fluid rounded-normal"
                  alt="logo"
                />
                <div className="logo-title">
                  <span className="text-primary text-uppercase">
                    Swara Admin
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="iq-search-bar ml-auto">
            <form action="#" className="searchbox">
              <input
                type="text"
                className="text search-input"
                placeholder="Search Here..."
              />
              <Link className="search-link" to="#">
                <i className="ri-search-line" />
              </Link>
            </form>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
          >
            <i className="ri-menu-3-line" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto navbar-list">
              <li className="nav-item nav-icon search-content">
                <Link
                  to="#"
                  className="search-toggle iq-waves-effect text-gray rounded"
                >
                  <i className="ri-search-line" />
                </Link>
                <form action="#" className="search-box p-0">
                  <input
                    type="text"
                    className="text search-input"
                    placeholder="Type here to search..."
                  />
                  <Link className="search-link" to="#">
                    <i className="ri-search-line" />
                  </Link>
                </form>
              </li>
              <li className="nav-item nav-icon">
                <Link
                  to="#"
                  className="search-toggle iq-waves-effect text-gray rounded"
                >
                  <i className="ri-notification-2-line" />
                  <span className="bg-primary dots" />
                </Link>
                <div className="iq-sub-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white">
                          Reminders
                          <small className="badge  badge-light float-right pt-1">
                            4
                          </small>
                        </h5>
                      </div>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">
                              Your subscription about to expire
                            </h6>
                            <small className="float-right font-size-12">
                              Just Now
                            </small>
                            <p className="mb-0">95 MB</p>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">
                              Your climax is yet finish!!
                            </h6>
                            <small className="float-right font-size-12">
                              5 days ago
                            </small>
                            <p className="mb-0">Cyst Barry</p>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Will Arnab kill radhka?</h6>
                            <small className="float-right font-size-12">
                              2 days ago
                            </small>
                            <p className="mb-0">Cyst Barry</p>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">New Mail from Fenny</h6>
                            <small className="float-right font-size-12">
                              3 days ago
                            </small>
                            <p className="mb-0">Cyst Barry</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item nav-icon dropdown">
                <Link
                  to="#"
                  className="search-toggle iq-waves-effect text-gray rounded"
                >
                  <i className="ri-chat-poll-line" />
                  <span className="bg-primary dots" />
                </Link>
                <div className="iq-sub-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white">
                          All Notifications
                          <small className="badge  badge-light float-right pt-1">
                            5
                          </small>
                        </h5>
                      </div>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">New thriller is live</h6>
                            <small className="float-left font-size-12">
                              13 Jun
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">
                              Rajamauli blockbuster is here
                            </h6>
                            <small className="float-left font-size-12">
                              20 Apr
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Why do we use it?</h6>
                            <small className="float-left font-size-12">
                              30 Jun
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">
                              Variations are our speciality
                            </h6>
                            <small className="float-left font-size-12">
                              12 Sep
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div>
                            <img
                              className="avatar-40 rounded"
                              src={User}
                              alt="logo"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                            <small className="float-left font-size-12">
                              5 Dec
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="line-height pt-3">
                <Link
                  to="#"
                  className="search-toggle iq-waves-effect d-flex align-items-center"
                >
                  <img
                    src={User}
                    className="img-fluid rounded-circle mr-3"
                    alt="user"
                  />
                </Link>
                <div className="iq-sub-dropdown iq-user-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white line-height">
                          {userDetail
                            ? userDetail.firstName + userDetail.lastName
                            : ''}
                        </h5>
                        <span className="text-white font-size-12">
                          Available
                        </span>
                      </div>
                      <Link to="#" className="iq-sub-card iq-bg-primary-hover">
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-file-user-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">My Profile</h6>
                            <p className="mb-0 font-size-12">
                              View personal profile details.
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="/settings/editProfile"
                        className="iq-sub-card iq-bg-primary-hover"
                      >
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-profile-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Edit Profile</h6>
                            <p className="mb-0 font-size-12">
                              Modify your personal details.
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card iq-bg-primary-hover">
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-account-box-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Account settings</h6>
                            <p className="mb-0 font-size-12">
                              Manage your account parameters.
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card iq-bg-primary-hover">
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-lock-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Privacy Settings</h6>
                            <p className="mb-0 font-size-12">
                              Control your privacy parameters.
                            </p>
                          </div>
                        </div>
                      </Link>
                      <div className="d-inline-block w-100 text-center p-3">
                        <button
                          className="bg-primary iq-sign-btn"
                          role="button"
                          onClick={handleSignOut}
                        >
                          Sign out
                          <i className="ri-login-box-line ml-2" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
