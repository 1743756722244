import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createMagazines,
  deleteMagazines,
  getMagazines,
  updateMagazines,
  getAuthors,
  deleteAuthors,
  getAllMagazinesByStatus,
} from './slice'

function* getAllMagazines(): any {
  try {
    const response = yield call(baseAxios.post, apiEndPoints.cms.magazineList)
    yield put(actions.setMagazines(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllMagazinesByStatuses({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.magazineByStatus(payload.status),
    )
    yield put(actions.setMagazineOptions(response))
  } catch (err) {
    console.log(err)
  }
}

function* deleteMagazine({ payload }: ReturnType<typeof deleteMagazines>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.cms.magazineWithId(payload.magazineId),
    )
    toast.success(message.MAGAZINE_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateMagazine({ payload }: ReturnType<typeof updateMagazines>): any {
  try {
    yield call(
      baseAxios.put,
      apiEndPoints.cms.magazineWithId(payload.magazineId),
    )
  } catch (err) {
    console.log(err)
  }
}

function* createMagazine(): any {
  try {
    yield call(baseAxios.post, apiEndPoints.cms.magazine)
  } catch (err) {
    console.log(err)
  }
}

function* getAllAuthors(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.cms.authors())
    yield put(actions.setAuthors(response))
  } catch (err) {
    console.log(err)
  }
}

function* deleteAuthor({ payload }: ReturnType<typeof deleteAuthors>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.cms.authorsWithId(payload.authorId),
    )
    toast.success(message.AUTHOR_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

export function* magazineSaga(): any {
  yield all([yield takeLatest(getMagazines, getAllMagazines)])
  yield all([yield takeLatest(deleteMagazines, deleteMagazine)])
  yield all([yield takeLatest(updateMagazines, updateMagazine)])
  yield all([yield takeLatest(createMagazines, createMagazine)])
  yield all([yield takeLatest(getAuthors, getAllAuthors)])
  yield all([yield takeLatest(deleteAuthors, deleteAuthor)])
  yield all([
    yield takeLatest(getAllMagazinesByStatus, getAllMagazinesByStatuses),
  ])
}
