import React, { useEffect } from 'react'
import Chart from 'react-apexcharts'
import { Dna } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardSelector } from 'store/dashboardManagement/selector'
import { getAudiosTrend, getVideosTrend } from 'store/dashboardManagement/slice'

const TrendSeries = () => {
  const dispatch = useDispatch()
  const { videoTrend, audioTrend } = useSelector(dashboardSelector)
  const defaultMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  useEffect(() => {
    dispatch(getVideosTrend({}))
    dispatch(getAudiosTrend({}))
  }, [dispatch])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          <div className="iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header">
              <div className="iq-header-title">
                <h4 className="card-title text-center">Video Analytics</h4>
              </div>
            </div>
            {videoTrend && videoTrend.videoWatchTrend.length > 0 ? (
              <div className="iq-card-body pb-0">
                <Chart
                  options={{
                    chart: {
                      height: 350,
                      type: 'line',
                      zoom: {
                        enabled: false,
                      },
                      dropShadow: { color: 'red' },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    colors: ['#ff9966'],
                    xaxis: {
                      categories:
                        videoTrend && videoTrend.videoWatchTrend.length > 0
                          ? videoTrend.videoWatchTrend.map(e => e.month)
                          : defaultMonths,
                    },
                  }}
                  series={
                    videoTrend && videoTrend.videoWatchTrend.length > 0
                      ? [
                          {
                            name: 'Minutes of watch',
                            data: videoTrend.videoWatchTrend.map(e =>
                              Math.round(e.totalTimeInSec / 60),
                            ),
                          },
                        ]
                      : []
                  }
                  type={'area'}
                  width={500}
                />
              </div>
            ) : (
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{ marginLeft: '40%', marginTop: '20%' }}
                wrapperClass="dna-wrapper"
              />
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header">
              <div className="iq-header-title">
                <h4 className="card-title text-center">Audio Analytics</h4>
              </div>
            </div>
            {audioTrend && audioTrend.audioWatchTrend.length > 0 ? (
              <div className="iq-card-body pb-0">
                <Chart
                  options={{
                    chart: {
                      height: 350,
                      type: 'area',
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    colors: ['#ffcc66'],
                    xaxis: {
                      categories:
                        audioTrend && audioTrend.audioWatchTrend.length > 0
                          ? audioTrend.audioWatchTrend.map(e => e.month)
                          : defaultMonths,
                    },
                  }}
                  series={
                    audioTrend && audioTrend.audioWatchTrend.length > 0
                      ? [
                          {
                            name: 'Minutes of watch',
                            data: audioTrend.audioWatchTrend.map(e =>
                              Math.round(e.totalTimeInSec / 60),
                            ),
                          },
                        ]
                      : []
                  }
                  type={'area'}
                  width={500}
                />
              </div>
            ) : (
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{ marginLeft: '40%', marginTop: '20%' }}
                wrapperClass="dna-wrapper"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrendSeries
