import React from 'react'
import Select from 'react-select'
import Search from '../DataTable/Search'

const DropDown = (props: {
  handleLengthChange: any
  handleSearchValue: any
  lengthOptions: any
  searchValue: any
  activeCategory: any
  showSearchInput: any
}) => {
  const {
    handleLengthChange,
    handleSearchValue,
    lengthOptions,
    searchValue,
    activeCategory,
    showSearchInput = true,
  } = props
  return (
    <div className="row mb-4">
      <div className="col-sm-12 col-md-4 d-flex justify-content-start align-items-center">
        <div className="dataTables_length" id="new-cons_length">
          <label className="d-flex justify-content-start align-items-center gap-2 p-0 m-0">
            <Select
              options={lengthOptions}
              onChange={(data: any) => {
                handleLengthChange(data)
              }}
              value={{
                label: activeCategory?.label,
                value: activeCategory?.value,
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: '#fcaaa4',
                  primary: '#E20E02',
                },
              })}
            />
          </label>
        </div>
      </div>
      {showSearchInput && (
        <div className="col-md-8 d-flex justify-content-end align-items-center">
          <Search onSearch={handleSearchValue} searchValue={searchValue} />
        </div>
      )}
    </div>
  )
}

export default DropDown
