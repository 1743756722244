/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useState, useRef, useEffect } from 'react'
import CustomHeader from 'components/CustomHeader'
import { useLocation } from 'react-router-dom'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { useDispatch, useSelector } from 'react-redux'
import { artistSelector } from 'store/artistManagement/selector'
import { Country, State } from 'country-state-city'
import { toast } from 'react-toastify'
import 'react-h5-audio-player/src/styles.scss'
import { setUploadStatus } from 'store/basicManagement/slice'
import { Uploader } from 'utils/helpers/uploader'
import { basicSelector } from 'store/basicManagement/selector'
import { tmdbSelector } from 'store/tmdbManagement/selectors'
import { getCertification } from 'store/tmdbManagement/slice'
import { getVideoPlaylistById } from 'store/videoPlaylistManagement/slice'
import { videoPlaylistSelector } from 'store/videoPlaylistManagement/selectors'
import ReactPlayer from 'react-player'
import { matchYoutubeUrl } from 'utils/helpers/systemHelpers'
import { UploadStatusType } from 'utils/enum'

const episodeValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().optional(),
  geoConfig: Yup.object()
    .shape({
      restrict: Yup.string().optional(),
      longitude: Yup.string().optional(),
      radius: Yup.string().optional(),
      allowedGeoLocations: Yup.object()
        .shape({
          country: Yup.string().optional(),
          state: Yup.string().optional(),
        })
        .optional(),
    })
    .optional(),
  enableDrm: Yup.boolean().optional(),
  enableOffline: Yup.boolean().optional(),
  videoType: Yup.string().required('Select Type'),
})

const ViewVideoPlaylist = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [multiVideoData, setMultiVideoData] = useState(location.state.data)
  const [episodeModalOpenType, setEpisodeModalOpenType] = useState('add')
  const [videoList, setVideoList] = useState([])

  const inputFileSelect = useRef<HTMLInputElement>(null)
  const episodeModalRef = useRef<HTMLDivElement>(null)
  const episodeCloseRef = useRef<HTMLButtonElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const inputFileSelectVideo = useRef<HTMLInputElement>(null)

  const artist = useSelector(artistSelector)
  const videoPlaylist = useSelector(videoPlaylistSelector)
  const basic = useSelector(basicSelector)

  const [loading, setLoading] = useState(false)
  const [countries, setCountries]: any = useState([])
  const [states, setStates]: any = useState([])
  const [currentAudio, setCurrentAudio]: any = useState(null)
  const [uploadType, setUploadType] = useState('youtube')
  const [uploader, setUploader] = useState<any>(undefined)
  const [progress, setProgress] = useState(101)
  const [videoUrl, setVideoUrl]: any = useState(null)
  const [selectedVideoFile, setSelectedVideoFile]: any = useState(null)
  const [lyricistList, setLyricistList] = useState([])
  const [selectedVideoId, setSelectedVideoId] = useState('0')
  const [selectedCountry, setSelectedCountry]: any = useState(null)
  const [playing, setPlaying]: any = useState(false)

  const [initialValuesEpisode] = useState({
    name: '',
    description: '',
    geoConfig: {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    },
    enableDrm: false,
    enableOffline: false,
    videoType: '',
  })

  useEffect(() => {
    if (multiVideoData != null) {
      dispatch(
        getVideoPlaylistById({
          videoPlaylistId: multiVideoData._id,
        }),
      )
      const country: any = Country.getAllCountries()
      setCountries(country)
      dispatch(getCertification({}))
    }
  }, [multiVideoData])

  const videoPlaylistData = videoPlaylist.videoPlaylistData
  useEffect(() => {
    if (videoPlaylistData.videos && videoPlaylistData.videos.length > 0) {
      setVideoList(videoPlaylistData.videos)
    }
  }, [videoPlaylistData])

  const artistList = artist.artists
  let status = basic.uploadStatus

  useEffect(() => {
    const lyricistListArr: any = artistList.map((artist: any) => {
      return { value: artist._id, label: artist.name }
    })
    setLyricistList(lyricistListArr)
  }, [artistList])

  const handleSubmit = async (
    value: any,
    videoPlaylistId: string,
    resetForm: any,
  ) => {
    if (!submitButtonRef.current) {
      return
    }
    submitButtonRef.current?.setAttribute('disabled', 'true')
    const geoData: any = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: [
        {
          country: '',
          state: '',
        },
      ],
    }
    // Activate indicator
    setLoading(true)
    if (selectedCountry) {
      // value.geoConfig.allowedGeoLocations.country = selectedCountry
      geoData.allowedGeoLocations[0].country = selectedCountry
    }
    geoData.allowedGeoLocations[0].state =
      value.geoConfig.allowedGeoLocations.state
    const videoData = {
      title: value.name,
      description: value.description,
      videoUrl: selectedVideoFile
        ? `${apiEndPoints.s3.base}playlists/${selectedVideoFile.name}`
        : value.youtubeUrl,
      accessType: value.videoType,
      enableDrm: value.enableDrm,
      enableOffline: value.enableOffline,
      geoConfig: [geoData],
    }
    baseAxios
      .post(apiEndPoints.cms.videos, videoData)
      .then((resp: any) => {
        const videoPlaylistUpdateData = JSON.parse(
          JSON.stringify(videoPlaylistData),
        )
        const videoIds: any = []
        videoIds.push(resp._id)
        if (
          videoPlaylistUpdateData &&
          videoPlaylistUpdateData.videoIds &&
          videoPlaylistUpdateData.videoIds.length > 0
        )
          videoPlaylistUpdateData.videoIds.forEach((data: any) => {
            videoIds.push(data)
          })

        const episodeVideoData = {
          ...videoPlaylistUpdateData,
          videoIds: videoIds,
        }

        baseAxios
          .put(
            apiEndPoints.cms.videoPlaylistWithId(videoPlaylistId),
            episodeVideoData,
          )
          .then((singleResp: any) => {
            dispatch(
              getVideoPlaylistById({
                videoPlaylistId: multiVideoData._id,
              }),
            )
            if (selectedVideoFile) uploadFile(resp)
            submitButtonRef.current?.removeAttribute('disabled')
            setLoading(false)
            episodeCloseRef.current?.click()
            toast.success('Video added successfully! ')
          })
          .catch((err: any) => {
            submitButtonRef.current?.removeAttribute('disabled')
            // Disable indicator
            setLoading(false)
          })
      })
      .catch((err: any) => {
        submitButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoading(false)
      })
  }

  const handleUpdate = (
    value: any,
    videoPlaylistId: string,
    resetForm: any,
  ) => {
    if (!submitButtonRef.current) {
      return
    }
    submitButtonRef.current?.setAttribute('disabled', 'true')
    setLoading(true)
    const geoData: any = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: [
        {
          country: '',
          state: '',
        },
      ],
    }
    // Activate indicator
    setLoading(true)
    if (selectedCountry) {
      // value.geoConfig.allowedGeoLocations.country = selectedCountry
      geoData.allowedGeoLocations[0].country = selectedCountry
    }
    geoData.allowedGeoLocations[0].state =
      value.geoConfig.allowedGeoLocations.state
    const videoData = {
      title: value.name,
      description: value.description,
      videoUrl: selectedVideoFile
        ? `${apiEndPoints.s3.base}playlists/${selectedVideoFile.name}`
        : value.youtubeUrl
        ? value.youtubeUrl
        : videoUrl,
      accessType: value.videoType,
      enableDrm: value.enableDrm,
      enableOffline: value.enableOffline,
      geoConfig: [geoData],
      uploadStatus: selectedVideoFile
        ? UploadStatusType.Processing
        : value.youtubeUrl
        ? UploadStatusType.Completed
        : UploadStatusType.Completed,
    }
    baseAxios
      .put(apiEndPoints.cms.videoWithId(selectedVideoId), videoData)
      .then((updateResp: any) => {
        dispatch(
          getVideoPlaylistById({
            videoPlaylistId: multiVideoData._id,
          }),
        )
        resetForm()
        if (selectedVideoFile) uploadFile(updateResp)
        submitButtonRef.current?.removeAttribute('disabled')
        setLoading(false)
        episodeCloseRef.current?.click()
        toast.success('Video updated successfully! ')
      })
  }

  const openPicker = (type: string) => {
    if (type === 'thumbnail') {
      inputFileSelect.current?.click()
    } else {
      inputFileSelectVideo.current?.click()
    }
  }
  const handleCountrySelect = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event.target.value)
    // initialValuesEpisode.geoConfig.allowedGeoLocations.country =
    //   event.target.value
    setSelectedCountry(event.target.value)
    setStates(statesArr)
  }

  const handleCountrySelectSetValue = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event)
    setSelectedCountry(event)
    setStates(statesArr)
  }
  const handleVideoSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedVideoFile(file)
  }

  const onUploadChange = (e: any) => {
    setUploadType(e.currentTarget.value)
  }

  const deleteVideo = (videoId: string, index: number) => {
    const videoPlaylistUpdateData = JSON.parse(
      JSON.stringify(videoPlaylistData),
    )
    const videoIds: any = []
    if (
      videoPlaylistUpdateData &&
      videoPlaylistUpdateData.videoIds &&
      videoPlaylistUpdateData.videoIds.length > 0
    )
      videoPlaylistUpdateData.videoIds.forEach((data: any) => {
        videoIds.push(data)
      })
    const indexItem = videoIds.indexOf(videoId)

    if (indexItem > -1) {
      videoIds.splice(indexItem, 1)
    }

    const episodeVideoData = {
      ...videoPlaylistUpdateData,
      videoIds: videoIds,
    }
    baseAxios
      .put(
        apiEndPoints.cms.videoPlaylistWithId(videoPlaylistUpdateData._id),
        episodeVideoData,
      )
      .then((singleResp: any) => {
        baseAxios
          .delete(apiEndPoints.cms.videoWithId(videoId))
          .then((resp: any) => {
            dispatch(
              getVideoPlaylistById({
                videoPlaylistId: multiVideoData._id,
              }),
            )
            toast.success('Video deleted successfully! ')
          })
      })
      .catch((err: any) => {
        // Disable indicator
        setLoading(false)
      })
  }

  const uploadFile = async (data: any) => {
    dispatch(setUploadStatus({ id: data._id, status: 0 }))
    status = basic.uploadStatus
    if (selectedVideoFile) {
      let percentage: any = undefined

      const videoUploaderOptions = {
        fileName: `playlists/${selectedVideoFile.name}`,
        file: selectedVideoFile,
      }
      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)

      uploader
        .onProgress(async ({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setProgress(percentage)
            dispatch(setUploadStatus({ id: data._id, status: percentage }))
            status = basic.uploadStatus
            if (percentage == 100) {
              setTimeout(async () => {
                dispatch(setUploadStatus({ id: data._id, status: 100 }))
                status = basic.uploadStatus
                setProgress(101)
                await handleUploadStatus(data, UploadStatusType.Completed)
              }, 8000)
            }
          }
        })
        .onError(async (error: any) => {
          await handleUploadStatus(data, UploadStatusType.Failed)
          selectedVideoFile(undefined)
        })
      uploader.start()
    }
  }

  const handleUploadStatus = async (updateData: any, status: string) => {
    const data = Object.assign({}, updateData)
    data.uploadStatus = status
    await baseAxios.put(apiEndPoints.cms.videoWithId(updateData._id), data)
    // await fetchData()
    toast.success('Video Uploaded Successfully!')
  }

  const setSelectedVideoPlaylist = async (value: any, index: number) => {
    await clearModal()
    setEpisodeModalOpenType('edit')
    initialValuesEpisode.name = value.title
    initialValuesEpisode.description = value.description
    // initialValuesEpisode.geoConfig = value.geoConfig[0]
    initialValuesEpisode.enableDrm = value.enableDrm
    initialValuesEpisode.enableOffline = value.enableOffline
    // initialValuesEpisode. = value.videoUrl
    initialValuesEpisode.videoType = value.accessType

    if (
      value.geoConfig[0].allowedGeoLocations &&
      value.geoConfig[0].allowedGeoLocations.length > 0
    ) {
      initialValuesEpisode.geoConfig.allowedGeoLocations =
        value.geoConfig[0].allowedGeoLocations[0]
    }

    if (
      value.geoConfig[0] &&
      value.geoConfig[0].allowedGeoLocations &&
      value.geoConfig[0].allowedGeoLocations[0] &&
      value.geoConfig[0].allowedGeoLocations[0].country
    ) {
      handleCountrySelectSetValue(
        value.geoConfig[0].allowedGeoLocations[0].country,
      )
    }
    setVideoUrl(value.videoUrl)
    setSelectedVideoId(value._id)
    if (matchYoutubeUrl(value.videoUrl)) {
      setUploadType('youtube')
    } else {
      setUploadType('upload')
    }
  }

  const clearModal = async () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    setEpisodeModalOpenType('add')
    initialValuesEpisode.name = ''
    initialValuesEpisode.description = ''
    initialValuesEpisode.geoConfig = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    }
    initialValuesEpisode.enableDrm = false
    initialValuesEpisode.enableOffline = false
    // initialValuesEpisode. = value.videoUrl
    initialValuesEpisode.videoType = ''
    setVideoUrl(null)
    setSelectedVideoId('')
    clearFieldOnClose()
    // }
  }

  const clearFieldOnClose = () => {
    if (inputFileSelectVideo.current) {
      inputFileSelectVideo.current.value = ''
      inputFileSelectVideo.current.files = null
    }
    setSelectedVideoFile(null)
  }

  const closeModal = () => {
    setPlaying(false)
    // playerModalRef.current?
  }

  return (
    <CustomHeader pageName={'View Album'}>
      <div id="content-page" className="content-page ">
        <div className="container-fluid">
          <div className="row d-flex flex-column px-3 pb-3">
            <h3>{videoPlaylistData.name}</h3>
            <span className="fs-5 mb-8">{videoPlaylistData.description}</span>
          </div>
          <div className="row">
            <div className="col-sm-4 mb-4">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: '500px',
                  width: '100%',
                  // display: 'wrap',
                  // flexWrap: 'wrap',
                }}
              >
                {' '}
                <img
                  src={videoPlaylistData.imageUrl}
                  className=""
                  alt="author-profile"
                  style={{
                    height: '100%',
                    width: '100%',
                    // display: 'wrap',
                    // flexWrap: 'wrap',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    // background: 'yellow',
                    height: '500px',
                    width: '92%',
                    position: 'absolute',
                    alignItems: 'end',
                    justifyContent: 'center',
                    top: 0,
                  }}
                ></div>
              </div>
              <div className="NewPlayer"> </div>
            </div>

            <div className="col-sm-8">
              {' '}
              <div style={{ cursor: 'pointer' }}>
                <a
                  data-toggle="modal"
                  className="AudioTrack"
                  data-target="#AddEpisodeModel"
                  onClick={() => clearModal()}
                >
                  + Add New Video
                </a>
              </div>
              <div className="col-md-12 p-0">
                <div className="iq-accordion career-style faq-style mt-3">
                  <div className="iq-card mt-5">
                    <div className="pl-4 mt-8">
                      <h4 className="pt-3">Videos List</h4>
                      {/* <p>{audioTrack.description} </p> */}
                      <div className="iq-card-body">
                        {videoList.length > 0 &&
                          videoList.map((videoData: any, index: number) => {
                            return (
                              <div
                                className="table-view"
                                key={`playlist-${videoData._id}`}
                              >
                                <table
                                  className="data-tables table movie_table"
                                  style={{ width: '100%' }}
                                >
                                  <tbody>
                                    <tr>
                                      {/* <td>
                                          <img
                                            src={videoData.imageUrl}
                                            // className="img-fluid avatar-50"
                                            alt="author-profile"
                                            height={120}
                                            width={80}
                                          />
                                        </td> */}
                                      <td>{videoData.title}</td>
                                      <td>
                                        <div
                                          className="d-inline-block text-truncate"
                                          style={{ width: '180px' }}
                                        >
                                          {videoData.description}
                                        </div>
                                      </td>
                                      {videoData.uploadStatus &&
                                      videoData.uploadStatus ===
                                        UploadStatusType.Processing ? (
                                        <td>
                                          {status.some(
                                            (item: any) =>
                                              item.id === videoData._id,
                                          )
                                            ? status.find(
                                                (item: any) =>
                                                  item.id === videoData._id,
                                              )?.status + '%'
                                            : UploadStatusType.Interrupted}
                                        </td>
                                      ) : (
                                        <td>
                                          {videoData.uploadStatus
                                            ? videoData.uploadStatus.toUpperCase()
                                            : 'NA'}
                                        </td>
                                      )}
                                      <td>
                                        <div className="justify-content-end d-flex p-3  list-user-action">
                                          <a
                                            className="iq-bg-warning"
                                            data-toggle="modal"
                                            data-target="#VideoPlayer"
                                            onClick={() => {
                                              setCurrentAudio(
                                                videoData.videoUrl,
                                              )
                                            }}
                                          >
                                            <i className="fa-solid fa-circle-play"></i>
                                          </a>
                                          <a
                                            className="iq-bg-success"
                                            data-toggle="modal"
                                            data-target="#AddEpisodeModel"
                                            onClick={() =>
                                              setSelectedVideoPlaylist(
                                                videoData,
                                                index,
                                              )
                                            }
                                          >
                                            <i className="ri-pencil-line" />
                                          </a>
                                          <a
                                            className="iq-bg-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            data-original-title="Delete"
                                            onClick={() =>
                                              deleteVideo(videoData._id, index)
                                            }
                                          >
                                            <i className="ri-delete-bin-line" />
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddEpisodeModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
        ref={episodeModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <Formik
            initialValues={initialValuesEpisode}
            validationSchema={episodeValidationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                videoType: string
                sensorBoard: object
                geoConfig: any
              },
              { resetForm }: any,
            ) => {
              episodeModalOpenType == 'add'
                ? handleSubmit(values, multiVideoData._id, resetForm)
                : handleUpdate(values, multiVideoData._id, resetForm)
            }}
          >
            {(formik: any) => {
              const { errors, touched, values, setFieldValue } = formik
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {episodeModalOpenType == 'edit'
                        ? 'Edit Video'
                        : 'Add Video'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={episodeCloseRef}
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className=" form-group">
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="textarea"
                              id="text"
                              name="description"
                              rows={5}
                              className="form-control"
                              placeholder="Description"
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="row ">
                            <div className="col-md-12 form-group">
                              <label htmlFor="form_gallery-upload">
                                Access Type
                              </label>
                              <Field
                                as="select"
                                name="videoType"
                                className="form-control"
                              >
                                <option value="">Select Type</option>
                                <option value="free">Free</option>
                                <option value="pay_per_view">
                                  Pay Per View
                                </option>
                              </Field>
                              <ErrorMessage
                                name="videoType"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row m-0">
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableDrm"
                                  name="enableDrm"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableDrm"
                                >
                                  Enable DRM availability
                                </label>
                                <ErrorMessage
                                  name="enableDrm"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableOffline"
                                  name="enableOffline"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableOffline"
                                >
                                  Offline access
                                </label>
                                <ErrorMessage
                                  name="enableOffline"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>

                          <p className="mt-2">GEO CONFIG</p>

                          <div className="form-group custom-switch">
                            <Field
                              type="checkbox"
                              className="custom-control-input"
                              id="geoConfig.restrict"
                              name="geoConfig.restrict"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="geoConfig.restrict"
                            >
                              Allow geo configure
                            </label>
                          </div>

                          {/* {geoConfig == true && ( */}
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.country"
                                  className="form-control"
                                  onChange={(e: any) => handleCountrySelect(e)}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country: any) => {
                                    return (
                                      <option
                                        value={country.isoCode}
                                        key={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.country"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.state"
                                  className="form-control"
                                >
                                  <option value="">Select State</option>
                                  {states.length > 0 &&
                                    states.map((state: any) => {
                                      return (
                                        <option
                                          value={state.isoCode}
                                          key={state.isoCode}
                                        >
                                          {state.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.country"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                          <div className="form-group">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio6"
                                name="customRadio-1"
                                value="youtube"
                                className="custom-control-input"
                                checked={uploadType == 'youtube' ? true : false}
                                onChange={(e: any) => onUploadChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                Youtube Link
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio7"
                                name="customRadio-1"
                                value="upload"
                                checked={uploadType == 'upload' ? true : false}
                                className="custom-control-input"
                                onChange={(e: any) => onUploadChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio7"
                              >
                                Upload File
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio8"
                                name="customRadio-1"
                                value="AWS Media Tailor"
                                checked={
                                  uploadType == 'AWS Media Tailor'
                                    ? true
                                    : false
                                }
                                className="custom-control-input"
                                onChange={(e: any) => onUploadChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio8"
                              >
                                {' '}
                                AWS Media Tailor{' '}
                              </label>
                            </div>
                          </div>
                          {episodeModalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <a href={videoUrl}>Open Video</a>
                            </div>
                          )}
                          {uploadType == 'youtube' ||
                          uploadType == 'AWS Media Tailor' ? (
                            <div className="form-group">
                              <Field
                                type="text"
                                className="form-control"
                                placeholder={
                                  episodeModalOpenType == 'edit'
                                    ? uploadType == 'AWS Media Tailor'
                                      ? 'New AWS Mediatailor URL'
                                      : 'New Youtube URL'
                                    : uploadType == 'AWS Media Tailor'
                                    ? 'AWS Mediatailor URL'
                                    : 'Youtube Url'
                                }
                                name="youtubeUrl"
                              />
                              <ErrorMessage
                                name="youtubeUrl"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          ) : (
                            <div className="form_gallery form-group">
                              <label
                                onClick={() => openPicker('video')}
                                htmlFor="form_gallery-upload"
                              >
                                {selectedVideoFile != null
                                  ? selectedVideoFile.name
                                  : 'Video File'}
                              </label>
                              <input
                                type="file"
                                ref={inputFileSelectVideo}
                                name="videoFile"
                                onChange={e => handleVideoSelect(e)}
                                accept=".mp4, .mov, .mkv, .avi"
                              />
                              <ErrorMessage
                                name="videoFile"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
      <div
        className="modal fade "
        id="VideoPlayer"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header d-flex justify-content-end align-items-center">
              <div
                className="p-0 m-0 d-flex justify-content-center align-items-center"
                style={{
                  // background: 'red',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              >
                <i
                  className="ri-close-line fs-1"
                  style={{ fontSize: '30px' }}
                ></i>
              </div>
            </div>
            <ReactPlayer
              className="react-player"
              url={currentAudio}
              width={'100%'}
              height={'100%'}
              playing={playing}
              controls={true}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              config={{
                file: {
                  attributes: {
                    crossorigin: 'anonymous',
                  },
                },
              }}
              fileConfig={{
                attributes: {
                  style: {
                    display: 'block',
                    width: 'auto',
                    height: 'auto',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default ViewVideoPlaylist
