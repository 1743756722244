import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createSingleVideos,
  deleteSingleVideos,
  getSingleVideos,
  updateSingleVideos,
  getAllSingleVideosByStatus,
} from './slice'

function* getAllSingleVideos(): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.cms.singleVideoList,
    )
    yield put(actions.setSingleVideos(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllSingleVideosByStatusNames({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.singleVideoByStatus(payload.status),
    )
    yield put(actions.setOptionsFormat(response))
  } catch (err) {
    console.log(err)
  }
}

function* deleteSingleVideo({
  payload,
}: ReturnType<typeof deleteSingleVideos>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.cms.singleVideoWithId(payload.singleVideoId),
    )
    toast.success(message.SINGLE_VIDEO_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateSingleVideo({
  payload,
}: ReturnType<typeof updateSingleVideos>): any {
  try {
    yield call(
      baseAxios.put,
      apiEndPoints.cms.singleVideoWithId(payload.singleVideoId),
    )
  } catch (err) {
    console.log(err)
  }
}

function* createSingleVideo(): any {
  try {
    yield call(baseAxios.post, apiEndPoints.cms.singleVideo)
  } catch (err) {
    console.log(err)
  }
}

export function* singleVideoSaga(): any {
  yield all([yield takeLatest(getSingleVideos, getAllSingleVideos)])
  yield all([yield takeLatest(deleteSingleVideos, deleteSingleVideo)])
  yield all([yield takeLatest(updateSingleVideos, updateSingleVideo)])
  yield all([yield takeLatest(createSingleVideos, createSingleVideo)])
  yield all([
    yield takeLatest(
      getAllSingleVideosByStatus,
      getAllSingleVideosByStatusNames,
    ),
  ])
}
