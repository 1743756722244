import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
// import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
// import { message } from 'utils/helpers/constants'
import { actions, getCategories } from './slice'

function* getAllCategories(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.category.getWebCategories,
    )
    yield put(actions.setCategories(response))
  } catch (err) {
    console.log(err)
  }
}

export function* categorySaga(): any {
  yield all([yield takeLatest(getCategories, getAllCategories)])
}
