import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFound from 'containers/404'
import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'
import { PERMISSIONS } from 'model/auth'

import Login from 'containers/Login'
import ForgotPassword from 'containers/ForgotPassword'
import NewPassword from 'containers/NewPassword'
import SetPassword from 'containers/SetPassword'
import Dashboard from 'containers/Dashboard'
import UsersList from 'containers/Users/UsersList'
import MultiVideoList from 'containers/MultiVideo/MultiVideoList'
import MultiVideoView from 'containers/MultiVideo/ViewMultiVideo'
import VideoPlaylistList from 'containers/VideoPlaylist/VideoPlaylistList'
import VideoPlaylistView from 'containers/VideoPlaylist/ViewVideoPlaylist'
import SingleVideoList from 'containers/SingleVideo/SingleVideoList'
import SingleVideoView from 'containers/SingleVideo/ViewSingleVideo'
import AuthorsList from 'containers/Magazine/AuthorList'
import MagazineList from 'containers/Magazine/MagazineList'
import MagazineView from 'containers/Magazine/ViewMagazine'
import AlbumList from 'containers/Album/AlbumList'
import SingleAudioList from 'containers/Album/SingleAudioList'
import AlbumView from 'containers/Album/ViewAlbum'
import EditProfile from 'containers/EditProfile'
import Roles from 'containers/Roles'
import Tenant from 'containers/Tenant'
import Artists from 'containers/Artist/ArtistList'
import Categories from 'containers/Category'
import Genres from 'containers/Genre'
import Banners from 'containers/Banners'
import Hosts from 'containers/Hosts'
import RadioHosts from 'containers/RadioShows'
import RadioEvents from 'containers/RadioShows/ViewRadioShowDetails'
import Sponsors from 'containers/Sponsor'
import Transcoding from 'containers/Transcoding'
import AudioTranscoding from 'containers/AudioTranscoding'
import Career from 'containers/Career'
import JobView from 'containers/Career/Job/ViewJob'

const AppRoutes = () => {
  return (
    // <React.Suspense fallback={<Loader />}>
    <Routes>
      <Route path="/" element={<PublicRoute component={Login} />} />
      <Route path="/login" element={<PublicRoute component={Login} />} />
      <Route
        path="/auth/forgot-password"
        element={<PublicRoute component={ForgotPassword} />}
      />
      <Route
        path="/auth/new-password"
        element={<PublicRoute component={NewPassword} />}
      />
      <Route
        path="/auth/set-password"
        element={<PublicRoute component={SetPassword} />}
      />
      <Route
        path="/settings/editProfile"
        element={<PrivateRoute component={EditProfile} permissions={[]} />}
      />
      <Route
        path="/settings/roles"
        element={
          <PrivateRoute
            component={Roles}
            permissions={[PERMISSIONS.VIEW_ALL_ROLES]}
            rootOnlyAccess={true}
          />
        }
      />
      <Route
        path="/settings/categories"
        element={
          <PrivateRoute
            component={Categories}
            permissions={[PERMISSIONS.VIEW_ALL_CONTENT_CATEGORIES]}
            rootOnlyAccess={false}
          />
        }
      />
      <Route
        path="/settings/genres"
        element={
          <PrivateRoute
            component={Genres}
            permissions={[PERMISSIONS.VIEW_ALL_GENRES]}
            rootOnlyAccess={false}
          />
        }
      />
      <Route
        path="/settings/tenant"
        element={
          <PrivateRoute
            component={Tenant}
            permissions={[PERMISSIONS.VIEW_ALL_TENANTS]}
            rootOnlyAccess={true}
          />
        }
      />
      <Route
        path="/dashboard"
        element={<PrivateRoute component={Dashboard} permissions={[]} />} //TODO: manage root url
      />
      <Route
        path="/artists"
        element={
          <PrivateRoute
            component={Artists}
            permissions={[PERMISSIONS.VIEW_ALL_ARTIST]}
          />
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute
            component={UsersList}
            permissions={[PERMISSIONS.VIEW_ALL_USERS]}
            rootOnlyAccess={false}
          />
        }
      />
      <Route
        path="/multi-audio"
        element={
          <PrivateRoute
            component={AlbumList}
            permissions={[PERMISSIONS.VIEW_ALL_ALBUM]}
          />
        }
      />
      <Route
        path="/single-audio"
        element={
          <PrivateRoute
            component={SingleAudioList}
            permissions={[PERMISSIONS.VIEW_ALL_ALBUM]}
          />
        }
      />
      <Route
        path="/multi-video"
        element={
          <PrivateRoute
            component={MultiVideoList}
            permissions={[PERMISSIONS.VIEW_ALL_PLAYLIST_VIDEOS]}
          />
        }
      />
      <Route
        path="/single-video"
        element={
          <PrivateRoute
            component={SingleVideoList}
            permissions={[PERMISSIONS.VIEW_ALL_SINGLE_VIDEOS]}
          />
        }
      />
      <Route
        path="/single-video/view"
        element={
          <PrivateRoute
            component={SingleVideoView}
            permissions={[PERMISSIONS.VIEW_SINGLE_VIDEO]}
          />
        }
      />
      <Route
        path="/multi-video/view"
        element={
          <PrivateRoute
            component={MultiVideoView}
            permissions={[PERMISSIONS.VIEW_PLAYLIST_VIDEO]}
          />
        }
      />
      <Route
        path="/video-playlist"
        element={
          <PrivateRoute
            component={VideoPlaylistList}
            permissions={[PERMISSIONS.VIEW_ALL_PLAYLIST_VIDEOS]}
          />
        }
      />
      <Route
        path="/video-playlist/view"
        element={
          <PrivateRoute
            component={VideoPlaylistView}
            permissions={[PERMISSIONS.VIEW_PLAYLIST_VIDEO]}
          />
        }
      />
      <Route
        path="/author"
        element={
          <PrivateRoute
            component={AuthorsList}
            permissions={[PERMISSIONS.VIEW_ALL_AUTHORS]}
          />
        }
      />
      <Route
        path="/magazine"
        element={
          <PrivateRoute
            component={MagazineList}
            permissions={[PERMISSIONS.VIEW_ALL_MAGAZINES]}
          />
        }
      />
      <Route
        path="/magazine/view"
        element={
          <PrivateRoute
            component={MagazineView}
            permissions={[PERMISSIONS.VIEW_MAGAZINE]}
          />
        }
      />
      <Route
        path="/audio/view"
        element={<PrivateRoute component={AlbumView} permissions={[]} />}
      />
      <Route path="*" element={<NotFound />} />
      <Route
        path="/banners"
        element={
          <PrivateRoute
            component={Banners}
            permissions={[PERMISSIONS.VIEW_ALL_BANNERS]}
          />
        }
      />
      <Route
        path="/hosts"
        element={
          <PrivateRoute
            component={Hosts}
            permissions={[PERMISSIONS.VIEW_ALL_HOSTS]}
          />
        }
      />
      <Route
        path="/radio-shows"
        element={
          <PrivateRoute
            component={RadioHosts}
            permissions={[PERMISSIONS.VIEW_ALL_RADIO_SHOWS]}
          />
        }
      />
      <Route
        path="radio-shows/view/:radioShowId"
        element={
          <PrivateRoute
            component={RadioEvents}
            permissions={[PERMISSIONS.VIEW_RADIO_SHOW]}
          />
        }
      />
      <Route
        path="/sponsors"
        element={
          <PrivateRoute
            component={Sponsors}
            permissions={[PERMISSIONS.VIEW_ALL_SPONSORS]}
          />
        }
      />
      <Route
        path="/transcoding"
        element={
          <PrivateRoute
            component={Transcoding}
            permissions={[PERMISSIONS.HANDLE_VIDEO_TRANSCODING]} // TODO: cleanup permissions for radio cms
          />
        }
      />
      <Route
        path="/audio-transcoding"
        element={
          <PrivateRoute
            component={AudioTranscoding}
            permissions={[PERMISSIONS.HANDLE_VIDEO_TRANSCODING]}
          />
        }
      />
      <Route
        path="/career"
        element={
          <PrivateRoute
            component={Career}
            permissions={[PERMISSIONS.VIEW_ALL_CAREER_JOBS]}
          />
        }
      />
      <Route
        path="jobs/view/:jobId"
        element={
          <PrivateRoute
            component={JobView}
            permissions={[PERMISSIONS.VIEW_ALL_CAREER_JOB_APPLICANTSS]}
          />
        }
      />
    </Routes>
    // </React.Suspense>
  )
}
export default AppRoutes
