/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'
import { all, fork } from 'redux-saga/effects'
import { InjectedReducersType } from 'utils/types/injector-typings'
import { reducer as Common } from './common/slice'
//Reducers
import { reducer as Users } from './userManagement/slice'
import { reducer as AdminRole } from './roleManagement/slice'
import { reducer as SingleVideo } from './singleVideoManagement/slice'
import { reducer as Video } from './videoManagement/slice'
import { reducer as Magazine } from './magazineManagement/slice'
import { reducer as Album } from './albumManagement/slice'
import { reducer as Tmdb } from './tmdbManagement/slice'
import { reducer as Artist } from './artistManagement/slice'
import { reducer as Basic } from './basicManagement/slice'
import { reducer as MultiVideo } from './multiVideoManagement/slice'
import { reducer as VideoPlaylist } from './videoPlaylistManagement/slice'
import { reducer as Menu } from './menuManagement/slice'
import { reducer as Banner } from './bannnerManagement/slice'
import { reducer as Category } from './categoryManagement/slice'
import { reducer as Host } from './hostManagement/slice'
import { reducer as Radio } from './radioManagement/slice'
import { reducer as Sponsor } from './sponsorManagement/slice'
import { reducer as Dashboard } from './dashboardManagement/slice'
import { reducer as Transcoding } from './transcodingManagement/slice'
import { reducer as Career } from './careerManagement/slice'
import { reducer as AudioTranscoding } from './audioTranscodingManagement/slice'

//Saga
import { usersSaga } from './userManagement/saga'
import { adminRoleSaga } from './roleManagement/saga'
import { singleVideoSaga } from './singleVideoManagement/saga'
import { videoSaga } from './videoManagement/saga'
import { magazineSaga } from './magazineManagement/saga'
import { albumSaga } from './albumManagement/saga'
import { tmdbSaga } from './tmdbManagement/saga'
import { artistSaga } from './artistManagement/saga'
import { basicSaga } from './basicManagement/saga'
import { multiVideoSaga } from './multiVideoManagement/saga'
import { videoPlaylistSaga } from './videoPlaylistManagement/saga'
import { menuSaga } from './menuManagement/saga'
import { bannerSaga } from './bannnerManagement/saga'
import { categorySaga } from './categoryManagement/saga'
import { hostSaga } from './hostManagement/saga'
import { radioSaga } from './radioManagement/saga'
import { sponsorSaga } from './sponsorManagement/saga'
import { dashboardSaga } from './dashboardManagement/saga'
import { transcodingSaga } from './transcodingManagement/saga'
import { careerSaga } from './careerManagement/saga'
import { audioTranscodingSaga } from './audioTranscodingManagement/saga'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error

  return combineReducers({
    Common,
    Users,
    AdminRole,
    SingleVideo,
    MultiVideo,
    Video,
    Magazine,
    Album,
    Tmdb,
    Artist,
    Basic,
    VideoPlaylist,
    Menu,
    Category,
    Banner,
    Host,
    Radio,
    Sponsor,
    Dashboard,
    Transcoding,
    Career,
    AudioTranscoding,
    ...injectedReducers,
  })
}
export function* rootSaga() {
  yield all([fork(usersSaga)])
  yield all([fork(adminRoleSaga)])
  yield all([fork(singleVideoSaga)])
  yield all([fork(videoSaga)])
  yield all([fork(magazineSaga)])
  yield all([fork(albumSaga)])
  yield all([fork(tmdbSaga)])
  yield all([fork(artistSaga)])
  yield all([fork(basicSaga)])
  yield all([fork(multiVideoSaga)])
  yield all([fork(videoPlaylistSaga)])
  yield all([fork(menuSaga)])
  yield all([fork(bannerSaga)])
  yield all([fork(categorySaga)])
  yield all([fork(hostSaga)])
  yield all([fork(radioSaga)])
  yield all([fork(sponsorSaga)])
  yield all([fork(dashboardSaga)])
  yield all([fork(transcodingSaga)])
  yield all([fork(careerSaga)])
  yield all([fork(audioTranscodingSaga)])
}
