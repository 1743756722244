import React, { useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CustomHeader from 'components/CustomHeader'
import { getJobApplicants, getJobInfo } from 'store/careerManagement/slice'
import { careerSelector } from 'store/careerManagement/selector'
import { TableHeader } from 'components/DataTable'
import NoData from 'components/NoData/nodata'

const headers = [
  { name: 'Applied Date', field: 'name', sortable: false },
  { name: 'Email', field: 'name', sortable: false },
  { name: 'Mobile', field: 'mobile', sortable: false },
  { name: 'Name', field: 'name', sortable: false },
  { name: 'notes', field: 'notes', sortable: false },
  { name: 'Resume/CV', field: 'action', sortable: false },
]
const ViewJob = () => {
  const { jobId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { jobApplicants } = useSelector(careerSelector)

  useEffect(() => {
    if (jobId) {
      dispatch(getJobApplicants({ jobId: jobId, skip: 0, limit: 200 }))
      dispatch(getJobInfo(jobId))
    } else {
      navigate('/career')
    }
  }, [dispatch, navigate, jobId])

  const downloadFile = (jobApplicantData: any) => {
    const s3ObjectUrl = jobApplicantData.resumeUrl

    fetch(s3ObjectUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${jobApplicantData.email}_${new Date().valueOf()}.pdf`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(error => {
        console.error('Error downloading file:', error)
      })
  }

  return (
    <CustomHeader pageName={'Job Applicants View'}>
      <div id="content-page" className="content-page ">
        <div className="container-fluid">
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Jobs Applicants List</h4>
                </div>
              </div>
              <div className="iq-card-body">
                {jobApplicants.length > 0 ? (
                  <div className="table-view">
                    {jobApplicants.length > 0 ? (
                      <div
                        className="row"
                        style={{ backgroundColor: 'white', padding: '20px' }}
                      >
                        <table
                          className="data-tables table movie_table"
                          style={{ width: '100%' }}
                        >
                          <TableHeader headers={headers} />
                          <tbody>
                            {jobApplicants?.map(
                              (jobApplicant: any, index: number) => (
                                <tr key={`job_applicants_${index}`}>
                                  <td>
                                    {moment(jobApplicant.createdAt).format(
                                      'YYYY-MM-DD hh:mm:ss',
                                    )}
                                  </td>
                                  <td>{jobApplicant.email}</td>
                                  <td>{jobApplicant.mobile}</td>
                                  <td>{jobApplicant.name}</td>
                                  <td>{jobApplicant.jobApplicationNotes}</td>
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      <a
                                        className="iq-bg-primary"
                                        data-toggle="tooltip"
                                        data-original-title="download"
                                        onClick={() => {
                                          downloadFile(jobApplicant)
                                        }}
                                      >
                                        <i className="ri-eye-line" />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default ViewJob
