import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Basic } from './types'

// eslint-disable-next-line
const getCategories = createAction<any>('cms/getCategory')
const getGenres = createAction<any>('cms/getGenre')
const setUploadStatus = createAction<any>('cms/setUploadStatus')

export const initialState: Basic = {
  categories: [],
  genres: [],
  uploadStatus: [],
}

const BasicSlice = createSlice({
  name: 'Basic',
  initialState: initialState,
  reducers: {
    setCategories(state, action: PayloadAction<any>) {
      state.categories = action.payload
    },
    setGenres(state, action: PayloadAction<any>) {
      state.genres = action.payload
    },
    setUploadStatusArray(state, action: PayloadAction<any>) {
      const { id, status } = action.payload
      const index = state.uploadStatus.findIndex((item: any) => item.id === id)

      if (index !== -1) {
        // If the status with the ID already exists, update it
        state.uploadStatus[index].status = status
      } else {
        // If the status with the ID doesn't exist, add it to the array
        state.uploadStatus.push({ id, status })
      }
    },
  },
})

export { getCategories, getGenres, setUploadStatus }

export const { actions, reducer, name: sliceKey } = BasicSlice
