import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectSponsorState = (state: RootState) => state.Sponsor || initialState

export const sponsorSelector = createSelector(
  [selectSponsorState],
  Sponsor => Sponsor,
)
