import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dashboard } from './types'

const getActiveUserAnalytics = createAction<any>('cms/getActiveUserAnalytics')
const getContentAnalytics = createAction<any>('cms/getContentAnalytics')
const getUserSignupsTrend = createAction<any>('cms/getUserSignupsTrend')
const getVideosTrend = createAction<any>('cms/getVideosTrend')
const getAudiosTrend = createAction<any>('cms/getAudiosTrend')

export const initialState: Dashboard = {
  activeUserAnalytics: {
    currentActiveUsers: [{ _id: '', count: 0 }],
    totalUsers: 0,
  },
  contentAnalytics: {
    totalVideoWatchTime: 0,
    totalAudioWatchTime: 0,
  },
  userSignupsTrend: [],
  videoTrend: { videoWatchTrend: [] },
  audioTrend: { audioWatchTrend: [] },
}

const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState: initialState,
  reducers: {
    setActiveUsersAnalytics(state, action: PayloadAction<any>) {
      state.activeUserAnalytics = action.payload
    },
    setContentAnalytics(state, action: PayloadAction<any>) {
      state.contentAnalytics = action.payload
    },
    setUserSignupsTrend(state, action: PayloadAction<any>) {
      state.userSignupsTrend = action.payload
    },
    setVideosTrend(state, action: PayloadAction<any>) {
      state.videoTrend = action.payload
    },
    setAudiosTrend(state, action: PayloadAction<any>) {
      state.audioTrend = action.payload
    },
  },
})

export {
  getActiveUserAnalytics,
  getContentAnalytics,
  getUserSignupsTrend,
  getVideosTrend,
  getAudiosTrend,
}

export const { actions, reducer, name: sliceKey } = DashboardSlice
