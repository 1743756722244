import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectCategoryState = (state: RootState) => state.Category || initialState

export const categorySelector = createSelector(
  [selectCategoryState],
  Category => Category,
)
