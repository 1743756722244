import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createArtists,
  deleteArtists,
  getArtists,
  updateArtists,
} from './slice'

function* getAllArtists(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.cms.artists)
    yield put(actions.setArtists(response))
  } catch (err) {
    console.log(err)
  }
}

function* deleteArtist({ payload }: ReturnType<typeof deleteArtists>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.cms.artistsWithId(payload.artistId),
    )
    toast.success(message.MAGAZINE_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateArtist({ payload }: ReturnType<typeof updateArtists>): any {
  try {
    yield call(baseAxios.put, apiEndPoints.cms.artistsWithId(payload.artistId))
  } catch (err) {
    console.log(err)
  }
}

function* createArtist(): any {
  try {
    yield call(baseAxios.post, apiEndPoints.cms.artists)
  } catch (err) {
    console.log(err)
  }
}

export function* artistSaga(): any {
  yield all([yield takeLatest(getArtists, getAllArtists)])
  yield all([yield takeLatest(deleteArtists, deleteArtist)])
  yield all([yield takeLatest(updateArtists, updateArtist)])
  yield all([yield takeLatest(createArtists, createArtist)])
}
