import moment from 'moment'

type Config = {
  baseUrl: string
  serverName: string
}

const config: Config = {
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  serverName: `My App`,
}

const s3Url = `https://${process.env.REACT_APP_SWARA_AWS_S3_IMAGES_BUCKET}.s3.amazonaws.com/`
const s3UrlPDF = `https://s3.amazonaws.com/${process.env.REACT_APP_SWARA_AWS_S3_IMAGES_BUCKET}/`

const swaraAdminCmsBasePath = `${process.env.REACT_APP_SWARA_ADMIN_CMS_BASE_PATH}`
const swaraAdminWebManagementServiceBasePath = `${process.env.REACT_APP_WEB_MANAGEMENT_API_BASE_PATH}`
const swaraAdminVideoCmsBasePath = `${process.env.REACT_APP_SWARA_VIDEO_CMS_BASE_PATH}`
const swaraAdminAudioCmsBasePath = `${process.env.REACT_APP_SWARA_AUDIO_CMS_BASE_PATH}`
const swaraAdminMagazineCmsBasePath = `${process.env.REACT_APP_SWARA_MAGAZINE_CMS_BASE_PATH}`
const swaraAdminRadioCmsBasePath = `${process.env.REACT_APP_RADIO_MANAGEMENT_API_BASE_PATH}`
const swaraAdminAnalyticsBasePath = `${process.env.REACT_APP_ANALYTICS_API_BASE_PATH}`
const swaraAdminCarrerBasePath = `${process.env.REACT_APP_CAREER_API_BASE_PATH}`

const DEFAULT_LIMIT = 10

const apiEndPoints = {
  s3: {
    base: s3Url,
    pdf: s3UrlPDF,
  },
  users: {
    // Auth
    login: `${swaraAdminCmsBasePath}/auth/login`,
    addUser: `${swaraAdminCmsBasePath}/auth/signup`,

    // Admin User Handler
    users: `${swaraAdminCmsBasePath}/admin/users`,
    adminUpdateUserPassword: `${swaraAdminCmsBasePath}/admin/users/updatePassword`,
    adminUser: `${swaraAdminCmsBasePath}/admin/users`,

    // Roles
    getRolesData: `${swaraAdminCmsBasePath}/roles`,
    createRoleData: `${swaraAdminCmsBasePath}/roles`,
    updateRoleData: (id: string) => `${swaraAdminCmsBasePath}/roles/${id}`,
    deleteRoleData: (id: string) => `${swaraAdminCmsBasePath}/roles/${id}`,

    // Permissions
    getPermissionsData: `${swaraAdminCmsBasePath}/permissions?limit=100`,
    createPermissionData: `${swaraAdminCmsBasePath}/permissions`,
    updatePermissionData: (id: string) =>
      `${swaraAdminCmsBasePath}/permissions/${id}`,
    deletePermissionData: (id: string) =>
      `${swaraAdminCmsBasePath}/permissions/${id}`,

    // Tenant
    getTenantsData: `${swaraAdminCmsBasePath}/tenants`,
    createTenantData: `${swaraAdminCmsBasePath}/tenants`,
    updateTenantData: (id: string) => `${swaraAdminCmsBasePath}/tenants/${id}`,
    deleteTenantData: (id: string) => `${swaraAdminCmsBasePath}/tenants/${id}`,

    // Basic User
    updateUser: `${swaraAdminCmsBasePath}/auth/users/`,
    userById: (id: string) => `${swaraAdminCmsBasePath}/users/${id}`,
    forgotPassword: `${swaraAdminCmsBasePath}/auth/signup/forgot-password`,
    verifyForgotPassword: `${swaraAdminCmsBasePath}/auth/signup/forgot-password/verify`,
    signupVerify: `${swaraAdminCmsBasePath}/auth/signup/verify`,
    signupResendCode: `${swaraAdminCmsBasePath}/auth/signup/resend-code`,
    changePasword: `${swaraAdminCmsBasePath}/auth/users/change-password`,
    logout: `${swaraAdminCmsBasePath}/auth/logout`,
  },
  cms: {
    // Single Video Routes
    singleVideoList: `${swaraAdminVideoCmsBasePath}/admin/single-videos`,
    singleVideo: `${swaraAdminVideoCmsBasePath}/single-videos`,
    singleVideoByStatus: (status: string) =>
      `${swaraAdminVideoCmsBasePath}/single-videos?status=${status}`,
    singleVideoWithId: (id: string) =>
      `${swaraAdminVideoCmsBasePath}/single-videos/${id}`,

    // Video Routes
    videos: `${swaraAdminVideoCmsBasePath}/videos`,
    videoWithId: (id: string) => `${swaraAdminVideoCmsBasePath}/videos/${id}`,

    // Multi Video Routes
    multiVideoList: `${swaraAdminVideoCmsBasePath}/admin/web-series`,
    multiVideo: `${swaraAdminVideoCmsBasePath}/web-series`,
    multiVideoByStatus: (status: string) =>
      `${swaraAdminVideoCmsBasePath}/web-series?status=${status}`,
    multiVideoWithId: (id: string) =>
      `${swaraAdminVideoCmsBasePath}/web-series/${id}`,

    // Video Playlist Routes
    videoPlaylistList: `${swaraAdminVideoCmsBasePath}/admin/playlists`,
    videoPlaylist: `${swaraAdminVideoCmsBasePath}/playlists`,
    videoPlaylistByStatus: (status: string) =>
      `${swaraAdminVideoCmsBasePath}/playlists?status=${status}`,
    videoPlaylistWithId: (id: string) =>
      `${swaraAdminVideoCmsBasePath}/playlists/${id}`,

    // Season Routes
    seasons: (workSpaceId: string, webSeriesId: string) =>
      `${swaraAdminVideoCmsBasePath}/work-spaces/${workSpaceId}/web-series/${webSeriesId}/seasons`,
    seasonWithId: (
      workSpaceId: string,
      webSeriesId: string,
      seasonId: string,
    ) =>
      `${swaraAdminVideoCmsBasePath}/work-spaces/${workSpaceId}/web-series/${webSeriesId}/seasons/${seasonId}`,

    // Episodes Routes
    episodes: (workSpaceId: string, webSeriesId: string, seasonId: string) =>
      `${swaraAdminVideoCmsBasePath}/work-spaces/${workSpaceId}/web-series/${webSeriesId}/seasons/${seasonId}/episodes`,
    episodeWithId: (
      workSpaceId: string,
      webSeriesId: string,
      seasonId: string,
      episodeId: string,
    ) =>
      `${swaraAdminVideoCmsBasePath}/work-spaces/${workSpaceId}/web-series/${webSeriesId}/seasons/${seasonId}/episodes/${episodeId}`,

    // Magazine Routes
    magazineList: `${swaraAdminMagazineCmsBasePath}/admin/magazines`,
    magazine: `${swaraAdminMagazineCmsBasePath}/magazines`,
    // TODO: shortgap solution for fixing pagination issue
    magazineByStatus: (status: string) =>
      `${swaraAdminMagazineCmsBasePath}/magazines?status=${status}&skip=0&limit=1000`,
    magazineWithId: (id: string) =>
      `${swaraAdminMagazineCmsBasePath}/magazines/${id}`,

    // Authors Routes
    authors: (skip = 0, limit = 30) =>
      `${swaraAdminCmsBasePath}/authors?skip=${skip}&limit=${limit}`,
    authorsWithId: (id: any) => `${swaraAdminCmsBasePath}/authors/${id}`,

    // Artists Routes
    artists: `${swaraAdminCmsBasePath}/artists?limit=200`,
    artistsWithId: (id: any) => `${swaraAdminCmsBasePath}/artists/${id}`,

    // Album
    album: `${swaraAdminAudioCmsBasePath}/albums`,
    albumByType: (type: string) =>
      `${swaraAdminAudioCmsBasePath}/albums?type=${type}`,

    singleAlbum: (type: string) =>
      `${swaraAdminAudioCmsBasePath}/albums?type=${type}`,
    singleAlbumByStatus: (type: string, status: string) =>
      `${swaraAdminAudioCmsBasePath}/albums?type=${type}&status=${status}`,
    albumWithId: (id: any) => `${swaraAdminAudioCmsBasePath}/albums/${id}`,

    // Audio Track
    audioTracks: (id: any) =>
      `${swaraAdminAudioCmsBasePath}/albums/${id}/audio-tracks`,
    audioTracksWithId: (albumId: any, id: any) =>
      `${swaraAdminAudioCmsBasePath}/albums/${albumId}/audio-tracks/${id}`,

    // Audio
    audio: `${swaraAdminAudioCmsBasePath}/audios?limit=200`,
    audioWithId: (id: any) => `${swaraAdminAudioCmsBasePath}/audios/${id}`,

    // Category Routes
    categories: `${swaraAdminCmsBasePath}/categories?limit=200`,
    categoriesWithId: (id: any) => `${swaraAdminCmsBasePath}/categories/${id}`,

    // Genres Routes
    genres: `${swaraAdminCmsBasePath}/genres?limit=200`,
    genresWithId: (id: any) => `${swaraAdminCmsBasePath}/genres/${id}`,
  },
  tmdb: {
    // Certification
    getMovieCertificates: `https://api.themoviedb.org/3/certification/movie/list?api_key=51054f035fcba2ab9cad420e3fef1127`,
  },
  category: {
    getWebCategories: `${swaraAdminWebManagementServiceBasePath}/categories`,
  },
  banner: {
    getBannersByCategoryId: `${swaraAdminWebManagementServiceBasePath}/banners?skip=0&limit=200`,
    createBanner: (categoryId: any) =>
      `${swaraAdminWebManagementServiceBasePath}/categories/${categoryId}/banners`,
    bannerInfo: (bannerId: any) =>
      `${swaraAdminWebManagementServiceBasePath}/banners/${bannerId}`,
    updateWebBanner: (bannerId: any) =>
      `${swaraAdminWebManagementServiceBasePath}/banners/${bannerId}`,
    deleteWebBanner: (bannerId: any) =>
      `${swaraAdminWebManagementServiceBasePath}/banners/${bannerId}`,
    publishWebCategoryBanners: (categoryId: any) =>
      `${swaraAdminWebManagementServiceBasePath}/categories/${categoryId}/publish`,
    updateWorkFlowStatus: (bannerId: any) =>
      `${swaraAdminWebManagementServiceBasePath}/banners/${bannerId}/change-status`,
  },
  hosts: {
    getHosts: (skip = 0, limit = 10) =>
      `${swaraAdminRadioCmsBasePath}/radio-hosts?skip=${skip}&limit=${limit}`,
    createHost: `${swaraAdminRadioCmsBasePath}/radio-hosts?limit=200`,
    hostsWithId: (id: any) => `${swaraAdminRadioCmsBasePath}/radio-hosts/${id}`,
    searchHosts: (searchValue: string, skip = 0, limit = 10) =>
      `${swaraAdminRadioCmsBasePath}/radio-hosts/search?searchValue=${searchValue}&skip=${skip}&limit=${limit}`,
  },
  radio: {
    getRadioShows: (skip = 0) =>
      `${swaraAdminRadioCmsBasePath}/radio-shows?skip=${skip}&limit=${DEFAULT_LIMIT}`,
    getRadioEvents: (radioShowId: any) =>
      `${swaraAdminRadioCmsBasePath}/radio-shows/${radioShowId}/events`,
    createRadioShow: `${swaraAdminRadioCmsBasePath}/radio-shows`,
    radioShowInfo: (radioShowId: any) =>
      `${swaraAdminRadioCmsBasePath}/radio-shows/${radioShowId}`,
    updateRadioShow: (radioShowId: any) =>
      `${swaraAdminRadioCmsBasePath}/radio-shows/${radioShowId}`,
    deleteRadioShow: (radioShowId: any) =>
      `${swaraAdminRadioCmsBasePath}/radio-shows/${radioShowId}`,
    launchRadioShow: (radioShowId: any) =>
      `${swaraAdminRadioCmsBasePath}/radio-shows/${radioShowId}/launch`,
    searchRadioShows: (searchValue: string, skip = 0, limit = 10) =>
      `${swaraAdminRadioCmsBasePath}/radio-shows/search?searchValue=${searchValue}&skip=${skip}&limit=${limit}`,
  },
  sponsors: {
    getSponsors: (skip = 0, limit = 10) =>
      `${swaraAdminCmsBasePath}/sponsors?skip=${skip}&limit=${limit}`,
    createSponsors: `${swaraAdminCmsBasePath}/sponsors`,
    sponsorsWithId: (id: any) => `${swaraAdminCmsBasePath}/sponsors/${id}`,
    searchSponsors: (searchValue: string, skip = 0, limit = 10) =>
      `${swaraAdminCmsBasePath}/sponsors/search?searchValue=${searchValue}&skip=${skip}&limit=${limit}`,
  },
  analytics: {
    getActiveUsers: `${swaraAdminAnalyticsBasePath}/users/active-users`,
    getContentAnalytics: `${swaraAdminAnalyticsBasePath}/content/activity-time`,
    getUserSignupsTrend: (year = moment().year()) =>
      `${swaraAdminAnalyticsBasePath}/users/signups/trend?year=${year}`,
    getVideosTrend: (year = moment().year()) =>
      `${swaraAdminAnalyticsBasePath}/videos/trend?year=${year}`,
    getAudiosTrend: (year = moment().year()) =>
      `${swaraAdminAnalyticsBasePath}/audios/trend?year=${year}`,
  },
  transcoding: {
    getAllTranscodingVideos: (skip = 0, limit = 10) =>
      `${swaraAdminVideoCmsBasePath}/videos/transcoded?skip=${skip}&limit=${limit}`,
    searchVideos: (searchValue: string, skip = 0, limit = 10) =>
      `${swaraAdminVideoCmsBasePath}/videos/search?searchValue=${searchValue}&skip=${skip}&limit=${limit}`,
    startTranscoding: `${swaraAdminVideoCmsBasePath}/transcoding/start`,
    cancelTranscoding: `${swaraAdminVideoCmsBasePath}/transcoding/cancel`,
  },
  audioTranscoding: {
    getAllTranscodingAudios: (skip = 0, limit = 10) =>
      `${swaraAdminAudioCmsBasePath}/audios/transcoded?skip=${skip}&limit=${limit}`,
    searchAudios: (searchValue: string, skip = 0, limit = 10) =>
      `${swaraAdminAudioCmsBasePath}/audios/search?searchValue=${searchValue}&skip=${skip}&limit=${limit}`,
    startTranscoding: `${swaraAdminAudioCmsBasePath}/transcoding/start`,
    cancelTranscoding: `${swaraAdminAudioCmsBasePath}/transcoding/cancel`,
  },
  career: {
    getJobs: (skip = 0, limit = 10) =>
      `${swaraAdminCarrerBasePath}/jobs?skip=${skip}&limit=${limit}`,
    createJobs: `${swaraAdminCarrerBasePath}/jobs`,
    jobsWithId: (id: any) => `${swaraAdminCarrerBasePath}/jobs/${id}`,
    searchJobs: (searchValue: string, skip = 0, limit = 10) =>
      `${swaraAdminCarrerBasePath}/jobs/search?searchValue=${searchValue}&skip=${skip}&limit=${limit}`,
    getJobApplicants: (id: any, skip = 0, limit = 10) =>
      `${swaraAdminCarrerBasePath}/jobs/${id}/applicants?skip=${skip}&limit=${limit}`,
  },
}

export { config, apiEndPoints }
