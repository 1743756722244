import React from 'react'
import Lottie from 'lottie-react'
import noData from '../../assets/animations/no-data-found.json'

const NoData = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div style={{ width: '30vw', height: '60vh' }}>
        <Lottie
          animationData={noData}
          loop={true}
          width="40px"
          height={'40px'}
        />
      </div>
    </div>
  )
}

export default NoData
