import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Career } from './types'

// eslint-disable-next-line
const getJobs = createAction<any>('cms/getJobs')
const deleteJobs = createAction<any>('cms/deleteJobs')
const updateJobs = createAction<any>('cms/updateJobs')
const createJobs = createAction<any>('cms/createJobs')
const searchJobs = createAction<any>('cms/searchJobs')
const getJobInfo = createAction<any>('cms/getJobInfo')
const getJobApplicants = createAction<any>('cms/getJobApplicants')

export const initialState: Career = {
  jobs: [],
  jobInfo: {},
  jobsLoading: false,
  jobsSearchData: [],
  jobApplicants: [],
}

const JobSlice = createSlice({
  name: 'Job',
  initialState: initialState,
  reducers: {
    setJobs(state, action: PayloadAction<any>) {
      if (action?.payload?.skip > 0) {
        state.jobs = state.jobs.concat(action.payload?.data)
      } else {
        state.jobs = action.payload?.data
      }
    },
    setSelectedJobInfo(state, action: PayloadAction<any>) {
      state.jobInfo = action.payload
    },
    setCreateJob(state, action: PayloadAction<any>) {
      state.jobs = [...state.jobs, action.payload]
    },
    setUpdateJob(state, action: PayloadAction<any>) {
      state.jobs[
        state.jobs.findIndex((x: any) => x._id == action.payload._id)
      ] = action.payload
    },
    setDeleteJob(state, action: PayloadAction<any>) {
      state.jobs = state.jobs.filter((x: any) => x._id != action.payload)
    },
    setJobLoader(state, action: PayloadAction<any>) {
      state.jobsLoading = action.payload
    },
    setJobSearch(state, action: PayloadAction<any>) {
      state.jobsSearchData = action.payload
    },
    setJobApplicants(state, action: PayloadAction<any>) {
      state.jobApplicants = action.payload
    },
  },
})

export {
  getJobs,
  deleteJobs,
  updateJobs,
  createJobs,
  searchJobs,
  getJobInfo,
  getJobApplicants,
}

export const { actions, reducer, name: sliceKey } = JobSlice
