/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useState, useRef, useEffect, createRef } from 'react'
import CustomHeader from 'components/CustomHeader'
import { userOne } from 'utils/imageUtils'
import { useLocation } from 'react-router-dom'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { useDispatch, useSelector } from 'react-redux'
import { getArtists } from 'store/artistManagement/slice'
import { artistSelector } from 'store/artistManagement/selector'
import { FieldArray } from 'formik'
import { Country, State } from 'country-state-city'
import { getAudioTracks } from 'store/albumManagement/slice'
import { albumSelector } from 'store/albumManagement/selector'
import { toast } from 'react-toastify'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/src/styles.scss'
import { AudioType, MediaContentStatusType, UploadStatusType } from 'utils/enum'
import { setUploadStatus } from 'store/basicManagement/slice'
import { Uploader } from 'utils/helpers/uploader'
import { basicSelector } from 'store/basicManagement/selector'
import MultiSelect from 'components/MultiSelect'
import SelectBox from 'components/Select'
import moment from 'moment'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'

const ViewAlbum = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading]: any = useState(true)
  const [albumData, setAlbumData] = useState(location.state.data)
  const [artistDetail, setArtistDetail]: any = useState([])
  const [modalOpenType, setModalOpenType] = useState('add')

  const inputFileSelect = useRef<HTMLInputElement>(null)
  const inputFileSelectAudio = useRef<HTMLInputElement>(null)
  const audioTrackModalRef = useRef<HTMLDivElement>(null)
  const audioTrackCloseRef = useRef<HTMLButtonElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const audioPlayerRef = createRef()

  const artist = useSelector(artistSelector)
  // const album = useSelector(albumSelector)
  const [audioTrack, setAudioTracks]: any = useState(null)

  const [loading, setLoading] = useState(false)
  const [countries, setCountries]: any = useState([])
  const [states, setStates]: any = useState([])
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const [selectedAudioFile, setSelectedAudioFile]: any = useState(null)
  const [modalImage, setModalImage]: any = useState(null)
  const [currentAudio, setCurrentAudio]: any = useState(null)
  const [selectedAudioTrackId, setSelectedAudioTrackId] = useState(null)
  const [selectedAudioId, setSelectedAudioId] = useState(null)
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null)
  const [uploadType, setUploadType] = useState('url')
  const basic = useSelector(basicSelector)
  const [uploader, setUploader] = useState<any>(undefined)
  const [progress, setProgress] = useState(101)
  const [lyricistList, setLyricistList] = useState([])
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(0)
  const [selectedCountry, setSelectedCountry]: any = useState(null)

  const [selectedIconImage, setSelectedIconImage] = useState(null)

  let status = basic.uploadStatus

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    audioType: Yup.string().required('Type is required'),
    singerId: Yup.array()
      .min(1, 'Select at least one composer')
      .required('Singer is required.'),
    composerId: Yup.array()
      .min(1, 'Select at least one composer')
      .required('Composer is required.'),
  })
  const [initialValues] = useState({
    name: '',
    description: '',
    audioType: '',
    singerId: '',
    composerId: '',
    lyricistId: '',
    geoConfig: {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    },
    enableDrm: false,
    enableOffline: false,
    audioUrl: '',
  })

  const fetchData = async () => {
    setPageLoading(true)
    const response = await baseAxios.get(
      apiEndPoints.cms.audioTracks(albumData._id),
    )
    if (response) {
      setAudioTracks(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    const country: any = Country.getAllCountries()
    setCountries(country)
    dispatch(getArtists({}))
    dispatch(getAudioTracks({ albumId: albumData._id }))
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setSelectedAudioTrack = (value: any, index: number) => {
    setModalOpenType('edit')
    initialValues.name = value.name
    initialValues.description = value.description
    initialValues.singerId = value.singers.map((singer: any) => {
      return { value: singer._id, label: singer.name }
    })
    initialValues.composerId = value.composers.map((composer: any) => {
      return { value: composer._id, label: composer.name }
    })
    initialValues.lyricistId = value.lyricists.map((lyricist: any) => {
      return { value: lyricist._id, label: lyricist.name }
    })
    initialValues.enableDrm = value.audio[0].enableDrm
    initialValues.enableOffline = value.audio[0].enableOffline
    initialValues.audioUrl = value.audio[0].audioUrl
    initialValues.audioType = value.audio[0].accessType

    initialValues.geoConfig.restrict = value.audio[0].geoConfig[0].restrict
    initialValues.geoConfig.longitude = value.audio[0].geoConfig[0].longitude
    initialValues.geoConfig.radius = value.audio[0].geoConfig[0].radius

    if (
      value.audio[0].geoConfig[0].allowedGeoLocations &&
      value.audio[0].geoConfig[0].allowedGeoLocations.length > 0
    ) {
      initialValues.geoConfig.allowedGeoLocations =
        value.audio[0].geoConfig[0].allowedGeoLocations[0]
    }

    if (
      value.audio[0].geoConfig[0] &&
      value.audio[0].geoConfig[0].allowedGeoLocations &&
      value.audio[0].geoConfig[0].allowedGeoLocations[0] &&
      value.audio[0].geoConfig[0].allowedGeoLocations[0].country
    ) {
      handleCountrySelectSetValue(
        value.audio[0].geoConfig[0].allowedGeoLocations[0].country,
      )
    }

    setModalImage(value.imageUrl)
    setSelectedAudioTrackId(value._id)
    setSelectedAudioId(value.audioId)
    setSelectedWorkspaceId(value.workSpaceId)
    setSelectedOrderIndex(index)
  }

  const discardFields = () => {
    setModalOpenType('add')
    initialValues.name = ''
    initialValues.description = ''
    initialValues.singerId = ''
    initialValues.composerId = ''
    initialValues.geoConfig = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    }
    initialValues.enableDrm = false
    initialValues.enableOffline = false
    initialValues.audioUrl = ''
    initialValues.audioType = ''
    setModalImage(null)
    setSelectedAudioTrackId(null)
    setSelectedWorkspaceId(null)
    // inputFileSelectAudio.current!.files = null
    clearFieldOnClose()
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    if (inputFileSelectAudio.current) {
      inputFileSelectAudio.current.value = ''
      inputFileSelectAudio.current.files = null
    }

    setSelectedFile(null)
    setSelectedAudioFile(null)
  }

  // useEffect(() => {
  //   if (albumData != null) {
  //     // fetchDetails(albumData)
  //     const artistData: any = []
  //     albumData.artists.forEach((artistId: any) => {
  //       baseAxios
  //         .get(apiEndPoints.cms.artistsWithId(artistId._id))
  //         .then(response => {
  //           artistData.push(response)
  //           // setArtistDetail(response)
  //         })
  //         .catch((err: any) => {
  //           setArtistDetail(undefined)
  //         })
  //     })
  //     setArtistDetail(artistData)
  //   }
  // }, [albumData])

  const artistList = artist.artists
  // let audioTrackList = album.audioTracks

  useEffect(() => {
    const lyricistListArr: any = artistList.map((artist: any) => {
      return { value: artist._id, label: artist.name }
    })
    setLyricistList(lyricistListArr)
  }, [artistList])

  const handleSubmit = async (
    values: {
      name: string
      description: string
      audioType: string
      enableDrm: string
      enableOffline: string
      audioUrl: string
      geoConfig: any
      singerId: any
      lyricistId: any
      composerId: any
    },
    resetForm: any,
  ) => {
    if (selectedFileDataURI != null) {
      //Disable button
      submitButtonRef.current?.setAttribute('disabled', 'true')
      // Activate indicator
      setLoading(true)
      const geoData: any = {
        restrict: false,
        longitude: '',
        radius: '',
        allowedGeoLocations: [
          {
            country: '',
            state: '',
          },
        ],
      }
      if (selectedCountry) {
        // value.geoConfig.allowedGeoLocations.country = selectedCountry
        geoData.allowedGeoLocations[0].country = selectedCountry
      }
      geoData.allowedGeoLocations[0].state =
        values.geoConfig.allowedGeoLocations.state
      geoData.restrict = values.geoConfig.restrict
      const audioData = {
        title: values.name,
        description: values.description,
        accessType: values.audioType,
        // audioUrl: values.audioUrl,
        audioUrl: selectedAudioFile
          ? `${apiEndPoints.s3.base}audios/${selectedAudioFile.name}`
          : values.audioUrl,
        status: MediaContentStatusType.Publish,
        enableDrm: values.enableDrm,
        enableOffline: values.enableOffline,
        geoConfig: [geoData],
      }
      const composersArray: any = values.composerId.map(
        (composer: any) => composer.value,
      )
      const singersArray: any = values.singerId.map(
        (singer: any) => singer.value,
      )
      const lyricistsArray: any = values.lyricistId
        ? values.lyricistId.map((lyricist: any) => lyricist.value)
        : []
      await baseAxios
        .post(apiEndPoints.cms.audio, audioData)
        .then(async (response: any) => {
          const audioTrackPostData = {
            name: values.name,
            description: values.description,
            imageUrl: selectedFileDataURI,
            composers: composersArray,
            singers: singersArray,
            lyricists: lyricistsArray,
            audioId: response._id,
          }
          await baseAxios
            .post(
              apiEndPoints.cms.audioTracks(albumData._id),
              audioTrackPostData,
            )
            .then(async (dataResp: any) => {
              await fetchData()
              if (selectedAudioFile) uploadFile(dataResp, albumData._id)

              //Disable button
              submitButtonRef.current?.removeAttribute('disabled')
              // Activate indicator
              setLoading(false)
              audioTrackCloseRef.current?.click()
              resetForm()

              toast.success('Audio Track Created Successfully!')
            })
            .catch(e => {
              submitButtonRef.current?.removeAttribute('disabled')
              // Activate indicator
              setLoading(false)
            })
        })
        .catch(e => {
          submitButtonRef.current?.removeAttribute('disabled')
          // Activate indicator
          setLoading(false)
        })
    }
  }

  const handleUpdate = (
    values: {
      name: string
      description: string
      audioType: string
      enableDrm: string
      enableOffline: string
      audioUrl: string
      geoConfig: any
      singerId: any
      lyricistId: any
      composerId: any
    },
    albumId: any,
    audioTrackId: any,
    audioId: any,
    resetForm: any,
  ) => {
    //Disable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const geoData: any = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: [
        {
          country: '',
          state: '',
        },
      ],
    }
    if (selectedCountry) {
      // value.geoConfig.allowedGeoLocations.country = selectedCountry
      geoData.allowedGeoLocations[0].country = selectedCountry
    }
    geoData.allowedGeoLocations[0].state =
      values.geoConfig.allowedGeoLocations.state
    geoData.restrict = values.geoConfig.restrict
    // if (selectedFileDataURI != null) {
    const audioData = {
      title: values.name,
      description: values.description,
      accessType: values.audioType,
      audioUrl: selectedAudioFile
        ? `${apiEndPoints.s3.base}audios/${selectedAudioFile.name}`
        : values.audioUrl,
      status: MediaContentStatusType.Publish,
      enableDrm: values.enableDrm,
      enableOffline: values.enableOffline,
      geoConfig: [geoData],
    }
    const composersArray: any = values.composerId.map(
      (composer: any) => composer.value,
    )
    const singersArray: any = values.singerId.map((singer: any) => singer.value)
    const lyricistsArray: any = values.lyricistId.map(
      (lyricist: any) => lyricist.value,
    )
    baseAxios
      .put(apiEndPoints.cms.audioWithId(audioId), audioData)
      .then((response: any) => {
        const audioTrackPostData = {
          name: values.name,
          description: values.description,
          imageUrl:
            selectedFileDataURI != null ? selectedFileDataURI : modalImage,
          composers: composersArray,
          singers: singersArray,
          lyricists: lyricistsArray,
          audioId: audioId,
          workSpaceId: selectedWorkspaceId,
          uploadStatus: selectedAudioFile
            ? UploadStatusType.Processing
            : values.audioUrl
            ? UploadStatusType.Completed
            : UploadStatusType.Completed,
        }

        baseAxios
          .put(
            apiEndPoints.cms.audioTracksWithId(albumId, audioTrackId),
            audioTrackPostData,
          )
          .then(async (dataResp: any) => {
            await fetchData()
            if (selectedAudioFile) uploadFile(dataResp, albumId)

            //Disable button
            submitButtonRef.current?.removeAttribute('disabled')
            // Activate indicator
            setLoading(false)
            audioTrackCloseRef.current?.click()
            resetForm()
          })
          .catch(e => {
            submitButtonRef.current?.removeAttribute('disabled')
            // Activate indicator
            setLoading(false)
          })
      })
      .catch(e => {
        submitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
      })
    // }
  }

  const openPicker = (type: string) => {
    if (type === 'thumbnail') {
      inputFileSelect.current?.click()
    } else {
      inputFileSelectAudio.current?.click()
    }
  }

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const handleAudioSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedAudioFile(file)
  }

  const handleCountrySelect = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event.target.value)
    initialValues.geoConfig.allowedGeoLocations.country = event.target.value
    setSelectedCountry(event.target.value)
    setStates(statesArr)
  }

  const handleCountrySelectSetValue = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event)
    setSelectedCountry(event)
    setStates(statesArr)
  }

  const deleteAudioTrack = async (
    audioTrackId: string,
    audioId: string,
    albumId: string,
  ) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.audioWithId(audioId))
            .then((response: any) => {
              baseAxios
                .delete(
                  apiEndPoints.cms.audioTracksWithId(albumId, audioTrackId),
                )
                .then(async (res: any) => {
                  await fetchData()
                  // dispatch(getAudioTracks({ albumId: albumData._id }))
                  // audioTrackList = album.audioTracks
                  toast.success('Audio Track Deleted!')
                })
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const onUploadChange = (e: any) => {
    setUploadType(e.currentTarget.value)
  }

  const uploadFile = async (data: any, albumId: any) => {
    status = basic.uploadStatus
    if (selectedAudioFile) {
      dispatch(setUploadStatus({ id: data._id, status: 0 }))
      let percentage: any = undefined
      // let toastCount = 0

      const videoUploaderOptions = {
        fileName: `audios/${selectedAudioFile.name}`,
        file: selectedAudioFile,
      }
      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)

      uploader
        .onProgress(async ({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setProgress(percentage)
            dispatch(setUploadStatus({ id: data._id, status: percentage }))
            status = basic.uploadStatus
            if (percentage == 100) {
              setTimeout(async () => {
                dispatch(setUploadStatus({ id: data._id, status: 100 }))
                await handleUploadStatus(
                  data,
                  UploadStatusType.Completed,
                  albumId,
                )
                status = basic.uploadStatus
                setProgress(101)
              }, 8000)
            }
          }
        })
        .onError(async (error: any) => {
          await handleUploadStatus(data, UploadStatusType.Failed, albumId)
          selectedAudioFile(undefined)
        })
      uploader.start()
    }
  }

  const handleUploadStatus = async (
    updateData: any,
    status: string,
    albumId: any,
  ) => {
    const data = Object.assign({}, updateData)
    data.uploadStatus = status
    await baseAxios.put(
      apiEndPoints.cms.audioTracksWithId(albumId, data._id),
      data,
    )
    await fetchData()
    toast.success('Audio Uploaded Successfully!')
  }

  const getType = (type: string): string => {
    switch (type) {
      case AudioType.SingleAudio:
        return 'Single Audio'
      case AudioType.MusicAlbum:
        return 'Music Album'
      case AudioType.Podcast:
        return 'Podcast'
      case AudioType.AudioPlaylist:
        return 'Audio Playlist'
      default:
        return ''
    }
  }

  return (
    <CustomHeader pageName={'View Album'}>
      <div id="content-page" className="content-page ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4 mb-4">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: '500px',
                  width: '100%',
                  // display: 'wrap',
                  // flexWrap: 'wrap',
                }}
              >
                {' '}
                <img
                  src={
                    selectedIconImage
                      ? selectedIconImage
                      : albumData.thumbnailImageUrl
                  }
                  className=""
                  alt="author-profile"
                  style={{
                    height: '100%',
                    width: '100%',
                    // display: 'wrap',
                    // flexWrap: 'wrap',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    // background: 'yellow',
                    height: '500px',
                    width: '92%',
                    position: 'absolute',
                    alignItems: 'end',
                    justifyContent: 'center',
                    top: 0,
                  }}
                >
                  {currentAudio != null && (
                    <AudioPlayer
                      autoPlay
                      src={currentAudio}
                      volume={0.5}
                      showSkipControls={false}
                    />
                  )}
                </div>
              </div>
              <div className="NewPlayer"> </div>
              <div className="iq-card">
                <div className="iq-card-header">Album Detail</div>
                <div className="iq-card-body">
                  <div className="">
                    <p className="m-0">Title</p>
                    <h6>{albumData.name}</h6>
                  </div>{' '}
                  <div className="mt-3">
                    <p className="m-0">Description</p>
                    <h6>{albumData.description}</h6>
                  </div>
                  <div className="row">
                    <div className="col">
                      {' '}
                      <div className="mt-3">
                        <p className="m-0">Release on</p>
                        <h6>
                          {moment(albumData.releaseDate).format('DD/MM/YYYY')}
                        </h6>
                      </div>
                    </div>

                    <div className="col">
                      {' '}
                      <div className="mt-3">
                        <p className="m-0">Type</p>
                        <h6>{getType(albumData.type)}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="m-0">Artists</p>
                    {albumData &&
                      albumData.artists &&
                      albumData.artists.length > 0 &&
                      albumData.artists.map((artistItem: any) => {
                        return <h6>{artistItem.name}</h6>
                      })}
                  </div>
                  <div className="mt-3">
                    <p className="m-0">Composers</p>
                    {audioTrack && audioTrack.length > 0
                      ? audioTrack[0].composers &&
                        audioTrack[0].composers.map((artistItem: any) => {
                          return <h6>{artistItem.name}</h6>
                        })
                      : null}
                  </div>
                  <div className="mt-3">
                    <p className="m-0">Singers</p>
                    {audioTrack && audioTrack.length > 0
                      ? audioTrack[0].singers &&
                        audioTrack[0].singers.map((artistItem: any) => {
                          return <h6>{artistItem.name}</h6>
                        })
                      : null}
                  </div>
                  <div className="mt-3">
                    <p className="m-0">Lyricist</p>
                    {audioTrack && audioTrack.length > 0
                      ? audioTrack[0].lyricists &&
                        audioTrack[0].lyricists.map((artistItem: any) => {
                          return <h6>{artistItem.name}</h6>
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-8">
              {' '}
              {albumData.type !== AudioType.SingleAudio && (
                <div style={{ cursor: 'pointer' }}>
                  <a
                    data-toggle="modal"
                    className="AudioTrack"
                    data-target="#AddAudioTrackModel"
                    onClick={() => discardFields()}
                  >
                    + Add New Audio Track
                  </a>
                </div>
              )}
              {pageLoading && (
                <div
                  className="container d-flex justify-content-center align-items-center"
                  style={{ height: '200px' }}
                >
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#e20e02"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
              {!pageLoading && audioTrack && audioTrack.length > 0 && (
                <div className="iq-card mt-5">
                  <div className="mt-8">
                    {albumData.type !== AudioType.SingleAudio && (
                      <h4>Audio Tracks</h4>
                    )}
                    {/* <p>{audioTrack.description} </p> */}
                    <div className="iq-card-body">
                      {audioTrack.map((audioTrackData: any, index: number) => {
                        return (
                          <div className="table-view">
                            <table
                              className="data-tables table movie_table mb-0"
                              style={{ width: '100%' }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <img
                                      src={audioTrackData.imageUrl}
                                      // className="img-fluid avatar-50"
                                      alt="author-profile"
                                      height={120}
                                      width={80}
                                    />
                                  </td>
                                  <td>{audioTrackData.name}</td>
                                  <td>{audioTrackData.description}</td>
                                  {audioTrackData.uploadStatus &&
                                  audioTrackData.uploadStatus ===
                                    UploadStatusType.Processing ? (
                                    <td>
                                      {status.some(
                                        (item: any) =>
                                          item.id === audioTrackData._id,
                                      )
                                        ? status.find(
                                            (item: any) =>
                                              item.id === audioTrackData._id,
                                          )?.status + '%'
                                        : UploadStatusType.Interrupted}
                                    </td>
                                  ) : (
                                    <td>
                                      {audioTrackData.uploadStatus
                                        ? audioTrackData.uploadStatus.toUpperCase()
                                        : 'NA'}
                                    </td>
                                  )}
                                  <td>
                                    <div className="justify-content-end d-flex p-3  list-user-action">
                                      <a
                                        className="iq-bg-warning"
                                        title=""
                                        onClick={() => {
                                          setCurrentAudio(
                                            audioTrackData.audio[0].audioUrl,
                                          )
                                          setSelectedIconImage(
                                            audioTrackData.imageUrl,
                                          )
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-play"></i>
                                      </a>
                                      <a
                                        className="iq-bg-success"
                                        data-toggle="modal"
                                        data-target="#AddAudioTrackModel"
                                        onClick={() =>
                                          setSelectedAudioTrack(
                                            audioTrackData,
                                            index,
                                          )
                                        }
                                      >
                                        <i className="ri-pencil-line" />
                                      </a>
                                      <a
                                        className="iq-bg-primary"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Delete"
                                        onClick={() =>
                                          deleteAudioTrack(
                                            audioTrackData._id,
                                            audioTrackData.audioId,
                                            audioTrackData.albumId,
                                          )
                                        }
                                      >
                                        <i className="ri-delete-bin-line" />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
              {!pageLoading && audioTrack && audioTrack.length == 0 && (
                <NoData />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddAudioTrackModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
        ref={audioTrackModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                audioType: string
                enableDrm: string
                enableOffline: string
                audioUrl: string
                geoConfig: object
                singerId: any
                lyricistId: any
                composerId: any
              },
              { resetForm }: any,
            ) => {
              modalOpenType == 'add'
                ? handleSubmit(values, resetForm)
                : handleUpdate(
                    values,
                    albumData._id,
                    selectedAudioTrackId,
                    selectedAudioId,
                    resetForm,
                  )
            }}
          >
            {(formik: any) => {
              const { errors, touched } = formik
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'edit'
                        ? 'Edit Audio Track'
                        : 'Add Audio Track'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={audioTrackCloseRef}
                      onClick={() => {
                        clearFieldOnClose()

                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className=" form-group">
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="textarea"
                              id="text"
                              name="description"
                              rows={5}
                              className="form-control"
                              placeholder="Description"
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {modalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <img src={modalImage} width={100} height={100} />
                            </div>
                          )}
                          <div className="form_gallery form-group">
                            <label
                              onClick={() => openPicker('thumbnail')}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedFile
                                ? selectedFile.name
                                : modalOpenType == 'add'
                                ? 'Audio thumbnail'
                                : 'New Audio thumbnail'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelect}
                              name="movieThumbnail"
                              onChange={e => handleFileSelect(e)}
                              accept=".png, .jpg, .jpeg, .webp"
                            />
                            <ErrorMessage
                              name="movieThumbnail"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="row ">
                            {/* <div className="col-md-6 form-group">
                              <label htmlFor="form_gallery-upload">
                                Release Date
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                data-placeholder="Release date"
                                name="releaseDate"
                              />
                              <ErrorMessage
                                name="releaseDate"
                                component="span"
                                className="text-primary"
                              />
                            </div> */}
                            <div className="col-md-12 form-group">
                              {/* <label htmlFor="form_gallery-upload">Type</label> */}
                              <Field
                                as="select"
                                name="audioType"
                                className="form-control"
                              >
                                <option value="">Select Type</option>
                                <option value="free">Free</option>
                                <option value="pay_per_view">
                                  Pay Per View
                                </option>
                              </Field>
                              <ErrorMessage
                                name="audioType"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row m-0">
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableDrm"
                                  name="enableDrm"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableDrm"
                                >
                                  Enable DRM availability
                                </label>
                                <ErrorMessage
                                  name="enableDrm"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableOffline"
                                  name="enableOffline"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableOffline"
                                >
                                  Offline access
                                </label>
                                <ErrorMessage
                                  name="enableOffline"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="">GEO CONFIG</p>

                          <div className="form-group custom-switch">
                            <Field
                              type="checkbox"
                              className="custom-control-input"
                              id="geoConfig.restrict"
                              name="geoConfig.restrict"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="geoConfig.restrict"
                            >
                              Allow geo configure
                            </label>
                          </div>

                          {/* {geoConfig == true && ( */}
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.country"
                                  className="form-control"
                                  onChange={(e: any) => handleCountrySelect(e)}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country: any) => {
                                    return (
                                      <option
                                        value={country.isoCode}
                                        key={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.country"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.state"
                                  className="form-control"
                                >
                                  <option value="">Select State</option>
                                  {states.length > 0 &&
                                    states.map((state: any) => {
                                      return (
                                        <option
                                          value={state.isoCode}
                                          key={state.isoCode}
                                        >
                                          {state.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.state"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio6"
                                name="customRadio-1"
                                value="url"
                                checked={uploadType == 'url' ? true : false}
                                className="custom-control-input"
                                onChange={(e: any) => onUploadChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                {' '}
                                Audio URL{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio7"
                                name="customRadio-1"
                                value="upload"
                                className="custom-control-input"
                                onChange={(e: any) => onUploadChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio7"
                              >
                                {' '}
                                Upload File{' '}
                              </label>
                            </div>
                          </div>
                          {uploadType == 'url' ? (
                            <div className="form-group">
                              <Field
                                type="text"
                                className="form-control"
                                placeholder={
                                  modalOpenType == 'edit'
                                    ? 'New Audio Url'
                                    : 'Audio Url'
                                }
                                name="audioUrl"
                              />
                              <ErrorMessage
                                name="audioUrl"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          ) : (
                            <div className="form_gallery form-group">
                              <label
                                onClick={() => openPicker('video')}
                                htmlFor="form_gallery-upload"
                              >
                                {selectedAudioFile != null
                                  ? selectedAudioFile.name
                                  : 'Audio File'}
                              </label>
                              <input
                                type="file"
                                ref={inputFileSelectAudio}
                                name="audioFile"
                                onChange={e => handleAudioSelect(e)}
                                accept=".mp3, .aac, .wav"
                              />
                              <ErrorMessage
                                name="audioFile"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          )}
                          <div className=" form-group">
                            <Field
                              name="singerId"
                              id="singerId"
                              placeholder="Select Singers"
                              isMulti={true}
                              component={SelectBox}
                              options={lyricistList}
                            />
                            <ErrorMessage
                              name="singerId"
                              component="span"
                              className="text-primary"
                            />
                            {/* <Field
                              as="select"
                              className="form-control"
                              name="singerId"
                              multiple={true}
                            >
                              <option value="">Select Singer</option>
                              {artistList.map((artist: any) => {
                                return (
                                  <option value={artist._id} key={artist._id}>
                                    {artist.name}
                                  </option>
                                )
                              })}
                            </Field>
                            <ErrorMessage
                              name="singerId"
                              component="span"
                              className="text-primary"
                            /> */}
                          </div>
                          <div className=" form-group">
                            <Field
                              name="composerId"
                              id="composerId"
                              placeholder="Select Composers"
                              isMulti={true}
                              component={SelectBox}
                              options={lyricistList}
                            />
                            <ErrorMessage
                              name="composerId"
                              component="span"
                              className="text-primary"
                            />
                            {/* <Field
                              as="select"
                              className="form-control"
                              name="composerId"
                              multiple={true}
                            >
                              <option value="">Select Composer</option>
                              {artistList.map((artist: any) => {
                                return (
                                  <option value={artist._id} key={artist.name}>
                                    {artist.name}
                                  </option>
                                )
                              })}
                            </Field>
                            <ErrorMessage
                              name="singerId"
                              component="span"
                              className="text-primary"
                            /> */}
                          </div>

                          <div className=" form-group">
                            <Field
                              name="lyricistId"
                              id="lyricistId"
                              placeholder="Select Lyricists"
                              isMulti={true}
                              component={SelectBox}
                              options={lyricistList}
                            />
                            <ErrorMessage
                              name="lyricistId"
                              component="span"
                              className="text-primary"
                            />
                            {/* <Field
                              as="select"
                              className="form-control"
                              name="lyricistId"
                              multiple={true}
                            >
                              <option value="">Select Composer</option>
                              {artistList.map((artist: any) => {
                                return (
                                  <option value={artist._id} key={artist.name}>
                                    {artist.name}
                                  </option>
                                )
                              })}
                            </Field>
                            <ErrorMessage
                              name="lyricistId"
                              component="span"
                              className="text-primary"
                            /> */}
                          </div>
                          {/* <div className="form_gallery form-group">
                            <label
                              onClick={() => openPicker('audio')}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedAudioFile != null
                                ? selectedAudioFile.name
                                : 'Audio File'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelectAudio}
                              name="videoFile"
                              onChange={e => handleAudioSelect(e)}
                              accept=".mp3, .wav"
                            />
                            <ErrorMessage
                              name="videoFile"
                              component="span"
                              className="text-primary"
                            />
                          </div> */}
                        </div>
                      </div>
                      {/* <p className="mt-2">Cast</p> */}
                      {/* <div className="form-control"> */}
                      {/* </div> */}
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              {modalOpenType == 'edit' ? 'Update' : 'Submit'}
                            </span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default ViewAlbum
