import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectVideoPlaylistState = (state: RootState) =>
  state.VideoPlaylist || initialState

export const videoPlaylistSelector = createSelector(
  [selectVideoPlaylistState],
  VideoPlaylist => VideoPlaylist,
)
