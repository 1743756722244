import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import { resetVerify } from 'store/userManagement/slice'

const changePasswordSchema = Yup.object().shape({
  verificationCode: Yup.string().required('OTP is required'),
  password: Yup.string()
    .required('Password is required')
    .min(4, 'Password is too short - should be 4 chars min'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf(
      [Yup.ref('password'), null],
      'Password & Confirm Password must match',
    ),
})

const NewPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [initialValues] = useState({
    email: location.state.email,
    verificationCode: '',
    password: '',
    confirmPassword: '',
  })

  const handleSubmit = (values: {
    verificationCode: string
    password: string
    confirmPassword: string
  }) => {
    dispatch(resetVerify({ data: values, navigate }))
  }

  return (
    <section className="sign-in-page">
      <div className="container h-100">
        <div className="row justify-content-center align-items-start height-self-center">
          <div className="col-12 h-100">
            {/* <p className="w-100">Logo</p> */}
            <div
              className=" d-flex align-items-center justify-content-center"
              style={{ height: '90%' }}
            >
              <div className="col-lg-5 col-md-12 align-self-center form-padding">
                <div className="sign-user_card ">
                  <div className="sign-in-page-data">
                    <div className="sign-in-from w-100 m-auto">
                      <h3 className="mb-3 text-center sign-in-font-color ">
                        Set Password
                      </h3>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={changePasswordSchema}
                        onSubmit={(values: {
                          verificationCode: string
                          password: string
                          confirmPassword: string
                        }) => {
                          handleSubmit(values)
                        }}
                      >
                        {() => {
                          return (
                            <Form className="mt-4">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  name="verificationCode"
                                  id="verificationCode"
                                  className="form-control  mb-0"
                                  placeholder="Enter OTP"
                                />
                                <ErrorMessage
                                  name="verificationCode"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="form-control  mb-0"
                                  placeholder="Enter Password"
                                />
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="password"
                                  name="confirmPassword"
                                  id="confirmPassword"
                                  className="form-control  mb-0"
                                  placeholder="Confirm Password"
                                />
                                <ErrorMessage
                                  name="confirmPassword"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="sign-info">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  continue
                                </button>
                              </div>
                            </Form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewPassword
