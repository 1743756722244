import { PayloadAction, createAction } from '@reduxjs/toolkit'
import { createSlice } from 'utils/@reduxjs/toolkit'
import { Menu } from './types'

// eslint-disable-next-line
const setMenu = createAction<any>('users/setMenu')
const setSubMenu = createAction<any>('users/setSubMenu')

export const initialState: Menu = {
  selectedMenu: 0,
  selectedSubMenu: {},
}

const MenuSlice = createSlice({
  name: 'Menu',
  initialState: initialState,
  reducers: {
    setSideMenu(state, action: PayloadAction<any>) {
      state.selectedMenu = action.payload
    },
    setSideSubMenu(state, action: PayloadAction<any>) {
      state.selectedSubMenu = action.payload
    },
  },
})

export { setMenu, setSubMenu }

export const { actions, reducer, name: sliceKey } = MenuSlice
