import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectMultiVideoState = (state: RootState) =>
  state.MultiVideo || initialState

export const multiVideoSelector = createSelector(
  [selectMultiVideoState],
  MultiVideo => MultiVideo,
)
