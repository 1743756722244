/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { roleSelector } from 'store/roleManagement/selectors'
import CustomHeader from 'components/CustomHeader'
import { getPermissions, getRoles } from 'store/roleManagement/slice'
import { toast } from 'react-toastify'
import { PERMISSIONS } from 'model/auth'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import Swal from 'sweetalert2'

const createRolesSchema = Yup.object().shape({
  name: Yup.string().required('Role Name is required'),
  permission: Yup.array(),
})

const createPermissionSchema = Yup.object().shape({
  name: Yup.string().required('Role Name is required'),
})

const Roles = () => {
  const [totalItems, setTotalItems] = useState(0)
  const [totalItemsPermission, setTotalItemsPermission] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPagePermission, setCurrentPagePermission] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [sortingPermission, setSortingPermission] = useState({
    field: '',
    order: '',
  })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [searchPermission, setSearchPermission] = useState('')
  const [pageSizePermission, setPageSizePermission] = useState(5)
  const dispatch = useDispatch()
  const role = useSelector(roleSelector)
  const permissionCloseRef = useRef<HTMLButtonElement>(null)
  const roleCloseRef = useRef<HTMLButtonElement>(null)
  const [roleModalOpenType, setRoleModalOpenType] = useState('add')
  const [permissionModalOpenType, setPermissionModalOpenType] = useState('add')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [roleSelectedValues, setRoleSelectedValues] = useState([])
  const [selectedRoleId, setSelectedRoleId] = useState('')
  const [selectedPermissionId, setSelectedPermissionId] = useState('')

  useEffect(() => {
    dispatch(getRoles({}))
    dispatch(getPermissions({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let roleData = role.roles
  let permission = role.permissions

  const permissionsList: any = []

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Role Name', field: 'role_name', sortable: false },
    { name: 'Permission', field: 'permission', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const permissionHeader = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Permission Name', field: 'permission_name', sortable: false },
    { name: 'Permission Code', field: 'permission_code', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const rolesData = useMemo(() => {
    let computedRoles: any = roleData

    if (search) {
      computedRoles = computedRoles.filter((role: { name: string }) =>
        role.name.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedRoles.length)

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedRoles.slice(firstPageIndex, lastPageIndex)
  }, [roleData, search, currentPage, pageSize])

  const permissionsData = useMemo(() => {
    let computedPermissions: any = permission

    if (searchPermission) {
      computedPermissions = computedPermissions.filter(
        (permissionDa: { name: string | string[] }) =>
          permissionDa.name.includes(searchPermission),
      )
    }

    setTotalItemsPermission(computedPermissions.length)

    //Current Page slice
    const firstPageIndex = (currentPagePermission - 1) * pageSizePermission
    const lastPageIndex = firstPageIndex + pageSizePermission
    return computedPermissions.slice(firstPageIndex, lastPageIndex)
  }, [permission, searchPermission, currentPagePermission, pageSizePermission])

  const [initialValues] = useState({
    name: '',
    permission: [],
  })

  const [initialValuesPermission] = useState({
    name: '',
    code: '',
  })

  const handleSubmit = async (
    value: { name: string; permission: any },
    resetForm: any,
  ) => {
    const resp = await baseAxios.post(apiEndPoints.users.createRoleData, value)
    if (resp) {
      dispatch(getRoles({}))
      roleData = role.roles
      roleCloseRef.current?.click()
      resetForm()
    }
  }

  const handleSubmitPermission = async (
    value: { name: string; code: string },
    resetForm: any,
  ) => {
    const resp = await baseAxios.post(
      apiEndPoints.users.createPermissionData,
      value,
    )
    if (resp) {
      dispatch(getPermissions({}))
      permission = role.permissions
      permissionCloseRef.current?.click()
      resetForm()
    }
  }

  const handleRoleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.users.deleteRoleData(value))
            .then(resp => {
              dispatch(getRoles({}))
              roleData = role.roles
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const handlePermissionDelete = async (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.users.deletePermissionData(value))
            .then(resp => {
              dispatch(getPermissions({}))
              permission = role.permissions
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const setSelectedRole = async (value: any) => {
    await clearModalRole()
    const permissionSelectedValuesArray: any = []
    setRoleModalOpenType('edit')
    initialValues.name = value.name
    value.permission.forEach((permissionItem: any) => {
      const permissionTempPush = {
        value: permissionItem._id,
        label: permissionItem.name,
      }
      permissionSelectedValuesArray.push(permissionTempPush)
    })
    setSelectedRoleId(value._id)
    setRoleSelectedValues(permissionSelectedValuesArray)
  }

  const clearModalRole = () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    setRoleModalOpenType('add')
    initialValues.name = ''
    setSelectedRoleId('')
    setRoleSelectedValues([])
    // }
  }

  const setSelectedPermission = async (value: any) => {
    await clearModalPermission()
    setPermissionModalOpenType('edit')
    initialValuesPermission.name = value.name
    initialValuesPermission.code = value.code
    setSelectedPermissionId(value._id)
  }

  const clearModalPermission = () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    setPermissionModalOpenType('add')
    initialValuesPermission.name = ''
    initialValuesPermission.code = ''
    setSelectedPermissionId('')
    // }
  }

  const handleRoleUpdate = async (
    value: { name: string; permission: any },
    roleId: string,
    resetForm: any,
  ) => {
    if (roleId != null && roleId != undefined && roleId != '') {
      const resp = await baseAxios.put(
        apiEndPoints.users.updateRoleData(roleId),
        value,
      )
      if (resp) {
        dispatch(getRoles({}))
        roleData = role.roles
        roleCloseRef.current?.click()
        resetForm()
      }
    } else {
      toast.error('Invalid Role Id')
    }
  }

  const handlePermissionUpdate = async (
    value: { name: string; code: string },
    permissionId: string,
    resetForm: any,
  ) => {
    const resp = await baseAxios.put(
      apiEndPoints.users.updatePermissionData(permissionId),
      value,
    )
    if (resp) {
      dispatch(getPermissions({}))
      permission = role.permissions
      permissionCloseRef.current?.click()
      resetForm()
    }
  }
  permission.forEach((permissionItem: any) => {
    const permissionTempPush = {
      value: permissionItem._id,
      label: permissionItem.name,
    }
    permissionsList.push(permissionTempPush)
  })

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <ul className="nav nav-tabs" id="myTab-two" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab-two"
                data-toggle="tab"
                href="#role-two"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                Roles
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-two"
                data-toggle="tab"
                href="#permission-two"
                role="tab"
                aria-controls="permission"
                aria-selected="false"
              >
                Permissions
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="role-two"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Role Lists</h4>
                      </div>
                      {getPermissions(PERMISSIONS.CREATE_ROLE) && (
                        <div className="sign-info">
                          <a
                            data-toggle="modal"
                            data-target="#ModalCenter"
                            className="btn btn-primary mx-1"
                            onClick={() => clearModalRole()}
                          >
                            Add Role
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="iq-card-body">
                      <div className="table-view">
                        <TableLength
                          handleLengthChange={(v: any) =>
                            setPageSize(parseInt(v))
                          }
                          handleSearchValue={(searchVal: any) =>
                            setSearch(searchVal)
                          }
                        />
                        <table
                          className="data-tables table movie_table"
                          style={{ width: '100%' }}
                        >
                          <TableHeader
                            headers={headers}
                            onSorting={(field: any, order: any) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {rolesData.map((role: any, index: number) => (
                              <tr key={`role-${index}`}>
                                <td>{index + 1}</td>
                                <td>{role.name}</td>
                                <td>
                                  {role.permission.map((permission: any) => {
                                    return (
                                      <span
                                        className="badge iq-bg-primary mx-1"
                                        key={`permission-data-${role._id}-${index}`}
                                      >
                                        {permission.name}
                                      </span>
                                    )
                                  })}
                                </td>
                                <td>
                                  <div className="flex align-items-center list-user-action">
                                    {getPermissions(
                                      PERMISSIONS.UPDATE_ROLE,
                                    ) && (
                                      <a
                                        className="iq-bg-success"
                                        data-toggle="modal"
                                        data-target="#ModalCenter"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Edit"
                                        onClick={() => setSelectedRole(role)}
                                      >
                                        <i className="ri-pencil-line" />
                                      </a>
                                    )}
                                    {getPermissions(
                                      PERMISSIONS.DELETE_ROLE,
                                    ) && (
                                      <a
                                        className="iq-bg-primary"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        type="button"
                                        data-original-title="Delete"
                                        onClick={() =>
                                          handleRoleDelete(role._id)
                                        }
                                      >
                                        <i className="ri-delete-bin-line" />
                                      </a>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Pagination
                          currentPage={currentPage}
                          totalCount={totalItems}
                          pageSize={pageSize}
                          onPageChange={(page: React.SetStateAction<number>) =>
                            setCurrentPage(page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="permission-two"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Permission Lists</h4>
                      </div>
                      {getPermissions(PERMISSIONS.CREATE_PERMISSION) && (
                        <div className="sign-info">
                          <a
                            data-toggle="modal"
                            data-target="#permissionModal"
                            className="btn btn-primary"
                            onClick={() => clearModalPermission()}
                          >
                            Add Permission
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="iq-card-body">
                      <div className="table-view">
                        <TableLength
                          handleLengthChange={(v: any) =>
                            setPageSizePermission(parseInt(v))
                          }
                          handleSearchValue={(searchVal: any) =>
                            setSearchPermission(searchVal)
                          }
                        />
                        <table
                          className="data-tables table movie_table"
                          style={{ width: '100%' }}
                        >
                          <TableHeader
                            headers={permissionHeader}
                            onSorting={(field: any, order: any) =>
                              setSortingPermission({ field, order })
                            }
                          />
                          <tbody>
                            {permissionsData.map(
                              (permission: any, index: number) => (
                                <tr key={`permission-table-${index}`}>
                                  <td>{index + 1}</td>
                                  <td>{permission.name}</td>
                                  <td>{permission.code}</td>
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      {getPermissions(
                                        PERMISSIONS.UPDATE_PERMISSION,
                                      ) && (
                                        <a
                                          className="iq-bg-success"
                                          data-placement="top"
                                          data-toggle="modal"
                                          data-target="#permissionModal"
                                          title=""
                                          data-original-title="Edit"
                                          onClick={() =>
                                            setSelectedPermission(permission)
                                          }
                                        >
                                          <i className="ri-pencil-line" />
                                        </a>
                                      )}
                                      {getPermissions(
                                        PERMISSIONS.DELETE_PERMISSION,
                                      ) && (
                                        <a
                                          className="iq-bg-primary"
                                          type="button"
                                          onClick={() =>
                                            handlePermissionDelete(
                                              permission._id,
                                            )
                                          }
                                        >
                                          <i className="ri-delete-bin-line" />
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                        <Pagination
                          currentPage={currentPagePermission}
                          totalCount={totalItemsPermission}
                          pageSize={pageSizePermission}
                          onPageChange={(page: React.SetStateAction<number>) =>
                            setCurrentPagePermission(page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={createRolesSchema}
            onSubmit={(
              values: { name: string; permission: any },
              { resetForm }: any,
            ) => {
              roleModalOpenType == 'add'
                ? handleSubmit(values, resetForm)
                : handleRoleUpdate(values, selectedRoleId, resetForm)
            }}
          >
            {(formik: any) => {
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {roleModalOpenType == 'add' ? 'Add Role' : 'Edit Role'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={roleCloseRef}
                      onClick={() => {
                        setRoleModalOpenType('add')
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control  mb-0"
                                placeholder="Enter Role Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12">
                              {/* <Field
                                name="permission"
                                id="permission"
                                className="form-control season-select"
                                component={SelectBox}
                                options={permissionsList}
                                isMulti={true}
                                value={roleSelectedValues}
                              /> */}
                              <Field
                                as="select"
                                name="permission"
                                className="form-control season-select"
                                multiple={true}
                              >
                                <option value="">Select Permission</option>
                                {permissionsList.map(
                                  (permission: any, index: any) => {
                                    return (
                                      <option
                                        value={permission.value}
                                        key={`permission-list-${index}`}
                                      >
                                        {permission.label}
                                      </option>
                                    )
                                  },
                                )}
                              </Field>
                              <ErrorMessage
                                name="permission"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                          <button type="submit" className="btn btn-primary">
                            {roleModalOpenType == 'add' ? 'Submit' : 'Update'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
      <div
        className="modal fade"
        id="permissionModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="permissionModalTile"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValuesPermission}
            validationSchema={createPermissionSchema}
            onSubmit={(
              values: { name: string; code: string },
              { resetForm }: any,
            ) => {
              permissionModalOpenType == 'add'
                ? handleSubmitPermission(values, resetForm)
                : handlePermissionUpdate(
                    values,
                    selectedPermissionId,
                    resetForm,
                  )
            }}
          >
            {(formik: any) => {
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="permissionModalTile">
                      {permissionModalOpenType == 'add'
                        ? 'Add Permission'
                        : 'Edit Permission'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={permissionCloseRef}
                      onClick={() => {
                        setPermissionModalOpenType('add')
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control  mb-0"
                                placeholder="Enter Permission Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="code"
                                id="code"
                                className="form-control  mb-0"
                                placeholder="Enter Permission Code"
                              />
                              <ErrorMessage
                                name="code"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                          <button type="submit" className="btn btn-primary">
                            {permissionModalOpenType == 'add'
                              ? 'Submit'
                              : 'Update'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default Roles
