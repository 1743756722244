/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import CustomHeader from 'components/CustomHeader'
import { ThreeDots } from 'react-loader-spinner'

import moment from 'moment'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getIsRoot,
  getPermission,
  getStatus,
  getTenantId,
} from 'utils/helpers/systemHelpers'
import PublishedVideos from '../Published'
import { artistSelector } from 'store/artistManagement/selector'
import ApprovedVideos from '../Approved'
import { PERMISSIONS } from 'model/auth'
import { getTenants } from 'store/roleManagement/slice'
import { getCategories } from 'store/basicManagement/slice'
import { basicSelector } from 'store/basicManagement/selector'
import { roleSelector } from 'store/roleManagement/selectors'
import { MediaContentStatusType } from 'utils/enum'
import Swal from 'sweetalert2'
import { FormikHelpers } from 'formik/dist/types'
import NoData from 'components/NoData/nodata'
import SelectBox from 'components/Select'

const videoValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().optional(),
  // movieThumbnail: Yup.string().required('Movie Thumbnail is required'),
  tenantId: Yup.string().required('Tenant is required'),
  categoryId: Yup.string().required('Category is required'),
})

const VideoPlaylistList = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const singleVideoCloseRef = useRef<HTMLButtonElement>(null)
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const basic = useSelector(basicSelector)
  const role = useSelector(roleSelector)
  const movieModalRef = useRef<any>(null)
  const [modalImageThumb, setModalImageThumb]: any = useState(null)
  const [multiVideoID, setMultiVideoId]: any = useState(null)
  const [selectedMultiVideo, setSelectedMultiVideo]: any = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const approveButtonRef = useRef<HTMLButtonElement>(null)
  const [selectedBtnId, setSelectedBtnId] = useState(-1)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const publishButtonRef = useRef<HTMLButtonElement>(null)
  const [videoPlaylist, setVideoPlaylist]: any = useState(null)
  const [tenantID, setTenantID] = useState(getTenantId())
  // const [uploadStatus, setUploadStatus]: any = useState([{ id: 0, status: 0 }])
  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.videoPlaylistByStatus(MediaContentStatusType.Draft),
    )
    if (response) {
      setVideoPlaylist(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    fetchData()
    dispatch(getTenants({}))
    dispatch(getCategories({}))
  }, [])

  const tenants = role.tenants
  const categories = basic.categories

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const singleVideosData = useMemo(() => {
    const tempDataDraft: any = []
    videoPlaylist &&
      videoPlaylist.length > 0 &&
      videoPlaylist.map((videoPo: any) => {
        tempDataDraft.push(videoPo)
      })
    let computedMultiVideos: any = tempDataDraft

    if (search) {
      computedMultiVideos = computedMultiVideos.filter(
        (singleVideo: { name: string; description: string; genre: string }) =>
          singleVideo.name.toLowerCase().includes(search.toLowerCase()) ||
          singleVideo.description
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          singleVideo.genre.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedMultiVideos.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedMultiVideos.slice(firstPageIndex, lastPageIndex)
  }, [videoPlaylist, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    description: '',
    movieThumbnail: '',
    categoryId: '',
    tenantId: tenantID,
    tags: [],
  })

  const setSelectedVideo = async (value: any) => {
    await clearModal()
    initialValues.name = value.name
    initialValues.description = value.description
    initialValues.categoryId = value.categoryId
    initialValues.tenantId = value.tenantId
    const tagData: any = []
    if (value.tags && value.tags.length > 0) {
      value.tags.forEach((tag: any) => {
        tagData.push({ label: tag, value: tag })
      })
    }
    initialValues.tags = tagData
    setModalImageThumb(value.imageUrl)
    setModalOpenType('edit')
    setMultiVideoId(value._id)
  }

  const clearModal = async () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    initialValues.name = ''
    initialValues.description = ''
    initialValues.categoryId = ''
    initialValues.tenantId = tenantID
    initialValues.tags = []
    setModalImageThumb(null)
    setModalOpenType('add')
    setMultiVideoId('')
    clearFieldOnClose()
    // }
  }
  const handleSubmit = async (value: any, resetForm: any) => {
    if (!submitButtonRef.current) {
      return
    }
    if (selectedFileDataURI) {
      //Disable button
      submitButtonRef.current?.setAttribute('disabled', 'true')
      // Activate indicator
      setLoading(true)
      const tagsData: any = []
      if (value.tags.length > 0) {
        value.tags.forEach((tag: any) => {
          tagsData.push(tag.value)
        })
      }
      const multiVideoData = {
        name: value.name,
        description: value.description,
        imageUrl: selectedFileDataURI,
        status: MediaContentStatusType.Draft,
        tenantId: value.tenantId,
        categoryId: value.categoryId,
        tags: tagsData,
      }
      baseAxios
        .post(apiEndPoints.cms.videoPlaylist, multiVideoData)
        .then(async (singleResp: any) => {
          await fetchData()
          //Enable button
          submitButtonRef.current?.removeAttribute('disabled')
          // Disable indicator
          setLoading(false)
          setSelectedFileDataURI(null)
          inputFileSelect.current!.value = ''
          toast.success('Video Playlist added successfully! ')
          videoPlaylist.videoPlaylists
          singleVideoCloseRef.current?.click()
          resetForm()
        })
        .catch((err: any) => {
          submitButtonRef.current?.removeAttribute('disabled')
          // Disable indicator
          setLoading(false)
        })
    } else {
      toast.error('Please select Thumbnail Image')
    }
  }

  const handleUpdate = async (value: any, multiVideoId: string) => {
    //Disable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const tagsData: any = []
    if (value.tags.length > 0) {
      value.tags.forEach((tag: any) => {
        tagsData.push(tag.value)
      })
    }
    const multiVideoData = {
      name: value.name,
      description: value.description,
      imageUrl:
        selectedFileDataURI != null ? selectedFileDataURI : modalImageThumb,
      status: MediaContentStatusType.Draft,
      tenantId: value.tenantId,
      categoryId: value.categoryId,
      tags: tagsData,
    }
    baseAxios
      .put(apiEndPoints.cms.videoPlaylistWithId(multiVideoId), multiVideoData)
      .then(async (singleResp: any) => {
        await fetchData()
        //Enable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoading(false)
        setModalImageThumb(null)
        toast.success('Video Playlist updated successfully! ')
        videoPlaylist.videoPlaylists
        singleVideoCloseRef.current?.click()
      })
  }

  const changePublish = async (value: any, type: string) => {
    //Disable button
    publishButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoadingPublish(true)
    setSelectedBtnId(value._id)
    const data = Object.assign({}, value)
    data.status = type
    data.releaseDate = moment(value.releaseDate, 'YYYY-MM-DD HH:SS').valueOf()
    baseAxios
      .put(apiEndPoints.cms.videoPlaylistWithId(value._id), data)
      .then(async (singleResp: any) => {
        await fetchData()
        //Enable button
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
        toast.success('Status updated successfully! ')
      })
      .catch((err: any) => {
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
      })
  }

  const changeStatus = async (value: any, type: string) => {
    //Disable button
    approveButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoadingApprove(true)
    setSelectedBtnId(value._id)
    const data = Object.assign({}, value)
    data.status = type
    data.releaseDate = moment(value.releaseDate, 'YYYY-MM-DD HH:SS').valueOf()
    baseAxios
      .put(apiEndPoints.cms.videoPlaylistWithId(value._id), data)
      .then(async (singleResp: any) => {
        await fetchData()
        //Enable button
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
        toast.success('Status updated successfully! ')
        videoPlaylist.videoPlaylists
      })
      .catch((err: any) => {
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
      })
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.videoPlaylistWithId(value))
            .then(async resp => {
              await fetchData()
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const openPicker = () => {
    inputFileSelect.current?.click()
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    setSelectedFile(null)
  }

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <ul className="nav nav-tabs" id="myTab-two" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab-two"
                data-toggle="tab"
                href="#role-two"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                In Review
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-three"
                data-toggle="tab"
                href="#permission-three"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-two"
                data-toggle="tab"
                href="#permission-two"
                role="tab"
                aria-controls="permission"
                aria-selected="false"
              >
                Published
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="role-two"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Video Playlist Lists</h4>
                      </div>
                      {getPermission(PERMISSIONS.CREATE_PLAYLIST_VIDEO) && (
                        <div className="sign-info">
                          <a
                            data-toggle="modal"
                            data-target="#AddMovieModel"
                            className="btn btn-primary"
                            onClick={() => clearModal()}
                          >
                            Add Video Playlist
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="iq-card-body">
                      <div className="table-view">
                        <TableLength
                          handleLengthChange={(v: any) =>
                            setPageSize(parseInt(v))
                          }
                          handleSearchValue={(searchVal: any) =>
                            setSearch(searchVal)
                          }
                        />
                        {pageLoading && (
                          <div
                            className="container d-flex justify-content-center align-items-center"
                            style={{ height: '200px' }}
                          >
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          </div>
                        )}
                        {!pageLoading &&
                          videoPlaylist &&
                          videoPlaylist.length > 0 && (
                            <table
                              className="data-tables table movie_table"
                              style={{ width: '100%' }}
                            >
                              <TableHeader
                                headers={headers}
                                onSorting={(field: any, order: any) =>
                                  setSorting({ field, order })
                                }
                              />

                              <tbody>
                                {singleVideosData.map(
                                  (videoContent: any, index: number) => (
                                    <tr key={`single_video_list_${index}`}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <img
                                          src={videoContent.imageUrl}
                                          width={50}
                                          height={70}
                                        />
                                      </td>
                                      <td>{videoContent.name}</td>
                                      <td>
                                        {getPermission(
                                          PERMISSIONS.UPDATE_PLAYLIST_VIDEO,
                                        ) == false ? (
                                          getStatus(videoContent.status)
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-primary "
                                            ref={approveButtonRef}
                                            onClick={() => {
                                              setSelectedMultiVideo(
                                                videoContent,
                                              )
                                              changeStatus(
                                                videoContent,
                                                MediaContentStatusType.Approved,
                                              )
                                            }}
                                          >
                                            {/* {loadingApprove == false &&
                                          selectedBtnId ==
                                            videoContent._id(
                                              <span className="indicator-label">
                                                Approve
                                              </span>, */}
                                            {/* )} */}
                                            {loadingApprove == true &&
                                            selectedBtnId ==
                                              videoContent._id ? (
                                              <span className="indicator-progress">
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                              </span>
                                            ) : (
                                              <span className="indicator-label">
                                                Approve
                                              </span>
                                            )}
                                          </button>
                                        )}
                                      </td>
                                      <td>
                                        <div className="flex align-items-center list-user-action">
                                          {getPermission(
                                            PERMISSIONS.VIEW_PLAYLIST_VIDEO,
                                          ) && (
                                            <Link
                                              className="iq-bg-warning"
                                              data-placement="top"
                                              to="/video-playlist/view"
                                              state={{ data: videoContent }}
                                            >
                                              <i className="ri-eye-line" />
                                            </Link>
                                          )}
                                          {getPermission(
                                            PERMISSIONS.UPDATE_PLAYLIST_VIDEO,
                                          ) && (
                                            <a
                                              className="iq-bg-success"
                                              data-placement="top"
                                              title=""
                                              data-toggle="modal"
                                              data-target="#AddMovieModel"
                                              data-original-title="Edit"
                                              onClick={() =>
                                                setSelectedVideo(videoContent)
                                              }
                                            >
                                              <i className="ri-pencil-line" />
                                            </a>
                                          )}
                                          {getPermission(
                                            PERMISSIONS.DELETE_PLAYLIST_VIDEO,
                                          ) && (
                                            <a
                                              className="iq-bg-primary"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title=""
                                              data-original-title="Delete"
                                              onClick={() =>
                                                handleDelete(videoContent._id)
                                              }
                                            >
                                              <i className="ri-delete-bin-line" />
                                            </a>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </table>
                          )}
                        {!pageLoading &&
                          videoPlaylist &&
                          videoPlaylist.length == 0 && <NoData />}
                        <Pagination
                          currentPage={currentPage}
                          totalCount={totalItems}
                          pageSize={pageSize}
                          onPageChange={(page: React.SetStateAction<number>) =>
                            setCurrentPage(page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-three"
              id="permission-three"
            >
              <ApprovedVideos
                data={videoPlaylist}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => setSelectedVideo(value)}
                handlePublish={(value: any) =>
                  changePublish(value, MediaContentStatusType.Publish)
                }
                publishButtonRef={publishButtonRef}
                loading={loadingPublish}
                selectedBtnId={selectedBtnId}
                // handleChange={(value: any) => changeStatus(value, MediaContentStatusType.Publish)}
              />
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="permission-two"
            >
              <PublishedVideos
                data={videoPlaylist}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => setSelectedVideo(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddMovieModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
        ref={movieModalRef}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={videoValidationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                releaseDate: number
                genreId: string
                contentType: string
                sensorBoard: object
                tenantId: string
                categoryId: string
                movieThumbnail: string
                tags: any
              },
              {
                resetForm,
              }: FormikHelpers<{
                name: string
                description: string
                releaseDate: number
                genreId: string
                contentType: string
                sensorBoard: object
                tenantId: string
                categoryId: string
                movieThumbnail: string
                tags: any
              }>,
            ) => {
              if (modalOpenType == 'add') {
                handleSubmit(values, resetForm)
              } else {
                handleUpdate(values, multiVideoID)
              }
              // resetForm()
            }}
          >
            {(formik: any) => {
              const { errors, touched, values, setFieldValue } = formik
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'edit'
                        ? 'Edit Video Playlist Content'
                        : 'Add Video Playlist Content'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={singleVideoCloseRef}
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row ">
                        <div className="col-md-12">
                          <div className=" form-group">
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="textarea"
                              id="text"
                              name="description"
                              rows={5}
                              className="form-control"
                              placeholder="Description"
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="tags"
                              id="tags"
                              placeholder="Tags"
                              isMulti={true}
                              component={SelectBox}
                              // options={lyricistList}
                            />
                            <ErrorMessage
                              name="tags"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {modalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <img
                                src={modalImageThumb}
                                width={100}
                                height={100}
                              />
                            </div>
                          )}
                          <div className="form_gallery form-group">
                            <label
                              onClick={() => openPicker()}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedFile != null
                                ? selectedFile.name
                                : 'Thumbnail'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelect}
                              name="movieThumbnail"
                              onChange={(e: any) => handleFileSelect(e)}
                              accept=".png, .jpg, .jpeg, .webp"
                            />
                            <ErrorMessage
                              name="movieThumbnail"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <div className="row">
                              {getIsRoot() && (
                                <div className="col-md-6 form-group">
                                  <Field
                                    as="select"
                                    name="tenantId"
                                    className="form-control"
                                  >
                                    <option value="">Select Tenant</option>
                                    {tenants.length > 0 &&
                                      tenants.map((tenant: any) => {
                                        return (
                                          <option
                                            value={tenant._id}
                                            key={tenant._id}
                                          >
                                            {tenant.name}
                                          </option>
                                        )
                                      })}
                                  </Field>
                                  <ErrorMessage
                                    name="tenantId"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                              )}
                              <div
                                className={
                                  getIsRoot()
                                    ? 'col-md-6 form-group'
                                    : 'col-md-12 form-group'
                                }
                              >
                                <Field
                                  as="select"
                                  name="categoryId"
                                  className="form-control"
                                >
                                  <option value="">Select Category</option>
                                  {categories.length > 0 &&
                                    categories.map((category: any) => {
                                      return (
                                        <option
                                          value={category._id}
                                          key={category._id}
                                        >
                                          {category.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="categoryId"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <Field
                              as="select"
                              className="form-control"
                              name="contentType"
                            >
                              <option value="">Select Content Type</option>
                              <option value="web_series">Web Series</option>
                            </Field>
                            <ErrorMessage
                              name="contentType"
                              component="span"
                              className="text-primary"
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default VideoPlaylistList
