/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useState, useRef, useEffect, createRef } from 'react'
import CustomHeader from 'components/CustomHeader'
import { useLocation } from 'react-router-dom'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { useDispatch, useSelector } from 'react-redux'
import { getArtists } from 'store/artistManagement/slice'
import { artistSelector } from 'store/artistManagement/selector'
import { FieldArray } from 'formik'
import { Country, State } from 'country-state-city'
import { toast } from 'react-toastify'
import { MediaContentStatusType, UploadStatusType, VideoType } from 'utils/enum'
import { getGenres, setUploadStatus } from 'store/basicManagement/slice'
import { Uploader } from 'utils/helpers/uploader'
import { basicSelector } from 'store/basicManagement/selector'
import { getSeasons } from 'store/multiVideoManagement/slice'
import { multiVideoSelector } from 'store/multiVideoManagement/selectors'
import moment from 'moment'
import { tmdbSelector } from 'store/tmdbManagement/selectors'
import { getCertification } from 'store/tmdbManagement/slice'
import Swal from 'sweetalert2'
import ReactPlayer from 'react-player'
import { matchYoutubeUrl } from 'utils/helpers/systemHelpers'

const ViewMultiVideo = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [multiVideoData, setMultiVideoData] = useState(location.state.data)
  const [artistDetail, setArtistDetail]: any = useState([])
  const [seasonModalOpenType, setSeasonModalOpenType] = useState('add')
  const [episodeModalOpenType, setEpisodeModalOpenType] = useState('add')

  const inputFileSelect = useRef<HTMLInputElement>(null)
  const seasonModalRef = useRef<HTMLDivElement>(null)
  const episodeModalRef = useRef<HTMLDivElement>(null)
  const seasonCloseRef = useRef<HTMLButtonElement>(null)
  const episodeCloseRef = useRef<HTMLButtonElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const submitButtonRefEpisode = useRef<HTMLButtonElement>(null)
  const inputFileSelectVideo = useRef<HTMLInputElement>(null)

  const artist = useSelector(artistSelector)
  const multiVideo = useSelector(multiVideoSelector)
  const basic = useSelector(basicSelector)
  const tmdb = useSelector(tmdbSelector)

  const [loading, setLoading] = useState(false)
  const [countries, setCountries]: any = useState([])
  const [states, setStates]: any = useState([])
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const [modalImageThumb, setModalImageThumb]: any = useState(null)
  const [selectedSeasonId, setSelectedSeasonId] = useState('0')
  const [uploadType, setUploadType] = useState('youtube')
  const [uploader, setUploader] = useState<any>(undefined)
  const [progress, setProgress] = useState(101)
  const [videoUrl, setVideoUrl]: any = useState(null)
  const [selectedVideoFile, setSelectedVideoFile]: any = useState(null)
  const [lyricistList, setLyricistList] = useState([])
  const [selectedVideo, setSelectedVideo]: any = useState(null)
  const [selectedCountry, setSelectedCountry]: any = useState(null)
  const [selectedVideoId, setSelectedVideoId]: any = useState(null)
  const [episodeId, setEpisodeId]: any = useState(null)
  const [playing, setPlaying]: any = useState(false)

  const seasonList = multiVideo.seasons

  const seasonValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().optional(),
    releaseDate: Yup.date().required('Release Date is required'),
  })
  const episodeValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().optional(),
    // movieThumbnail: Yup.string().required('Movie Thumbnail is required'),
    releaseDate: Yup.date().required('Release Date is required'),
    geoConfig: Yup.object()
      .shape({
        restrict: Yup.string().optional(),
        longitude: Yup.string().optional(),
        radius: Yup.string().optional(),
        allowedGeoLocations: Yup.object()
          .shape({
            country: Yup.string().optional(),
            state: Yup.string().optional(),
          })
          .optional(),
      })
      .optional(),
    enableDrm: Yup.boolean().optional(),
    enableOffline: Yup.boolean().optional(),
    videoType: Yup.string().required('Select Type'),
    duration: Yup.number().required('Duration is required'),
  })
  const [initialValuesSeason] = useState({
    name: '',
    description: '',
    releaseDate: '',
  })

  const [initialValuesEpisode] = useState({
    name: '',
    description: '',
    releaseDate: '',
    geoConfig: {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    },
    enableDrm: false,
    enableOffline: false,
    videoType: '',
    movieThumbnail: '',
    castConfig: [
      {
        reelName: '',
        realName: '',
        role: '',
      },
    ],
    duration: '',
  })

  const setSelectedSeason = async (value: any) => {
    await discardSeasonFields()
    initialValuesSeason.name = value.name
    initialValuesSeason.description = value.description
    initialValuesSeason.releaseDate = moment(value.releaseDate).format(
      'YYYY-MM-DD',
    )
    setSelectedSeasonId(value._id)
    setSeasonModalOpenType('edit')
  }

  const discardSeasonFields = async () => {
    // setTimeout(() => innerModalClear(), 100)
    // const innerModalClear = () => {
    initialValuesSeason.name = ''
    initialValuesSeason.description = ''
    initialValuesSeason.releaseDate = ''
    setSeasonModalOpenType('add')
    // }
  }

  const openEpisodeModal = async (seasonid: string) => {
    await discardEpisodeFields()
    setSelectedSeasonId(seasonid)
  }

  const discardEpisodeFields = async () => {
    // setTimeout(() => innerModalClear(), 100)
    // const innerModalClear = () => {
    initialValuesEpisode.name = ''
    initialValuesEpisode.description = ''
    initialValuesEpisode.releaseDate = ''
    initialValuesEpisode.enableDrm = false
    initialValuesEpisode.enableOffline = false
    initialValuesEpisode.videoType = ''
    initialValuesEpisode.movieThumbnail = ''
    initialValuesEpisode.castConfig = [
      {
        reelName: '',
        realName: '',
        role: '',
      },
    ]
    initialValuesEpisode.geoConfig = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    }
    initialValuesEpisode.duration = ''
    // initialValuesEpisode.castConfig =
    setModalImageThumb(null)
    setVideoUrl(null)
    setEpisodeModalOpenType('add')
    setSelectedSeasonId('')

    clearFieldOnClose()
    // }
  }

  const [activeAcordian, setActiveAcordian] = useState(0)

  useEffect(() => {
    if (multiVideoData != null) {
      dispatch(
        getSeasons({
          workSpaceId: multiVideoData.workSpaceId,
          webSeriesId: multiVideoData._id,
        }),
      )
    }
  }, [multiVideoData])

  useEffect(() => {
    const country: any = Country.getAllCountries()
    setCountries(country)
    dispatch(getGenres({}))
    dispatch(getCertification({}))
    dispatch(getArtists({}))
  }, [])

  const artistList = artist.artists
  const genres = basic.genres
  const certification = tmdb.movieCertificates
  let status = basic.uploadStatus

  useEffect(() => {
    const lyricistListArr: any = artistList.map((artist: any) => {
      return { value: artist._id, label: artist.name }
    })
    setLyricistList(lyricistListArr)
  }, [artistList])

  const handleSubmitSeason = (
    values: {
      name: string
      description: string
      releaseDate: string
    },
    workSpaceId: string,
    webSeriesId: string,
    resetForm: any,
  ) => {
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const seasonData: any = {
      name: values.name,
      description: values.description,
      releaseDate: moment(values.releaseDate, 'YYYY-MM-DD HH:SS').valueOf(),
    }
    baseAxios
      .post(apiEndPoints.cms.seasons(workSpaceId, webSeriesId), seasonData)
      .then((seasonResp: any) => {
        toast.success('Season Created Successfully!')
        dispatch(
          getSeasons({
            workSpaceId: multiVideoData.workSpaceId,
            webSeriesId: multiVideoData._id,
          }),
        )
        //Enable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoading(false)
        seasonCloseRef.current?.click()
        resetForm()
      })
      .catch(error => {
        //Enable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoading(false)
        toast.error(error)
      })
  }

  const handleUpdateSeason = (
    values: {
      name: string
      description: string
      releaseDate: string
    },
    seasonId: any,
    resetForm: any,
  ) => {
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const seasonData: any = {
      name: values.name,
      description: values.description,
      releaseDate: moment(values.releaseDate, 'YYYY-MM-DD HH:SS').valueOf(),
    }
    baseAxios
      .put(
        apiEndPoints.cms.seasonWithId(
          multiVideoData.workSpaceId,
          multiVideoData._id,
          seasonId,
        ),
        seasonData,
      )
      .then((resp: any) => {
        toast.success('Season Updated Successfully!')

        dispatch(
          getSeasons({
            workSpaceId: multiVideoData.workSpaceId,
            webSeriesId: multiVideoData._id,
          }),
        )
        //Enable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoading(false)
        seasonCloseRef.current?.click()
        resetForm()
      })
  }

  const handleSubmit = async (
    value: any,
    workSpaceId: string,
    webSeriesId: string,
    seasonId: string,
    postType: string,
    resetForm: any,
  ) => {
    if (!submitButtonRefEpisode.current) {
      return
    }
    setLoading(true)
    submitButtonRefEpisode.current?.setAttribute('disabled', 'true')
    const geoData: any = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: [
        {
          country: '',
          state: '',
        },
      ],
    }
    if (selectedCountry) {
      // value.geoConfig.allowedGeoLocations.country = selectedCountry
      geoData.allowedGeoLocations[0].country = selectedCountry
    }
    geoData.allowedGeoLocations[0].state =
      value.geoConfig.allowedGeoLocations.state
    geoData.restrict = value.geoConfig.restrict

    if (postType == 'create') {
      if (selectedFileDataURI) {
        if (selectedVideoFile || value.youtubeUrl) {
          const videoData = {
            title: value.name,
            description: value.description,
            videoUrl: selectedVideoFile
              ? `${apiEndPoints.s3.base}web-series/${selectedVideoFile.name}`
              : value.youtubeUrl,
            accessType: value.videoType,
            enableDrm: value.enableDrm,
            enableOffline: value.enableOffline,
            geoConfig: [geoData],
            duration: value.duration,
          }
          await baseAxios
            .post(apiEndPoints.cms.videos, videoData)
            .then((resp: any) => {
              const episodeVideoData = {
                name: value.name,
                description: value.description,
                releaseDate: moment(
                  value.releaseDate,
                  'YYYY-MM-DD HH:SS',
                ).valueOf(),
                videoId: resp._id,
                titleImageUrl: selectedFileDataURI,
                cast:
                  value.castConfig.length > 0 ? { data: value.castConfig } : {},
              }
              baseAxios
                .post(
                  apiEndPoints.cms.episodes(workSpaceId, webSeriesId, seasonId),
                  episodeVideoData,
                )
                .then(async (singleResp: any) => {
                  dispatch(
                    getSeasons({
                      workSpaceId: multiVideoData.workSpaceId,
                      webSeriesId: multiVideoData._id,
                    }),
                  )

                  if (selectedVideoFile)
                    await uploadFile(
                      singleResp,
                      workSpaceId,
                      webSeriesId,
                      seasonId,
                    )

                  submitButtonRefEpisode.current?.removeAttribute('disabled')
                  setLoading(false)
                  episodeCloseRef.current?.click()
                  toast.success('Episode added successfully! ')
                  resetForm()
                })
                .catch((err: any) => {
                  submitButtonRefEpisode.current?.removeAttribute('disabled')
                  // Disable indicator
                  setLoading(false)
                })
            })
            .catch((err: any) => {
              // Disable indicator
              submitButtonRefEpisode.current?.removeAttribute('disabled')
              setLoading(false)
            })
        } else {
          submitButtonRefEpisode.current?.removeAttribute('disabled')
          setLoading(false)
          toast.error('Please select video file!')
        }
      } else {
        submitButtonRefEpisode.current?.removeAttribute('disabled')
        setLoading(false)
        toast.error('Please select Thumbnail Image')
      }
    }

    if (postType == 'update') {
      const videoData = {
        title: value.name,
        description: value.description,
        videoUrl: selectedVideoFile
          ? `${apiEndPoints.s3.base}playlists/${selectedVideoFile.name}`
          : value.youtubeUrl
          ? value.youtubeUrl
          : videoUrl,
        accessType: value.videoType,
        enableDrm: value.enableDrm,
        enableOffline: value.enableOffline,
        geoConfig: [geoData],
        duration: value.duration,
      }
      baseAxios
        .put(apiEndPoints.cms.videoWithId(selectedVideoId), videoData)
        .then((resp: any) => {
          const episodeVideoData = {
            name: value.name,
            description: value.description,
            releaseDate: moment(
              value.releaseDate,
              'YYYY-MM-DD HH:SS',
            ).valueOf(),
            videoId: resp._id,
            titleImageUrl: selectedFileDataURI
              ? selectedFileDataURI
              : modalImageThumb,
            cast: value.castConfig.length > 0 ? { data: value.castConfig } : {},
            uploadStatus: selectedVideoFile
              ? UploadStatusType.Processing
              : value.youtubeUrl
              ? UploadStatusType.Completed
              : UploadStatusType.Completed,
          }
          baseAxios
            .put(
              apiEndPoints.cms.episodeWithId(
                workSpaceId,
                webSeriesId,
                seasonId,
                episodeId,
              ),
              episodeVideoData,
            )
            .then((singleResp: any) => {
              dispatch(
                getSeasons({
                  workSpaceId: multiVideoData.workSpaceId,
                  webSeriesId: multiVideoData._id,
                }),
              )
              if (selectedVideoFile)
                uploadFile(singleResp, workSpaceId, webSeriesId, seasonId)
              submitButtonRefEpisode.current?.removeAttribute('disabled')
              setLoading(false)
              episodeCloseRef.current?.click()
              resetForm()
              toast.success('Episode updated successfully! ')
            })
            .catch((err: any) => {
              submitButtonRefEpisode.current?.removeAttribute('disabled')
              // Disable indicator
              setLoading(false)
            })
        })
        .catch((err: any) => {
          // Disable indicator
          submitButtonRefEpisode.current?.removeAttribute('disabled')
          setLoading(false)
        })
    }
  }

  const openPicker = (type: string) => {
    if (type === 'thumbnail') {
      inputFileSelect.current?.click()
    } else {
      inputFileSelectVideo.current?.click()
    }
  }

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const handleCountrySelect = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event.target.value)
    initialValuesEpisode.geoConfig.allowedGeoLocations.country =
      event.target.value
    setSelectedCountry(event.target.value)
    setStates(statesArr)
  }

  const handleCountrySelectSetValue = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event)
    setSelectedCountry(event)
    setStates(statesArr)
  }

  const deleteSeason = async (
    workSpaceId: string,
    webSeriesId: string,
    seasonId: string,
  ) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(
              apiEndPoints.cms.seasonWithId(workSpaceId, webSeriesId, seasonId),
            )
            .then((response: any) => {
              dispatch(
                getSeasons({
                  workSpaceId: workSpaceId,
                  webSeriesId: webSeriesId,
                }),
              )
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const handleVideoSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedVideoFile(file)
  }

  const onLinkChange = (e: any) => {
    setUploadType(e.currentTarget.value)
  }

  const onUploadChange = (e: any) => {
    setUploadType(e.currentTarget.value)
  }

  const uploadFile = async (
    data: any,
    workSpaceId: string,
    webSeriesId: string,
    seasonId: string,
  ) => {
    dispatch(setUploadStatus({ id: data._id, status: 0 }))
    status = basic.uploadStatus
    if (selectedVideoFile) {
      let percentage: any = undefined

      const videoUploaderOptions = {
        fileName: `web-series/${selectedVideoFile.name}`,
        file: selectedVideoFile,
      }
      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)

      uploader
        .onProgress(async ({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setProgress(percentage)
            dispatch(setUploadStatus({ id: data._id, status: percentage }))
            status = basic.uploadStatus
            if (percentage == 100) {
              setTimeout(async () => {
                await handleUploadStatus(
                  data,
                  UploadStatusType.Completed,
                  workSpaceId,
                  webSeriesId,
                  seasonId,
                  data._id,
                )
                dispatch(setUploadStatus({ id: data._id, status: 100 }))
                status = basic.uploadStatus
                setProgress(101)
              }, 8000)
            }
          }
        })
        .onError(async (error: any) => {
          await handleUploadStatus(
            data,
            UploadStatusType.Failed,
            workSpaceId,
            webSeriesId,
            seasonId,
            episodeId,
          )
          selectedVideoFile(undefined)
        })
      uploader.start()
    }
  }

  const handleUploadStatus = async (
    updateData: any,
    status: string,
    workSpaceId: string,
    webSeriesId: string,
    seasonId: string,
    episodeId: string,
  ) => {
    const data = Object.assign({}, updateData)
    data.uploadStatus = status
    data.releaseDate = moment(
      updateData.releaseDate,
      'YYYY-MM-DD HH:SS',
    ).valueOf()
    await baseAxios.put(
      apiEndPoints.cms.episodeWithId(
        workSpaceId,
        webSeriesId,
        seasonId,
        episodeId,
      ),
      data,
    )
    dispatch(
      getSeasons({
        workSpaceId: multiVideoData.workSpaceId,
        webSeriesId: multiVideoData._id,
      }),
    )
    toast.success('Episode Uploaded Successfully!')
  }

  const deleteEpisode = (
    workSpaceId: string,
    webSeriesId: string,
    seasonId: string,
    episodeId: string,
  ) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(
              apiEndPoints.cms.episodeWithId(
                workSpaceId,
                webSeriesId,
                seasonId,
                episodeId,
              ),
            )
            .then((delResp: any) => {
              dispatch(
                getSeasons({
                  workSpaceId: multiVideoData.workSpaceId,
                  webSeriesId: multiVideoData._id,
                }),
              )
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const setSelectedEpisode = async (episodeVal: any) => {
    await discardEpisodeFields()
    setEpisodeModalOpenType('edit')
    initialValuesEpisode.name = episodeVal.name
    initialValuesEpisode.description = episodeVal.description
    initialValuesEpisode.releaseDate = moment(episodeVal.releaseData).format(
      'YYYY-MM-DD',
    )
    initialValuesEpisode.enableDrm = episodeVal.videoInfo[0].enableDrm
    initialValuesEpisode.enableOffline = episodeVal.videoInfo[0].enableOffline
    initialValuesEpisode.videoType = episodeVal.videoInfo[0].accessType
    initialValuesEpisode.movieThumbnail = episodeVal.titleImageUrl
    initialValuesEpisode.castConfig = episodeVal.cast.data
    initialValuesEpisode.geoConfig.restrict =
      episodeVal.videoInfo[0].geoConfig[0].restrict
    initialValuesEpisode.geoConfig.longitude =
      episodeVal.videoInfo[0].geoConfig[0].longitude
    initialValuesEpisode.geoConfig.radius =
      episodeVal.videoInfo[0].geoConfig[0].radius
    // initialValuesEpisode.castConfig =

    initialValuesEpisode.duration = episodeVal.videoInfo[0].duration
    if (
      episodeVal.videoInfo[0].geoConfig[0].allowedGeoLocations &&
      episodeVal.videoInfo[0].geoConfig[0].allowedGeoLocations.length > 0
    ) {
      initialValuesEpisode.geoConfig.allowedGeoLocations =
        episodeVal.videoInfo[0].geoConfig[0].allowedGeoLocations[0]
    }

    if (
      episodeVal.videoInfo[0].geoConfig[0] &&
      episodeVal.videoInfo[0].geoConfig[0].allowedGeoLocations &&
      episodeVal.videoInfo[0].geoConfig[0].allowedGeoLocations[0] &&
      episodeVal.videoInfo[0].geoConfig[0].allowedGeoLocations[0].country
    ) {
      handleCountrySelectSetValue(
        episodeVal.videoInfo[0].geoConfig[0].allowedGeoLocations[0].country,
      )
    }
    setSelectedVideoId(episodeVal.videoInfo[0]._id)
    setModalImageThumb(episodeVal.titleImageUrl)
    setVideoUrl(episodeVal.videoInfo[0].videoUrl)
    setEpisodeId(episodeVal._id)
    setSelectedSeasonId(episodeVal.seasonId)
    if (matchYoutubeUrl(episodeVal.videoInfo[0].videoUrl)) {
      setUploadType('youtube')
    } else {
      setUploadType('upload')
    }
  }

  const getContentType = (type: string) => {
    switch (type) {
      case VideoType.WebSeries:
        return 'Web Series'
      case VideoType.TalkShow:
        return 'Talk Show'
      case VideoType.Interview:
        return 'Interview'
      default:
        'NAN'
    }
  }

  const closeModal = () => {
    setPlaying(false)
    // playerModalRef.current?
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    if (inputFileSelectVideo.current) {
      inputFileSelectVideo.current.value = ''
      inputFileSelectVideo.current.files = null
    }
    setSelectedFile(null)
    setSelectedVideoFile(null)
  }

  return (
    <CustomHeader pageName={'View Album'}>
      <div id="content-page" className="content-page ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4 mb-4">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: '500px',
                  width: '100%',
                  // display: 'wrap',
                  // flexWrap: 'wrap',
                }}
              >
                {' '}
                <img
                  src={multiVideoData.titleImageUrl}
                  className=""
                  alt="author-profile"
                  style={{
                    height: '100%',
                    width: '100%',
                    // display: 'wrap',
                    // flexWrap: 'wrap',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    // background: 'yellow',
                    height: '500px',
                    width: '92%',
                    position: 'absolute',
                    alignItems: 'end',
                    justifyContent: 'center',
                    top: 0,
                  }}
                >
                  {/* {currentAudio != null && (
                    <AudioPlayer
                      autoPlay
                      src={currentAudio}
                      volume={0.5}
                      showSkipControls={false}
                    />
                  )} */}
                </div>
              </div>
              <div className="NewPlayer"> </div>
              <div className="mt-5">
                <p className="m-0">Title</p>
                <h6>{multiVideoData.name}</h6>
              </div>{' '}
              <div className="mt-3">
                <p className="m-0">Description</p>
                <h6>{multiVideoData.description}</h6>
              </div>
              <div className="row">
                {/* <div className="col">
                  {' '}
                  <div className="mt-3">
                    <p className="m-0">Release on</p>
                    <h6>{multiVideoData.releaseDate}</h6>
                  </div>
                </div> */}

                <div className="col">
                  {' '}
                  <div className="mt-3">
                    <p className="m-0">Type</p>
                    <h6>{getContentType(multiVideoData.contentType)}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-8">
              {' '}
              <div style={{ cursor: 'pointer' }}>
                <a
                  data-toggle="modal"
                  className="AudioTrack"
                  data-target="#AddSeasonModel"
                  onClick={() => discardSeasonFields()}
                >
                  + Add New Season
                </a>
              </div>
              <div className="col-md-12 p-0">
                <div className="iq-accordion career-style faq-style mt-3">
                  {seasonList != null &&
                    seasonList.length > 0 &&
                    seasonList.map((season: any, index: number) => {
                      return (
                        <div
                          className={
                            activeAcordian == index
                              ? 'iq-card iq-accordion-block '
                              : 'iq-card iq-accordion-block'
                          }
                        >
                          <div className="active-faq clearfix" style={{}}>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <a
                                        className="accordion-title"
                                        onClick={() => setActiveAcordian(index)}
                                      >
                                        <h6>{season.name}</h6>
                                      </a>
                                    </div>
                                    <div className="sign-info col-md-4 d-flex justify-content-end align-items-center">
                                      <a
                                        data-toggle="modal"
                                        data-target="#AddEpisodeModel"
                                        className="btn btn-primary"
                                        onClick={() =>
                                          openEpisodeModal(season._id)
                                        }
                                      >
                                        Add Episodes
                                      </a>
                                    </div>
                                    <div className="col-md-2">
                                      <i
                                        className="btn ri-pencil-line "
                                        data-toggle="modal"
                                        data-target="#AddSeasonModel"
                                        onClick={() =>
                                          setSelectedSeason(season)
                                        }
                                      />
                                      <i
                                        className="btn ri-delete-bin-line"
                                        onClick={() =>
                                          deleteSeason(
                                            multiVideoData.workSpaceId,
                                            multiVideoData._id,
                                            season._id,
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              activeAcordian == index
                                ? 'accordion-details d-flex align-items-center w-full justify-content-center'
                                : 'accordion-details d-none'
                            }
                          >
                            <div className="table-view w-100">
                              {season &&
                                season.episodesInfo &&
                                season.episodesInfo.length > 0 &&
                                season.episodesInfo.map(
                                  (episodeData: any, index: any) => {
                                    return (
                                      <table
                                        className="data-tables table movie_table"
                                        style={{ width: '100%' }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              <img
                                                src={episodeData.titleImageUrl}
                                                // className="img-fluid avatar-50"
                                                alt="author-profile"
                                                height={120}
                                                width={80}
                                              />
                                            </td>
                                            <td>{episodeData.name}</td>
                                            <td>{episodeData.description}</td>
                                            {episodeData.uploadStatus &&
                                            episodeData.uploadStatus ===
                                              UploadStatusType.Processing ? (
                                              <td>
                                                {status.some(
                                                  (item: any) =>
                                                    item.id === episodeData._id,
                                                )
                                                  ? status.find(
                                                      (item: any) =>
                                                        item.id ===
                                                        episodeData._id,
                                                    )?.status + '%'
                                                  : UploadStatusType.Interrupted}
                                              </td>
                                            ) : (
                                              <td>
                                                {episodeData.uploadStatus
                                                  ? episodeData.uploadStatus.toUpperCase()
                                                  : 'NA'}
                                              </td>
                                            )}
                                            <td>
                                              <div className="justify-content-end d-flex p-3  list-user-action">
                                                <a
                                                  className="iq-bg-warning"
                                                  title=""
                                                  data-toggle="modal"
                                                  data-target="#VideoPlayer"
                                                  onClick={() =>
                                                    setSelectedVideo(
                                                      episodeData.videoInfo[0]
                                                        .videoUrl,
                                                    )
                                                  }
                                                >
                                                  <i className="fa-solid fa-circle-play"></i>
                                                </a>
                                                <a
                                                  className="iq-bg-success"
                                                  data-toggle="modal"
                                                  data-target="#AddEpisodeModel"
                                                  onClick={() =>
                                                    setSelectedEpisode(
                                                      episodeData,
                                                    )
                                                  }
                                                >
                                                  <i className="ri-pencil-line" />
                                                </a>
                                                <a
                                                  className="iq-bg-primary"
                                                  onClick={() =>
                                                    deleteEpisode(
                                                      multiVideoData.workSpaceId,
                                                      multiVideoData._id,
                                                      season._id,
                                                      episodeData._id,
                                                    )
                                                  }
                                                >
                                                  <i className="ri-delete-bin-line" />
                                                </a>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    )
                                  },
                                )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddSeasonModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
        ref={seasonModalRef}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValuesSeason}
            validationSchema={seasonValidationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                releaseDate: string
              },
              { resetForm }: any,
            ) => {
              seasonModalOpenType == 'add'
                ? handleSubmitSeason(
                    values,
                    multiVideoData.workSpaceId,
                    multiVideoData._id,
                    resetForm,
                  )
                : handleUpdateSeason(values, selectedSeasonId, resetForm)
            }}
          >
            {(formik: any) => {
              const { errors, touched } = formik
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {seasonModalOpenType == 'edit'
                        ? 'Edit Season'
                        : 'Add Season'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={seasonCloseRef}
                      onClick={() => formik.resetForm()}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row ">
                        <div className="col-md-12">
                          <div className=" form-group">
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className=" form-group">
                            <Field
                              type="textarea"
                              className="form-control"
                              placeholder="Enter Desription"
                              name="description"
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className=" form-group">
                            <label htmlFor="form_gallery-upload">
                              Release Date
                            </label>
                            <Field
                              type="date"
                              className="form-control"
                              placeholder="Release Date"
                              name="releaseDate"
                            />
                            <ErrorMessage
                              name="releaseDate"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              ref={submitButtonRef}
                            >
                              {!loading && (
                                <span className="indicator-label">
                                  {seasonModalOpenType == 'edit'
                                    ? 'Update'
                                    : 'Submit'}
                                </span>
                              )}
                              {loading && (
                                <span className="indicator-progress">
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddEpisodeModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
        ref={episodeModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <Formik
            initialValues={initialValuesEpisode}
            validationSchema={episodeValidationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                movieThumbnail: string
                releaseDate: number
                genreId: string
                contentType: string
                sensorBoard: object
                geoConfig: object
                tenantId: string
                categoryId: string
              },
              { resetForm }: any,
            ) => {
              if (episodeModalOpenType == 'add') {
                handleSubmit(
                  values,
                  multiVideoData.workSpaceId,
                  multiVideoData._id,
                  selectedSeasonId,
                  'create',
                  resetForm,
                )
              } else {
                handleSubmit(
                  values,
                  multiVideoData.workSpaceId,
                  multiVideoData._id,
                  selectedSeasonId,
                  'update',
                  resetForm,
                )
              }
            }}
          >
            {(formik: any) => {
              const { errors, touched, values, setFieldValue } = formik
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {episodeModalOpenType == 'edit'
                        ? 'Edit Episode'
                        : 'Add Episode'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={episodeCloseRef}
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className=" form-group">
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="textarea"
                              id="text"
                              name="description"
                              rows={5}
                              className="form-control"
                              placeholder="Description"
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {episodeModalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <img
                                src={modalImageThumb}
                                width={100}
                                height={100}
                              />
                            </div>
                          )}
                          <div className="form_gallery form-group">
                            <label
                              onClick={() => openPicker('thumbnail')}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedFile != null
                                ? selectedFile.name
                                : 'Movie thumbnail'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelect}
                              name="movieThumbnail"
                              onChange={e => handleFileSelect(e)}
                              accept=".png, .jpg, .jpeg, .webp"
                            />
                            <ErrorMessage
                              name="movieThumbnail"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="row ">
                            <div className="col-md-6 form-group">
                              <label htmlFor="form_gallery-upload">
                                Release Date
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                data-placeholder="Release date"
                                name="releaseDate"
                              />
                              <ErrorMessage
                                name="releaseDate"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <label htmlFor="form_gallery-upload">
                                Access Type
                              </label>
                              <Field
                                as="select"
                                name="videoType"
                                className="form-control"
                              >
                                <option value="">Select Type</option>
                                <option value="free">Free</option>
                                <option value="pay_per_view">
                                  Pay Per View
                                </option>
                              </Field>
                              <ErrorMessage
                                name="videoType"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row m-0">
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableDrm"
                                  name="enableDrm"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableDrm"
                                >
                                  Enable DRM availability
                                </label>
                                <ErrorMessage
                                  name="enableDrm"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableOffline"
                                  name="enableOffline"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableOffline"
                                >
                                  Offline access
                                </label>
                                <ErrorMessage
                                  name="enableOffline"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>

                          <p className="mt-2">GEO CONFIG</p>

                          <div className="form-group custom-switch">
                            <Field
                              type="checkbox"
                              className="custom-control-input"
                              id="geoConfig.restrict"
                              name="geoConfig.restrict"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="geoConfig.restrict"
                            >
                              Allow geo configure
                            </label>
                          </div>

                          {/* {geoConfig == true && ( */}
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.country"
                                  className="form-control"
                                  onChange={(e: any) => handleCountrySelect(e)}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country: any) => {
                                    return (
                                      <option
                                        value={country.isoCode}
                                        key={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.country"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.state"
                                  className="form-control"
                                >
                                  <option value="">Select State</option>
                                  {states.length > 0 &&
                                    states.map((state: any) => {
                                      return (
                                        <option
                                          value={state.isoCode}
                                          key={state.isoCode}
                                        >
                                          {state.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.country"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                          <div className="form-group">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio6"
                                name="customRadio-1"
                                value="youtube"
                                checked={uploadType == 'youtube' ? true : false}
                                className="custom-control-input"
                                onChange={(e: any) => onLinkChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                {' '}
                                Youtube Link{' '}
                              </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio7"
                                name="customRadio-1"
                                value="upload"
                                checked={uploadType == 'upload' ? true : false}
                                className="custom-control-input"
                                onChange={(e: any) => onUploadChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio7"
                              >
                                {' '}
                                Upload File{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio8"
                                name="customRadio-1"
                                value="AWS Media Tailor"
                                checked={
                                  uploadType == 'AWS Media Tailor'
                                    ? true
                                    : false
                                }
                                className="custom-control-input"
                                onChange={(e: any) => onLinkChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio8"
                              >
                                {' '}
                                AWS Media Tailor{' '}
                              </label>
                            </div>
                          </div>
                          {episodeModalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              {videoUrl} &nbsp;
                              <a href={videoUrl}>Open Video</a>
                            </div>
                          )}
                          {uploadType == 'youtube' ||
                          uploadType == 'AWS Media Tailor' ? (
                            <div className="form-group">
                              <Field
                                type="text"
                                className="form-control"
                                placeholder={
                                  episodeModalOpenType == 'edit'
                                    ? uploadType == 'AWS Media Tailor'
                                      ? 'New AWS Mediatailor URL'
                                      : 'New Youtube URL'
                                    : uploadType == 'AWS Media Tailor'
                                    ? 'AWS Mediatailor URL'
                                    : 'Youtube Url'
                                }
                                name="youtubeUrl"
                              />
                              <ErrorMessage
                                name="youtubeUrl"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          ) : (
                            <div className="form_gallery form-group">
                              <label
                                onClick={() => openPicker('video')}
                                htmlFor="form_gallery-upload"
                              >
                                {selectedVideoFile != null
                                  ? selectedVideoFile.name
                                  : 'Video File'}
                              </label>
                              <input
                                type="file"
                                ref={inputFileSelectVideo}
                                name="videoFile"
                                onChange={e => handleVideoSelect(e)}
                                accept=".mp4, .mov, .mkv, .avi"
                              />
                              <ErrorMessage
                                name="videoFile"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          )}
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12 form-group">
                                <Field
                                  type="number"
                                  name="duration"
                                  className="form-control"
                                  placeholder="Enter duration in seconds"
                                />

                                <ErrorMessage
                                  name="duration"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="mt-2">Cast</p>
                      {/* <div className="form-control"> */}
                      <FieldArray
                        name="castConfig"
                        render={(arrayHelpers: any) => (
                          <div>
                            {values.castConfig.map((caste: any, index: any) => (
                              <div className="row mb-5" key={index}>
                                <div className="col-md-4">
                                  <Field
                                    type="text"
                                    className="form-control"
                                    placeholder="Reel Name"
                                    name={`castConfig[${index}].reelName`}
                                  />
                                  <ErrorMessage
                                    name={`castConfig[${index}].reelName`}
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Field
                                    as="select"
                                    className="form-control"
                                    name={`castConfig[${index}].realName`}
                                  >
                                    <option value="">Real Name</option>
                                    {artistList.length > 0 &&
                                      artistList.map((artistData: any) => {
                                        return (
                                          <option
                                            value={artistData._id}
                                            key={artistData._id}
                                          >
                                            {artistData.name}
                                          </option>
                                        )
                                      })}
                                  </Field>
                                  <ErrorMessage
                                    name={`castConfig[${index}].realName`}
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Field
                                    type="text"
                                    className="form-control"
                                    placeholder="Role"
                                    name={`castConfig[${index}].role`}
                                  />
                                  <ErrorMessage
                                    name={`castConfig[${index}].role`}
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-1 d-flex align-items-center justify-content-center p-0 m-0 w-100">
                                  <div className="row d-flex align-items-center justify-content-start w-100 p-1">
                                    <div className="col-md-6 align-items-start justify-content-center p-0 m-0">
                                      <div
                                        className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          borderRadius: '50%',
                                        }}
                                        onClick={() =>
                                          arrayHelpers.push({
                                            reelName: '',
                                            realName: '',
                                            role: '',
                                          })
                                        }
                                      >
                                        {/* <i className="fa-sharp fa-solid fa-circle-plus p-0"></i> */}
                                        <i className="fa-solid fa-plus p-0 m-0"></i>
                                      </div>
                                    </div>
                                    {index != 0 && (
                                      <div className="col-md-6 align-items-start justify-content-center p-0 m-0">
                                        <div
                                          className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                          }}
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          {/* <i className="fa-solid fa-circle-xmark"></i> */}
                                          <i className="fa-solid fa-xmark p-0 m-0"></i>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      />
                      {/* {castConfig.map((cast: any, index: any) => {
                        return (
                          
                        )
                      })} */}

                      {/* </div> */}
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={submitButtonRefEpisode}
                        >
                          {!loading && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
      <div
        className="modal fade "
        id="VideoPlayer"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header d-flex justify-content-end align-items-center">
              <div
                className="p-0 m-0 d-flex justify-content-center align-items-center"
                style={{
                  // background: 'red',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              >
                <i
                  className="ri-close-line fs-1"
                  style={{ fontSize: '30px' }}
                ></i>
              </div>
            </div>
            <ReactPlayer
              className="react-player"
              url={selectedVideo}
              width={'100%'}
              height={'100%'}
              playing={playing}
              controls={true}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              config={{
                file: {
                  attributes: {
                    crossorigin: 'anonymous',
                  },
                },
              }}
              fileConfig={{
                attributes: {
                  style: {
                    display: 'block',
                    width: 'auto',
                    height: 'auto',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default ViewMultiVideo
