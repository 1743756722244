import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Banner } from './types'
import { MediaContentStatusType } from 'utils/enum'

const getBannersByCategoryId = createAction<any>('cms/getBannersByCategoryId')
const createBannerForCategory = createAction<any>('cms/createBannerForCategory')
const updateBannerForCategory = createAction<any>('cms/updateBannerForCategory')
const deleteBannerForCategory = createAction<any>('cms/deleteBannerForCategory')
const getBannerInfoForCategory = createAction<any>(
  'cms/getBannerInfoForCategory',
)
const changeBannerPublishStatus = createAction<any>(
  'cms/changeBannerPublishStatus',
)
const manageActiveCategory = createAction<any>('cms/manageActiveCategory')
const manageSelectedBanner = createAction<any>('cms/manageSelectedBanner')
const manageModalOpenType = createAction<any>('cms/manageModalOpenType')
const manageBannerErrorMessage = createAction<any>(
  'cms/manageBannerErrorMessage',
)
const manageBannerLoading = createAction<any>('cms/manageBannerLoading')
const manageActiveTab = createAction<any>('cms/manageActiveTab')

export const initialState: Banner = {
  banners: [],
  activeCategory: null,
  selectedBannerInfo: null,
  modalOpenType: 'add',
  bannerErrorMessage: '',
  bannersLoading: false,
  activeTab: MediaContentStatusType.Draft,
}

const BannerSlice = createSlice({
  name: 'Banner',
  initialState: initialState,
  reducers: {
    setBanners(state, action: PayloadAction<any>) {
      state.banners = action.payload?.filter(
        (e: any) =>
          e?.webCategoryId === state.activeCategory?.value &&
          e?.status === state.activeTab,
      )
    },
    setAllBanners(state, action: PayloadAction<any>) {
      state.banners = action.payload?.filter(
        (e: any) => e?.status === state.activeTab,
      )
    },

    setSelectedBannerInfo(state, action: PayloadAction<any>) {
      state.selectedBannerInfo = action.payload
    },
    setActiveCategory(state, action: PayloadAction<any>) {
      state.activeCategory = action.payload
    },
    setModalOpenType(state, action: PayloadAction<any>) {
      state.modalOpenType = action.payload
    },
    setBannerDeletion(state, action: PayloadAction<any>) {
      state.banners = state.banners.filter((x: any) => x._id != action.payload)
    },
    setCreatedBannersToList(state, action: PayloadAction<any>) {
      state.banners = [...state.banners, action.payload]
    },
    setBannerErrorMessage(state, action: PayloadAction<any>) {
      state.bannerErrorMessage = action.payload
    },
    setUpdateBanner(state, action: PayloadAction<any>) {
      state.banners[
        state.banners.findIndex((x: any) => x._id == action.payload._id)
      ] = action.payload
    },
    setBannerLoader(state, action: PayloadAction<any>) {
      state.bannersLoading = action.payload
    },
    setActiveTab(state, action: PayloadAction<any>) {
      state.activeTab = action.payload
    },
    setBannerRowLoader(state, action: PayloadAction<any>) {
      const { bannerId, loading } = action.payload
      const bannerIndex = state.banners.findIndex(
        (x: any) => x._id === bannerId,
      )
      // If the banner with the given ID exists in the array
      state.banners[bannerIndex] = {
        ...state.banners[bannerIndex],
        loading,
      }
    },
  },
})

export {
  getBannersByCategoryId,
  createBannerForCategory,
  updateBannerForCategory,
  deleteBannerForCategory,
  getBannerInfoForCategory,
  changeBannerPublishStatus,
  manageSelectedBanner,
  manageActiveCategory,
  manageModalOpenType,
  manageBannerErrorMessage,
  manageBannerLoading,
  manageActiveTab,
}

export const { actions, reducer, name: sliceKey } = BannerSlice
