import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectTranscodingState = (state: RootState) =>
  state.AudioTranscoding || initialState

export const transcodingSelector = createSelector(
  [selectTranscodingState],
  AudioTranscoding => AudioTranscoding,
)
