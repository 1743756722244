/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomHeader from 'components/CustomHeader'
import moment from 'moment'

import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { Link } from 'react-router-dom'
import {
  getIsRoot,
  getPermission,
  getStatus,
  getTenantId,
} from 'utils/helpers/systemHelpers'
import { albumSelector } from 'store/albumManagement/selector'
import { getArtists } from 'store/artistManagement/slice'
import { artistSelector } from 'store/artistManagement/selector'
import { toast } from 'react-toastify'
import ApprovedAlbum from './Approved'
import PublishedAlbum from './Published'
import { PERMISSIONS } from 'model/auth'
import { basicSelector } from 'store/basicManagement/selector'
import { roleSelector } from 'store/roleManagement/selectors'
import { getCategories, getGenres } from 'store/basicManagement/slice'
import { getTenants } from 'store/roleManagement/slice'
import { AudioType, MediaContentStatusType, UploadStatusType } from 'utils/enum'
import { Country, State } from 'country-state-city'
import SelectBox from 'components/Select'
import { setUploadStatus } from 'store/basicManagement/slice'
import { Uploader } from 'utils/helpers/uploader'
import Swal from 'sweetalert2'
import NoData from 'components/NoData/nodata'
import { ThreeDots } from 'react-loader-spinner'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  releaseDate: Yup.date().required('Date is Required'),
  type: Yup.string().required('Album Type is Required'),
  artist: Yup.array()
    .min(1, 'Select at least 1 artist.')
    .required('Artist is Required'),
  singerId: Yup.array()
    .min(1, 'Select at least 1 singer.')
    .required('Singers is Required'),
  composerId: Yup.array()
    .min(1, 'Select at least 1 composer.')
    .required('Composer is Required'),
  genreId: Yup.string().required('Genre is Required'),
  categoryId: Yup.string().required('Category is required'),
})

const AlbumList = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const albumCloseRef = useRef<HTMLButtonElement>(null)
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const inputFileSelectAudio = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const dispatch = useDispatch()
  const albumStore = useSelector(albumSelector)
  const basic = useSelector(basicSelector)
  const role = useSelector(roleSelector)
  const artist = useSelector(artistSelector)
  const [modalImage, setModalImage]: any = useState(null)
  const [selectedBtnId, setSelectedBtnId] = useState(-1)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const publishButtonRef = useRef<HTMLButtonElement>(null)
  const approveButtonRef = useRef<HTMLButtonElement>(null)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [lyricistList, setLyricistList] = useState([])
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(0)
  const [uploadType, setUploadType] = useState('url')
  const [uploader, setUploader] = useState<any>(undefined)
  const [progress, setProgress] = useState(101)
  const [loading, setLoading] = useState(false)
  const [selectedCountry, setSelectedCountry]: any = useState(null)

  const [countries, setCountries]: any = useState([])
  const [states, setStates]: any = useState([])
  const [selectedAudioFile, setSelectedAudioFile]: any = useState(null)
  const [selectedAudioId, setSelectedAudioId]: any = useState(null)
  const [selectedAudioTrackId, setSelectedAudioTrackId]: any = useState(null)
  const [selectedWorkSpaceId, setSelectedWorkSpaceId]: any = useState(null)
  const [album, setAlbum]: any = useState(null)
  const [tenantID, setTenantID] = useState(getTenantId())

  let status = basic.uploadStatus

  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.singleAlbumByStatus(
        AudioType.SingleAudio,
        MediaContentStatusType.Draft,
      ),
    )
    if (response) {
      setAlbum(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    fetchData()
    const country: any = Country.getAllCountries()
    setCountries(country)
    // dispatch(getAlbums({}))
    dispatch(getArtists({}))
    dispatch(getTenants({}))
    dispatch(getCategories({}))
    dispatch(getGenres({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // let albumData = album.albums
  const tenants = role.tenants
  const categories = basic.categories
  const artistList = artist.artists
  const genres = basic.genres
  // const audioTrackList = albumStore.audioTracks

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Type', field: 'type', sortable: false },
    { name: 'Release Date', field: 'release_date', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Upload Status', field: 'upload_status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const albumsData = useMemo(() => {
    const tempDataDraft: any = []
    album &&
      album.length > 0 &&
      album.map((videoPo: any) => {
        tempDataDraft.push(videoPo)
      })
    let computedMagazines: any = tempDataDraft

    if (search) {
      computedMagazines = computedMagazines.filter(
        (magazine: {
          name: string
          description: string
          //   rating: string
        }) =>
          magazine.name.toLowerCase().includes(search.toLowerCase()) ||
          magazine.description.toLowerCase().includes(search.toLowerCase()),
        //   magazine.rating.includes(search),
      )
    }

    setTotalItems(computedMagazines.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedMagazines.slice(firstPageIndex, lastPageIndex)
  }, [album, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    description: '',
    releaseDate: '',
    genreId: '',
    type: '',
    artist: '',
    categoryId: '',
    tenantId: tenantID,

    audioType: '',
    singerId: '',
    composerId: '',
    lyricistId: '',
    geoConfig: {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    },
    enableDrm: false,
    enableOffline: false,
    audioUrl: '',
    tags: [],
  })

  const setSelectedTenant = async (value: any) => {
    await clearModal()
    setModalOpenType('edit')

    const track: any = await baseAxios.get(
      apiEndPoints.cms.audioTracks(value._id),
    )

    initialValues.name = value.name
    initialValues.description = value.description
    initialValues.releaseDate = moment(value.releaseDate).format('YYYY-MM-DD')
    initialValues.genreId = value.genreId
    initialValues.type = value.type
    initialValues.categoryId = value.categoryId
    initialValues.tenantId = value.tenantId

    const tagData: any = []
    if (value.tags && value.tags.length > 0) {
      value.tags.forEach((tag: any) => {
        tagData.push({ label: tag, value: tag })
      })
    }
    initialValues.tags = tagData

    initialValues.artist = value.artists.map((artist: any) => {
      return { value: artist._id, label: artist.name }
    })

    initialValues.singerId = track[0].singers.map((singer: any) => {
      return { value: singer._id, label: singer.name }
    })
    initialValues.composerId = track[0].composers.map((composer: any) => {
      return { value: composer._id, label: composer.name }
    })
    initialValues.lyricistId = track[0].lyricists.map((lyricist: any) => {
      return { value: lyricist._id, label: lyricist.name }
    })
    initialValues.enableDrm = track[0].audio[0].enableDrm
    initialValues.enableOffline = track[0].audio[0].enableOffline
    initialValues.audioUrl = track[0].audio[0].audioUrl
    initialValues.audioType = track[0].audio[0].accessType

    initialValues.geoConfig.restrict = track[0].audio[0].geoConfig[0].restrict
    initialValues.geoConfig.longitude = track[0].audio[0].geoConfig[0].longitude
    initialValues.geoConfig.radius = track[0].audio[0].geoConfig[0].radius

    if (
      track[0].audio[0].geoConfig[0].allowedGeoLocations &&
      track[0].audio[0].geoConfig[0].allowedGeoLocations[0]
    ) {
      initialValues.geoConfig.allowedGeoLocations =
        track[0].audio[0].geoConfig[0].allowedGeoLocations[0]
    }

    if (
      track[0].audio[0].geoConfig[0] &&
      track[0].audio[0].geoConfig[0].allowedGeoLocations &&
      track[0].audio[0].geoConfig[0].allowedGeoLocations[0] &&
      track[0].audio[0].geoConfig[0].allowedGeoLocations[0].country
    ) {
      handleCountrySelectSetValue(
        track[0].audio[0].geoConfig[0].allowedGeoLocations[0].country,
      )
    }

    setModalImage(value.thumbnailImageUrl)
    setSelectedTenantId(value._id)
    setSelectedAudioId(track[0].audio[0]._id)
    setSelectedAudioTrackId(track[0]._id)
    setSelectedWorkSpaceId(track[0].workSpaceId)
  }

  const clearModal = async () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    initialValues.name = ''
    initialValues.description = ''
    initialValues.releaseDate = ''
    initialValues.genreId = ''
    initialValues.type = ''
    initialValues.artist = ''
    initialValues.categoryId = ''
    initialValues.tenantId = tenantID

    initialValues.audioType = ''
    initialValues.singerId = ''
    initialValues.composerId = ''
    initialValues.lyricistId = ''
    initialValues.geoConfig = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    }
    initialValues.enableDrm = false
    initialValues.enableOffline = false
    initialValues.audioUrl = ''
    initialValues.tags = []

    setModalOpenType('add')
    setModalImage(null)
    setSelectedTenantId('')
    setSelectedAudioId(null)
    setSelectedAudioTrackId(null)
    setSelectedWorkSpaceId(null)
    clearFieldOnClose()
    // }
  }

  useEffect(() => {
    const lyricistListArr: any = artistList.map((artist: any) => {
      return { value: artist._id, label: artist.name }
    })
    setLyricistList(lyricistListArr)
  }, [artistList])

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const openPicker = (type: string) => {
    if (type === 'video') {
      inputFileSelectAudio.current?.click()
    } else {
      inputFileSelect.current?.click()
    }
  }

  const handleSubmitAlbum = async (
    values: {
      name: string
      description: string
      type: string
      releaseDate: string
      artist: any
      genreId: string
      tenantId: string
      categoryId: string
      audioType: string
      enableDrm: string
      enableOffline: string
      audioUrl: string
      geoConfig: any
      singerId: any
      lyricistId: any
      composerId: any
      tags: any
    },
    resetForm: any,
  ) => {
    if (selectedFileDataURI != null) {
      if (selectedAudioFile || values.audioUrl) {
        //Disable button
        submitButtonRef.current?.setAttribute('disabled', 'true')
        // Activate indicator
        setLoading(true)
        const tagsData: any = []
        if (values.tags.length > 0) {
          values.tags.forEach((tag: any) => {
            tagsData.push(tag.value)
          })
        }
        const artistArray: any = values.artist.map((art: any) => art.value)
        const albumDatas = {
          name: values.name,
          description: values.description,
          releaseDate: moment(values.releaseDate, 'YYYY-MM-DD').valueOf(),
          type: values.type,
          artists: artistArray,
          genreId: values.genreId,
          totalDuration: 0,
          thumbnailImageUrl: selectedFileDataURI,
          categoryId: values.categoryId,
          tenantId: values.tenantId,
          tags: tagsData,
          status: MediaContentStatusType.Draft,
        }
        await baseAxios
          .post(apiEndPoints.cms.album, albumDatas)
          .then(async (albumResp: any) => {
            const geoData: any = {
              restrict: false,
              longitude: '',
              radius: '',
              allowedGeoLocations: [
                {
                  country: '',
                  state: '',
                },
              ],
            }
            if (selectedCountry) {
              // value.geoConfig.allowedGeoLocations.country = selectedCountry
              geoData.allowedGeoLocations[0].country = selectedCountry
            }
            geoData.allowedGeoLocations[0].state =
              values.geoConfig.allowedGeoLocations.state
            geoData.restrict = values.geoConfig.restrict
            const audioData = {
              title: values.name,
              description: values.description,
              accessType: values.audioType,
              // audioUrl: values.audioUrl,
              audioUrl:
                selectedAudioFile != undefined && selectedAudioFile != null
                  ? `${apiEndPoints.s3.base}audios/${selectedAudioFile.name}`
                  : values.audioUrl,
              status: MediaContentStatusType.Publish,
              enableDrm: values.enableDrm,
              enableOffline: values.enableOffline,
              geoConfig: [geoData],
            }
            const composersArray: any = values.composerId.map(
              (composer: any) => composer.value,
            )
            const singersArray: any = values.singerId.map(
              (singer: any) => singer.value,
            )
            const lyricistsArray: any = values.lyricistId
              ? values.lyricistId.map((lyricist: any) => lyricist.value)
              : []
            await baseAxios
              .post(apiEndPoints.cms.audio, audioData)
              .then(async (response: any) => {
                const audioTrackPostData = {
                  name: values.name,
                  description: values.description,
                  imageUrl: selectedFileDataURI,
                  composers: composersArray,
                  singers: singersArray,
                  lyricists: lyricistsArray,
                  audioId: response._id,
                }
                await baseAxios
                  .post(
                    apiEndPoints.cms.audioTracks(albumResp._id),
                    audioTrackPostData,
                  )
                  .then(async (dataResp: any) => {
                    await fetchData()
                    if (selectedAudioFile) uploadFile(albumResp, 'Created')

                    // submitButtonRef.current?.removeAttribute('disabled')
                    // // Activate indicator
                    // setLoading(false)
                    // albumCloseRef.current?.click()
                    // toast.success('Single Audio Created Successfully!')
                    // } else {
                    submitButtonRef.current?.removeAttribute('disabled')
                    // Activate indicator
                    setLoading(false)
                    resetForm()
                    albumCloseRef.current?.click()
                    toast.success('Single Audio Created Successfully!')
                    // }
                  })
              })
              .catch(e => {
                //Disable button
                submitButtonRef.current?.removeAttribute('disabled')
                // Activate indicator
                setLoading(false)
              })
          })
          .catch(e => {
            //Disable button
            submitButtonRef.current?.removeAttribute('disabled')
            // Activate indicator
            setLoading(false)
          })
      } else {
        toast.error('Select audio!')
        submitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
      }
    } else {
      toast.error('Select thumbnail image!')
      submitButtonRef.current?.removeAttribute('disabled')
      // Activate indicator
      setLoading(false)
    }
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    if (inputFileSelectAudio.current) {
      inputFileSelectAudio.current.value = ''
      inputFileSelectAudio.current.files = null
    }

    setSelectedFile(null)
    setSelectedAudioFile(null)
  }

  const handleUpdate = async (
    values: {
      name: string
      description: string
      type: string
      releaseDate: string
      artist: any
      genreId: string
      tenantId: string
      categoryId: string
      audioType: string
      enableDrm: string
      enableOffline: string
      audioUrl: string
      geoConfig: any
      singerId: any
      lyricistId: any
      composerId: any
      tags: any
    },
    albumId: string,
    resetForm: any,
  ) => {
    //Disable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const tagsData: any = []
    if (values.tags.length > 0) {
      values.tags.forEach((tag: any) => {
        tagsData.push(tag.value)
      })
    }
    const artistArray: any = values.artist.map((art: any) => art.value)
    const albumDataPut = {
      name: values.name,
      description: values.description,
      releaseDate: moment(values.releaseDate, 'YYYY-MM-DD').valueOf(),
      type: values.type,
      artists: artistArray,
      genreId: values.genreId,
      totalDuration: 0,
      thumbnailImageUrl: selectedFileDataURI ? selectedFileDataURI : modalImage,
      categoryId: values.categoryId,
      tenantId: values.tenantId,
      tags: tagsData,
      status: MediaContentStatusType.Draft,
      uploadStatus: selectedAudioFile
        ? UploadStatusType.Processing
        : values.audioUrl
        ? UploadStatusType.Completed
        : UploadStatusType.Completed,
    }

    await baseAxios
      .put(apiEndPoints.cms.albumWithId(albumId), albumDataPut)
      .then(async (albumResp: any) => {
        const geoData: any = {
          restrict: false,
          longitude: '',
          radius: '',
          allowedGeoLocations: [
            {
              country: '',
              state: '',
            },
          ],
        }
        if (selectedCountry) {
          // value.geoConfig.allowedGeoLocations.country = selectedCountry
          geoData.allowedGeoLocations[0].country = selectedCountry
        }
        geoData.allowedGeoLocations[0].state =
          values.geoConfig.allowedGeoLocations.state
        geoData.restrict = values.geoConfig.restrict
        const audioData = {
          title: values.name,
          description: values.description,
          accessType: values.audioType,
          // audioUrl: values.audioUrl,
          audioUrl: selectedAudioFile
            ? `${apiEndPoints.s3.base}audios/${selectedAudioFile.name}`
            : values.audioUrl,
          status: MediaContentStatusType.Publish,
          enableDrm: values.enableDrm,
          enableOffline: values.enableOffline,
          geoConfig: [geoData],
        }
        const composersArray: any = values.composerId.map(
          (composer: any) => composer.value,
        )
        const singersArray: any = values.singerId.map(
          (singer: any) => singer.value,
        )
        const lyricistsArray: any = values.lyricistId.map(
          (lyricist: any) => lyricist.value,
        )

        await baseAxios
          .put(apiEndPoints.cms.audioWithId(selectedAudioId), audioData)
          .then(async (response: any) => {
            const audioTrackPostData = {
              name: values.name,
              description: values.description,
              imageUrl: selectedFileDataURI ? selectedFileDataURI : modalImage,
              composers: composersArray,
              singers: singersArray,
              lyricists: lyricistsArray,
              audioId: response._id,
              workSpaceId: selectedWorkSpaceId,
            }
            await baseAxios
              .put(
                apiEndPoints.cms.audioTracksWithId(
                  albumId,
                  selectedAudioTrackId,
                ),
                audioTrackPostData,
              )
              .then(async (dataResp: any) => {
                await fetchData()
                if (selectedAudioFile) {
                  uploadFile(albumResp, 'Uploaded')
                }
                submitButtonRef.current?.removeAttribute('disabled')
                // Activate indicator
                setLoading(false)
                albumCloseRef.current?.click()

                resetForm()
                toast.success('Single Audio Updated Successfully!')
              })
          })
          .catch(e => {
            //Disable button
            submitButtonRef.current?.removeAttribute('disabled')
            // Activate indicator
            setLoading(false)
          })
        // dispatch(getAlbums({}))
        // albumData = album.albums
        // albumCloseRef.current?.click()
        // resetForm()
      })
      .catch(e => {
        //Disable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
      })
  }

  const uploadFile = async (data: any, type: string) => {
    status = basic.uploadStatus
    if (selectedAudioFile) {
      dispatch(setUploadStatus({ id: data._id, status: 0 }))
      // let toastCount = 0
      let percentage: any = undefined

      const videoUploaderOptions = {
        fileName: `audios/${selectedAudioFile.name}`,
        file: selectedAudioFile,
      }
      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)

      uploader
        .onProgress(async ({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setProgress(percentage)
            dispatch(setUploadStatus({ id: data._id, status: percentage }))
            status = basic.uploadStatus
            if (percentage == 100) {
              setTimeout(async () => {
                await handleUploadStatus(data, UploadStatusType.Completed)
                dispatch(setUploadStatus({ id: data._id, status: 100 }))
                status = basic.uploadStatus
                setProgress(101)
              }, 8000)
            }
          }
        })
        .onError(async (error: any) => {
          await handleUploadStatus(data, UploadStatusType.Failed)
          selectedAudioFile(undefined)
        })
      uploader.start()
    }
  }

  const handleUploadStatus = async (updateData: any, status: string) => {
    const data = Object.assign({}, updateData)
    data.uploadStatus = status
    await baseAxios.put(apiEndPoints.cms.albumWithId(updateData._id), data)
    debugger
    await fetchData()
    debugger
    toast.success('Single Audio Uploaded Successfully!')
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.albumWithId(value))
            .then(async resp => {
              // dispatch(getAlbums({}))
              // albumData = album.albums
              await fetchData()
              albumCloseRef.current?.click()
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const changePublish = async (value: any, type: string) => {
    // if (!publishButtonRef.current) {
    //   return
    // }
    //Disable button
    publishButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoadingPublish(true)
    setSelectedBtnId(value._id)
    const data = Object.assign({}, value)
    data.status = type
    baseAxios
      .put(apiEndPoints.cms.albumWithId(value._id), data)
      .then(async (albumResp: any) => {
        await fetchData()
        //Enable button
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
        toast.success('Status updated successfully! ')
      })
      .catch((err: any) => {
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
      })
  }

  const changeStatus = async (value: any, type: string) => {
    // if (!approveButtonRef.current) {
    //   return
    // }
    //Disable button
    approveButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setSelectedBtnId(value._id)
    setLoadingApprove(true)
    const data = Object.assign({}, value)
    data.status = type
    baseAxios
      .put(apiEndPoints.cms.albumWithId(value._id), data)
      .then(async (singleResp: any) => {
        await fetchData()
        //Enable button
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
        toast.success('Status updated successfully! ')
      })
      .catch((err: any) => {
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
      })
  }

  const handleCountrySelect = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event.target.value)
    initialValues.geoConfig.allowedGeoLocations.country = event.target.value
    setSelectedCountry(event.target.value)
    setStates(statesArr)
  }

  const handleCountrySelectSetValue = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event)
    setSelectedCountry(event)
    setStates(statesArr)
  }

  const onUploadChange = (e: any) => {
    setUploadType(e.currentTarget.value)
  }

  const handleAudioSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedAudioFile(file)
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <ul className="nav nav-tabs" id="myTab-two" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab-two"
                data-toggle="tab"
                href="#role-two"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                In Review
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-three"
                data-toggle="tab"
                href="#permission-three"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-two"
                data-toggle="tab"
                href="#permission-two"
                role="tab"
                aria-controls="permission"
                aria-selected="false"
              >
                Published
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="role-two"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Single Audio List</h4>
                      </div>
                      {/* {getPermission('create_magazine') && (
                    
                  )} */}
                      <a
                        data-toggle="modal"
                        data-target="#AddAlbumModel"
                        className="btn btn-primary m-1"
                        onClick={() => clearModal()}
                      >
                        Add Audio
                      </a>
                    </div>
                    <div className="iq-card-body">
                      <div className="table-view">
                        <TableLength
                          handleLengthChange={(v: any) =>
                            setPageSize(parseInt(v))
                          }
                          handleSearchValue={(searchVal: any) =>
                            setSearch(searchVal)
                          }
                        />
                        {pageLoading && (
                          <div
                            className="container d-flex justify-content-center align-items-center"
                            style={{ height: '200px' }}
                          >
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          </div>
                        )}
                        {!pageLoading && album && album.length > 0 && (
                          <table
                            className="data-tables table movie_table"
                            style={{ width: '100%' }}
                          >
                            <TableHeader
                              headers={headers}
                              onSorting={(field: any, order: any) =>
                                setSorting({ field, order })
                              }
                            />
                            <tbody>
                              {albumsData.map((album: any, index: number) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <img
                                      src={album.thumbnailImageUrl}
                                      width={70}
                                      height={100}
                                    />
                                  </td>
                                  <td>{album.name}</td>
                                  <td>
                                    {album.type.charAt(0).toUpperCase() +
                                      album.type.slice(1)}
                                  </td>
                                  <td>
                                    {moment(album.releaseDate).format(
                                      'YYYY-MM-DD',
                                    )}
                                  </td>
                                  <td>
                                    {getPermission(PERMISSIONS.APPROVE_ALBUM) ==
                                    false
                                      ? getStatus(album.status)
                                      : album.uploadStatus &&
                                        album.uploadStatus ===
                                          UploadStatusType.Completed && (
                                          <button
                                            type="button"
                                            className="btn btn-primary "
                                            ref={approveButtonRef}
                                            onClick={() => {
                                              setSelectedTenant(album)
                                              changeStatus(
                                                album,
                                                MediaContentStatusType.Approved,
                                              )
                                            }}
                                          >
                                            {loadingApprove == true &&
                                            selectedBtnId == album._id ? (
                                              <span className="indicator-progress">
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                              </span>
                                            ) : (
                                              <span className="indicator-label">
                                                Approve
                                              </span>
                                            )}
                                          </button>
                                        )}
                                  </td>
                                  {album.uploadStatus &&
                                  album.uploadStatus ===
                                    UploadStatusType.Processing ? (
                                    <td>
                                      {status.some(
                                        (item: any) => item.id === album._id,
                                      )
                                        ? status.find(
                                            (item: any) =>
                                              item.id === album._id,
                                          )?.status + '%'
                                        : UploadStatusType.Interrupted}
                                    </td>
                                  ) : (
                                    <td>
                                      {album.uploadStatus
                                        ? album.uploadStatus.toUpperCase()
                                        : 'NA'}
                                    </td>
                                  )}
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      {/* {getPermission('view_magazine') && (
                                  
                                )} */}
                                      <Link
                                        className="iq-bg-warning"
                                        data-placement="top"
                                        to="/audio/view"
                                        state={{ data: album }}
                                      >
                                        <i className="ri-eye-line" />
                                      </Link>
                                      {getPermission('update_magazine') && (
                                        <a
                                          className="iq-bg-success"
                                          data-placement="top"
                                          title=""
                                          data-toggle="modal"
                                          data-target="#AddAlbumModel"
                                          data-original-title="Edit"
                                          onClick={() =>
                                            setSelectedTenant(album)
                                          }
                                        >
                                          <i className="ri-pencil-line" />
                                        </a>
                                      )}
                                      {getPermission('delete_magazine') && (
                                        <a
                                          className="iq-bg-primary"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title=""
                                          data-original-title="Delete"
                                          onClick={() =>
                                            handleDelete(album._id)
                                          }
                                        >
                                          <i className="ri-delete-bin-line" />
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                        {!pageLoading && album && album.length == 0 && (
                          <NoData />
                        )}
                        <Pagination
                          currentPage={currentPage}
                          totalCount={totalItems}
                          pageSize={pageSize}
                          onPageChange={(page: React.SetStateAction<number>) =>
                            setCurrentPage(page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-three"
              id="permission-three"
            >
              <ApprovedAlbum
                data={album}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => {
                  setSelectedTenant(value)
                  setModalOpenType('edit')
                }}
                handlePublish={(value: any) =>
                  changePublish(value, MediaContentStatusType.Publish)
                }
                publishButtonRef={publishButtonRef}
                selectedBtnId={selectedBtnId}
                loading={loadingPublish}
                // handleChange={(value: any) => changeStatus(value, MediaContentStatusType.Publish)}
              />
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="permission-two"
            >
              <PublishedAlbum
                data={album}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => {
                  setSelectedTenant(value)
                  setModalOpenType('edit')
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddAlbumModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                type: string
                releaseDate: string
                artist: any
                genreId: string
                tenantId: string
                categoryId: string

                audioType: string
                enableDrm: string
                enableOffline: string
                audioUrl: string
                geoConfig: any
                singerId: any
                lyricistId: any
                composerId: any
                tags: any
              },
              { resetForm }: any,
            ) => {
              modalOpenType == 'edit'
                ? handleUpdate(values, selectedTenantId, resetForm)
                : handleSubmitAlbum(values, resetForm)
            }}
          >
            {(formik: any) => {
              const { errors, touched } = formik
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'edit'
                        ? 'Edit Single Audio'
                        : 'Add Single Audio'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      ref={albumCloseRef}
                      aria-label="Close"
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Name"
                                  name="name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <Field
                                  as="textarea"
                                  id="text"
                                  name="description"
                                  rows={5}
                                  className="form-control"
                                  placeholder="Description"
                                  defaultValue={''}
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              {modalOpenType == 'edit' && (
                                <div className="form-group col-md-12">
                                  <img
                                    src={modalImage}
                                    width={100}
                                    height={150}
                                  />
                                </div>
                              )}
                              <div className="form_gallery form-group">
                                <label
                                  onClick={() => openPicker('thumbnail')}
                                  htmlFor="form_gallery-upload"
                                >
                                  {selectedFile != null
                                    ? selectedFile.name
                                    : modalOpenType == 'add'
                                    ? 'Upload Thumbnail'
                                    : 'Upload New Thumbnail'}
                                </label>

                                <input
                                  type="file"
                                  ref={inputFileSelect}
                                  name="thumbnail"
                                  onChange={e => handleFileSelect(e)}
                                  accept=".png, .jpg, .jpeg, .webp"
                                />
                                <ErrorMessage
                                  name="thumbnail"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 form-group">
                              <label htmlFor="form_gallery-upload">
                                Release Date
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                data-placeholder="Release date"
                                name="releaseDate"
                              />
                              <ErrorMessage
                                name="releaseDate"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <label htmlFor="form_gallery-upload">
                                Album Type
                              </label>
                              <Field
                                as="select"
                                name="type"
                                className="form-control"
                              >
                                <option value="">Select Type</option>
                                <option value="single_audio">
                                  Single Audio
                                </option>
                              </Field>
                              <ErrorMessage
                                name="type"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                as="select"
                                name="genreId"
                                className="form-control"
                              >
                                <option value="">Select Genre</option>
                                {genres.map((genre: any) => {
                                  return (
                                    <option value={genre._id} key={genre._id}>
                                      {genre.name}
                                    </option>
                                  )
                                })}
                              </Field>
                              <ErrorMessage
                                name="genreId"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                name="artist"
                                id="artist"
                                placeholder="Select Artists"
                                isMulti={true}
                                component={SelectBox}
                                options={lyricistList}
                              />
                              <ErrorMessage
                                name="artist"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            {getIsRoot() && (
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="tenantId"
                                  className="form-control"
                                >
                                  <option value="">Select Tenant</option>
                                  {tenants.length > 0 &&
                                    tenants.map((tenant: any) => {
                                      return (
                                        <option
                                          value={tenant._id}
                                          key={tenant._id}
                                        >
                                          {tenant.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="tenantId"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            )}
                            <div
                              className={
                                getIsRoot()
                                  ? 'col-md-6 form-group'
                                  : 'col-md-12 form-group'
                              }
                            >
                              <Field
                                as="select"
                                name="categoryId"
                                className="form-control"
                              >
                                <option value="">Select Category</option>
                                {categories.length > 0 &&
                                  categories.map((category: any) => {
                                    return (
                                      <option
                                        value={category._id}
                                        key={category._id}
                                      >
                                        {category.name}
                                      </option>
                                    )
                                  })}
                              </Field>
                              <ErrorMessage
                                name="categoryId"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <div className="row ">
                                <div className="col-md-12 form-group">
                                  {/* <label htmlFor="form_gallery-upload">Type</label> */}
                                  <Field
                                    as="select"
                                    name="audioType"
                                    className="form-control"
                                  >
                                    <option value="">Select Type</option>
                                    <option value="free">Free</option>
                                    <option value="pay_per_view">
                                      Pay Per View
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="audioType"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="row m-0">
                                  <div className=" col-md-6 custom-switch">
                                    <Field
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="enableDrm"
                                      name="enableDrm"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="enableDrm"
                                    >
                                      Enable DRM availability
                                    </label>
                                    <ErrorMessage
                                      name="enableDrm"
                                      component="span"
                                      className="text-primary"
                                    />
                                  </div>
                                  <div className=" col-md-6 custom-switch">
                                    <Field
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="enableOffline"
                                      name="enableOffline"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="enableOffline"
                                    >
                                      Offline access
                                    </label>
                                    <ErrorMessage
                                      name="enableOffline"
                                      component="span"
                                      className="text-primary"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="col-md-12">
                            <p className="">GEO CONFIG</p>

                            <div className="form-group custom-switch">
                              <Field
                                type="checkbox"
                                className="custom-control-input"
                                id="geoConfig.restrict"
                                name="geoConfig.restrict"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="geoConfig.restrict"
                              >
                                Allow geo configure
                              </label>
                            </div>

                            {/* {geoConfig == true && ( */}
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6 form-group">
                                  <Field
                                    as="select"
                                    name="geoConfig.allowedGeoLocations.country"
                                    className="form-control"
                                    onChange={(e: any) =>
                                      handleCountrySelect(e)
                                    }
                                  >
                                    <option value="">Select Country</option>
                                    {countries.map((country: any) => {
                                      return (
                                        <option
                                          value={country.isoCode}
                                          key={country.isoCode}
                                        >
                                          {country.name}
                                        </option>
                                      )
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="geoConfig.allowedGeoLocations.country"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-6 form-group">
                                  <Field
                                    as="select"
                                    name="geoConfig.allowedGeoLocations.state"
                                    className="form-control"
                                  >
                                    <option value="">Select State</option>
                                    {states.length > 0 &&
                                      states.map((state: any) => {
                                        return (
                                          <option
                                            value={state.isoCode}
                                            key={state.isoCode}
                                          >
                                            {state.name}
                                          </option>
                                        )
                                      })}
                                  </Field>
                                  <ErrorMessage
                                    name="geoConfig.allowedGeoLocations.state"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="customRadio6"
                                  name="customRadio-1"
                                  value="url"
                                  checked={uploadType == 'url' ? true : false}
                                  className="custom-control-input"
                                  onChange={(e: any) => onUploadChange(e)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customRadio6"
                                >
                                  {' '}
                                  Audio URL{' '}
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="customRadio7"
                                  name="customRadio-1"
                                  value="upload"
                                  className="custom-control-input"
                                  onChange={(e: any) => onUploadChange(e)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customRadio7"
                                >
                                  {' '}
                                  Upload File{' '}
                                </label>
                              </div>
                            </div>
                            {uploadType == 'url' ? (
                              <div className="form-group">
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    modalOpenType == 'edit'
                                      ? 'New Audio Url'
                                      : 'Audio Url'
                                  }
                                  name="audioUrl"
                                />
                                <ErrorMessage
                                  name="audioUrl"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            ) : (
                              <div className="form_gallery form-group">
                                <label
                                  onClick={() => openPicker('video')}
                                  htmlFor="form_gallery-upload"
                                >
                                  {selectedAudioFile != null
                                    ? selectedAudioFile.name
                                    : 'Audio File'}
                                </label>
                                <input
                                  type="file"
                                  ref={inputFileSelectAudio}
                                  name="audioFile"
                                  onChange={e => handleAudioSelect(e)}
                                  accept=".mp3, .aac, .wav"
                                />
                                <ErrorMessage
                                  name="audioFile"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            )}
                            <div className=" form-group">
                              <Field
                                name="singerId"
                                id="singerId"
                                placeholder="Select Singers"
                                isMulti={true}
                                component={SelectBox}
                                options={lyricistList}
                              />
                              <ErrorMessage
                                name="singerId"
                                component="span"
                                className="text-primary"
                              />
                              {/* <Field
                            as="select"
                            className="form-control"
                            name="singerId"
                            multiple={true}
                          >
                            <option value="">Select Singer</option>
                            {artistList.map((artist: any) => {
                              return (
                                <option value={artist._id} key={artist._id}>
                                  {artist.name}
                                </option>
                              )
                            })}
                          </Field>
                          <ErrorMessage
                            name="singerId"
                            component="span"
                            className="text-primary"
                          /> */}
                            </div>
                            <div className=" form-group">
                              <Field
                                name="composerId"
                                id="composerId"
                                placeholder="Select Composers"
                                isMulti={true}
                                component={SelectBox}
                                options={lyricistList}
                              />
                              <ErrorMessage
                                name="composerId"
                                component="span"
                                className="text-primary"
                              />
                              {/* <Field
                            as="select"
                            className="form-control"
                            name="composerId"
                            multiple={true}
                          >
                            <option value="">Select Composer</option>
                            {artistList.map((artist: any) => {
                              return (
                                <option value={artist._id} key={artist.name}>
                                  {artist.name}
                                </option>
                              )
                            })}
                          </Field>
                          <ErrorMessage
                            name="singerId"
                            component="span"
                            className="text-primary"
                          /> */}
                            </div>

                            <div className=" form-group">
                              <Field
                                name="lyricistId"
                                id="lyricistId"
                                placeholder="Select Lyricists"
                                isMulti={true}
                                component={SelectBox}
                                options={lyricistList}
                              />
                              <ErrorMessage
                                name="lyricistId"
                                component="span"
                                className="text-primary"
                              />

                              {/* <Field
                            as="select"
                            className="form-control"
                            name="lyricistId"
                            multiple={true}
                          >
                            <option value="">Select Composer</option>
                            {artistList.map((artist: any) => {
                              return (
                                <option value={artist._id} key={artist.name}>
                                  {artist.name}
                                </option>
                              )
                            })}
                          </Field>
                          <ErrorMessage
                            name="lyricistId"
                            component="span"
                            className="text-primary"
                          /> */}
                            </div>
                            <div className=" form-group">
                              <Field
                                name="tags"
                                id="tags"
                                placeholder="Tags"
                                isMulti={true}
                                component={SelectBox}
                                // options={lyricistList}
                              />
                              <ErrorMessage
                                name="tags"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            {/* <div className="form_gallery form-group">
                          <label
                            onClick={() => openPicker('audio')}
                            htmlFor="form_gallery-upload"
                          >
                            {selectedAudioFile != null
                              ? selectedAudioFile.name
                              : 'Audio File'}
                          </label>
                          <input
                            type="file"
                            ref={inputFileSelectAudio}
                            name="videoFile"
                            onChange={e => handleAudioSelect(e)}
                            accept=".mp3, .wav"
                          />
                          <ErrorMessage
                            name="videoFile"
                            component="span"
                            className="text-primary"
                          />
                        </div> */}
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end ">
                          <button type="submit" className="btn btn-primary ">
                            {modalOpenType == 'edit' ? 'Update' : 'Submit'}
                          </button>
                        </div> */}
                      </div>

                      {/* Audio Track */}

                      <div className="row "></div>
                      {/* <p className="mt-2">Cast</p> */}
                      {/* <div className="form-control"> */}
                      {/* </div> */}
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              {modalOpenType == 'edit' ? 'Update' : 'Submit'}
                            </span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default AlbumList
