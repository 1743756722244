import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectSingleVideoState = (state: RootState) =>
  state.SingleVideo || initialState

export const singleVideoSelector = createSelector(
  [selectSingleVideoState],
  SingleVideo => SingleVideo,
)
