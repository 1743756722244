import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Album } from './types'

// eslint-disable-next-line
const getAlbums = createAction<any>('cms/getAlbums')
const deleteAlbums = createAction<any>('cms/deleteAlbums')
const updateAlbums = createAction<any>('cms/updateAlbums')
const createAlbums = createAction<any>('cms/createAlbums')
const getAuthors = createAction<any>('cms/getAuthors')
const deleteAuthors = createAction<any>('cms/deleteAuthors')
const getAudioTracks = createAction<any>('cms/getAudioTracks')
const createAudioTracks = createAction<any>('cms/getAudioTracks')
const updateAudioTracks = createAction<any>('cms/getAudioTracks')
const deleteAudioTracks = createAction<any>('cms/getAudioTracks')
const getAudios = createAction<any>('cms/getAudios')
const createAudios = createAction<any>('cms/createAudios')
const updateAudios = createAction<any>('cms/updateAudios')
const deleteAudios = createAction<any>('cms/deleteAudios')
const getAllSingleAlbumsByStatus = createAction<any>(
  'cms/getAllSingleAlbumsByStatus',
)
const getAllPodcastsByStatus = createAction<any>('cms/getAllPodcastsByStatus')

export const initialState: Album = {
  albums: [],
  audioTracks: [],
  audios: [],
  singleAlbumsOptions: [],
  podcastsAudioOptions: [],
}

const AlbumSlice = createSlice({
  name: 'Album',
  initialState: initialState,
  reducers: {
    setAlbums(state, action: PayloadAction<any>) {
      state.albums = action.payload
    },
    setAudioTracks(state, action: PayloadAction<any>) {
      state.audioTracks = action.payload
    },
    setAudios(state, action: PayloadAction<any>) {
      state.audios = action.payload
    },
    setSingleAudioOptionsFormat(state, action: PayloadAction<any>) {
      state.singleAlbumsOptions = action.payload.map((val: any) => {
        return {
          value: val._id,
          label: val.name,
          imageUrl: val.thumbnailImageUrl,
        }
      })
    },
    setPodcastOptionsFormat(state, action: PayloadAction<any>) {
      state.podcastsAudioOptions = action.payload.map((val: any) => {
        return {
          value: val._id,
          label: val.name,
          imageUrl: val.thumbnailImageUrl,
        }
      })
    },
  },
})

export {
  getAlbums,
  deleteAlbums,
  updateAlbums,
  createAlbums,
  getAuthors,
  deleteAuthors,
  getAudioTracks,
  createAudioTracks,
  updateAudioTracks,
  deleteAudioTracks,
  getAudios,
  createAudios,
  updateAudios,
  deleteAudios,
  getAllSingleAlbumsByStatus,
  getAllPodcastsByStatus,
}

export const { actions, reducer, name: sliceKey } = AlbumSlice
