import { plainAxios } from 'api/plainAxios'
import { apiEndPoints } from 'api/variables'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { actions, getCertification } from './slice'

function* getAllCertifications(): any {
  try {
    const response = yield call(
      plainAxios.get,
      apiEndPoints.tmdb.getMovieCertificates,
    )
    yield put(actions.setMovieCertificates(response.certifications.IN))
  } catch (err) {
    console.log(err)
  }
}

export function* tmdbSaga(): any {
  yield all([yield takeLatest(getCertification, getAllCertifications)])
}
