import React, { ReactNode } from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

type LayoutProps = {
  children?: ReactNode
  pageName: string
  baseHeader?: boolean
}

const CustomHeader = (props: LayoutProps) => {
  return (
    <div className="wrapper">
      {props.baseHeader !== true && <Header pageName={props.pageName} />}
      {props.baseHeader !== true && <Sidebar />}
      {props.children}
    </div>
  )
}

export default CustomHeader
