/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomHeader from 'components/CustomHeader'
import { Pagination, TableHeader } from 'components/DataTable'
import InfiniteScroll from 'react-infinite-scroll-component'
import TableLength from 'components/DataTable/TableLength'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'
import {
  createHosts,
  deleteHosts,
  getHosts,
  searchHosts,
  updateHosts,
} from 'store/hostManagement/slice'
import { hostSelector } from 'store/hostManagement/selector'
import { RadioHostType } from 'utils/enum'
import { debounce, isEmpty } from 'lodash'

const createTenantSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  radioHostType: Yup.string().required('Radio Host Type is required'),
})

const DEFAULT_LIMIT = 10

const Hosts = () => {
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const modalCloseRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [search, setSearch] = useState('')

  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const [modalImage, setModalImage]: any = useState(null)
  const formikRef = useRef<any>(null)
  const [skip, setSkip] = useState(0)
  const [timeout, saveTimeout] = useState(null)

  const { hosts, hostsLoading, hostsSearchData } = useSelector(hostSelector)

  useEffect(() => {
    dispatch(getHosts({}))
  }, [dispatch])

  // Debounce the setSearch function
  const debouncedSetSearch = debounce((searchValue: string) => {
    setSearch(searchValue)
  }, 500) // You can adjust the debounce delay here (e.g., 500ms).

  useEffect(() => {
    if (search) {
      dispatch(
        searchHosts({ skip: skip, limit: DEFAULT_LIMIT, searchValue: search }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search])

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Image', field: 'profile', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Type', field: 'name', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const [initialValues] = useState({
    name: '',
    radioHostType: '',
  })

  const setSelectedTenant = async (value: any) => {
    await clearModal()
    setModalOpenType('edit')
    initialValues.name = value.name
    initialValues.radioHostType = value.type
    setModalImage(value.profileImageUrl)
    setSelectedTenantId(value._id)
  }

  const handleSubmit = async (
    value: { name: string; radioHostType: string },
    resetForm: any,
  ) => {
    if (selectedFileDataURI) {
      const HostPostData: any = {
        name: value.name,
        profileImageUrl: selectedFileDataURI,
        type: value.radioHostType,
        buttonRef: modalCloseRef,
      }
      dispatch(createHosts(HostPostData))
    } else {
      toast.error('Please Select Profile Image')
    }
  }

  const handleUpdate = async (
    value: { name: string; radioHostType: string },
    hostId: string,
    resetForm: any,
  ) => {
    const hostsPutData: any = {
      hostId,
      name: value.name,
      profileImageUrl:
        selectedFileDataURI != null ? selectedFileDataURI : modalImage,
      type: value.radioHostType,
      buttonRef: modalCloseRef,
    }
    dispatch(updateHosts(hostsPutData))
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        dispatch(deleteHosts(value))
      }
    })
  }

  const openPicker = () => {
    inputFileSelect.current?.click()
  }

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const clearModal = async () => {
    initialValues.name = ''
    setModalImage(null)
    setSelectedTenantId('')
    setModalOpenType('add')
    setSelectedFile(null)
    setSelectedFileDataURI(null)
    // }
    clearFieldOnClose()
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    setSelectedFile(null)
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const hostsDataToMap = !isEmpty(search) ? hostsSearchData : hosts

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Host Lists</h4>
                  </div>

                  <div className="sign-info">
                    <a
                      data-toggle="modal"
                      data-target="#ModalCenter"
                      className="btn btn-primary"
                      onClick={() => clearModal()}
                    >
                      Add Host
                    </a>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => {
                        return null
                      }}
                      handleSearchValue={(searchVal: any) =>
                        debouncedSetSearch(searchVal)
                      }
                      disableSelectBox={true}
                    />
                    {hostsLoading && (
                      <div
                        className="container d-flex justify-content-center align-items-center"
                        style={{ height: '200px' }}
                      >
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#e20e02"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                    )}
                    {!hostsLoading &&
                      hostsDataToMap &&
                      hostsDataToMap.length > 0 && (
                        <InfiniteScroll
                          dataLength={hostsDataToMap.length}
                          next={() => {
                            setSkip(skip + 10)
                            dispatch(
                              getHosts({
                                skip: skip + 10,
                                limit: DEFAULT_LIMIT,
                              }),
                            )
                          }}
                          hasMore={true} // Replace with a condition based on your data source
                          loader={
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{ marginLeft: '45%' }}
                              visible={hostsLoading}
                            />
                          }
                          // endMessage={<p>No more data to load.</p>}
                          scrollThreshold={1}
                        >
                          <table
                            className="data-tables table movie_table"
                            style={{ width: '100%' }}
                          >
                            <TableHeader headers={headers} />
                            <tbody>
                              {hostsDataToMap.map(
                                (host: any, index: number) => (
                                  <tr key={`artist-${index}`}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <img
                                        src={host.profileImageUrl}
                                        width={50}
                                        height={50}
                                      />
                                    </td>
                                    <td>{host.name}</td>
                                    <td>{capitalizeFirstLetter(host.type)}</td>
                                    <td>
                                      <div className="flex align-items-center list-user-action">
                                        <a
                                          className="iq-bg-success"
                                          data-placement="top"
                                          title=""
                                          data-toggle="modal"
                                          data-target="#ModalCenter"
                                          data-original-title="Edit"
                                          onClick={() =>
                                            setSelectedTenant(host)
                                          }
                                        >
                                          <i className="ri-pencil-line" />
                                        </a>
                                        <a
                                          className="iq-bg-primary"
                                          data-toggle="tooltip"
                                          data-original-title="Delete"
                                          onClick={() => handleDelete(host._id)}
                                        >
                                          <i className="ri-delete-bin-line" />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      )}
                    {!hostsLoading && hosts.length == 0 && <NoData />}
                    {!hostsLoading &&
                      !hostsDataToMap.length &&
                      !isEmpty(search) && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <p>{`No Hosts found with the name ${search}`}</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={createTenantSchema}
            onSubmit={(
              values: { name: string; radioHostType: string },
              { resetForm }: any,
            ) => {
              modalOpenType == 'add'
                ? handleSubmit(values, resetForm)
                : handleUpdate(values, selectedTenantId, resetForm)
            }}
            innerRef={formikRef}
          >
            {(formik: any) => {
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add' ? 'Add Host' : 'Edit Host'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={modalCloseRef}
                      onClick={() => {
                        setModalOpenType('add')
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control  mb-0"
                                placeholder="Enter Host Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="text-primary"
                              />
                            </div>

                            {modalOpenType == 'edit' && (
                              <div className="form-group col-md-12">
                                <img
                                  src={selectedFileDataURI || modalImage}
                                  width={100}
                                  height={100}
                                />
                              </div>
                            )}
                            <div className="col-md-12  form_gallery form-group">
                              <label
                                onClick={openPicker}
                                htmlFor="form_gallery-upload"
                              >
                                {selectedFile != null
                                  ? selectedFile.name
                                  : modalOpenType == 'edit'
                                  ? 'New Profile Image'
                                  : 'Profile Image'}
                              </label>
                              <input
                                type="file"
                                ref={inputFileSelect}
                                name="videoFile"
                                onChange={e => handleFileSelect(e)}
                                accept=".jpg, .jpeg, .png, .webp"
                              />
                              <ErrorMessage
                                name="videoFile"
                                component="span"
                                className="text-primary"
                              />
                            </div>

                            <div className="col-md-12  form_gallery form-group">
                              <Field
                                as="select"
                                name="radioHostType"
                                className="form-control"
                              >
                                <option value="">Select Radio Host Type</option>
                                <option value={`${RadioHostType.Rj}`}>
                                  Rj
                                </option>
                                <option value={`${RadioHostType.Guest}`}>
                                  Guest
                                </option>
                              </Field>
                              <ErrorMessage
                                name="type"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                          <button type="submit" className="btn btn-primary">
                            {!hostsLoading && (
                              <span className="indicator-label">
                                {modalOpenType == 'add' ? 'Submit' : 'Update'}
                              </span>
                            )}
                            {hostsLoading && (
                              <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default Hosts
