/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomHeader from 'components/CustomHeader'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { getPermission } from 'utils/helpers/systemHelpers'
import { PERMISSIONS } from 'model/auth'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
})

const MagazineList = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const authorCloseRef = useRef<HTMLButtonElement>(null)
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [modalImage, setModalImage]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const [loading, setLoading] = useState(false)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const [authors, setAuthors]: any = useState(null)

  const fetchData = async () => {
    setPageLoading(true)
    const response = await baseAxios.get(apiEndPoints.cms.authors())
    if (response) {
      setAuthors(response)
    }
    setPageLoading(false)
  }
  useEffect(() => {
    fetchData()
    // dispatch(getAuthors({}))

    // setTimeout(() => , 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // let authorsListData = magazine.authors

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Profile', field: 'profile', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Description', field: 'desc', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const authorsData = useMemo(() => {
    const tempDataDraft: any = []
    authors &&
      authors.length > 0 &&
      authors.map((videoPo: any) => {
        tempDataDraft.push(videoPo)
      })
    let computedAuthors: any = tempDataDraft

    if (search) {
      computedAuthors = computedAuthors.filter((authorz: { name: string }) =>
        authorz.name.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedAuthors.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedAuthors.slice(firstPageIndex, lastPageIndex)
  }, [authors, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    description: '',
  })

  const setSelectedTenant = (value: any) => {
    setModalOpenType('edit')
    initialValues.name = value.name
    initialValues.description = value.description
    setModalImage(value.profileImageUrl)
    setSelectedTenantId(value._id)
  }

  const clearModal = () => {
    initialValues.name = ''
    initialValues.description = ''
    setModalImage(null)
    setSelectedTenantId('')
    setModalOpenType('add')
    clearFieldOnClose()
  }

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      // console.log(reader.result)
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        // initialValues.movieThumbnail = reader.result!.toString()
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const openPicker = () => {
    inputFileSelect.current?.click()
  }

  const handleSubmitAuthor = async (
    values: {
      name: string
      description: string
    },
    resetForm: any,
  ) => {
    if (!submitButtonRef.current) {
      return
    }

    if (selectedFileDataURI) {
      //Disable button
      submitButtonRef.current?.setAttribute('disabled', 'true')
      // Activate indicator
      setLoading(true)
      const authorDataPost = {
        name: values.name,
        profileImageUrl: selectedFileDataURI,
        description: values.description,
      }
      await baseAxios
        .post(apiEndPoints.cms.authors(), authorDataPost)
        .then(async () => {
          await fetchData()
          // dispatch(getAuthors({}))
          //Disable button
          submitButtonRef.current?.removeAttribute('disabled')
          // Activate indicator
          setLoading(false)
          // authorsListData = magazine.authors
          authorCloseRef.current?.click()
          resetForm()
        })
        .catch((err: any) => {
          submitButtonRef.current?.removeAttribute('disabled')
          // Activate indicator
          setLoading(false)
        })
    } else {
      toast.error('Please Select Profile Image')
    }
  }

  const handleUpdate = async (
    value: { name: string; description: string },
    authorId: string,
    resetForm: any,
  ) => {
    const authorPutData: any = {
      name: value.name,
      description: value.description,
      profileImageUrl:
        selectedFileDataURI != null ? selectedFileDataURI : modalImage,
    }
    await baseAxios
      .put(apiEndPoints.cms.authorsWithId(authorId), authorPutData)
      .then(async () => {
        await fetchData()
        // dispatch(getAuthors({}))
        // authorsListData = magazine.authors
        authorCloseRef.current?.click()
        resetForm()
      })
  }

  const handleDelete = async (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.authorsWithId(value))
            .then(async resp => {
              // dispatch(getMagazines({}))
              await fetchData()
              // magazineData = magazine.magazines
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
    // dispatch(deleteAuthors({ authorId: value }))
    // dispatch(getAuthors({}))
    // authorsListData = magazine.authors
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    setSelectedFile(null)
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Authors List</h4>
                  </div>
                  {getPermission(PERMISSIONS.CREATE_AUTHOR) && (
                    <a
                      data-toggle="modal"
                      data-target="#AddAuthorModel"
                      className="btn btn-primary m-1"
                      onClick={() => clearModal()}
                    >
                      Add Author
                    </a>
                  )}
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                      handleSearchValue={(searchVal: any) =>
                        setSearch(searchVal)
                      }
                    />
                    {pageLoading && (
                      <div
                        className="container d-flex justify-content-center align-items-center"
                        style={{ height: '200px' }}
                      >
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#e20e02"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                    )}
                    {!pageLoading && authors && authors.length > 0 && (
                      <table
                        className="data-tables table movie_table"
                        style={{ width: '100%' }}
                      >
                        <TableHeader
                          headers={headers}
                          onSorting={(field: any, order: any) =>
                            setSorting({ field, order })
                          }
                        />
                        <tbody>
                          {authorsData.map((author: any, index: number) => (
                            <tr key={`author-${index}`}>
                              <td>{index + 1}</td>
                              <td>
                                <img
                                  src={author.profileImageUrl}
                                  width={50}
                                  height={50}
                                />
                              </td>
                              <td>{author.name}</td>
                              <td className="">
                                <p
                                  style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    width: '200px',
                                  }}
                                >
                                  {author.description}
                                </p>
                              </td>
                              {/* <td>
                              {moment(magazine.releaseDate).format(
                               'YYYY-MM-DD',
                              )}
                            </td> */}
                              <td>
                                <div className="flex align-items-center list-user-action">
                                  {getPermission(PERMISSIONS.UPDATE_AUTHOR) && (
                                    <a
                                      className="iq-bg-success"
                                      data-placement="top"
                                      title=""
                                      data-toggle="modal"
                                      data-target="#AddAuthorModel"
                                      data-original-title="Edit"
                                      onClick={() => setSelectedTenant(author)}
                                    >
                                      <i className="ri-pencil-line" />
                                    </a>
                                  )}
                                  {getPermission(PERMISSIONS.DELETE_AUTHOR) && (
                                    <a
                                      className="iq-bg-primary"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Delete"
                                      onClick={() => handleDelete(author._id)}
                                    >
                                      <i className="ri-delete-bin-line" />
                                    </a>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {!pageLoading && authors && authors.length == 0 && (
                      <NoData />
                    )}
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddAuthorModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          {' '}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
              values: { name: string; description: string },
              { resetForm }: any,
            ) => {
              modalOpenType == 'add'
                ? handleSubmitAuthor(values, resetForm)
                : handleUpdate(values, selectedTenantId, resetForm)
            }}
          >
            {(formik: any) => {
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add' ? 'Add Author' : 'Edit Author'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={authorCloseRef}
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className=" form-group">
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Author Name"
                          name="name"
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="text-primary"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          id="text"
                          as="textarea"
                          name="description"
                          rows={5}
                          className="form-control"
                          placeholder="Description"
                          defaultValue={''}
                        />
                        <ErrorMessage
                          name="description"
                          component="span"
                          className="text-primary"
                        />
                      </div>
                      {modalOpenType == 'edit' && (
                        <div className="form-group col-md-12">
                          <img src={modalImage} width={100} height={100} />
                        </div>
                      )}
                      <div className="form_gallery form-group">
                        <label
                          onClick={() => openPicker()}
                          htmlFor="form_gallery-upload"
                        >
                          {selectedFile != null
                            ? selectedFile.name
                            : 'Upload Profile Image'}
                        </label>
                        <input
                          type="file"
                          ref={inputFileSelect}
                          name="profile"
                          onChange={e => handleFileSelect(e)}
                          accept=".jpg, .png, .jpeg, .webp"
                        />
                        <ErrorMessage
                          name="profile"
                          component="span"
                          className="text-primary"
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <button
                          type="submit"
                          className="btn btn-primary "
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              {modalOpenType == 'add' ? 'Submit' : 'Update'}
                            </span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default MagazineList
