import React from 'react'
import Select from 'react-select'
import Search from '../Search'

const TableLength = (props: {
  handleLengthChange: any
  handleSearchValue: any
  disableSelectBox?: boolean
  placeholder?: any
}) => {
  const {
    handleLengthChange,
    handleSearchValue,
    disableSelectBox,
    placeholder,
  } = props

  const lengthOptions = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ]
  return (
    <div className="row mb-4">
      <div className="col-sm-12 col-md-4 d-flex justify-content-start align-items-center">
        <div className="dataTables_length" id="new-cons_length">
          {disableSelectBox ? (
            <></>
          ) : (
            <label className="d-flex justify-content-start align-items-center gap-2 p-0 m-0">
              Show
              <Select
                // components={{
                //   DropdownIndicator: () => null,
                //   IndicatorSeparator: () => null,
                // }}
                options={lengthOptions}
                onChange={(data: any) => {
                  handleLengthChange(data.value)
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: '#fcaaa4',
                    primary: '#E20E02',
                  },
                })}
              />
              {/* <select name="new-cons_length" aria-controls="new-cons" className="form-control form-control-sm">
                        {lengthOptions.map( options => {
                            return (<option key={options.value} onClick={(e) => console.log(e)} value={options.value}>{options.label}</option>)
                        })}
                    </select> */}
              entries
            </label>
          )}
        </div>
      </div>

      <div className="col-md-8 d-flex justify-content-end align-items-center">
        <Search onSearch={handleSearchValue} placeholder={placeholder} />
      </div>
    </div>
  )
}

export default TableLength
