import { PayloadAction, createAction } from '@reduxjs/toolkit'
import { createSlice } from 'utils/@reduxjs/toolkit'
import { Users } from './types'

// eslint-disable-next-line
const getUsersList = createAction<any>('users/getUsersList')
const getUserById = createAction<any>('users/getUserById')
const loginUser = createAction<any>('users/loginUser')
const logoutUser = createAction<any>('users/logoutUser')
const addNewUser = createAction<any>('users/addNewUser')
const updateUser = createAction<any>('users/updateUser')
const deleteUser = createAction<any>('users/deleteUser')
const resetUser = createAction<any>('users/resetUser')
const resetVerify = createAction<any>('users/resetVerify')
const updatePassword = createAction<any>('users/updatePassword')
const adminPasswordUpdate = createAction<any>('users/adminPasswordUpdate')
const adminCreateUser = createAction<any>('users/adminCreateUser')
const adminDeleteUser = createAction<any>('users/adminDeleteUser')
const adminUpdateUser = createAction<any>('users/adminUpdateUser')

export const initialState: Users = {
  users: [],
  user: {},
  authUser: {},
  userInfo: {},
  userEmail: null,
}

const UserSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    setUsersData(state, action: PayloadAction<any>) {
      state.users = action.payload
    },
    setAuthUser(state, action: PayloadAction<any>) {
      state.authUser = action.payload
    },
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload
    },
    setUsers(state, action: PayloadAction<any>) {
      state.users = action.payload
    },
    setUserEmail(state, action: PayloadAction<any>) {
      state.userEmail = action.payload
    },
  },
})

export {
  loginUser,
  getUsersList,
  addNewUser,
  deleteUser,
  getUserById,
  updateUser,
  logoutUser,
  resetUser,
  updatePassword,
  resetVerify,
  adminPasswordUpdate,
  adminCreateUser,
  adminDeleteUser,
  adminUpdateUser,
}

export const { actions, reducer, name: sliceKey } = UserSlice
