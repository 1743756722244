import React, { useState } from 'react'
import './styles.scss'

const ButtonGroup = ({ options, onPressButtonGroup, selected = [] }: any) => {
  const [clickedButtons, setClickedButtons] = useState<any>(selected)

  const handleClick = (event: any, label: any) => {
    let newButtons = []
    if (clickedButtons.includes(label)) {
      newButtons = clickedButtons.filter((x: any) => x != label)
    } else {
      newButtons = [...clickedButtons, ...[label]]
    }
    setClickedButtons(newButtons)
    onPressButtonGroup(newButtons)
  }

  return (
    <div className="button-group-container">
      {options.map((buttonLabel: any, i: any) => (
        <button
          key={i}
          type="button"
          name={buttonLabel}
          onClick={event => handleClick(event, buttonLabel)}
          className={
            buttonLabel ===
            clickedButtons.find((ele: any) => ele === buttonLabel)
              ? 'customButton active'
              : 'customButton'
          }
        >
          {buttonLabel}
        </button>
      ))}
    </div>
  )
}

export default ButtonGroup
