import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createHosts,
  deleteHosts,
  getHosts,
  updateHosts,
  searchHosts,
} from './slice'

function* getAllHosts({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.hosts.getHosts(payload?.skip, payload?.limit),
    )
    yield put(actions.setHosts({ data: response, skip: payload?.skip }))
  } catch (err) {
    console.log(err)
  }
}

function* deleteHost({ payload }: ReturnType<typeof deleteHosts>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.hosts.hostsWithId(payload))
    yield put(actions.setDeleteHost(payload))
    toast.success(message.HOST_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateHost({ payload }: ReturnType<typeof updateHosts>): any {
  try {
    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    yield put(actions.setHostLoader(true))
    const response = yield call(
      baseAxios.put,
      apiEndPoints.hosts.hostsWithId(payload.hostId),
      payload,
    )
    yield put(actions.setUpdateHost(response))
    buttonRef?.current?.click()
    yield put(actions.setHostLoader(false))
  } catch (err) {
    yield put(actions.setHostLoader(false))
    console.log(err)
  }
}

function* createHost({ payload }: any): any {
  try {
    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    yield put(actions.setHostLoader(true))
    const response = yield call(
      baseAxios.post,
      apiEndPoints.hosts.createHost,
      payload,
    )
    buttonRef?.current?.click()
    yield put(actions.setCreateHost(response))
    yield put(actions.setHostLoader(false))
  } catch (err) {
    yield put(actions.setHostLoader(false))
    console.log(err)
  }
}

function* searchRadioHosts({ payload }: any): any {
  try {
    yield put(actions.setHostLoader(true))

    const response = yield call(
      baseAxios.get,
      apiEndPoints.hosts.searchHosts(payload?.searchValue),
    )
    yield put(actions.setSearchHosts(response))
    yield put(actions.setHostLoader(false))
  } catch (err) {
    yield put(actions.setHostLoader(false))

    console.log(err)
  }
}

export function* hostSaga(): any {
  yield all([yield takeLatest(getHosts, getAllHosts)])
  yield all([yield takeLatest(searchHosts, searchRadioHosts)])
  yield all([yield takeLatest(deleteHosts, deleteHost)])
  yield all([yield takeLatest(updateHosts, updateHost)])
  yield all([yield takeLatest(createHosts, createHost)])
}
