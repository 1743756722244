export const message = {
  USER_CREATE_MSG: 'User created successfully',
  USER_UPDATE_MSG: 'User updated successfully',
  USER_DELETE_MSG: 'User deleted successfully',
  USER_REGISTER_MSG: 'You have successfully registered',
  REST_SUCCESS_MSG: 'Reset Password Successfully!',
  PASSWORD_UPDATE_SUCCESS: 'Password Updated Successfully!',
  PERMISSION_CREATE_SUCCESS_MESSAGE: 'Permission Created Successfully!',
  PERMISSION_DELETE_SUCCESS_MESSAGE: 'Permission Deleted Successfully!',
  PERMISSION_UPDATE_SUCCESS_MESSAGE: 'Permission Updated Successfully!',
  ROLE_CREATE_SUCCESS_MESSAGE: 'Role Created Successfully!',
  ROLE_DELETE_SUCCESS_MESSAGE: 'Role Deleted Successfully!',
  ROLE_UPDATE_SUCCESS_MESSAGE: 'Role Updated Successfully!',
  INVALID_ROLE_ID: 'Invalid Role ID!',
  AUTHOR_DELETE_SUCCESS: 'Authors deleted successfully!',
  AUTHOR_CREATE_SUCCESS: 'Authors create successfully!',
  MAGAZINE_DELETE_SUCCESS: 'Magazine deleted successfully!',
  MAGAZINE_CREATE_SUCCESS: 'Magazine create successfully!',
  SINGLE_VIDEO_DELETE_SUCCESS: 'Single Video Content Deleted Successfully!',
  AUDIO_TRACK_DELETE_SUCCESS: 'Audio Track Deleted Successfully!',
  HOST_DELETE_SUCCESS: 'Host deleted successfully!',
  SPONSOR_DELETE_SUCCESS: 'Sponsor deleted successfully!',
  RADIO_SHOW_DELETE_SUCCESS: 'Radio show deleted successfully!',
  JOB_DELETE_SUCCESS: 'Job deleted successfully!',
}

export const PaginationParams = {
  Offset: 0,
  Limit: 10000,
}

export const LoginCredentails = {
  email: 'manikyamallu@gmail.com',
  password: 'Pass12!@',
}

export const LoginResponse = {
  email: 'manikyamallu@gmail.com',
  name: 'Manikyam',
  mobileNumber: 9441136987,
  appId: '632089028fa3612011b811bc',
  role: 'User',
}

export const ExpirationMessage = {
  AccessTokenExpired: 'Access Token has expired',
  AccessTokenRevoked: 'Access Token has been revoked',
}

export const getRoleName = (roleName: string) => {
  switch (roleName) {
    case 'content_creator':
      return 'Content Creator'
    case 'content_reviewer':
      return 'Content Reviewer'
    case 'super_admin':
      return 'Super Admin'
    default:
      return 'NAN'
  }
}
