/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { apiEndPoints } from 'api/variables'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CustomHeader from '../../../components/CustomHeader'
import { baseAxios } from 'api/axios'
import ReactPlayer from 'react-player'

const ViewSingleVideo = () => {
  const location = useLocation()
  const [videoData, setVideoData] = useState(location.state.data)
  const [videoDetail, setVideoDetail]: any = useState({})
  const [selectedGenre, setSelectedGenre]: any = useState(null)
  const [playing, setPlaying]: any = useState(false)
  const playerModalRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (videoData != null) {
      getData()
    }
  }, [videoData])

  useEffect(() => {})

  const getData = async () => {
    await baseAxios
      .get(apiEndPoints.cms.videoWithId(videoData.videoId))
      .then(response => {
        setVideoDetail(response)
      })
    const genre: any = await baseAxios.get(
      apiEndPoints.cms.genresWithId(videoData.genreId),
    )
    setSelectedGenre(genre)
  }

  const closeModal = () => {
    setPlaying(false)
    // playerModalRef.current?
  }

  return (
    <CustomHeader pageName={'Movie View'}>
      <div id="content-page" className="content-page ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="iq-card" style={{ padding: '0px !important' }}>
                {/* <div className="iq-card-header-toolbar d-flex justify-content-end ">
                  
                </div> */}
                <div className="iq-card-body w-100 p-0">
                  <div className="card bg-dark text-white">
                    <img
                      src={videoData.bannerImageUrl}
                      className="card-img"
                      style={{ height: '450px' }}
                      alt="Movie Poster"
                    />
                    <div className="card-img-overlay d-flex align-items-center justify-content-center">
                      <div
                        className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                        }}
                        data-toggle="modal"
                        data-target="#VideoPlayer"
                      >
                        <i
                          className="fas fa-play fs-1"
                          style={{ fontSize: '1.3rem' }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="iq-card" style={{ padding: '0px !important' }}>
                {/* <div className="iq-card-header-toolbar d-flex justify-content-end ">
                  
                </div> */}
                <div className="iq-card-body w-100 p-0">
                  <div className="card bg-dark text-white">
                    <img
                      src={videoData.titleImageUrl}
                      className="card-img"
                      alt="Movie Poster"
                    />
                    {/* <div className="card-img-overlay d-flex align-items-center justify-content-center">
                      <div
                        className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                        }}
                        data-toggle="modal"
                        data-target="#VideoPlayer"
                      >
                        <i
                          className="fas fa-play fs-1"
                          style={{ fontSize: '1.3rem' }}
                        ></i>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="iq-card">
                <div className="iq-card-body">
                  <h5 className="iq-card-title">{videoData.name}</h5>
                  {/* <p className="iq-card-text p-0 m-0">
                    <strong>Rating:</strong> 4.5/5{' '}
                    <i className="fas fa-star text-warning"></i>
                  </p> */}
                  <p className="iq-card-text p-0 m-0">
                    <strong>Release Date:</strong>{' '}
                    {moment(videoData.releaseDate).format('DD/MM/YYYY')}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>Duration:</strong>{' '}
                    {`${videoDetail.duration} Minutes`}
                  </p>
                  <p className="iq-card-text pb-3 m-0">
                    <strong>Genre:</strong>{' '}
                    {selectedGenre && selectedGenre.name
                      ? selectedGenre.name
                      : ''}
                  </p>
                  <p className="iq-card-text">{videoData.description}</p>

                  <div>
                    <h6 className="card-subtitle mb-2 text-muted">
                      Cast &amp; Crew
                    </h6>
                    <ul className="list-group list-group-flush">
                      {videoData.cast != null &&
                        videoData.cast != undefined &&
                        videoData.cast.data != null &&
                        videoData.cast.data != undefined &&
                        videoData.cast.data.length > 0 &&
                        videoData.cast.data.map((castData: any) => {
                          return (
                            <li className="list-group-item">
                              {castData.reelName} -{' '}
                              <strong>{castData.role}</strong>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="VideoPlayer"
        tabIndex={-1}
        role="dialog"
        data-backdrop="static"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header d-flex justify-content-end align-items-center">
              <div
                className="p-0 m-0 d-flex justify-content-center align-items-center"
                style={{
                  // background: 'red',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              >
                <i
                  className="ri-close-line fs-1"
                  style={{ fontSize: '30px' }}
                ></i>
              </div>
            </div>

            <ReactPlayer
              className="react-player"
              url={videoDetail.videoUrl}
              width={'100%'}
              height={'100%'}
              playing={playing}
              controls={true}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              config={{
                file: {
                  attributes: {
                    crossorigin: 'anonymous',
                  },
                },
              }}
              fileConfig={{
                attributes: {
                  style: {
                    display: 'block',
                    width: 'auto',
                    height: 'auto',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default ViewSingleVideo
