import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SingleVideo } from './types'

// eslint-disable-next-line
const getSingleVideos = createAction<any>('cms/getSingleVideos')
const deleteSingleVideos = createAction<any>('cms/deleteSingleVideos')
const updateSingleVideos = createAction<any>('cms/updateSingleVideos')
const createSingleVideos = createAction<any>('cms/createSingleVideos')
const getAllSingleVideosByStatus = createAction<any>(
  'cms/getAllSingleVideosByStatus',
)

export const initialState: SingleVideo = {
  singleVideos: [],
  singleVideosOptions: [],
}

const SingleVideoSlice = createSlice({
  name: 'SingleVideo',
  initialState: initialState,
  reducers: {
    setSingleVideos(state, action: PayloadAction<any>) {
      state.singleVideos = action.payload
    },

    setOptionsFormat(state, action: PayloadAction<any>) {
      state.singleVideosOptions = action.payload.map((val: any) => {
        return {
          value: val._id,
          label: val.name,
          imageUrl: val.titleImageUrl,
        }
      })
    },
  },
})

export {
  getSingleVideos,
  deleteSingleVideos,
  updateSingleVideos,
  createSingleVideos,
  getAllSingleVideosByStatus,
}

export const { actions, reducer, name: sliceKey } = SingleVideoSlice
