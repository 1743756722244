import React from 'react'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AppRoutes from './routes'

function App() {
  return (
    <HashRouter>
      <AppRoutes />
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
      />
    </HashRouter>
  )
}

export default App
