/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import CustomHeader from 'components/CustomHeader'
import {
  createTenant,
  deleteTenant,
  getTenants,
  updateTenant,
} from 'store/roleManagement/slice'
import { getPermission } from 'utils/helpers/systemHelpers'
import { PERMISSIONS } from 'model/auth'
import { getCategories } from 'store/basicManagement/slice'
import { basicSelector } from 'store/basicManagement/selector'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const createTenantSchema = Yup.object().shape({
  name: Yup.string().required('FirstName is required'),
})

const Categories = () => {
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const categoryCloseRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const basic = useSelector(basicSelector)

  useEffect(() => {
    dispatch(getCategories({}))
  }, [])

  let categoryData = basic.categories

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Category Name', field: 'contact', sortable: false },
    { name: 'Display Name', field: 'contact', sortable: false },
    { name: 'Category Type', field: 'contact', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const categorysData = useMemo(() => {
    let computedTenants: any = categoryData

    if (search) {
      computedTenants = computedTenants.filter(
        (user: {
          firstName: string
          lastName: string
          phoneNumber: string
          email: string
        }) =>
          user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          user.lastName.toLowerCase().includes(search.toLowerCase()) ||
          user.phoneNumber.toLowerCase().includes(search.toLowerCase()) ||
          user.email.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedTenants.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedTenants.slice(firstPageIndex, lastPageIndex)
  }, [categoryData, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    displayName: '',
    contentType: '',
  })

  const setSelectedCategory = async (value: any) => {
    await clearModal()
    setSelectedCategoryId(value._id)
    setModalOpenType('edit')
    initialValues.name = value.name
    initialValues.displayName = value.contactDetails.displayName
    initialValues.contentType = value.contactDetails.contentType
  }

  const clearModal = async () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    setModalOpenType('add')
    initialValues.name = ''
    initialValues.displayName = ''
    initialValues.contentType = ''
    setSelectedCategoryId('')
    // }
  }

  const handleSubmit = async (
    value: {
      name: string
      displayName: string
      contentType: string
    },
    resetForm: any,
  ) => {
    baseAxios.post(apiEndPoints.cms.categories, value).then((resp: any) => {
      dispatch(getCategories({}))
      categoryData = basic.categories
      categoryCloseRef.current?.click()
      resetForm()
    })
  }

  const handleUpdate = async (
    value: { name: string; displayName: string; contentType: string },
    categoryId: string,
    resetForm: any,
  ) => {
    baseAxios
      .put(apiEndPoints.cms.categoriesWithId(categoryId), value)
      .then((res: any) => {
        dispatch(getCategories({}))
        categoryData = basic.categories
        categoryCloseRef.current?.click()
        resetForm()
      })
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.categoriesWithId(value))
            .then(resp => {
              dispatch(getCategories({}))
              categoryData = basic.categories
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Category Lists</h4>
                  </div>

                  <div className="sign-info">
                    <a
                      data-toggle="modal"
                      data-target="#ModalCenter"
                      className="btn btn-primary"
                      onClick={() => clearModal()}
                    >
                      Add Category
                    </a>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                      handleSearchValue={(searchVal: any) =>
                        setSearch(searchVal)
                      }
                    />
                    <table
                      className="data-tables table movie_table"
                      style={{ width: '100%' }}
                    >
                      <TableHeader
                        headers={headers}
                        onSorting={(field: any, order: any) =>
                          setSorting({ field, order })
                        }
                      />
                      <tbody>
                        {categorysData.map((category: any, index: number) => (
                          <tr key={`category-${index}`}>
                            <td>{index + 1}</td>
                            <td>{category.name}</td>
                            <td>{category.displayName}</td>
                            <td>{category.contentType}</td>
                            <td>
                              <div className="flex align-items-center list-user-action">
                                {getPermission(PERMISSIONS.UPDATE_TENANT) && (
                                  <a
                                    className="iq-bg-success"
                                    data-placement="top"
                                    title=""
                                    data-toggle="modal"
                                    data-target="#ModalCenter"
                                    data-original-title="Edit"
                                    onClick={() =>
                                      setSelectedCategory(category)
                                    }
                                  >
                                    <i className="ri-pencil-line" />
                                  </a>
                                )}
                                {getPermission(PERMISSIONS.DELETE_TENANT) && (
                                  <a
                                    className="iq-bg-primary"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Delete"
                                    onClick={() => handleDelete(category._id)}
                                  >
                                    <i className="ri-delete-bin-line" />
                                  </a>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={createTenantSchema}
            onSubmit={(
              values: {
                name: string
                displayName: string
                contentType: string
              },
              { resetForm }: any,
            ) => {
              modalOpenType == 'add'
                ? handleSubmit(values, resetForm)
                : handleUpdate(values, selectedCategoryId, resetForm)
            }}
          >
            {(formik: any) => {
              const { errors, touched } = formik
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add'
                        ? 'Add Category'
                        : 'Edit Category'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={categoryCloseRef}
                      onClick={() => {
                        setModalOpenType('add')
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control  mb-0"
                                placeholder="Enter Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="displayName"
                                id="displayName"
                                className="form-control  mb-0"
                                placeholder="Enter Web Display Text"
                              />
                              <ErrorMessage
                                name="displayName"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                as="select"
                                className="form-control"
                                name="contentType"
                              >
                                <option value="">Select Category Type</option>
                                <option value="video">Video</option>
                                <option value="video_playlist">
                                  Video Playlist
                                </option>
                                <option value="audio">Audio</option>
                                <option value="audio_playlist">
                                  Audio Playlist
                                </option>
                                <option value="magazine">Magazine</option>
                                <option value="awards&cermonries">
                                  Awards & Cermonries
                                </option>
                              </Field>
                              <ErrorMessage
                                name="contentType"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                          <button type="submit" className="btn btn-primary">
                            {modalOpenType == 'add' ? 'Submit' : 'Update'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default Categories
