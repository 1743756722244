import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectArtistState = (state: RootState) => state.Artist || initialState

export const artistSelector = createSelector(
  [selectArtistState],
  Artist => Artist,
)
