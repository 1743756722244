import React, { useState } from 'react'

const Search = ({ onSearch, placeholder, width, searchValue }: any) => {
  const [search, setSearch] = useState(searchValue)

  const onInputChange = (value: React.SetStateAction<string>) => {
    setSearch(value)
    onSearch(value)
  }

  const preventEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <div className="iq-search-bar ml-auto">
      <form action="#" className="searchbox">
        <input
          type="text"
          className="text search-input"
          style={{ width: `${width}px` }}
          placeholder={placeholder}
          value={search}
          onChange={e => onInputChange(e.target.value)}
          onKeyDown={preventEnterSubmit}
        />
        <a className="search-link" href="#/dashboard">
          <i className="ri-search-line"></i>
        </a>
      </form>
    </div>
  )
}

export default Search
