/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomHeader from 'components/CustomHeader'
import moment from 'moment'

import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { Link } from 'react-router-dom'
import {
  getIsRoot,
  getPermission,
  getStatus,
  getTenantId,
} from 'utils/helpers/systemHelpers'
import { getArtists } from 'store/artistManagement/slice'
import { artistSelector } from 'store/artistManagement/selector'
import { toast } from 'react-toastify'
import ApprovedAlbum from '../Approved'
import PublishedAlbum from '../Published'
import { PERMISSIONS } from 'model/auth'
import { basicSelector } from 'store/basicManagement/selector'
import { roleSelector } from 'store/roleManagement/selectors'
import { getCategories, getGenres } from 'store/basicManagement/slice'
import { getTenants } from 'store/roleManagement/slice'
import { AudioType, MediaContentStatusType } from 'utils/enum'
import Swal from 'sweetalert2'
import SelectBox from 'components/Select'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  releaseDate: Yup.date().required('Date is Required'),
  type: Yup.string().required('Album Type is Required'),
  artist: Yup.array()
    .min(1, 'Select at least 1 artist.')
    .required('Artist is Required'),
  genreId: Yup.string().required('Genre is Required'),
  categoryId: Yup.string().required('Category is required'),
})

const AlbumList = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const albumCloseRef = useRef<HTMLButtonElement>(null)
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const dispatch = useDispatch()
  // const album = useSelector(albumSelector)
  const basic = useSelector(basicSelector)
  const role = useSelector(roleSelector)
  const artist = useSelector(artistSelector)
  const [modalImage, setModalImage]: any = useState(null)
  const [selectedBtnId, setSelectedBtnId] = useState(-1)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const publishButtonRef = useRef<HTMLButtonElement>(null)
  const approveButtonRef = useRef<HTMLButtonElement>(null)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [artistListItem, setArtistListItem] = useState(null)
  const [album, setAlbum]: any = useState(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const [loading, setLoading] = useState(false)
  const [tenantID, setTenantID] = useState(getTenantId())

  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.singleAlbumByStatus(
        `${AudioType.AudioPlaylist},${AudioType.Podcast},${AudioType.MusicAlbum}`,
        MediaContentStatusType.Draft,
      ),
    )
    if (response) {
      setAlbum(response)
    }
    setPageLoading(false)
  }
  useEffect(() => {
    fetchData()
    dispatch(getArtists({}))
    dispatch(getTenants({}))
    dispatch(getCategories({}))
    dispatch(getGenres({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tenants = role.tenants
  const categories = basic.categories
  const artistList = artist.artists
  const genres = basic.genres

  useEffect(() => {
    const artistListArr: any = artistList.map((artist: any) => {
      return { value: artist._id, label: artist.name }
    })
    setArtistListItem(artistListArr)
  }, [artistList])

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Type', field: 'type', sortable: false },
    { name: 'Release Date', field: 'release_date', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const albumsData = useMemo(() => {
    const tempDataDraft: any = []
    album &&
      album.length > 0 &&
      album.map((videoPo: any) => {
        if (videoPo.status == MediaContentStatusType.Draft) {
          tempDataDraft.push(videoPo)
        }
      })
    let computedMagazines: any = tempDataDraft

    if (search) {
      computedMagazines = computedMagazines.filter(
        (magazine: { name: string; description: string }) =>
          magazine.name.includes(search) ||
          magazine.description.includes(search),
        //   magazine.rating.includes(search),
      )
    }

    setTotalItems(computedMagazines.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedMagazines.slice(firstPageIndex, lastPageIndex)
  }, [album, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    description: '',
    releaseDate: '',
    genreId: '',
    type: '',
    artist: '',
    categoryId: '',
    tenantId: tenantID,
    tags: [],
  })

  const setSelectedTenant = (value: any) => {
    setModalOpenType('edit')
    initialValues.name = value.name
    initialValues.description = value.description
    initialValues.releaseDate = moment(value.releaseDate).format('YYYY-MM-DD')
    initialValues.genreId = value.genreId
    initialValues.type = value.type
    initialValues.artist = value.artists.map((lyricist: any) => {
      return { value: lyricist._id, label: lyricist.name }
    })
    const tagData: any = []
    if (value.tags && value.tags.length > 0) {
      value.tags.forEach((tag: any) => {
        tagData.push({ label: tag, value: tag })
      })
    }
    initialValues.tags = tagData
    setModalImage(value.thumbnailImageUrl)
    setSelectedTenantId(value._id)
    initialValues.categoryId = value.categoryId
    initialValues.tenantId = value.tenantId
  }

  const clearModal = () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    initialValues.name = ''
    initialValues.description = ''
    initialValues.releaseDate = ''
    initialValues.genreId = ''
    initialValues.type = ''
    initialValues.artist = ''
    initialValues.tags = []
    initialValues.categoryId = ''
    initialValues.tenantId = tenantID
    setModalImage(null)
    setSelectedTenantId('')
    setModalOpenType('add')

    clearFieldOnClose()
    // }
  }
  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      // console.log(reader.result)
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const openPicker = () => {
    inputFileSelect.current?.click()
  }

  const handleSubmitAlbum = (
    values: {
      name: string
      description: string
      type: string
      releaseDate: string
      artist: any
      genreId: string
      tenantId: string
      categoryId: string
      tags: any
    },
    resetForm: any,
  ) => {
    //Disable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const tagsData: any = []
    if (values.tags.length > 0) {
      values.tags.forEach((tag: any) => {
        tagsData.push(tag.value)
      })
    }
    const artistArray: any = values.artist.map((art: any) => art.value)
    const albumDatas = {
      name: values.name,
      description: values.description,
      releaseDate: moment(values.releaseDate, 'YYYY-MM-DD').valueOf(),
      type: values.type,
      artists: artistArray,
      genreId: values.genreId,
      totalDuration: 0,
      thumbnailImageUrl: selectedFileDataURI,
      categoryId: values.categoryId,
      tenantId: values.tenantId,
      tags: tagsData,
      status: MediaContentStatusType.Draft,
    }
    baseAxios.post(apiEndPoints.cms.album, albumDatas).then(async () => {
      await fetchData()
      // dispatch(getAlbums({}))
      // albumData = album.albums
      //Disable button
      submitButtonRef.current?.removeAttribute('disabled')
      // Activate indicator
      setLoading(false)
      albumCloseRef.current?.click()
      resetForm()
    })
  }

  const handleUpdate = async (
    values: {
      name: string
      description: string
      type: string
      releaseDate: string
      artist: any
      genreId: string
      tenantId: string
      categoryId: string
      tags: any
    },
    albumId: string,
    resetForm: any,
  ) => {
    //Disable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const tagsData: any = []
    if (values.tags.length > 0) {
      values.tags.forEach((tag: any) => {
        tagsData.push(tag.value)
      })
    }
    const artistArray: any = values.artist.map((art: any) => art.value)
    const albumDataPut = {
      name: values.name,
      description: values.description,
      releaseDate: moment(values.releaseDate, 'YYYY-MM-DD').valueOf(),
      type: values.type,
      artists: artistArray,
      genreId: values.genreId,
      totalDuration: 0,
      thumbnailImageUrl:
        selectedFileDataURI != null ? selectedFileDataURI : modalImage,
      categoryId: values.categoryId,
      tenantId: values.tenantId,
      tags: tagsData,
      status: MediaContentStatusType.Draft,
    }

    baseAxios
      .put(apiEndPoints.cms.albumWithId(albumId), albumDataPut)
      .then(async () => {
        await fetchData()
        // dispatch(getAlbums({}))
        // albumData = album.albums
        //Disable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
        albumCloseRef.current?.click()
        resetForm()
      })
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.albumWithId(value))
            .then(async resp => {
              // dispatch(getAlbums({}))
              // albumData = album.albums
              await fetchData()
              albumCloseRef.current?.click()
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const changePublish = async (value: any, type: string) => {
    // if (!publishButtonRef.current) {
    //   return
    // }
    //Disable button
    publishButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setSelectedBtnId(value._id)
    setLoadingPublish(true)

    const data = Object.assign({}, value)
    data.status = type
    baseAxios
      .put(apiEndPoints.cms.albumWithId(value._id), data)
      .then(async (albumResp: any) => {
        await fetchData()
        //Enable button
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
        // dispatch(getAlbums({}))
        toast.success('Status updated successfully! ')
      })
      .catch((err: any) => {
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
      })
  }

  const changeStatus = async (value: any, type: string) => {
    // if (!approveButtonRef.current) {
    //   return
    // }
    //Disable button
    approveButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setSelectedBtnId(value._id)
    setLoadingApprove(true)
    const data = Object.assign({}, value)
    data.status = type
    baseAxios
      .put(apiEndPoints.cms.albumWithId(value._id), data)
      .then(async (singleResp: any) => {
        await fetchData()
        //Enable button
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
        // dispatch(getAlbums({}))
        toast.success('Status updated successfully! ')
      })
      .catch((err: any) => {
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
      })
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    setSelectedFile(null)
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <ul className="nav nav-tabs" id="myTab-two" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab-two"
                data-toggle="tab"
                href="#role-two"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                In Review
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-three"
                data-toggle="tab"
                href="#permission-three"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-two"
                data-toggle="tab"
                href="#permission-two"
                role="tab"
                aria-controls="permission"
                aria-selected="false"
              >
                Published
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="role-two"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Multi Audio List</h4>
                      </div>
                      {getPermission(PERMISSIONS.CREATE_ALBUM) && (
                        <a
                          data-toggle="modal"
                          data-target="#AddAlbumModel"
                          className="btn btn-primary m-1"
                          onClick={() => clearModal()}
                        >
                          Add Audio
                        </a>
                      )}
                    </div>
                    <div className="iq-card-body">
                      <div className="table-view">
                        <TableLength
                          handleLengthChange={(v: any) =>
                            setPageSize(parseInt(v))
                          }
                          handleSearchValue={(searchVal: any) =>
                            setSearch(searchVal)
                          }
                        />
                        {pageLoading && (
                          <div
                            className="container d-flex justify-content-center align-items-center"
                            style={{ height: '200px' }}
                          >
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          </div>
                        )}
                        {!pageLoading && album && album.length > 0 && (
                          <table
                            className="data-tables table movie_table"
                            style={{ width: '100%' }}
                          >
                            <TableHeader
                              headers={headers}
                              onSorting={(field: any, order: any) =>
                                setSorting({ field, order })
                              }
                            />
                            <tbody>
                              {albumsData.map((album: any, index: number) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <img
                                      src={album.thumbnailImageUrl}
                                      width={70}
                                      height={100}
                                    />
                                  </td>
                                  <td>{album.name}</td>
                                  <td>
                                    {album.type.charAt(0).toUpperCase() +
                                      album.type.slice(1)}
                                  </td>
                                  <td>
                                    {moment(album.releaseDate).format(
                                      'YYYY-MM-DD',
                                    )}
                                  </td>
                                  <td>
                                    {getPermission(PERMISSIONS.APPROVE_ALBUM) ==
                                    false ? (
                                      getStatus(album.status)
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-primary "
                                        ref={approveButtonRef}
                                        onClick={() => {
                                          setSelectedTenant(album)
                                          changeStatus(
                                            album,
                                            MediaContentStatusType.Approved,
                                          )
                                        }}
                                      >
                                        {/* {loadingApprove == false &&
                                          selectedBtnId ==
                                            videoContent._id(
                                              <span className="indicator-label">
                                                Approve
                                              </span>, */}
                                        {/* )} */}
                                        {loadingApprove == true &&
                                        selectedBtnId == album._id ? (
                                          <span className="indicator-progress">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                          </span>
                                        ) : (
                                          <span className="indicator-label">
                                            Approve
                                          </span>
                                        )}
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      {getPermission(
                                        PERMISSIONS.VIEW_ALBUM,
                                      ) && (
                                        <Link
                                          className="iq-bg-warning"
                                          data-placement="top"
                                          to="/audio/view"
                                          state={{ data: album }}
                                        >
                                          <i className="ri-eye-line" />
                                        </Link>
                                      )}

                                      {getPermission(
                                        PERMISSIONS.UPDATE_ALBUM,
                                      ) && (
                                        <a
                                          className="iq-bg-success"
                                          data-placement="top"
                                          title=""
                                          data-toggle="modal"
                                          data-target="#AddAlbumModel"
                                          data-original-title="Edit"
                                          onClick={() =>
                                            setSelectedTenant(album)
                                          }
                                        >
                                          <i className="ri-pencil-line" />
                                        </a>
                                      )}
                                      {getPermission(
                                        PERMISSIONS.DELETE_ALBUM,
                                      ) && (
                                        <a
                                          className="iq-bg-primary"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title=""
                                          data-original-title="Delete"
                                          onClick={() =>
                                            handleDelete(album._id)
                                          }
                                        >
                                          <i className="ri-delete-bin-line" />
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                        {!pageLoading && album && album.length == 0 && (
                          <NoData />
                        )}
                        <Pagination
                          currentPage={currentPage}
                          totalCount={totalItems}
                          pageSize={pageSize}
                          onPageChange={(page: React.SetStateAction<number>) =>
                            setCurrentPage(page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-three"
              id="permission-three"
            >
              <ApprovedAlbum
                data={album}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => {
                  setSelectedTenant(value)
                  setModalOpenType('edit')
                }}
                handlePublish={(value: any) =>
                  changePublish(value, MediaContentStatusType.Publish)
                }
                publishButtonRef={publishButtonRef}
                selectedBtnId={selectedBtnId}
                loading={loadingPublish}
                // handleChange={(value: any) => changeStatus(value, MediaContentStatusType.Publish)}
              />
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="permission-two"
            >
              <PublishedAlbum
                data={album}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => {
                  setSelectedTenant(value)
                  setModalOpenType('edit')
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddAlbumModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                type: string
                releaseDate: string
                artist: string
                genreId: string
                tenantId: string
                categoryId: string
                tags: any
              },
              { resetForm }: any,
            ) => {
              modalOpenType == 'edit'
                ? handleUpdate(values, selectedTenantId, resetForm)
                : handleSubmitAlbum(values, resetForm)
            }}
          >
            {(formik: any) => {
              const { errors, touched } = formik
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'edit'
                        ? 'Edit Multi Audio'
                        : 'Add Multi Audio'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      ref={albumCloseRef}
                      aria-label="Close"
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row-md-12">
                        {' '}
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            name="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            as="textarea"
                            id="text"
                            name="description"
                            rows={5}
                            className="form-control"
                            placeholder="Description"
                            defaultValue={''}
                          />
                          <ErrorMessage
                            name="description"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                        {modalOpenType == 'edit' && (
                          <div className="form-group col-md-12">
                            <img src={modalImage} width={100} height={150} />
                          </div>
                        )}
                        <div className="form_gallery form-group">
                          <label
                            onClick={() => openPicker()}
                            htmlFor="form_gallery-upload"
                          >
                            {selectedFile != null
                              ? selectedFile.name
                              : modalOpenType == 'add'
                              ? 'Upload Thumbnail'
                              : 'Upload New Thumbnail'}
                          </label>
                          <input
                            type="file"
                            ref={inputFileSelect}
                            name="thumbnail"
                            onChange={e => handleFileSelect(e)}
                            accept=".png, .jpg, .jpeg, .webp"
                          />
                          <ErrorMessage
                            name="thumbnail"
                            component="span"
                            className="text-primary"
                          />
                        </div>{' '}
                        <div className="row">
                          <div className="col-md-6 form-group">
                            <label htmlFor="form_gallery-upload">
                              Release Date
                            </label>
                            <Field
                              type="date"
                              className="form-control"
                              data-placeholder="Release date"
                              name="releaseDate"
                            />
                            <ErrorMessage
                              name="releaseDate"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <label htmlFor="form_gallery-upload">
                              Album Type
                            </label>
                            <Field
                              as="select"
                              name="type"
                              className="form-control"
                            >
                              <option value="">Select Value</option>
                              <option value="music_album">Music Album</option>
                              <option value="podcast">Podcast</option>
                              {/* <option value="single_audio">Single Audio</option> */}
                              <option value="audio_playlist">
                                Audio Playlist
                              </option>
                            </Field>
                            <ErrorMessage
                              name="type"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="col-md-12 form-group">
                            <Field
                              as="select"
                              name="genreId"
                              className="form-control"
                            >
                              <option value="">Select Genre</option>
                              {genres.map((genre: any) => {
                                return (
                                  <option value={genre._id} key={genre._id}>
                                    {genre.name}
                                  </option>
                                )
                              })}
                            </Field>
                            <ErrorMessage
                              name="genreId"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="col-md-12 form-group">
                            <Field
                              name="tags"
                              id="tags"
                              placeholder="Tags"
                              isMulti={true}
                              component={SelectBox}
                              // options={lyricistList}
                            />
                            <ErrorMessage
                              name="tags"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="col-md-12 form-group">
                            <Field
                              name="artist"
                              id="artist"
                              placeholder="Select Artist"
                              isMulti={true}
                              component={SelectBox}
                              options={artistListItem}
                            />
                            {/* <Field
                              as="select"
                              className="form-control"
                              name="artist"
                              multiple={true}
                            >
                              {artistList.length > 0 &&
                                artistList.map((artistData: any) => {
                                  return (
                                    <option
                                      value={artistData._id}
                                      key={artistData._id}
                                    >
                                      {artistData.name}
                                    </option>
                                  )
                                })}
                            </Field> */}
                            <ErrorMessage
                              name="artist"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {getIsRoot() && (
                            <div className="col-md-6 form-group">
                              <Field
                                as="select"
                                name="tenantId"
                                className="form-control"
                              >
                                <option value="">Select Tenant</option>
                                {tenants.length > 0 &&
                                  tenants.map((tenant: any) => {
                                    return (
                                      <option
                                        value={tenant._id}
                                        key={tenant._id}
                                      >
                                        {tenant.name}
                                      </option>
                                    )
                                  })}
                              </Field>
                              <ErrorMessage
                                name="tenantId"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          )}
                          <div
                            className={
                              getIsRoot()
                                ? 'col-md-6 form-group'
                                : 'col-md-12 form-group'
                            }
                          >
                            <Field
                              as="select"
                              name="categoryId"
                              className="form-control"
                            >
                              <option value="">Select Category</option>
                              {categories.length > 0 &&
                                categories.map((category: any) => {
                                  return (
                                    <option
                                      value={category._id}
                                      key={category._id}
                                    >
                                      {category.name}
                                    </option>
                                  )
                                })}
                            </Field>
                            <ErrorMessage
                              name="categoryId"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            ref={submitButtonRef}
                          >
                            {!loading && (
                              <span className="indicator-label">
                                {modalOpenType == 'edit' ? 'Update' : 'Submit'}
                              </span>
                            )}
                            {loading && (
                              <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default AlbumList
