import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
// import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import {
  actions,
  getBannersByCategoryId,
  createBannerForCategory,
  updateBannerForCategory,
  deleteBannerForCategory,
  getBannerInfoForCategory,
  changeBannerPublishStatus,
  manageActiveCategory,
  manageSelectedBanner,
  manageModalOpenType,
  manageBannerErrorMessage,
  manageBannerLoading,
  manageActiveTab,
} from './slice'
import storeInstance from 'store/configureStore'

function* getAllBannersByCategoryId({ payload }: any): any {
  try {
    yield put(actions.setBannerLoader(true))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.banner.getBannersByCategoryId,
    )
    if (
      payload?.type ||
      storeInstance.store.getState().Banner.activeCategory?.value ===
        'no_select'
    ) {
      yield put(actions.setAllBanners(response))
    } else {
      yield put(actions.setBanners(response))
    }
    yield put(actions.setBannerLoader(false))
  } catch (err) {
    console.log(err)
    yield put(actions.setBannerLoader(false))
  }
}

function* createBanner({ payload }: any): any {
  try {
    yield put(actions.setBannerLoader(true))

    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    const response = yield call(
      baseAxios.post,
      apiEndPoints.banner.createBanner(payload.webCategoryId),
      payload,
    )
    yield getBannerInfo({
      payload: { bannerId: response._id, type: 'create_banner' },
    })
    yield getAllBannersByCategoryId({})
    buttonRef?.current?.click()
    yield put(actions.setBannerLoader(false))
  } catch (err) {
    yield put(actions.setBannerLoader(false))
    console.log(err)
  }
}

function* getBannerInfo({ payload }: any): any {
  try {
    yield put(actions.setBannerLoader(true))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.banner.bannerInfo(payload?.bannerId),
    )
    yield put(actions.setBannerLoader(false))
    if (payload.type === 'create_banner') {
      yield put(actions.setCreatedBannersToList(response))
    }
  } catch (err) {
    yield put(actions.setBannerLoader(false))
    console.log(err)
  }
}

function* updateBanner({ payload }: any): any {
  try {
    yield put(actions.setBannerLoader(true))
    const bannerId = payload.bannerId
    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    delete payload.bannerId
    const response = yield call(
      baseAxios.put,
      apiEndPoints.banner.updateWebBanner(bannerId),
      payload,
    )
    yield put(actions.setBannerLoader(false))
    yield put(actions.setUpdateBanner(response))
    yield getAllBannersByCategoryId({})
    buttonRef?.current?.click()
  } catch (err) {
    yield put(actions.setBannerLoader(false))
    console.log(err)
  }
}

function* deleteBanner({ payload }: any): any {
  try {
    yield put(actions.setBannerLoader(true))
    yield call(baseAxios.delete, apiEndPoints.banner.deleteWebBanner(payload))
    yield put(actions.setBannerLoader(false))
    yield put(actions.setBannerDeletion(payload))
  } catch (err) {
    yield put(actions.setBannerLoader(false))
    console.log(err)
  }
}

function* changeBannerStatus({ payload }: any): any {
  try {
    yield put(
      actions.setBannerRowLoader({
        loading: true,
        bannerId: payload?.bannerId,
      }),
    )
    yield call(
      baseAxios.put,
      apiEndPoints.banner.updateWorkFlowStatus(payload?.bannerId),
      payload,
    )
    yield put(actions.setBannerLoader(false))
    yield getAllBannersByCategoryId({})
  } catch (err) {
    yield put(actions.setBannerLoader(false))
    console.log(err)
  }
}

function* setActiveCategory({ payload }: any): any {
  try {
    yield put(actions.setActiveCategory(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setSelectedBannerInfo({ payload }: any): any {
  try {
    yield put(actions.setSelectedBannerInfo(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setModalOpenType({ payload }: any): any {
  try {
    yield put(actions.setModalOpenType(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setBannerErrorMessages({ payload }: any): any {
  try {
    yield put(actions.setBannerErrorMessage(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setBannerLoading({ payload }: any): any {
  try {
    yield put(actions.setBannerLoader(payload))
  } catch (err) {
    console.log(err)
  }
}

function* setActiveTab({ payload }: any): any {
  try {
    yield put(actions.setActiveTab(payload))
  } catch (err) {
    console.log(err)
  }
}

export function* bannerSaga(): any {
  yield all([
    yield takeLatest(getBannersByCategoryId, getAllBannersByCategoryId),
    yield takeLatest(createBannerForCategory, createBanner),
    yield takeLatest(getBannerInfoForCategory, getBannerInfo),
    yield takeLatest(updateBannerForCategory, updateBanner),
    yield takeLatest(deleteBannerForCategory, deleteBanner),
    yield takeLatest(changeBannerPublishStatus, changeBannerStatus),
    yield takeLatest(manageSelectedBanner, setSelectedBannerInfo),
    yield takeLatest(manageActiveCategory, setActiveCategory),
    yield takeLatest(manageModalOpenType, setModalOpenType),
    yield takeLatest(manageBannerErrorMessage, setBannerErrorMessages),
    yield takeLatest(manageBannerLoading, setBannerLoading),
    yield takeLatest(manageActiveTab, setActiveTab),
  ])
}
