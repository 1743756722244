/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableHeader, Pagination } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getPermission, getStatus } from 'utils/helpers/systemHelpers'
import React, { useEffect, useMemo, useState } from 'react'
import { PERMISSIONS } from 'model/auth'
import { MediaContentStatusType } from 'utils/enum'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import NoData from 'components/NoData/nodata'
import { ThreeDots } from 'react-loader-spinner'

const ApprovedAlbum = (props: any) => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [selectedBtnId, setSelectedBtnId] = useState(-1)
  const [album, setAlbum]: any = useState(null)
  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Type', field: 'type', sortable: false },
    { name: 'Release Date', field: 'release_date', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]
  const albumApprovedData = props.data
  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.singleAlbumByStatus(
        'single_audio',
        MediaContentStatusType.Approved,
      ),
    )
    if (response) {
      setAlbum(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [albumApprovedData])

  const albumsData = useMemo(() => {
    const tempData: any = []
    album &&
      album.length > 0 &&
      album.map((videoP: any) => {
        tempData.push(videoP)
      })

    let computedAlbums: any = tempData

    if (search) {
      computedAlbums = computedAlbums.filter(
        (magazine: {
          name: string
          description: string
          //   rating: string
        }) =>
          magazine.name.toLowerCase().includes(search.toLowerCase()) ||
          magazine.description.toLowerCase().includes(search.toLowerCase()),
        //   magazine.rating.includes(search),
      )
    }

    setTotalItems(computedAlbums.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedAlbums.slice(firstPageIndex, lastPageIndex)
  }, [album, search, currentPage, pageSize])

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h4 className="card-title">Approved Single Audio List</h4>
            </div>
            {/* {getPermission('create_magazine') && (
                    
                  )} */}
            {/* <a
              data-toggle="modal"
              data-target="#AddAlbumModel"
              className="btn btn-primary m-1"
            >
              Add Album
            </a> */}
          </div>
          <div className="iq-card-body">
            <div className="table-view">
              <TableLength
                handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                handleSearchValue={(searchVal: any) => setSearch(searchVal)}
              />
              {pageLoading && (
                <div
                  className="container d-flex justify-content-center align-items-center"
                  style={{ height: '200px' }}
                >
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#e20e02"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
              {!pageLoading && album && album.length > 0 && (
                <table
                  className="data-tables table movie_table"
                  style={{ width: '100%' }}
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) =>
                      setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {albumsData.map((album: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            src={album.thumbnailImageUrl}
                            width={70}
                            height={100}
                          />
                        </td>
                        <td>{album.name}</td>
                        <td>
                          {album.type.charAt(0).toUpperCase() +
                            album.type.slice(1)}
                        </td>
                        <td>
                          {moment(album.releaseDate).format('YYYY-MM-DD')}
                        </td>
                        <td>
                          {getPermission(PERMISSIONS.PUBLISH_ALBUM) ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              ref={props.publishButtonRef}
                              onClick={() => props.handlePublish(album)}
                            >
                              {props.loading == true &&
                              props.selectedBtnId == album._id ? (
                                <span className="indicator-progress">
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                </span>
                              ) : (
                                <span className="indicator-label">Publish</span>
                              )}
                            </button>
                          ) : (
                            getStatus(album.status)
                          )}
                        </td>
                        <td>
                          <div className="flex align-items-center list-user-action">
                            {getPermission(PERMISSIONS.VIEW_ALBUM) && (
                              <Link
                                className="iq-bg-warning"
                                data-placement="top"
                                to="/audio/view"
                                state={{ data: album }}
                                // title=""
                                // data-toggle="modal"
                                // data-target="#VideoPlayer"
                                // data-original-title="Edit"
                              >
                                <i className="ri-eye-line" />
                              </Link>
                            )}

                            {getPermission(PERMISSIONS.UPDATE_ALBUM) && (
                              <a
                                className="iq-bg-success"
                                data-placement="top"
                                title=""
                                data-toggle="modal"
                                data-target="#AddAlbumModel"
                                data-original-title="Edit"
                                onClick={() => props.handleUpdatePress(album)}
                              >
                                <i className="ri-pencil-line" />
                              </a>
                            )}
                            {getPermission(PERMISSIONS.DELETE_ALBUM) && (
                              <a
                                className="iq-bg-primary"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Delete"
                                onClick={() =>
                                  props.handleDeletePress(album._id)
                                }
                              >
                                <i className="ri-delete-bin-line" />
                              </a>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!pageLoading && album && album.length == 0 && <NoData />}
              <Pagination
                currentPage={currentPage}
                totalCount={totalItems}
                pageSize={pageSize}
                onPageChange={(page: React.SetStateAction<number>) =>
                  setCurrentPage(page)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApprovedAlbum
