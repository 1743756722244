import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createMultiVideos,
  deleteMultiVideos,
  getMultiVideos,
  updateMultiVideos,
  getSeasons,
  getAllMultiVideosByStatus,
} from './slice'

function* getAllMultiVideos(): any {
  try {
    const response = yield call(baseAxios.post, apiEndPoints.cms.multiVideoList)
    yield put(actions.setMultiVideos(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllMultiVideosByStatuses({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.multiVideoByStatus(payload.status),
    )
    yield put(actions.setOptionsFormat(response))
  } catch (err) {
    console.log(err)
  }
}

function* deleteMultiVideo({
  payload,
}: ReturnType<typeof deleteMultiVideos>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.cms.multiVideoWithId(payload.multiVideoId),
    )
    toast.success(message.SINGLE_VIDEO_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateMultiVideo({
  payload,
}: ReturnType<typeof updateMultiVideos>): any {
  try {
    yield call(
      baseAxios.put,
      apiEndPoints.cms.multiVideoWithId(payload.multiVideoId),
    )
  } catch (err) {
    console.log(err)
  }
}

function* createMultiVideo(): any {
  try {
    yield call(baseAxios.post, apiEndPoints.cms.singleVideo)
  } catch (err) {
    console.log(err)
  }
}

function* getAllSeasons({
  payload,
}: ReturnType<typeof deleteMultiVideos>): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.seasons(payload.workSpaceId, payload.webSeriesId),
    )
    yield put(actions.setSeasons(response))
  } catch (err) {
    console.log(err)
  }
}

export function* multiVideoSaga(): any {
  yield all([yield takeLatest(getMultiVideos, getAllMultiVideos)])
  yield all([yield takeLatest(deleteMultiVideos, deleteMultiVideo)])
  yield all([yield takeLatest(updateMultiVideos, updateMultiVideo)])
  yield all([yield takeLatest(createMultiVideos, createMultiVideo)])
  yield all([yield takeLatest(getSeasons, getAllSeasons)])
  yield all([
    yield takeLatest(getAllMultiVideosByStatus, getAllMultiVideosByStatuses),
  ])
}
