import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { actions, getCategories, getGenres, setUploadStatus } from './slice'

function* getAllCategories(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.cms.categories)
    yield put(actions.setCategories(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllGenres(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.cms.genres)
    yield put(actions.setGenres(response))
  } catch (err) {
    console.log(err)
  }
}

function* createUploadStatus({
  payload,
}: ReturnType<typeof setUploadStatus>): any {
  try {
    const status = {
      id: payload.id,
      status: payload.status,
    }
    yield put(actions.setUploadStatusArray(status))
  } catch (err) {
    console.log(err)
  }
}

export function* basicSaga(): any {
  yield all([yield takeLatest(getCategories, getAllCategories)])
  yield all([yield takeLatest(getGenres, getAllGenres)])
  yield all([yield takeLatest(setUploadStatus, createUploadStatus)])
}
