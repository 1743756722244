import { PayloadAction, createAction } from '@reduxjs/toolkit'
import { createSlice } from 'utils/@reduxjs/toolkit'
import { AdminRole } from './types'

// eslint-disable-next-line
const getRoles = createAction<any>('users/getRoles')
const createRole = createAction<any>('users/createRole')
const updateRole = createAction<any>('users/updateRole')
const deleteRole = createAction<any>('users/deleteRole')
const getPermissions = createAction<any>('users/getPermissions')
const createPermission = createAction<any>('users/createPermission')
const updatePermission = createAction<any>('users/updatePermission')
const deletePermission = createAction<any>('users/deletePermission')
const getTenants = createAction<any>('users/getTenants')
const createTenant = createAction<any>('users/createTenant')
const updateTenant = createAction<any>('users/updateTenant')
const deleteTenant = createAction<any>('users/deleteTenant')

export const initialState: AdminRole = {
  roles: [],
  permissions: [],
  tenants: [],
}

const AdminRoleSlice = createSlice({
  name: 'AdminRole',
  initialState: initialState,
  reducers: {
    setRoles(state, action: PayloadAction<any>) {
      state.roles = action.payload
    },
    setPermissions(state, action: PayloadAction<any>) {
      state.permissions = action.payload
    },
    setTenants(state, action: PayloadAction<any>) {
      state.tenants = action.payload
    },
  },
})

export {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getPermissions,
  createPermission,
  updatePermission,
  deletePermission,
  getTenants,
  createTenant,
  updateTenant,
  deleteTenant,
}

export const { actions, reducer, name: sliceKey } = AdminRoleSlice
