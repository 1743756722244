import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Category } from './types'

const getCategories = createAction<any>('cms/getCategories')

export const initialState: Category = {
  webCategories: [],
}

const CategorySlice = createSlice({
  name: 'Category',
  initialState: initialState,
  reducers: {
    setCategories(state, action: PayloadAction<any>) {
      state.webCategories = action.payload
    },
  },
})

export { getCategories }

export const { actions, reducer, name: sliceKey } = CategorySlice
