import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectRoleState = (state: RootState) => state.AdminRole || initialState

export const roleSelector = createSelector(
  [selectRoleState],
  AdminRole => AdminRole,
)
