// @flow
import jwtDecode from 'jwt-decode'

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser()
  if (!user) {
    return false
  }
  const decoded: any = jwtDecode(user.jwt)
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    console.warn('access token expired')
    return false
  } else {
    return true
  }
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  // const cookies = new Cookies()
  // const user = cookies.get('user')
  const user = JSON.parse(localStorage.getItem('user')!)
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null
}

/**
 * Returns the logged in user
 */
const logOutUser = () => {
  // const cookies = new Cookies()
  // cookies.remove('user')
  localStorage.removeItem('user')
  window.location.href = '/'
}

export { isUserAuthenticated, getLoggedInUser, logOutUser }
