/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableLength from 'components/DataTable/TableLength'
import InfiniteScroll from 'react-infinite-scroll-component'
import CustomHeader from 'components/CustomHeader'
import { TableHeader } from 'components/DataTable'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'
import { debounce, isEmpty } from 'lodash'
import {
  actions,
  beginAudioTranscoding,
  getTranscodingAudios,
  searchAudios,
  stopAudioTranscoding,
} from 'store/audioTranscodingManagement/slice'
import { transcodingSelector } from 'store/audioTranscodingManagement/selector'
import { TranscoderJobStatusType } from 'utils/enum'

const DEFAULT_LIMIT = 10

const AudioTranscoding = () => {
  const dispatch = useDispatch()

  const [skip, setSkip] = useState(0)
  const [search, setSearch] = useState('')

  const { transcodedAudios, audioTranscodingLoading, audiosSearchData } =
    useSelector(transcodingSelector)

  const debouncedSetSearch = debounce((searchValue: string) => {
    setSearch(searchValue)
  }, 500)

  useEffect(() => {
    if (search == '') {
      dispatch(actions.setTranscodingAudioSearch([]))
    } else {
      dispatch(
        searchAudios({
          searchValue: search,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search])

  useEffect(() => {
    dispatch(
      getTranscodingAudios({
        skip,
        limit: DEFAULT_LIMIT,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const headers = audiosSearchData.length
    ? [
        { name: 'No.', field: 'number', sortable: false },
        { name: 'Image', field: 'profile', sortable: false },
        { name: 'Name', field: 'name', sortable: false },
        { name: 'Action', field: 'action', sortable: false },
      ]
    : [
        { name: 'No.', field: 'number', sortable: false },
        { name: 'Image', field: 'profile', sortable: false },
        { name: 'Name', field: 'name', sortable: false },
        { name: 'Status', field: 'name', sortable: false },
        { name: 'Action', field: 'action', sortable: false },
      ]

  const handleStopTranscoding = (value: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to stop the job?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, stop it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        dispatch(stopAudioTranscoding({ jobId: value }))
      }
    })
  }

  const handleStartTranscoding = (audioId: any) => {
    dispatch(beginAudioTranscoding({ audioId }))
  }

  const transcodedAudiosToMap = !isEmpty(search)
    ? audiosSearchData
    : transcodedAudios

  const isYouTubeURL = (url: any) => {
    // Regular expression to match YouTube audio URLs
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=[\w-]+(&\S*)?$/

    return youtubeRegex.test(url)
  }

  const formatTranscoderJobStatus = (status: TranscoderJobStatusType) => {
    switch (status) {
      case TranscoderJobStatusType.NotStarted:
        return 'Not Started'
      case TranscoderJobStatusType.InProgress:
        return 'In Progress'
      case TranscoderJobStatusType.Canceled:
        return 'Canceled'
      case TranscoderJobStatusType.Failed:
        return 'Failed'
      case TranscoderJobStatusType.Completed:
        return 'Completed'
      default:
        return '' // Handle unknown values gracefully
    }
  }

  return (
    <CustomHeader pageName={'Audio Transcoding List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Transcoded Audios List</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => {
                        return null
                      }}
                      handleSearchValue={(searchVal: any) =>
                        debouncedSetSearch(searchVal)
                      }
                      disableSelectBox={true}
                      placeholder={'Search Audios for Transcoding...'}
                    />
                    {audioTranscodingLoading &&
                      !transcodedAudiosToMap.length && (
                        <div
                          className="container d-flex justify-content-center align-items-center"
                          style={{ height: '200px' }}
                        >
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#e20e02"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                          />
                        </div>
                      )}
                    {!audioTranscodingLoading &&
                      transcodedAudiosToMap &&
                      transcodedAudiosToMap?.length > 0 && (
                        <InfiniteScroll
                          dataLength={transcodedAudiosToMap?.length}
                          next={() => {
                            setSkip(skip + 10)
                            dispatch(
                              getTranscodingAudios({
                                skip: skip + 10,
                                limit: DEFAULT_LIMIT,
                              }),
                            )
                          }}
                          hasMore={isEmpty(search)} // Replace with a condition based on your data source
                          loader={<></>}
                          // endMessage={<p>No more data to load.</p>}
                          scrollThreshold={1}
                        >
                          <table
                            className="data-tables table movie_table"
                            style={{ width: '100%' }}
                          >
                            <TableHeader headers={headers} />
                            <tbody>
                              {transcodedAudiosToMap.map(
                                (transcodedAudio: any, index: number) => (
                                  <tr key={`artist-${index}`}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <img
                                        src={
                                          transcodedAudio?.audioTrackInfo
                                            ?.imageUrl
                                        }
                                        width={50}
                                        height={50}
                                      />
                                    </td>
                                    <td>
                                      {transcodedAudio?.audioTrackInfo?.name}
                                    </td>
                                    {!audiosSearchData.length && (
                                      <td>
                                        {formatTranscoderJobStatus(
                                          transcodedAudio?.transcodingStatus,
                                        )}
                                      </td>
                                    )}

                                    <td>
                                      {audiosSearchData.length ? (
                                        isYouTubeURL(
                                          transcodedAudio?.audioUrl,
                                        ) ? (
                                          <p>Cannot Transcode</p>
                                        ) : !isYouTubeURL(
                                            transcodedAudio?.audioUrl,
                                          ) &&
                                          !(
                                            transcodedAudio?.transcodingStatus ===
                                              TranscoderJobStatusType?.InProgress ||
                                            transcodedAudio?.transcodingStatus ===
                                              TranscoderJobStatusType?.Completed
                                          ) ? (
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                              handleStartTranscoding(
                                                transcodedAudio?._id,
                                              )
                                            }
                                          >
                                            {transcodedAudio?.loading ? (
                                              <span className="indicator-progress">
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                              </span>
                                            ) : (
                                              <span className="indicator-label">
                                                Start
                                              </span>
                                            )}
                                          </button>
                                        ) : transcodedAudio?.transcodingStatus ===
                                          TranscoderJobStatusType?.InProgress ? (
                                          <p>Transcoding InProgress</p>
                                        ) : transcodedAudio?.transcodingStatus ===
                                          TranscoderJobStatusType?.NotStarted ? (
                                          <div className="flex align-items-center list-user-action">
                                            <a
                                              className="iq-bg-primary"
                                              data-toggle="tooltip"
                                              data-original-title="Stop"
                                              onClick={() =>
                                                handleStartTranscoding(
                                                  transcodedAudio
                                                    ?.audioTrackInfo?.audioId,
                                                )
                                              }
                                            >
                                              <i className="ri-play-line" />
                                            </a>
                                          </div>
                                        ) : (
                                          <p>Completed</p>
                                        )
                                      ) : (
                                        <>
                                          {transcodedAudio?.transcodingStatus ===
                                          TranscoderJobStatusType?.InProgress ? (
                                            <div className="flex align-items-center list-user-action">
                                              <a
                                                className="iq-bg-primary"
                                                data-toggle="tooltip"
                                                data-original-title="Stop"
                                                onClick={() =>
                                                  handleStopTranscoding(
                                                    transcodedAudio
                                                      ?.audioTrackInfo
                                                      ?.lastTranscoderJobId,
                                                  )
                                                }
                                              >
                                                <i className="ri-close-circle-line" />
                                              </a>
                                            </div>
                                          ) : transcodedAudio?.transcodingStatus ===
                                              TranscoderJobStatusType?.NotStarted ||
                                            transcodedAudio?.transcodingStatus ===
                                              TranscoderJobStatusType?.Canceled ? (
                                            <div className="flex align-items-center list-user-action">
                                              <a
                                                className="iq-bg-primary"
                                                data-toggle="tooltip"
                                                data-original-title="Stop"
                                                onClick={() =>
                                                  handleStartTranscoding(
                                                    transcodedAudio?._id,
                                                  )
                                                }
                                              >
                                                <i className="ri-play-line" />
                                              </a>
                                            </div>
                                          ) : (
                                            <p>--</p>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      )}
                    {!audioTranscodingLoading &&
                      transcodedAudiosToMap?.length == 0 && <NoData />}
                    {!audioTranscodingLoading &&
                      !transcodedAudiosToMap?.length &&
                      !isEmpty(search) && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <p>{`No Audios found with the title ${search}`}</p>
                        </div>
                      )}
                    {/* <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default AudioTranscoding
