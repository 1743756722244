import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createJobs,
  deleteJobs,
  getJobs,
  updateJobs,
  searchJobs,
  getJobInfo,
  getJobApplicants,
} from './slice'

function* getAllJobs({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.career.getJobs(payload?.skip, payload?.limit),
    )
    yield put(actions.setJobs({ data: response, skip: payload?.skip }))
  } catch (err) {
    console.log(err)
  }
}

function* deleteJob({ payload }: ReturnType<typeof deleteJobs>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.career.jobsWithId(payload))
    yield put(actions.setDeleteJob(payload))
    toast.success(message.JOB_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* getJobDetails({ payload }: any): any {
  try {
    yield put(actions.setJobLoader(true))

    const response = yield call(
      baseAxios.get,
      apiEndPoints.career.jobsWithId(payload),
    )
    yield put(actions.setSelectedJobInfo(response))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(actions.setJobLoader(false))
  }
}

function* updateJob({ payload }: any): any {
  const buttonRef: any = payload?.buttonRef
  try {
    delete payload.buttonRef
    yield put(actions.setJobLoader(true))
    const response = yield call(
      baseAxios.put,
      apiEndPoints.career.jobsWithId(payload.jobId),
      payload,
    )
    yield put(actions.setUpdateJob(response))
    buttonRef?.current?.click()
    yield put(actions.setJobLoader(false))
  } catch (err) {
    yield put(actions.setJobLoader(false))
    console.log(err)
  }
}

function* createJob({ payload }: any): any {
  try {
    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    yield put(actions.setJobLoader(true))
    const response = yield call(
      baseAxios.post,
      apiEndPoints.career.createJobs,
      payload,
    )
    buttonRef?.current?.click()
    yield put(actions.setCreateJob(response))
    yield put(actions.setJobLoader(false))
  } catch (err) {
    yield put(actions.setJobLoader(false))
    console.log(err)
  }
}

function* searchAllJobs({ payload }: any): any {
  try {
    yield put(actions.setJobLoader(true))

    const response = yield call(
      baseAxios.get,
      apiEndPoints.career.searchJobs(payload?.searchValue),
    )
    yield put(actions.setJobSearch(response))
    yield put(actions.setJobLoader(false))
  } catch (err) {
    yield put(actions.setJobLoader(false))
    console.log(err)
  }
}

function* getAllJobApplicants({ payload }: any): any {
  try {
    yield put(actions.setJobLoader(true))

    const response = yield call(
      baseAxios.post,
      apiEndPoints.career.getJobApplicants(
        payload?.jobId,
        payload?.skip,
        payload?.limit,
      ),
    )
    yield put(actions.setJobApplicants(response))
    yield put(actions.setJobLoader(false))
  } catch (err) {
    yield put(actions.setJobLoader(false))
    console.log(err)
  }
}

export function* careerSaga(): any {
  yield all([yield takeLatest(getJobs, getAllJobs)])
  yield all([yield takeLatest(deleteJobs, deleteJob)])
  yield all([yield takeLatest(updateJobs, updateJob)])
  yield all([yield takeLatest(createJobs, createJob)])
  yield all([yield takeLatest(searchJobs, searchAllJobs)])
  yield all([yield takeLatest(getJobInfo, getJobDetails)])
  yield all([yield takeLatest(getJobApplicants, getAllJobApplicants)])
}
