import React, { useState, useRef, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  manageRadioShowErrorMessage,
  manageSelectedRadioShow,
} from 'store/radioManagement/slice'
import { hostSelector } from 'store/hostManagement/selector'
import { DatePicker } from 'antd'
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'
import ButtonGroup from 'components/ButtonGroup'
import SelectBox from 'components/Select'
import dayjs from 'dayjs'
import { radioSelector } from 'store/radioManagement/selector'
import moment from 'moment-timezone'
import { RadioHostType } from 'utils/enum'

const { RangePicker }: any = DatePicker

const AddRadioShow = (props: any) => {
  const { onSubmit } = props

  const radioShowValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name of the show is required'),
    description: Yup.string().required('Description of the show is required'),
    hosts: Yup.array()
      .min(1, 'Select at least 1 host.')
      .required('Host is Required'),
    meetingUrl: Yup.string().required('Meeting url of the show is required'),
    userRegistration: Yup.boolean(),
    occurencType: Yup.string().required('Please select occurence of the show'),
  })

  const radioShowCloseRef = useRef<HTMLButtonElement>(null)
  const [weekDays, setWeekDays] = useState<any>([])
  const [bannerFile, setBannerFile] = useState<any>(null)
  const [thumbnailFile, setThumbnailFile] = useState<any>(null)
  const [iconFile, setIconFile] = useState<any>(null)
  const [bannerFileURI, setBannerFileURI] = useState<any>(null)
  const [iconFileURI, setIconFileURI] = useState<any>(null)
  const [thumbnailFileURI, setThumbnailFileURI] = useState<any>(null)
  const [startDateAndTime, setStartDateAndTime] = useState<any>(null)
  const [endDateAndTime, setEndDateAndTime] = useState<any>(null)
  const [selectedDates, setSelectedDates] = useState<any>([])

  const bannerImageRef = useRef<HTMLInputElement>(null)
  const thumbnailImageRef = useRef<HTMLInputElement>(null)
  const iconImageRef = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()
  const radioHosts = useSelector(hostSelector)

  const {
    radioShowInfo,
    modalOpenType,
    radioShowErrorMessage,
    radioShows,
    radioShowsLoading,
  } = useSelector(radioSelector)

  const formikRef: any = useRef()

  const getHostArray = useCallback(
    (hostIds: any) => {
      return (
        hostIds.map((id: any) => {
          const item = radioHosts.hosts.find(
            (element: any) => element._id === id,
          )
          if (item) {
            return { label: item?.name, value: item?._id, type: item?.type }
          } else {
            return { label: '', value: '' }
          }
        }) || [{ label: '', value: '' }]
      )
    },
    [radioHosts.hosts],
  )

  const getDefaultDate = useCallback(() => {
    return radioShowInfo
      ? [
          dayjs(`${radioShowInfo?.startDate} ${radioShowInfo?.startTime}`),
          dayjs(`${radioShowInfo?.endDate} ${radioShowInfo?.endTime}`),
        ]
      : []
  }, [radioShowInfo])

  useEffect(() => {
    if (formikRef.current && radioShowInfo) {
      const combinedRadioShowIds: any =
        radioShowInfo?.radioHostIds?.concat(radioShowInfo?.guestIds) || []
      formikRef.current.resetForm()
      formikRef.current.setFieldValue('name', radioShowInfo?.name)
      formikRef.current.setFieldValue('description', radioShowInfo?.description)
      formikRef.current.setFieldValue('meetingUrl', radioShowInfo?.meetingUrl)
      formikRef.current.setFieldValue(
        'hosts',
        getHostArray(combinedRadioShowIds || []),
      )
      formikRef.current.setFieldValue(
        'userRegistration',
        radioShowInfo?.userRegistrationAllowed || false,
      )
      formikRef.current.setFieldValue(
        'occurencType',
        radioShowInfo?.occurencType,
      )
      setSelectedDates(getDefaultDate())
      if (radioShowInfo?.dayOccurences?.length) {
        setWeekDays(radioShowInfo?.dayOccurences || [])
      }
    }
  }, [getDefaultDate, getHostArray, radioShowInfo])

  useEffect(() => {
    clearFieldOnClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioShows])

  const clearFieldOnClose = () => {
    //cleanup
    dispatch(manageSelectedRadioShow(null))
    formikRef.current.setFieldValue('name', '')
    formikRef.current.setFieldValue('description', '')
    formikRef.current.setFieldValue('meetingUrl', '')
    formikRef.current.setFieldValue('hosts', [])
    formikRef.current.setFieldValue('userRegistration', false)
    formikRef.current.setFieldValue('occurencType', '')
    setBannerFileURI(null)
    setThumbnailFile(null)
    setIconFileURI(null)
    setIconFile(null)
    setBannerFile(null)
    setThumbnailFileURI(null)
    setWeekDays([])
    dispatch(manageRadioShowErrorMessage(''))
    setSelectedDates([])
    setStartDateAndTime(null)
    setEndDateAndTime(null)
  }

  const handleSubmit = (values: any) => {
    dispatch(manageRadioShowErrorMessage(''))
    if (!bannerFileURI && modalOpenType != 'edit') {
      dispatch(manageRadioShowErrorMessage('Banner Image is required'))
      return
    }
    if (!thumbnailFileURI && modalOpenType != 'edit') {
      dispatch(manageRadioShowErrorMessage('Thumbnail Image is required'))
      return
    }
    if (!iconFileURI && modalOpenType != 'edit') {
      dispatch(manageRadioShowErrorMessage('Icon Image is required'))
      return
    }
    if ((!startDateAndTime || !endDateAndTime) && modalOpenType != 'edit') {
      dispatch(
        manageRadioShowErrorMessage('Dates and Times of the show are required'),
      )
      return
    }

    const {
      name,
      description,
      hosts,
      meetingUrl,
      userRegistration,
      occurencType,
    } = values

    if (occurencType === 'daily' && !weekDays?.length) {
      dispatch(
        manageRadioShowErrorMessage(
          'Please select the week days where event needs to be recurrred',
        ),
      )
    }
    const payload: any = {}
    payload.name = name
    payload.description = description
    payload.buttonRef = radioShowCloseRef
    payload.radioHostIds = hosts
      .map((e: any) => (e?.type === RadioHostType.Rj ? e.value : null))
      .filter((id: any) => id !== null)
    payload.thumbnailImageUrl =
      thumbnailFileURI || radioShowInfo?.thumbnailImageUrl
    payload.bannerImageUrl = bannerFileURI || radioShowInfo?.bannerImageUrl
    payload.iconImageUrl = iconFileURI || radioShowInfo?.iconImageUrl
    payload.meetingUrl = meetingUrl
    payload.userRegistrationAllowed = userRegistration || false
    payload.occurencType = occurencType
    payload.startDate =
      startDateAndTime?.split(' ')[0] || radioShowInfo?.startDate
    payload.endDate = endDateAndTime?.split(' ')[0] || radioShowInfo?.endDate
    payload.startTime =
      startDateAndTime?.split(' ')[1] || radioShowInfo?.startTime
    payload.endTime = endDateAndTime?.split(' ')[1] || radioShowInfo?.endTime
    payload.guestIds = hosts
      .map((e: any) => (e?.type === RadioHostType.Guest ? e.value : null))
      .filter((id: any) => id !== null)
    if (occurencType === 'daily') {
      payload.dayOccurences = weekDays
    }
    onSubmit(payload)
  }

  const onChange = (value: any, dateString: any) => {
    setSelectedDates(value)
    setStartDateAndTime(dateString[0])
    setEndDateAndTime(dateString[1])
  }

  const onOk = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
  ) => {
    console.log('onOk: ', value)
  }

  const onPressButtonGroup = (arr: any) => {
    setWeekDays(arr)
  }

  const openPicker = (ref: any) => {
    ref?.current?.click()
  }

  const handleFileSelect = (e: any, type: any) => {
    dispatch(manageRadioShowErrorMessage(''))
    const files: any = e.target.files[0]

    type === 'banner'
      ? setBannerFile(files)
      : type === 'thumbnail'
      ? setThumbnailFile(files)
      : setIconFile(files)
    const reader: any = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        type === 'banner'
          ? setBannerFileURI(reader.result!.toString())
          : type === 'thumbnail'
          ? setThumbnailFileURI(reader.result!.toString())
          : setIconFileURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(files)
  }

  return (
    <div
      className="modal fade"
      id="ModalCenter"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="ModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <Formik
          validationSchema={radioShowValidationSchema}
          initialValues={{}}
          innerRef={formikRef}
          onSubmit={async (values: {
            name: string
            description: string
            hosts: any
            meetingUrl: string
            userRegistration: boolean
            occurencType: any
          }) => {
            handleSubmit(values)
          }}
        >
          {(formik: any) => {
            const { values } = formik
            return (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="ModalCenterTitle">
                    {modalOpenType == 'edit'
                      ? 'Edit Radio Show'
                      : 'Add Radio Show'}
                  </h5>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={radioShowCloseRef}
                    onClick={clearFieldOnClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          {' '}
                          <Field
                            type="text"
                            name="name"
                            className="form-control  mb-0"
                            placeholder="Enter name of the show"
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            as="textarea"
                            id="text"
                            name="description"
                            rows={5}
                            className="form-control"
                            placeholder="Description"
                            defaultValue={''}
                          />
                          <ErrorMessage
                            name="description"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            type="text"
                            name="meetingUrl"
                            className="form-control  mb-0"
                            placeholder="meeting url"
                          />
                          <ErrorMessage
                            name="meetingUrl"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                        <div className="form-group">
                          {/* <Space direction="vertical" size={5}> */}

                          <RangePicker
                            disabledDate={(current: any) =>
                              current.isBefore(
                                moment
                                  .tz('America/Los_Angeles')
                                  .subtract(1, 'day'),
                              )
                            }
                            showTime={{
                              hideDisabledOptions: true,
                              format: 'HH:mm',
                            }}
                            value={selectedDates}
                            format="YYYY-MM-DD HH:mm"
                            onChange={onChange}
                            onOk={onOk}
                          />
                          <p>All dates and times are in PST</p>

                          {/* </Space> */}
                        </div>
                        <div
                          className="col-md-12 custom-switch"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Field
                            type="checkbox"
                            className="custom-control-input"
                            id="userRegistration"
                            name="userRegistration"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="userRegistration"
                          >
                            User Registration Required
                          </label>
                          <ErrorMessage
                            name="userRegistration"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_gallery form-group">
                          <label
                            onClick={() => openPicker(bannerImageRef)}
                            htmlFor="form_gallery-upload"
                          >
                            {bannerFile != null
                              ? bannerFile.name
                              : 'Banner image'}
                          </label>
                          <input
                            type="file"
                            ref={bannerImageRef}
                            onChange={(e: any) => handleFileSelect(e, 'banner')}
                            accept=".png, .jpg, .jpeg, .webp"
                          />
                        </div>

                        <div className="form_gallery form-group">
                          <label
                            onClick={() => openPicker(thumbnailImageRef)}
                            htmlFor="form_gallery-upload"
                          >
                            {thumbnailFile != null
                              ? thumbnailFile.name
                              : 'Thumbnail image'}
                          </label>
                          <input
                            type="file"
                            ref={thumbnailImageRef}
                            onChange={(e: any) =>
                              handleFileSelect(e, 'thumbnail')
                            }
                            accept=".png, .jpg, .jpeg, .webp"
                          />
                        </div>
                        <div className="form_gallery form-group">
                          <label
                            onClick={() => openPicker(iconImageRef)}
                            htmlFor="form_gallery-upload"
                          >
                            {iconFile != null ? iconFile.name : 'Icon image'}
                          </label>
                          <input
                            type="file"
                            ref={iconImageRef}
                            onChange={(e: any) =>
                              handleFileSelect(e, 'iconImage')
                            }
                            accept=".png, .jpg, .jpeg, .webp, .svg"
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            name="hosts"
                            id="hosts"
                            placeholder="Select Hosts"
                            isMulti={true}
                            component={SelectBox}
                            options={radioHosts?.hosts.map((host: any) => {
                              return {
                                label: host.name,
                                value: host._id,
                                type: host?.type,
                              }
                            })}
                          />
                          <ErrorMessage
                            name="hosts"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            as="select"
                            name="occurencType"
                            className="form-control"
                          >
                            <option value="">Select Occurence</option>
                            <option value="dont_repeat">Once</option>
                            <option value="daily">Daily</option>
                          </Field>
                          <ErrorMessage
                            name="type"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                        {values.occurencType === 'daily' && (
                          <div className="form-group">
                            <ButtonGroup
                              options={[
                                'Mon',
                                'Tue',
                                'Wed',
                                'Thu',
                                'Fri',
                                'Sat',
                                'Sun',
                              ]}
                              selected={weekDays}
                              onPressButtonGroup={onPressButtonGroup}
                            />
                          </div>
                        )}
                      </div>

                      {/* </div> */}
                      <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // onClick={() => handleSubmit(values)}
                        >
                          {!radioShowsLoading && (
                            <span className="indicator-label">
                              {modalOpenType == 'edit' ? 'Update' : 'Submit'}
                            </span>
                          )}

                          {radioShowsLoading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className="col-md-12 d-flex justify-content-end align-items-center">
                        <p
                          style={{
                            color: 'red',
                            alignSelf: 'flex-end',
                            justifySelf: 'flex-end',
                          }}
                        >
                          {radioShowErrorMessage}
                        </p>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default AddRadioShow
