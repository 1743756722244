import axios, { AxiosInstance } from 'axios'
import { config } from 'api/variables'
import { toast } from 'react-toastify'
import storeInstance from 'store/configureStore'
import { actions } from 'store/common/slice'

const plainAxios: AxiosInstance = axios.create({
  baseURL: `${config.baseUrl}`,
  timeout: 15000,
})

plainAxios.interceptors.request.use(
  function (request: any) {
    storeInstance.store.dispatch(
      actions.setNetworkCallRequestConfig({ loading: true }),
    )
    return request
  },
  function (error) {
    console.log(error)
    throw error
  },
)

plainAxios.interceptors.response.use(
  function (response: any) {
    // Success response
    if (response.status === 200) {
      storeInstance.store.dispatch(
        actions.setNetworkCallResponseConfig({ loading: false, error: null }),
      )
      return response.data
    }

    // Failure response
    storeInstance.store.dispatch(
      actions.setNetworkCallResponseConfig({ loading: false, error: response }),
    )
    toast.error(`${response.data.error}`)

    throw response
  },
  function (error) {
    const errorMessage: string = error?.response?.data?.error
    if (errorMessage) {
      toast.error(errorMessage)
    }
    storeInstance.store.dispatch(
      actions.setNetworkCallResponseConfig({
        loading: false,
        error: errorMessage,
      }),
    )
    throw error
  },
)

export { plainAxios }
