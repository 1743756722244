import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createSponsors,
  deleteSponsors,
  getSponsors,
  updateSponsors,
  searchSponsors,
} from './slice'

function* getAllSponsors({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.sponsors.getSponsors(payload?.skip, payload?.limit),
    )
    yield put(actions.setSponsors({ data: response, skip: payload?.skip }))
  } catch (err) {
    console.log(err)
  }
}

function* deleteSponsor({ payload }: ReturnType<typeof deleteSponsors>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.sponsors.sponsorsWithId(payload))
    yield put(actions.setDeleteSponsor(payload))
    toast.success(message.SPONSOR_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateSponsor({ payload }: ReturnType<typeof updateSponsors>): any {
  try {
    console.log('object', payload)

    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    yield put(actions.setSponsorLoader(true))
    const response = yield call(
      baseAxios.put,
      apiEndPoints.sponsors.sponsorsWithId(payload.sponsorId),
      payload,
    )
    yield put(actions.setUpdateSponsor(response))
    buttonRef?.current?.click()
    yield put(actions.setSponsorLoader(false))
  } catch (err) {
    yield put(actions.setSponsorLoader(false))
    console.log(err)
  }
}

function* createSponsor({ payload }: any): any {
  try {
    const buttonRef: any = payload.buttonRef
    delete payload.buttonRef
    yield put(actions.setSponsorLoader(true))
    const response = yield call(
      baseAxios.post,
      apiEndPoints.sponsors.createSponsors,
      payload,
    )
    buttonRef?.current?.click()
    yield put(actions.setCreateSponsor(response))
    yield put(actions.setSponsorLoader(false))
  } catch (err) {
    yield put(actions.setSponsorLoader(false))
    console.log(err)
  }
}

function* searchRadioSponsors({ payload }: any): any {
  try {
    yield put(actions.setSponsorLoader(true))

    const response = yield call(
      baseAxios.get,
      apiEndPoints.sponsors.searchSponsors(payload?.searchValue),
    )
    yield put(actions.setRadioSponsorSearch(response))
    yield put(actions.setSponsorLoader(false))
  } catch (err) {
    yield put(actions.setSponsorLoader(false))

    console.log(err)
  }
}

export function* sponsorSaga(): any {
  yield all([yield takeLatest(getSponsors, getAllSponsors)])
  yield all([yield takeLatest(deleteSponsors, deleteSponsor)])
  yield all([yield takeLatest(updateSponsors, updateSponsor)])
  yield all([yield takeLatest(createSponsors, createSponsor)])
  yield all([yield takeLatest(searchSponsors, searchRadioSponsors)])
}
