import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CustomHeader from 'components/CustomHeader'
import { getRadioEvents, getRadioShowInfo } from 'store/radioManagement/slice'
import { radioSelector } from 'store/radioManagement/selector'
import { hostSelector } from 'store/hostManagement/selector'
import { getHosts } from 'store/hostManagement/slice'
import { TableHeader } from 'components/DataTable'
import { ThreeDots } from 'react-loader-spinner'
import { sponsorSelector } from 'store/sponsorManagement/selector'

const headers = [
  { name: 'No.', field: 'number', sortable: false },
  { name: 'Name', field: 'name', sortable: false },
  { name: 'Meeting Url', field: 'action', sortable: false },
  { name: 'Date', field: 'order', sortable: false },
  { name: 'Start Time', field: 'order', sortable: false },
  { name: 'End Time', field: 'order', sortable: false },
]
const ViewRadioShowDetails = () => {
  const [hostsForShow, setHostsForShow] = useState<any>([null])
  const [guestsForShow, setGuestsForShow] = useState<any>([null])
  const [sponsorsForShow, setSponsorsForShow] = useState<any>([null])

  const { radioShowId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { radioShowInfo, radioShowsLoading, radioEvents } =
    useSelector(radioSelector)

  const { hosts } = useSelector(hostSelector)
  const { sponsors } = useSelector(sponsorSelector)

  useEffect(() => {
    if (radioShowId) {
      dispatch(getRadioEvents(radioShowId))
      dispatch(getRadioShowInfo(radioShowId))
      dispatch(getHosts({ skip: 0, limit: 200 }))
    } else {
      navigate('/radio-shows')
    }
    dispatch(getRadioShowInfo(radioShowId))
  }, [dispatch, navigate, radioShowId])

  const getHostArray = (hostIds: any) => {
    return (
      hostIds
        .map((id: any) => {
          const item = hosts.find((element: any) => element._id === id)
          if (item) {
            return item
          }
        })
        .filter((x: any) => x) || []
    )
  }

  const getSponsorArray = (sponsorIds: any) => {
    return (
      sponsorIds
        .map((id: any) => {
          const item = sponsors.find((element: any) => element._id === id)
          if (item) {
            return item
          }
        })
        .filter((x: any) => x) || []
    )
  }

  useEffect(() => {
    setHostsForShow(getHostArray(radioShowInfo?.radioHostIds || []))

    if (
      radioShowInfo &&
      radioShowInfo.sponsorIds &&
      radioShowInfo.sponsorIds.length
    ) {
      setSponsorsForShow(getSponsorArray(radioShowInfo?.sponsorIds))
    }

    if (
      radioShowInfo &&
      radioShowInfo.guestIds &&
      radioShowInfo.guestIds.length
    ) {
      setGuestsForShow(getHostArray(radioShowInfo?.guestIds))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioShowInfo])

  return (
    <CustomHeader pageName={'Movie View'}>
      <div id="content-page" className="content-page ">
        <div className="container-fluid">
          {radioShowInfo && radioShowInfo.bannerImageUrl ? (
            <div className="row">
              <div className="col-md-12">
                <div className="iq-card" style={{ padding: '0px !important' }}>
                  <div className="iq-card-body w-100 p-0">
                    <div className="card bg-dark text-white">
                      <img
                        src={radioShowInfo?.bannerImageUrl}
                        className="card-img"
                        style={{ height: '450px' }}
                        alt="Movie Poster"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="col-md-4">
              <div className="iq-card" style={{ padding: '0px !important' }}>
                <div className="iq-card-body w-100 p-0">
                  <div className="card bg-dark text-white">
                    <img
                      src={radioShowInfo?.thumbnailImageUrl}
                      style={{ maxHeight: '70vh', minHeight: '50vh' }}
                      className="card-img"
                      alt="Movie Poster"
                    />
                  </div>
                </div>
                {radioShowsLoading && (
                  <div
                    className="container d-flex justify-content-center align-items-center"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '200px',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      position: 'absolute',
                    }}
                  >
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#e20e02"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      visible={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="iq-card">
                <div className="iq-card-body">
                  {radioShowInfo?.iconImageUrl ? (
                    <>
                      <img
                        src={radioShowInfo.iconImageUrl}
                        className="img-fluid avatar-80 rounded-circle"
                        alt="Profile picture"
                        style={{ border: '2px solid #333' }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <p className="iq-card-text p-0 m-0">
                    <strong>Radio Show Title:</strong> {radioShowInfo?.name}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>Meeting Url:</strong> {radioShowInfo?.meetingUrl}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>Status:</strong> {radioShowInfo?.status}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>Start Date:</strong> {radioShowInfo?.startDate}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>End Date:</strong> {radioShowInfo?.endDate}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>Start Time:</strong>
                    {radioShowInfo?.startTime}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>End Time:</strong>
                    {radioShowInfo?.endTime}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>User Registration Allowed:</strong>{' '}
                    {radioShowInfo?.userRegistrationAllowed ? 'Yes' : 'No'}
                  </p>
                  <p className="iq-card-text p-0 m-0">
                    <strong>Occurence:</strong>{' '}
                    {radioShowInfo?.occurencType === 'dont_repeat'
                      ? 'Once'
                      : radioShowInfo?.occurencType}
                  </p>
                  {radioShowInfo &&
                  radioShowInfo.dayOccurences &&
                  radioShowInfo.dayOccurences.length ? (
                    <p className="iq-card-text p-0 m-0">
                      <strong>Day Occurences:</strong>{' '}
                      {radioShowInfo.dayOccurences.toString()}
                    </p>
                  ) : (
                    <></>
                  )}
                  <p className="iq-card-text p-0 m-0">
                    <strong>Description:</strong> {radioShowInfo?.description}
                  </p>
                  {hostsForShow && hostsForShow.length ? (
                    <p className="iq-card-text p-0 m-0">
                      <strong>Hosts:</strong>{' '}
                      {hostsForShow.map((e: any) => e?.name).toString()}
                    </p>
                  ) : (
                    <></>
                  )}
                  {guestsForShow && guestsForShow.length ? (
                    <p className="iq-card-text p-0 m-0">
                      <strong>Guests:</strong>{' '}
                      {guestsForShow.map((e: any) => e?.name).toString() ||
                        'NA'}
                    </p>
                  ) : (
                    <></>
                  )}
                  {hostsForShow && hostsForShow.length ? (
                    <p className="iq-card-text p-0 m-0">
                      <strong>Sponsors:</strong>{' '}
                      {sponsorsForShow.map((e: any) => e?.name).toString() ||
                        'NA'}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* radio show events */}
            <div
              className="row"
              style={{ backgroundColor: 'white', padding: '20px' }}
            >
              {radioEvents.length && radioShowInfo?.dayOccurences?.length && (
                <table
                  className="data-tables table movie_table"
                  style={{ width: '100%' }}
                >
                  <TableHeader headers={headers} />
                  <tbody>
                    {radioEvents?.map((radioEvent: any, index: number) => (
                      <tr key={`radio_show_event_list_${index}`}>
                        <td>{index + 1}</td>

                        <td>{radioEvent.name}</td>
                        <td>{radioEvent.meetingUrl}</td>
                        <td>
                          {moment(radioEvent.startDate).format('YYYY-MM-DD')}
                        </td>
                        <td>{radioEvent?.startDate?.split(' ')[1] || '--'}</td>

                        <td>{radioEvent?.endDate?.split(' ')[1] || '--'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default ViewRadioShowDetails
