import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TMDB } from './types'

// eslint-disable-next-line
const getCertification = createAction<any>('tmdb/getCertification')

export const initialState: TMDB = {
  movieCertificates: [],
}

const TmdbSlice = createSlice({
  name: 'Tmdb',
  initialState: initialState,
  reducers: {
    setMovieCertificates(state, action: PayloadAction<any>) {
      state.movieCertificates = action.payload
    },
  },
})

export { getCertification }

export const { actions, reducer, name: sliceKey } = TmdbSlice
