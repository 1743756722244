import { useField } from 'formik'
import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
const SelectBox = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [field, state, { setValue, setTouched }]: any = useField(
    props.field.name,
  )

  // value is an array now
  function onChange(value: any) {
    setValue(value)
  }
  if (!props.isMulti) {
    return (
      <Select
        options={props.options}
        name={field.name}
        value={
          props.options
            ? props.options.find((option: any) => option.value === field.value)
            : ''
        }
        // onChange={option => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        placeholder={props.placeholder}
      />
    )
  } else {
    return (
      <CreatableSelect
        {...props}
        value={state?.value}
        onChange={onChange}
        onBlur={setTouched}
        isMulti={true}
      />
    )
  }
}

export default SelectBox
