import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectMagazineState = (state: RootState) => state.Magazine || initialState

export const magazineSelector = createSelector(
  [selectMagazineState],
  Magazine => Magazine,
)
