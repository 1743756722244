import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'

// Store
import { loginUser } from 'store/userManagement/slice'

const signInSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(4, 'Password is too short - should be 4 chars min'),
})

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginBtnRef = useRef<HTMLButtonElement>(null)
  const [loading, setLoading] = useState(false)

  const [initialValues] = useState({
    email: '',
    password: '',
  })

  const handleSubmit = (values: { email: string; password: string }) => {
    //Disable button
    loginBtnRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)

    dispatch(loginUser({ data: values, navigate }))
    //Enable button
    loginBtnRef.current?.removeAttribute('disabled')
    // Disable indicator
    setLoading(false)
  }

  return (
    <section className="sign-in-page">
      <div className="sign-in-container">
        <div className="container h-100">
          <div className="row justify-content-center align-items-start height-self-center ">
            <div className="col-12 h-100">
              {/* <p className="w-100">Logo</p> */}
              <div
                className=" d-flex align-items-center justify-content-center"
                style={{ height: '90%' }}
              >
                <div className="col-lg-5 col-md-12 align-self-center form-padding">
                  <div className="sign-user_card ">
                    <div className="sign-in-page-data">
                      <div className="sign-in-from w-100 m-auto">
                        <h3 className="mb-3 text-center sign-in-font-color ">
                          Sign in
                        </h3>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={signInSchema}
                          onSubmit={(values: {
                            email: string
                            password: string
                          }) => {
                            handleSubmit(values)
                          }}
                        >
                          {() => {
                            return (
                              <Form className="mt-4">
                                <div className="form-group">
                                  <Field
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-control  mb-0"
                                    placeholder="Enter email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="form-group">
                                  <Field
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control mb-0"
                                    placeholder="Password"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="sign-info">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    ref={loginBtnRef}
                                  >
                                    {!loading && (
                                      <span className="indicator-label">
                                        Sign In
                                      </span>
                                    )}
                                    {loading && (
                                      <span className="indicator-progress">
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                      </span>
                                    )}
                                  </button>
                                  <div className="custom-control custom-checkbox d-inline-block">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="customCheck"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck"
                                    >
                                      Remember Me
                                    </label>
                                  </div>
                                </div>
                              </Form>
                            )
                          }}
                        </Formik>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="d-flex justify-content-center links">
                        <Link to="/auth/forgot-password" className="f-link">
                          Forgot your password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
