import React, { useEffect, useState } from 'react'
import CustomHeader from '../../components/CustomHeader'
import Chart from 'react-apexcharts'
import {
  movieThumbOne,
  movieThumbTwo,
  movieThumbThree,
  movieThumbFour,
  movieThumbFive,
} from './../../utils/imageUtils'
import { useDispatch, useSelector } from 'react-redux'
import {
  getActiveUserAnalytics,
  getContentAnalytics,
  getUserSignupsTrend,
} from 'store/dashboardManagement/slice'
import { dashboardSelector } from 'store/dashboardManagement/selector'
import { convertToK } from 'utils/helpers/systemHelpers'
import { Dna, ThreeDots } from 'react-loader-spinner'
import TrendSeries from './TrendSeries'

const Dashboard = () => {
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [movieData, setMovieData] = useState([
    {
      name: 'Champions',
      rating: 9.2,
      image: movieThumbOne,
      category: 'Horror',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Last Race',
      rating: 8,
      image: movieThumbTwo,
      category: 'Horror',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Boop Bitty',
      rating: 6.5,
      image: movieThumbThree,
      category: 'Thriller',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Ant Man & the Wasp',
      rating: 7.3,
      category: 'Action, Thriller',
      image: movieThumbFour,
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Iron Man 1',
      rating: 10,
      category: 'Action, Si-fi',
      image: movieThumbFive,
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Last Race',
      rating: 5,
      image: movieThumbOne,
      category: 'Horror',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Champions',
      rating: 4,
      image: movieThumbTwo,
      category: 'Horror',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Boop Bitty',
      rating: 1.4,
      image: movieThumbThree,
      category: 'Thriller',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Doctor Strange & Multiverse of MAdness',
      rating: 8.6,
      image: movieThumbFour,
      category: 'Action, Thriller',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
    {
      name: 'Iron Man 2',
      rating: 9.4,
      image: movieThumbFive,
      category: 'Action, Thriller, Si-fi',
      user: 'Unsubscriber',
      date: '21 July,2022',
    },
  ])

  const { activeUserAnalytics, contentAnalytics, userSignupsTrend } =
    useSelector(dashboardSelector)

  const loginOptions = {
    colors: ['#e20e02', '#f68a04', '#007aff', '#545e75'],
    labels: ['Web', 'Android', 'IOS', 'Mobile'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 0,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }

  const loginSeriesValues = [44, 55, 30, 30]

  const userSignupsTrendOptions = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        // will get it from api
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    yaxis: {
      title: {
        text: 'Total Signups',
      },
    },
    fill: {
      opacity: 1,
    },
  }

  useEffect(() => {
    dispatch(getActiveUserAnalytics({}))
    dispatch(getContentAnalytics({}))
    dispatch(getUserSignupsTrend({}))
  }, [dispatch])

  return (
    <CustomHeader pageName={'Dashboard'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-sm-6 col-lg-6 col-xl-3">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="iq-cart-text text-capitalize">
                          <p className="mb-0 font-size-14">Total Watch Time</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <h4 className=" mb-0">
                          {contentAnalytics &&
                          contentAnalytics.totalVideoWatchTime ? (
                            convertToK(
                              Math.round(
                                contentAnalytics.totalVideoWatchTime / 60,
                              ),
                            )
                          ) : (
                            <ThreeDots
                              height="40"
                              width="40"
                              radius="5"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          )}
                        </h4>
                        (In min)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-xl-3">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="iq-cart-text text-capitalize">
                          <p className="mb-0 font-size-14">Total Audio Time</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <h4 className=" mb-0">
                          {contentAnalytics &&
                          contentAnalytics.totalAudioWatchTime ? (
                            convertToK(
                              Math.round(
                                contentAnalytics.totalAudioWatchTime / 60,
                              ),
                            )
                          ) : (
                            <ThreeDots
                              height="40"
                              width="40"
                              radius="5"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          )}
                        </h4>
                        (In min)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-xl-3">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="iq-cart-text text-capitalize">
                          <p className="mb-0 font-size-14">
                            Active User Sessions
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <h4 className="mb-0 align-items-center">
                          {activeUserAnalytics &&
                          activeUserAnalytics.currentActiveUsers &&
                          activeUserAnalytics.currentActiveUsers.length > 1 ? (
                            convertToK(
                              activeUserAnalytics.currentActiveUsers.reduce(
                                (accumulator, current) =>
                                  accumulator + current.count,
                                0,
                              ),
                            )
                          ) : (
                            <ThreeDots
                              height="40"
                              width="40"
                              radius="5"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-xl-3">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-body">
                      <div className="iq-cart-text text-capitalize">
                        <p className="mb- font-size-14">Total Users</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <h4 className="mb-0 align-items-center">
                          {activeUserAnalytics &&
                          activeUserAnalytics.totalUsers ? (
                            convertToK(activeUserAnalytics.totalUsers)
                          ) : (
                            <ThreeDots
                              height="40"
                              width="40"
                              radius="5"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between align-items-center">
                  <div className="iq-header-title">
                    <h4 className="card-title">New User Registrations Trend</h4>
                  </div>
                  <div
                    id="top-rated-item-slick-arrow"
                    className="slick-aerrow-block  iq-rtl-direction"
                  />
                </div>
                {userSignupsTrend && userSignupsTrend.length > 1 ? (
                  <div className="iq-card-body">
                    <Chart
                      options={
                        userSignupsTrend && userSignupsTrend.length
                          ? {
                              ...userSignupsTrendOptions,
                              xaxis: {
                                categories: userSignupsTrend.map(e => e.month),
                              },
                            }
                          : userSignupsTrendOptions
                      }
                      series={
                        userSignupsTrend && userSignupsTrend.length
                          ? [
                              {
                                name: 'Total Signups',
                                data: userSignupsTrend.map(e => e.totalSignups),
                              },
                            ]
                          : []
                      }
                      type={'bar'}
                      height={240}
                    />
                  </div>
                ) : (
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{ marginLeft: '40%', marginTop: '10%' }}
                    wrapperClass="dna-wrapper"
                  />
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header">
                  <div className="iq-header-title">
                    <h4 className="card-title text-center">
                      User Sessions by platform
                    </h4>
                  </div>
                </div>
                {activeUserAnalytics &&
                activeUserAnalytics.currentActiveUsers &&
                activeUserAnalytics.currentActiveUsers.length > 1 ? (
                  <div className="iq-card-body pb-0">
                    <Chart
                      options={
                        activeUserAnalytics &&
                        activeUserAnalytics.currentActiveUsers
                          ? {
                              ...loginOptions,
                              labels:
                                activeUserAnalytics.currentActiveUsers.map(
                                  e => e._id,
                                ),
                            }
                          : loginOptions
                      }
                      series={
                        activeUserAnalytics &&
                        activeUserAnalytics.currentActiveUsers
                          ? activeUserAnalytics.currentActiveUsers.map(
                              e => e.count,
                            )
                          : loginSeriesValues
                      }
                      type={'donut'}
                      width={250}
                    />
                    <div className="row mt-1">
                      <div className="col-sm-6 col-md-3 col-lg-6 iq-user-list">
                        <div className="iq-card">
                          <div className="iq-card-body">
                            <div className="media align-items-center">
                              <div className="iq-user-box bg-info" />
                              <div className="media-body text-black">
                                <p className="mb-0 font-size-14 line-height">
                                  Mobile
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3 col-lg-6 iq-user-list">
                        <div className="iq-card">
                          <div className="iq-card-body">
                            <div className="media align-items-center">
                              <div className="iq-user-box bg-warning" />
                              <div className="media-body text-black">
                                <p className="mb-0 font-size-14 line-height">
                                  IOS
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3 col-lg-6 iq-user-list">
                        <div className="iq-card">
                          <div className="iq-card-body">
                            <div className="media align-items-center">
                              <div className="iq-user-box bg-primary" />
                              <div className="media-body text-black">
                                <p className="mb-0 font-size-14 line-height">
                                  Android
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3 col-lg-6 iq-user-list">
                        <div className="iq-card">
                          <div className="iq-card-body">
                            <div className="media align-items-center">
                              <div className="iq-user-box bg-danger" />
                              <div className="media-body text-black">
                                <p className="mb-0 font-size-14 line-height">
                                  Web
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{ marginLeft: '30%', marginTop: '40%' }}
                    wrapperClass="dna-wrapper"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <TrendSeries />
      </div>
    </CustomHeader>
  )
}

export default Dashboard
