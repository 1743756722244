export enum MediaContentStatusType {
  Draft = 'draft',
  Publish = 'publish',
  Approved = 'approved',
  UnPublish = 'un_publish',
}

export enum AudioType {
  SingleAudio = 'single_audio',
  MusicAlbum = 'music_album',
  Podcast = 'podcast',
  AudioPlaylist = 'audio_playlist',
}

export enum VideoType {
  WebSeries = 'web_series',
  TalkShow = 'talk_shows',
  Interview = 'interviews',
}

export enum UploadStatusType {
  Processing = 'processing',
  Failed = 'failed',
  Completed = 'completed',
  Interrupted = 'Interrupted',
}

export enum bannerTypes {
  Movie = 'movie',
  WebSeries = 'web_series',
  TalkShows = 'talk_shows',
  Interviews = 'interviews',
  external = 'external',
  Music = 'music',
  Podcast = 'podcast',
  Magazine = 'magazine',
}

export enum RadioShowStatus {
  NotStarted = 'not_started',
  Launched = 'launched',
  Pause = 'pause',
  Cancelled = 'cancelled',
}

export enum RadioHostType {
  Rj = 'Rj',
  Guest = 'guest',
}

export enum TranscoderJobStatusType {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Canceled = 'canceled',
  Failed = 'failed',
  Completed = 'completed',
}

export enum JobDurationType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}
