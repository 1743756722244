import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Radio } from './types'

// eslint-disable-next-line
const getRadioShows = createAction<any>('cms/getRadioShows')
const getRadioEvents = createAction<any>('cms/getRadioEvents')
const getRadioShowInfo = createAction<any>('cms/getRadioShowInfo')
const deleteRadioShows = createAction<any>('cms/deleteRadioShows')
const updateRadioShows = createAction<any>('cms/updateRadioShows')
const createRadioShows = createAction<any>('cms/createRadioShows')
const launchRadioShows = createAction<any>('cms/launchRadioShows')
const manageSelectedRadioShow = createAction<any>('cms/manageSelectedRadioShow')
const manageModalOpenType = createAction<any>('cms/manageModalOpenType')
const manageRadioShowErrorMessage = createAction<any>(
  'cms/manageRadioShowErrorMessage',
)
const manageRadioShowLoading = createAction<any>('cms/manageRadioShowLoading')
const searchRadioShows = createAction<any>('cms/searchRadioShows')

export const initialState: Radio = {
  radioShows: [],
  radioShowsSearchData: [],
  radioEvents: [],
  radioShowsLoading: false,
  radioShowErrorMessage: '',
  radioShowInfo: '',
  modalOpenType: 'add',
}

const RadioSlice = createSlice({
  name: 'Radio',
  initialState: initialState,
  reducers: {
    setRadioShows(state, action: PayloadAction<any>) {
      if (action?.payload?.skip > 0) {
        state.radioShows = state.radioShows.concat(action.payload?.data)
      } else {
        state.radioShows = action.payload?.data
      }
    },

    setRadioEvents(state, action: PayloadAction<any>) {
      state.radioEvents = action.payload
    },

    setCreateRadioShow(state, action: PayloadAction<any>) {
      state.radioShows = [...state.radioShows, action.payload]
      state.modalOpenType = 'add'
    },

    setUpdateRadioShow(state, action: PayloadAction<any>) {
      state.radioShows[
        state.radioShows.findIndex((x: any) => x._id == action.payload._id)
      ] = action.payload
      state.modalOpenType = 'add'
    },

    setDeleteRadioShow(state, action: PayloadAction<any>) {
      state.radioShows = state.radioShows.filter(
        (x: any) => x._id != action.payload,
      )
    },

    setRadioShowLoader(state, action: PayloadAction<any>) {
      state.radioShowsLoading = action.payload
    },

    setSelectedRadioShowInfo(state, action: PayloadAction<any>) {
      state.radioShowInfo = action.payload
    },

    setModalOpenType(state, action: PayloadAction<any>) {
      state.modalOpenType = action.payload
    },

    setRadioShowErrorMessage(state, action: PayloadAction<any>) {
      state.radioShowErrorMessage = action.payload
    },
    setRadioShowSearch(state, action: PayloadAction<any>) {
      state.radioShowsSearchData = action.payload
    },
  },
})

export {
  getRadioShows,
  deleteRadioShows,
  updateRadioShows,
  createRadioShows,
  launchRadioShows,
  manageSelectedRadioShow,
  manageModalOpenType,
  manageRadioShowErrorMessage,
  manageRadioShowLoading,
  getRadioEvents,
  getRadioShowInfo,
  searchRadioShows,
}

export const { actions, reducer, name: sliceKey } = RadioSlice
