// import { Cookies } from 'react-cookie'
import { baseAxios } from 'api/axios'
import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiEndPoints } from 'api/variables'
import {
  actions,
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getPermissions,
  createPermission,
  updatePermission,
  deletePermission,
  createTenant,
  deleteTenant,
  getTenants,
  updateTenant,
} from './slice'
import { toast } from 'react-toastify'
import { message } from 'utils/helpers/constants'

function* getAllRoles(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.users.getRolesData)
    yield put(actions.setRoles(response))
  } catch (err) {
    console.log(err)
  }
}

function* createUserRole({ payload }: ReturnType<typeof createRole>): any {
  try {
    yield call(baseAxios.post, apiEndPoints.users.createRoleData, payload.data)
    toast.success(message.ROLE_CREATE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* updateUserRole({ payload }: ReturnType<typeof updateRole>): any {
  try {
    yield call(
      baseAxios.put,
      apiEndPoints.users.updateRoleData(payload.roleId),
      payload.data,
    )
    toast.success(message.ROLE_UPDATE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* deleteUserRole({ payload }: ReturnType<typeof deleteRole>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.users.deleteRoleData(payload.roleId),
    )
    toast.success(message.ROLE_DELETE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* getAllPermissions(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.users.getPermissionsData,
    )
    yield put(actions.setPermissions(response))
  } catch (err) {
    console.log(err)
  }
}

function* createRolePermissions({
  payload,
}: ReturnType<typeof createPermission>): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.users.createPermissionData,
      payload.data,
    )
    toast.success(message.PERMISSION_CREATE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* updateRolePermissions({
  payload,
}: ReturnType<typeof updatePermission>): any {
  try {
    yield call(
      baseAxios.put,
      apiEndPoints.users.updatePermissionData(payload.permissionId),
      payload.data,
    )
    toast.success(message.PERMISSION_UPDATE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* deleteRolePermissions({
  payload,
}: ReturnType<typeof deletePermission>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.users.deletePermissionData(payload.permissionId),
    )
    toast.success(message.PERMISSION_DELETE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* getAllTenants(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.users.getTenantsData,
    )
    yield put(actions.setTenants(response))
  } catch (err) {
    console.log(err)
  }
}

function* createUserTenants({ payload }: ReturnType<typeof createTenant>): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.users.createTenantData,
      payload.data,
    )
    toast.success(message.PERMISSION_CREATE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* updateUserTenants({ payload }: ReturnType<typeof updateTenant>): any {
  try {
    yield call(
      baseAxios.put,
      apiEndPoints.users.updateTenantData(payload.tenantId),
      payload.data,
    )
    toast.success(message.PERMISSION_UPDATE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

function* deleteUserTenants({ payload }: ReturnType<typeof deleteTenant>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.users.deleteTenantData(payload.tenantId),
    )
    toast.success(message.PERMISSION_DELETE_SUCCESS_MESSAGE)
  } catch (err) {
    console.log(err)
  }
}

export function* adminRoleSaga(): any {
  yield all([yield takeLatest(getRoles, getAllRoles)])
  yield all([yield takeLatest(createRole, createUserRole)])
  yield all([yield takeLatest(updateRole, updateUserRole)])
  yield all([yield takeLatest(deleteRole, deleteUserRole)])
  yield all([yield takeLatest(getPermissions, getAllPermissions)])
  yield all([yield takeLatest(createPermission, createRolePermissions)])
  yield all([yield takeLatest(updatePermission, updateRolePermissions)])
  yield all([yield takeLatest(deletePermission, deleteRolePermissions)])
  yield all([yield takeLatest(getTenants, getAllTenants)])
  yield all([yield takeLatest(createTenant, createUserTenants)])
  yield all([yield takeLatest(updateTenant, updateUserTenants)])
  yield all([yield takeLatest(deleteTenant, deleteUserTenants)])
}
