import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Sponsor } from './types'

// eslint-disable-next-line
const getSponsors = createAction<any>('cms/getSponsors')
const deleteSponsors = createAction<any>('cms/deleteSponsors')
const updateSponsors = createAction<any>('cms/updateSponsors')
const createSponsors = createAction<any>('cms/createSponsors')
const searchSponsors = createAction<any>('cms/searchSponsors')

export const initialState: Sponsor = {
  sponsors: [],
  sponsorsLoading: false,
  sponsorsSearchData: [],
}

const SponsorSlice = createSlice({
  name: 'Sponsor',
  initialState: initialState,
  reducers: {
    setSponsors(state, action: PayloadAction<any>) {
      if (action?.payload?.skip > 0) {
        state.sponsors = state.sponsors.concat(action.payload?.data)
      } else {
        state.sponsors = action.payload?.data
      }
    },
    setCreateSponsor(state, action: PayloadAction<any>) {
      state.sponsors = [...state.sponsors, action.payload]
    },
    setUpdateSponsor(state, action: PayloadAction<any>) {
      state.sponsors[
        state.sponsors.findIndex((x: any) => x._id == action.payload._id)
      ] = action.payload
    },
    setDeleteSponsor(state, action: PayloadAction<any>) {
      state.sponsors = state.sponsors.filter(
        (x: any) => x._id != action.payload,
      )
    },
    setSponsorLoader(state, action: PayloadAction<any>) {
      state.sponsorsLoading = action.payload
    },
    setRadioSponsorSearch(state, action: PayloadAction<any>) {
      state.sponsorsSearchData = action.payload
    },
  },
})

export {
  getSponsors,
  deleteSponsors,
  updateSponsors,
  createSponsors,
  searchSponsors,
}

export const { actions, reducer, name: sliceKey } = SponsorSlice
