import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Magazine } from './types'

// eslint-disable-next-line
const getMagazines = createAction<any>('cms/getMagazines')
const deleteMagazines = createAction<any>('cms/deleteMagazines')
const updateMagazines = createAction<any>('cms/updateMagazines')
const createMagazines = createAction<any>('cms/createMagazines')
const getAuthors = createAction<any>('cms/getAuthors')
const deleteAuthors = createAction<any>('cms/deleteAuthors')
const getAllMagazinesByStatus = createAction<any>('cms/getAllMagazinesByStatus')

export const initialState: Magazine = {
  magazines: [],
  authors: [],
  magazineOptions: [],
}

const MagazineSlice = createSlice({
  name: 'Magazine',
  initialState: initialState,
  reducers: {
    setMagazines(state, action: PayloadAction<any>) {
      state.magazines = action.payload
    },
    setAuthors(state, action: PayloadAction<any>) {
      state.authors = action.payload
    },
    setMagazineOptions(state, action: PayloadAction<any>) {
      state.magazineOptions = action.payload.map((val: any) => {
        return {
          value: val._id,
          label: val.name,
          imageUrl: val.imageUrl,
        }
      })
    },
  },
})

export {
  getMagazines,
  deleteMagazines,
  updateMagazines,
  createMagazines,
  getAuthors,
  deleteAuthors,
  getAllMagazinesByStatus,
}

export const { actions, reducer, name: sliceKey } = MagazineSlice
