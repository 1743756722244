/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { getPermission, getStatus } from 'utils/helpers/systemHelpers'
import { Link } from 'react-router-dom'
import { PERMISSIONS } from 'model/auth'
import { useDispatch } from 'react-redux'
import { MediaContentStatusType } from 'utils/enum'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'

const ApprovedVideos = (props: any) => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [videoPlaylist, setVideoPlaylist]: any = useState(null)

  const videoApprovedData = props.data

  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.videoPlaylistByStatus(MediaContentStatusType.Approved),
    )
    if (response) {
      setVideoPlaylist(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [videoApprovedData])

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const videosApprovedData = useMemo(() => {
    const tempData: any = []
    videoPlaylist &&
      videoPlaylist.length > 0 &&
      videoPlaylist.map((videoP: any) => {
        tempData.push(videoP)
      })

    let computedTenants: any = tempData
    if (search) {
      computedTenants = computedTenants.filter(
        (approvedVideo: { name: string; description: string; genre: string }) =>
          approvedVideo.name.toLowerCase().includes(search.toLowerCase()) ||
          approvedVideo.description
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          approvedVideo.genre.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedTenants.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedTenants.slice(firstPageIndex, lastPageIndex)
  }, [videoPlaylist, search, currentPage, pageSize])

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h4 className="card-title">Approved Video PlayLists</h4>
            </div>
          </div>
          <div className="iq-card-body">
            <div className="table-view">
              <TableLength
                handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                handleSearchValue={(searchVal: any) => setSearch(searchVal)}
              />
              {pageLoading && (
                <div
                  className="container d-flex justify-content-center align-items-center"
                  style={{ height: '200px' }}
                >
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#e20e02"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
              {!pageLoading && videoPlaylist && videoPlaylist.length > 0 && (
                <table
                  className="data-tables table movie_table"
                  style={{ width: '100%' }}
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) =>
                      setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {videosApprovedData.map(
                      (videoContent: any, index: number) => (
                        <tr key={`single_video_list_${index}`}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={videoContent.imageUrl}
                              width={50}
                              height={70}
                            />
                          </td>
                          <td>{videoContent.name}</td>
                          <td>
                            {getPermission(
                              PERMISSIONS.PUBLISH_PLAYLIST_VIDEO,
                            ) ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                ref={props.publishButtonRef}
                                onClick={() =>
                                  props.handlePublish(videoContent)
                                }
                              >
                                {props.loading == true &&
                                props.selectedBtnId == videoContent._id ? (
                                  <span className="indicator-progress">
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                  </span>
                                ) : (
                                  <span className="indicator-label">
                                    Publish
                                  </span>
                                )}
                              </button>
                            ) : (
                              getStatus(videoContent.status)
                            )}
                          </td>
                          <td>
                            <div className="flex align-items-center list-user-action">
                              <Link
                                className="iq-bg-warning"
                                data-placement="top"
                                to="/video-playlist/view"
                                state={{ data: videoContent }}
                              >
                                <i className="ri-eye-line" />
                              </Link>
                              {getPermission(
                                PERMISSIONS.UPDATE_PLAYLIST_VIDEO,
                              ) && (
                                <a
                                  className="iq-bg-success"
                                  data-placement="top"
                                  title=""
                                  data-toggle="modal"
                                  data-target="#AddMovieModel"
                                  data-original-title="Edit"
                                  onClick={() =>
                                    props.handleUpdatePress(videoContent)
                                  }
                                >
                                  <i className="ri-pencil-line" />
                                </a>
                              )}
                              {getPermission(
                                PERMISSIONS.DELETE_PLAYLIST_VIDEO,
                              ) && (
                                <a
                                  className="iq-bg-primary"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Delete"
                                  onClick={() =>
                                    props.handleDeletePress(videoContent._id)
                                  }
                                >
                                  <i className="ri-delete-bin-line" />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              )}
              {!pageLoading && videoPlaylist && videoPlaylist.length == 0 && (
                <NoData />
              )}
              <Pagination
                currentPage={currentPage}
                totalCount={totalItems}
                pageSize={pageSize}
                onPageChange={(page: React.SetStateAction<number>) =>
                  setCurrentPage(page)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApprovedVideos
