import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { message } from 'utils/helpers/constants'
import {
  actions,
  createAlbums,
  deleteAlbums,
  getAlbums,
  getAudios,
  getAudioTracks,
  updateAlbums,
  getAllSingleAlbumsByStatus,
  getAllPodcastsByStatus,
} from './slice'
import { AudioType } from 'utils/enum'

function* getAllAlbums(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.cms.album)
    yield put(actions.setAlbums(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllSingleAlbumsByStatuses({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.singleAlbumByStatus(
        AudioType.SingleAudio,
        payload.status,
      ),
    )
    yield put(actions.setSingleAudioOptionsFormat(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllPodcastsByStatuses({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.singleAlbumByStatus(
        `${AudioType.AudioPlaylist},${AudioType.Podcast},${AudioType.MusicAlbum}`,
        payload.status,
      ),
    )

    yield put(actions.setPodcastOptionsFormat(response))
  } catch (err) {
    console.log(err)
  }
}

function* deleteAlbum({ payload }: ReturnType<typeof deleteAlbums>): any {
  try {
    yield call(
      baseAxios.delete,
      apiEndPoints.cms.magazineWithId(payload.magazineId),
    )
    toast.success(message.MAGAZINE_DELETE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* updateAlbum({ payload }: ReturnType<typeof updateAlbums>): any {
  try {
    yield call(
      baseAxios.put,
      apiEndPoints.cms.magazineWithId(payload.magazineId),
    )
  } catch (err) {
    console.log(err)
  }
}

function* createAlbum(): any {
  try {
    yield call(baseAxios.post, apiEndPoints.cms.magazine)
  } catch (err) {
    console.log(err)
  }
}

function* getAllAudioTracks({
  payload,
}: ReturnType<typeof getAudioTracks>): any {
  try {
    // const response = yield call(baseAxios.get, apiEndPoints.cms.audioTracks)
    const response = yield call(
      baseAxios.get,
      apiEndPoints.cms.audioTracks(payload.albumId),
    )
    yield put(actions.setAudioTracks(response))
  } catch (err) {
    console.log(err)
  }
}

function* getAllAudios(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.cms.audio)
    yield put(actions.setAudios(response))
  } catch (err) {
    console.log(err)
  }
}

export function* albumSaga(): any {
  yield all([yield takeLatest(getAlbums, getAllAlbums)])
  yield all([yield takeLatest(deleteAlbums, deleteAlbum)])
  yield all([yield takeLatest(updateAlbums, updateAlbum)])
  yield all([yield takeLatest(createAlbums, createAlbum)])
  yield all([yield takeLatest(getAudioTracks, getAllAudioTracks)])
  yield all([yield takeLatest(getAudios, getAllAudios)])
  yield all([
    yield takeLatest(getAllSingleAlbumsByStatus, getAllSingleAlbumsByStatuses),
  ])
  yield all([
    yield takeLatest(getAllPodcastsByStatus, getAllPodcastsByStatuses),
  ])
}
