import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AudioTranscoding } from './types'

// eslint-disable-next-line
const getTranscodingAudios = createAction<any>('cms/getTranscodingAudios')
const beginAudioTranscoding = createAction<any>('cms/beginAudioTranscoding')
const stopAudioTranscoding = createAction<any>('cms/stopAudioTranscoding')
const searchAudios = createAction<any>('cms/searchAllAudios')

export const initialState: AudioTranscoding = {
  transcodedAudios: [],
  audioTranscodingLoading: false,
  audioTranscodingStartLoading: false,
  audiosSearchData: [],
}

const AudioTranscodingSlice = createSlice({
  name: 'AudioTranscoding',
  initialState: initialState,
  reducers: {
    setTranscodingAudios(state, action: PayloadAction<any>) {
      if (action?.payload?.skip > 0) {
        state.transcodedAudios = state.transcodedAudios.concat(
          action.payload?.data,
        )
      } else {
        state.transcodedAudios = action.payload?.data
      }
    },
    setTranscodingAudioSearch(state, action: PayloadAction<any>) {
      state.audiosSearchData = action.payload
    },
    setAudioTranscodingStartLoading(state, action: PayloadAction<any>) {
      state.audioTranscodingStartLoading = action.payload
    },
    setAudioTranscodingLoading(state, action: PayloadAction<any>) {
      state.audioTranscodingLoading = action.payload
    },
    setStartAudioTranscodingRowLoader(state, action: PayloadAction<any>) {
      const { transcodingId, loading, transcodingStatus } = action.payload
      const audioIndex = state.audiosSearchData.length
        ? state.audiosSearchData.findIndex((x: any) => x._id === transcodingId)
        : state.transcodedAudios.findIndex((x: any) => x._id === transcodingId)
      if (state.audiosSearchData.length) {
        state.audiosSearchData[audioIndex] = {
          ...state.audiosSearchData[audioIndex],
          loading,
          transcodingStatus,
        }
      } else {
        state.transcodedAudios[audioIndex] = {
          ...state.transcodedAudios[audioIndex],
          loading,
        }
      }
    },
  },
})

export {
  getTranscodingAudios,
  beginAudioTranscoding,
  stopAudioTranscoding,
  searchAudios,
}

export const { actions, reducer, name: sliceKey } = AudioTranscodingSlice
