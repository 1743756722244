import { baseAxios } from 'api/axios'
import { call, put, takeLatest, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { apiEndPoints } from 'api/variables'
import {
  actions,
  addNewUser,
  getUserById,
  deleteUser,
  updateUser,
  loginUser,
  getUsersList,
  logoutUser,
  resetUser,
  resetVerify,
  updatePassword,
  adminPasswordUpdate,
  adminCreateUser,
  adminDeleteUser,
  adminUpdateUser,
} from './slice'
import { message } from 'utils/helpers/constants'
import { logOutUser } from 'utils/helpers/authUtils'

function* signInUser({ payload }: ReturnType<typeof loginUser>): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.users.login,
      payload.data,
    )
    localStorage.setItem('user', JSON.stringify(response))
    yield put(actions.setAuthUser(response))
    payload.navigate('/dashboard')
  } catch (err) {
    console.log(err)
  }
}

function* signOutUser(): any {
  try {
    yield call(baseAxios.post, apiEndPoints.users.logout)
    yield call(logOutUser)
  } catch (err) {
    yield call(logOutUser)
    console.log(err)
  }
}

function* forgotUserPassword({ payload }: ReturnType<typeof resetUser>): any {
  try {
    yield call(baseAxios.post, apiEndPoints.users.forgotPassword, payload.data)
    payload.navigate('/auth/new-password', { state: payload.data })
  } catch (err) {
    console.log(err)
  }
}

function* forgotUserPasswordVerify({
  payload,
}: ReturnType<typeof resetVerify>): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.users.verifyForgotPassword,
      payload.data,
    )
    toast.success(message.REST_SUCCESS_MSG)
    payload.navigate('/')
  } catch (err) {
    console.log(err)
  }
}

function* updateUserPassword({
  payload,
}: ReturnType<typeof updatePassword>): any {
  try {
    yield call(baseAxios.post, apiEndPoints.users.changePasword, payload.data)
    toast.success(message.PASSWORD_UPDATE_SUCCESS)
  } catch (err) {
    console.log(err)
  }
}

function* fetchUsers(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.users.users)
    yield put(actions.setUsers(response))
  } catch (err) {
    console.log(err)
  }
}

function* fetchUserById({ payload }: ReturnType<typeof getUserById>): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.users.userById(payload.userId),
    )
    yield put(actions.setUser(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* deleteUserById({ payload }: ReturnType<typeof deleteUser>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.users.userById(payload.id))
    toast.success(message.USER_DELETE_MSG)
  } catch (err) {
    console.log(err)
  }
}

function* createUser({ payload }: ReturnType<typeof addNewUser>): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.users.addUser,
      payload.data,
    )
    if (response.data) {
      yield put(actions.setUser(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* updateUserById({
  payload,
}: ReturnType<typeof adminPasswordUpdate>): any {
  try {
    yield call(baseAxios.put, apiEndPoints.users.updateUser, payload.data)
    yield call(baseAxios.post, apiEndPoints.users.logout)
    toast.success(message.USER_UPDATE_MSG)
    yield call(logOutUser)
  } catch (err) {
    console.log(err)
  }
}

function* updatePasswordAdmin({
  payload,
}: ReturnType<typeof adminPasswordUpdate>): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.users.adminUpdateUserPassword,
      payload.data,
    )
    toast.success(message.USER_UPDATE_MSG)
    payload.navigate('/')
  } catch (err) {
    console.log(err)
  }
}

function* createUserAdmin({
  payload,
}: ReturnType<typeof adminCreateUser>): any {
  try {
    yield call(baseAxios.post, apiEndPoints.users.adminUser, payload.data)
    toast.success(message.USER_REGISTER_MSG)
  } catch (err) {
    console.log(err)
  }
}

function* deleteUserAdmin({
  payload,
}: ReturnType<typeof adminDeleteUser>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.users.adminUser, payload.data)
    toast.success(message.USER_DELETE_MSG)
  } catch (err) {
    console.log(err)
  }
}

function* updateUserAdmin({
  payload,
}: ReturnType<typeof adminUpdateUser>): any {
  try {
    yield call(baseAxios.delete, apiEndPoints.users.adminUser, payload.data)
    toast.success(message.USER_DELETE_MSG)
  } catch (err) {
    console.log(err)
  }
}

export function* usersSaga(): any {
  yield all([yield takeLatest(loginUser, signInUser)])
  yield all([yield takeLatest(getUsersList, fetchUsers)])
  yield all([yield takeLatest(addNewUser, createUser)])
  yield all([yield takeLatest(getUserById, fetchUserById)])
  yield all([yield takeLatest(deleteUser, deleteUserById)])
  yield all([yield takeLatest(updateUser, updateUserById)])
  yield all([yield takeLatest(logoutUser, signOutUser)])
  yield all([yield takeLatest(resetUser, forgotUserPassword)])
  yield all([yield takeLatest(resetVerify, forgotUserPasswordVerify)])
  yield all([yield takeLatest(updatePassword, updateUserPassword)])
  yield all([yield takeLatest(adminPasswordUpdate, updatePasswordAdmin)])
  yield all([yield takeLatest(adminCreateUser, createUserAdmin)])
  yield all([yield takeLatest(adminDeleteUser, deleteUserAdmin)])
  yield all([yield takeLatest(adminUpdateUser, updateUserAdmin)])
}
