/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomHeader from 'components/CustomHeader'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { artistSelector } from 'store/artistManagement/selector'
import { getArtists } from 'store/artistManagement/slice'
import { getPermission } from 'utils/helpers/systemHelpers'
import { PERMISSIONS } from 'model/auth'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'

const createTenantSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
})

const Artists = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const tenantCloseRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const artist = useSelector(artistSelector)
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const [modalImage, setModalImage]: any = useState(null)
  const artistSubmitButtonRef = useRef<HTMLButtonElement>(null)
  const [loading, setLoading] = useState(false)
  const formikRef = useRef<any>(null)
  useEffect(() => {
    setPageLoading(true)
    dispatch(getArtists({}))
    setTimeout(() => setPageLoading(false), 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getArtists])

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Profile', field: 'profile', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Description', field: 'description', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  let artistData = artist.artists

  const artistsData = useMemo(() => {
    let computedArtists: any = artistData

    if (search) {
      computedArtists = computedArtists.filter(
        (user: { name: string | string[] }) => user.name.includes(search),
      )
    }

    setTotalItems(computedArtists.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedArtists.slice(firstPageIndex, lastPageIndex)
  }, [artistData, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    description: '',
  })

  const setSelectedTenant = async (value: any) => {
    await clearModal()
    setModalOpenType('edit')
    initialValues.name = value.name
    initialValues.description = value.description
    setModalImage(value.profileImageUrl)
    setSelectedTenantId(value._id)
  }

  const handleSubmit = async (
    value: { name: string; description: string },
    resetForm: any,
  ) => {
    if (!artistSubmitButtonRef.current) {
      return
    }
    if (selectedFileDataURI) {
      //Disable button
      artistSubmitButtonRef.current?.setAttribute('disabled', 'true')
      // Activate indicator
      setLoading(true)
      const artistPostData: any = {
        name: value.name,
        description: value.description,
        profileImageUrl: selectedFileDataURI,
      }
      await baseAxios
        .post(apiEndPoints.cms.artists, artistPostData)
        .then(() => {
          dispatch(getArtists({}))
          //Disable button
          artistSubmitButtonRef.current?.removeAttribute('disabled')
          // Activate indicator
          setLoading(false)
          artistData = artist.artists
          tenantCloseRef.current?.click()
          resetForm()
        })
        .catch((err: any) => {
          artistSubmitButtonRef.current?.removeAttribute('disabled')
          // Activate indicator
          setLoading(false)
        })
    } else {
      toast.error('Please Select Profile Image')
    }
  }

  const handleUpdate = async (
    value: { name: string; description: string },
    artistId: string,
    resetForm: any,
  ) => {
    if (!artistSubmitButtonRef.current) {
      return
    }
    //Disable button
    artistSubmitButtonRef.current?.removeAttribute('disabled')
    // Activate indicator
    setLoading(true)

    const artistPutData: any = {
      name: value.name,
      description: value.description,
      profileImageUrl:
        selectedFileDataURI != null ? selectedFileDataURI : modalImage,
    }
    baseAxios
      .put(apiEndPoints.cms.artistsWithId(artistId), artistPutData)
      .then(async () => {
        dispatch(getArtists({}))
        artistData = artist.artists
        tenantCloseRef.current?.click()
        const data = await baseAxios.get(apiEndPoints.cms.artists)
        //Disable button
        artistSubmitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
        artistData = data
        tenantCloseRef.current?.click()
        resetForm()
      })
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios.delete(apiEndPoints.cms.artistsWithId(value)).then(resp => {
            dispatch(getArtists({}))
            artistData = artist.artists
          }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const openPicker = () => {
    inputFileSelect.current?.click()
  }

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const clearModal = async () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    initialValues.name = ''
    initialValues.description = ''
    setModalImage(null)
    setSelectedTenantId('')
    setModalOpenType('add')
    // }
    clearFieldOnClose()
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    setSelectedFile(null)
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Artist Lists</h4>
                  </div>
                  {getPermission(PERMISSIONS.CREATE_ARTIST) && (
                    <div className="sign-info">
                      <a
                        data-toggle="modal"
                        data-target="#ModalCenter"
                        className="btn btn-primary"
                        onClick={() => clearModal()}
                      >
                        Add Artist
                      </a>
                    </div>
                  )}
                </div>
                <div className="iq-card-body">
                  <div className="table-view">
                    <TableLength
                      handleLengthChange={(v: any) => setPageSize(parseInt(v))}
                      handleSearchValue={(searchVal: any) =>
                        setSearch(searchVal)
                      }
                    />
                    {pageLoading && (
                      <div
                        className="container d-flex justify-content-center align-items-center"
                        style={{ height: '200px' }}
                      >
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#e20e02"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                    )}
                    {!pageLoading &&
                      artist.artists &&
                      artist.artists.length > 0 && (
                        <table
                          className="data-tables table movie_table"
                          style={{ width: '100%' }}
                        >
                          <TableHeader
                            headers={headers}
                            onSorting={(field: any, order: any) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {artistsData.map((artist: any, index: number) => (
                              <tr key={`artist-${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                  <img
                                    src={artist.profileImageUrl}
                                    width={50}
                                    height={50}
                                  />
                                </td>
                                <td>{artist.name}</td>
                                <td>{artist.description}</td>
                                <td>
                                  <div className="flex align-items-center list-user-action">
                                    {getPermission(
                                      PERMISSIONS.UPDATE_ARTIST,
                                    ) && (
                                      <a
                                        className="iq-bg-success"
                                        data-placement="top"
                                        title=""
                                        data-toggle="modal"
                                        data-target="#ModalCenter"
                                        data-original-title="Edit"
                                        onClick={() =>
                                          setSelectedTenant(artist)
                                        }
                                      >
                                        <i className="ri-pencil-line" />
                                      </a>
                                    )}
                                    {getPermission(
                                      PERMISSIONS.DELETE_ARTIST,
                                    ) && (
                                      <a
                                        className="iq-bg-primary"
                                        data-toggle="tooltip"
                                        data-original-title="Delete"
                                        onClick={() => handleDelete(artist._id)}
                                      >
                                        <i className="ri-delete-bin-line" />
                                      </a>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    {!pageLoading && artist.artists && artist.artists == 0 && (
                      <NoData />
                    )}
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={pageSize}
                      onPageChange={(page: React.SetStateAction<number>) =>
                        setCurrentPage(page)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={createTenantSchema}
            onSubmit={(
              values: { name: string; description: string },
              { resetForm }: any,
            ) => {
              modalOpenType == 'add'
                ? handleSubmit(values, resetForm)
                : handleUpdate(values, selectedTenantId, resetForm)
            }}
            innerRef={formikRef}
          >
            {(formik: any) => {
              return (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add' ? 'Add Artist' : 'Edit Artist'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={tenantCloseRef}
                      onClick={() => {
                        setModalOpenType('add')
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control  mb-0"
                                placeholder="Enter Artist Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                type="text"
                                name="description"
                                id="email"
                                className="form-control  mb-0"
                                placeholder="Enter Description"
                              />
                              <ErrorMessage
                                name="description"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            {modalOpenType == 'edit' && (
                              <div className="form-group col-md-12">
                                <img
                                  src={modalImage}
                                  width={100}
                                  height={100}
                                />
                              </div>
                            )}
                            <div className="col-md-12  form_gallery form-group">
                              <label
                                onClick={() => openPicker()}
                                htmlFor="form_gallery-upload"
                              >
                                {selectedFile != null
                                  ? selectedFile.name
                                  : modalOpenType == 'edit'
                                  ? 'New Profile Image'
                                  : 'Profile Image'}
                              </label>
                              <input
                                type="file"
                                ref={inputFileSelect}
                                name="videoFile"
                                onChange={e => handleFileSelect(e)}
                                accept=".jpg, .jpeg, .png, .webp"
                              />
                              <ErrorMessage
                                name="videoFile"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            ref={artistSubmitButtonRef}
                          >
                            {!loading && (
                              <span className="indicator-label">
                                {modalOpenType == 'add' ? 'Submit' : 'Update'}
                              </span>
                            )}
                            {loading && (
                              <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default Artists
