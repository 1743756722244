import React from 'react'
import { usePagination, DOTS } from './usePagination'
//import './styles.scss';
const Pagination = (props: {
  onPageChange: any
  totalCount: any
  siblingCount?: 1 | undefined
  currentPage: any
  pageSize: any
}) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (
    currentPage === 0 ||
    (paginationRange != null &&
      paginationRange != undefined &&
      paginationRange.length < 2)
  ) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  const lastPage =
    paginationRange != null && paginationRange != undefined
      ? paginationRange[paginationRange.length - 1]
      : 0
  return (
    <div className="row">
      <div className="col-sm-12 col-md-5">
        <div
          className="dataTables_info"
          id="new-cons_info"
          role="status"
          aria-live="polite"
        >
          Showing {(currentPage - 1) * pageSize + 1} to{' '}
          {currentPage * pageSize < totalCount
            ? currentPage * pageSize
            : totalCount}{' '}
          of {totalCount} entries
        </div>
      </div>
      <div className="col-sm-12 col-md-7 d-flex justify-content-end align-items-center">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="new-cons_paginate"
        >
          <ul className="pagination">
            <li
              className={
                currentPage == 1
                  ? 'paginate_button page-item previous disabled'
                  : 'paginate_button page-item previous'
              }
              id="new-cons_previous"
            >
              <a onClick={onPrevious} className="page-link">
                Previous
              </a>
            </li>
            {paginationRange != null &&
              paginationRange != undefined &&
              paginationRange.map((pageNumber: any) => {
                if (pageNumber === DOTS) {
                  return (
                    <li
                      key={'pg-No-0' + pageNumber}
                      className="paginate_button page-item"
                    >
                      <a
                        onClick={e => e.preventDefault()}
                        className="page-link"
                      >
                        &#8230;
                      </a>
                    </li>
                  )
                }

                return (
                  <li
                    key={pageNumber}
                    className={
                      pageNumber === currentPage
                        ? 'paginate_button page-item active'
                        : 'paginate_button page-item'
                    }
                  >
                    <a
                      onClick={() => onPageChange(pageNumber)}
                      aria-controls="new-cons"
                      className="page-link"
                    >
                      {pageNumber}
                    </a>
                  </li>
                )
              })}
            <li
              className={
                currentPage == lastPage
                  ? 'paginate_button page-item next disabled'
                  : 'paginate_button page-item next'
              }
              id="new-cons_next"
            >
              <a onClick={onNext} className="page-link">
                Next
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Pagination
