import CustomHeader from 'components/CustomHeader'
import React, { useState } from 'react'
import { userEleven } from 'utils/imageUtils'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import { updatePassword, updateUser } from 'store/userManagement/slice'
import { getLoggedInUser } from 'utils/helpers/authUtils'

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string()
    .required('Password is required')
    .min(4, 'Password is too short - should be 4 chars min'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Password & Confirm Password must match',
    ),
})

const updateUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Firstname is required'),
  lastName: Yup.string().required('Lastname is required'),
  gender: Yup.string().required('Gender is required'),
})

const updateUserContactSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string().required('email is required'),
})

const EditProfile = () => {
  const dispatch = useDispatch()
  const user = getLoggedInUser()

  const [initialValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const [initialValuesUpdateUser] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    gender: user.gender,
  })

  const [initialValuesUpdateContact] = useState({
    phoneNumber: user.phoneNumber,
    email: user.email,
  })

  const handleSubmitChangePassword = (
    values: {
      oldPassword: string
      newPassword: string
      confirmPassword: string
    },
    actions: any,
  ) => {
    dispatch(updatePassword({ data: values }))
    actions.resetForm({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
  }

  const handleSubmitUserUpdate = (values: {
    firstName: string
    lastName: string
    gender: string
  }) => {
    const updateData = {
      email: user.email,
      tenant: user.tenant,
      role: user.role._id,
      phoneNumber: user.phoneNumber,
      gender: values.gender,
      firstName: values.firstName,
      lastName: values.lastName,
    }
    dispatch(updateUser({ data: updateData }))
  }

  const handleSubmitUserContactUpdate = (values: {
    phoneNumber: string
    email: string
  }) => {
    const updateData = {
      email: user.email,
      tenant: user.tenant,
      role: user.role._id,
      phoneNumber: values.phoneNumber,
      gender: user.gender,
      firstName: user.firstName,
      lastName: user.lastName,
    }
    dispatch(updateUser({ data: updateData }))
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="iq-card">
                <div className="iq-card-body p-0">
                  <div className="iq-edit-list">
                    <ul className="iq-edit-profile d-flex nav nav-pills">
                      <li className="col-md-3 p-0">
                        <a
                          className="nav-link active"
                          data-toggle="pill"
                          href="#personal-information"
                        >
                          Personal Information
                        </a>
                      </li>
                      <li className="col-md-3 p-0">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#chang-pwd"
                        >
                          Change Password
                        </a>
                      </li>
                      <li className="col-md-3 p-0">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#emailandsms"
                        >
                          Email and SMS
                        </a>
                      </li>
                      <li className="col-md-3 p-0">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#manage-contact"
                        >
                          Manage Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="iq-edit-list-data">
                <div className="tab-content">
                  <div
                    className="tab-pane fade active show"
                    id="personal-information"
                    role="tabpanel"
                  >
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Personal Information</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <Formik
                          initialValues={initialValuesUpdateUser}
                          validationSchema={updateUserSchema}
                          onSubmit={(values: {
                            firstName: string
                            lastName: string
                            gender: string
                          }) => {
                            handleSubmitUserUpdate(values)
                          }}
                        >
                          {() => {
                            return (
                              <Form>
                                <div className="form-group row align-items-center">
                                  <div className="col-md-12">
                                    <div className="profile-img-edit">
                                      <img
                                        className="profile-pic"
                                        src={userEleven}
                                        alt="profile-pic"
                                      />
                                      <div className="p-image">
                                        <i className="ri-pencil-line upload-button" />
                                        <input
                                          className="file-upload"
                                          type="file"
                                          accept="image/*"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className=" row align-items-center">
                                  <div className="form-group col-sm-6">
                                    <label htmlFor="fname">First Name:</label>
                                    <Field
                                      type="text"
                                      name="firstName"
                                      id="firstName"
                                      className="form-control  mb-0"
                                      placeholder="Enter FirstName"
                                    />
                                    <ErrorMessage
                                      name="firstName"
                                      component="span"
                                      className="text-primary"
                                    />
                                  </div>
                                  <div className="form-group col-sm-6">
                                    <label htmlFor="lname">Last Name:</label>
                                    <Field
                                      type="text"
                                      name="lastName"
                                      id="lastName"
                                      className="form-control  mb-0"
                                      placeholder="Enter LastName"
                                    />
                                    <ErrorMessage
                                      name="lastName"
                                      component="span"
                                      className="text-primary"
                                    />
                                  </div>
                                  <div
                                    className="form-group col-sm-6"
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                  >
                                    <label className="d-block">Gender:</label>
                                    <div className="custom-control custom-radio custom-control-inline">
                                      <Field
                                        type="radio"
                                        name="gender"
                                        value="Male"
                                        id="customRadio6"
                                        className="custom-control-input"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customRadio6"
                                      >
                                        {' '}
                                        Male{' '}
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                      <Field
                                        type="radio"
                                        name="gender"
                                        className="custom-control-input"
                                        value="Female"
                                        id="customRadio7"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customRadio7"
                                      >
                                        {' '}
                                        Female{' '}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary mr-2"
                                >
                                  Submit
                                </button>
                                {/* <button type="reset" className="btn iq-bg-black">
                                    Cancel
                                  </button> */}
                              </Form>
                            )
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="chang-pwd" role="tabpanel">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Change Password</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={changePasswordSchema}
                          onSubmit={(
                            values: {
                              oldPassword: string
                              newPassword: string
                              confirmPassword: string
                            },
                            actions: any,
                          ) => {
                            handleSubmitChangePassword(values, actions)
                          }}
                        >
                          {() => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="cpass">Old Password:</label>
                                  <Field
                                    type="password"
                                    name="oldPassword"
                                    id="oldPassword"
                                    className="form-control  mb-0"
                                    placeholder="Enter Password"
                                  />
                                  <ErrorMessage
                                    name="oldPassword"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="npass">New Password:</label>
                                  <Field
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    className="form-control  mb-0"
                                    placeholder="Enter Password"
                                  />
                                  <ErrorMessage
                                    name="newPassword"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="vpass">
                                    Confirm Password:
                                  </label>
                                  <Field
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    className="form-control  mb-0"
                                    placeholder="Enter Password"
                                  />
                                  <ErrorMessage
                                    name="confirmPassword"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary mr-2"
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  className="btn iq-bg-black"
                                >
                                  Cancel
                                </button>
                              </Form>
                            )
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="emailandsms"
                    role="tabpanel"
                  >
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Email and SMS</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <form>
                          <div className="form-group row align-items-center">
                            <label
                              className="col-8 col-md-3"
                              htmlFor="emailnotification"
                            >
                              Email Notification:
                            </label>
                            <div className="col-4 col-md-9 custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="emailnotification"
                                disabled
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="emailnotification"
                              />
                            </div>
                          </div>
                          {/* <div className="form-group row align-items-center">
                            <label className="col-md-3" htmlFor="npass">
                              When To Email
                            </label>
                            <div className="col-md-9">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="email01"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="email01"
                                >
                                  You have new notifications.
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="email02"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="email02"
                                >
                                  You're sent a direct message
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="email03"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="email03"
                                >
                                  Someone adds you as a connection
                                </label>
                              </div>
                            </div>
                          </div> */}
                          {/* <button
                            type="submit"
                            className="btn btn-primary mr-2"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            className="btn iq-bg-black"
                            disabled
                          >
                            Cancel
                          </button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="manage-contact"
                    role="tabpanel"
                  >
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Manage Contact</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <Formik
                          initialValues={initialValuesUpdateContact}
                          validationSchema={updateUserContactSchema}
                          onSubmit={(values: {
                            phoneNumber: string
                            email: string
                          }) => {
                            handleSubmitUserContactUpdate(values)
                          }}
                        >
                          {() => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="cno">Contact Number:</label>
                                  <Field
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className="form-control  mb-0"
                                    placeholder="Enter PhoneNumber"
                                  />
                                  <ErrorMessage
                                    name="phoneNumber"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="email">Email:</label>
                                  <Field
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-control  mb-0"
                                    placeholder="Enter Email"
                                    disabled
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary mr-2"
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  className="btn iq-bg-black"
                                >
                                  Cancel
                                </button>
                              </Form>
                            )
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default EditProfile
