import { bannerTypes } from 'utils/enum'
import { getLoggedInUser } from './authUtils'

export const handleFileSelect = (e: any) => {
  const file: any = e.target.files[0]
  //   setSelectedFile(file)
  const reader = new FileReader()
  reader.onloadend = () => {
    if (
      reader.result != null &&
      reader.result != undefined &&
      reader.result != ''
    ) {
      // initialValues.movieThumbnail = reader.result!.toString()
      //   setSelectedFileDataURI(reader.result!.toString())
      return reader.result!.toString()
    }
  }
  reader.readAsDataURL(file)
}

export const getStatus = (status: string) => {
  switch (status) {
    case 'draft':
      return 'In Review'
    case 'approved':
      return 'Approved'
    case 'publish':
      return 'Published'
    default:
      return 'Unknown'
  }
}

export const compareValues = (key: any, order = 'asc') => {
  return function innerSort(
    a: { [x: string]: any; hasOwnProperty: (arg0: any) => any },
    b: { [x: string]: any; hasOwnProperty: (arg0: any) => any },
  ) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}

export const getPermission = (data: string) => {
  const isAuthenticated = getLoggedInUser()
  const permissions = [data]
  const userHasRequiredPermisssion = isAuthenticated.roleId.permission.some(
    (data: any) => permissions.includes(data.code),
  )
  return userHasRequiredPermisssion
}

export const getIsRoot = () => {
  const isAuthenticated = getLoggedInUser()
  const isRoot = isAuthenticated.tenant.isRoot
  return isRoot ? isRoot : false
}

export const getTenantId = () => {
  const isAuthenticated = getLoggedInUser()
  const tenant = isAuthenticated.tenant._id
  return tenant
}

export const matchYoutubeUrl = (url: string) => {
  var p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  if (url.match(p)) {
    return true
  }
  return false
}

export const formatWebCategories = (categories: any) => {
  const formattedCategories = categories?.map((category: any) => {
    return { label: category.name, value: category._id }
  })

  formattedCategories.unshift({ label: 'All Banners', value: 'no_select' })

  return formattedCategories
}

export const formattedBannerTypes = (bannerType: bannerTypes) => {
  switch (bannerType) {
    case bannerTypes.Movie:
      return 'Movie'
    case bannerTypes.WebSeries:
      return 'Web Series'
    case bannerTypes.TalkShows:
      return 'Talk Shows'
    case bannerTypes.Interviews:
      return 'Interviews'
    case bannerTypes.external:
      return 'External'
    case bannerTypes.Music:
      return 'Music'
    case bannerTypes.Podcast:
      return 'Podcast'
    case bannerTypes.Magazine:
      return 'Magazine'
    default:
      return ''
  }
}

export const formatedBannerTypes = () => {
  return [
    {
      label: 'Movie',
      value: bannerTypes.Movie,
    },
    {
      label: 'WebSeries',
      value: bannerTypes.WebSeries,
    },
    {
      label: 'TalkShows',
      value: bannerTypes.TalkShows,
    },
    {
      label: 'Interviews',
      value: bannerTypes.Interviews,
    },
    {
      label: 'external',
      value: bannerTypes.external,
    },
    {
      label: 'Music',
      value: bannerTypes.Music,
    },
    {
      label: 'Podcast',
      value: bannerTypes.Podcast,
    },
    {
      label: 'Magazine',
      value: bannerTypes.Magazine,
    },
  ]
}

export const convertToK = (value: number) => {
  if (value < 0) {
    return 'NA'
  }

  if (value < 1000) {
    return value
  }
  let absNumber = Math.abs(value)
  const abbreviations = ['', 'K', 'M', 'B', 'T']
  let index = 0

  while (absNumber >= 1000 && index < abbreviations.length - 1) {
    absNumber /= 1000
    index++
  }

  return (value < 0 ? '-' : '') + absNumber.toFixed(1) + abbreviations[index]
}
