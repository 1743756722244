import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Host } from './types'

// eslint-disable-next-line
const getHosts = createAction<any>('cms/getHosts')
const deleteHosts = createAction<any>('cms/deleteHosts')
const updateHosts = createAction<any>('cms/updateHosts')
const createHosts = createAction<any>('cms/createHosts')
const searchHosts = createAction<any>('cms/searchHosts')

export const initialState: Host = {
  hosts: [],
  hostsLoading: false,
  hostsSearchData: [],
}

const HostSlice = createSlice({
  name: 'Host',
  initialState: initialState,
  reducers: {
    setHosts(state, action: PayloadAction<any>) {
      if (action?.payload?.skip > 0) {
        state.hosts = state.hosts.concat(action.payload?.data)
      } else {
        state.hosts = action.payload?.data
      }
    },
    setCreateHost(state, action: PayloadAction<any>) {
      state.hosts = [...state.hosts, action.payload]
    },
    setUpdateHost(state, action: PayloadAction<any>) {
      state.hosts[
        state.hosts.findIndex((x: any) => x._id == action.payload._id)
      ] = action.payload
    },
    setDeleteHost(state, action: PayloadAction<any>) {
      state.hosts = state.hosts.filter((x: any) => x._id != action.payload)
    },
    setHostLoader(state, action: PayloadAction<any>) {
      state.hostsLoading = action.payload
    },
    setSearchHosts(state, action: PayloadAction<any>) {
      state.hostsSearchData = action.payload
    },
  },
})

export { getHosts, deleteHosts, updateHosts, createHosts, searchHosts }

export const { actions, reducer, name: sliceKey } = HostSlice
