import { PERMISSIONS } from 'model/auth'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { menuSelector } from 'store/menuManagement/selectors'
import { setSubMenu } from 'store/menuManagement/slice'
import { getPermission, getIsRoot } from 'utils/helpers/systemHelpers'
import Logo from './../../assets/images/logo.png'

const Sidebar = () => {
  // const [nav, setNav] = useState(0)
  const dispatch = useDispatch()
  const navStore = useSelector(menuSelector)
  // const nav = navStore.selectedMenu
  const subMenu = navStore.selectedSubMenu

  const clear = () => {
    dispatch(setSubMenu({ selectedSubMenu: { subNavId: 0, navId: 0 } }))
  }

  const setSubMenuCollapse = (subNavId: any, navId: any) => {
    dispatch(
      setSubMenu({ selectedSubMenu: { subNavId: subNavId, navId: navId } }),
    )
  }
  return (
    <div className="iq-sidebar">
      <div className="iq-sidebar-logo d-flex justify-content-between">
        <Link to="/dashboard" className="header-logo">
          <img src={Logo} className="img-fluid rounded-normal" alt="logo" />
          <div className="logo-title">
            <span className="text-primary text-uppercase">Swara Admin</span>
          </div>
        </Link>
        <div className="iq-menu-bt-sidebar">
          <div className="iq-menu-bt align-self-center">
            <div
              className="wrapper-menu"
              onClick={() => document.body.classList.toggle('sidebar-main')}
            >
              <div className="main-circle">
                <i className="las la-bars" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            <li className={subMenu.navId == 0 ? 'active' : ''}>
              <Link
                to="/dashboard"
                className="iq-waves-effect"
                onClick={() => {
                  clear()
                  setSubMenuCollapse(0, 0)
                }}
              >
                <i className="las la-home iq-arrow-left" />
                {/* <img className="las la-home" src={dashboardIcon} /> */}
                <span>Dashboard</span>
              </Link>
            </li>
            {/* User Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_USERS) && (
              <li className={subMenu.navId == 1 ? 'active' : ''}>
                <Link
                  to="/users"
                  className="iq-waves-effect"
                  onClick={() => {
                    clear()
                    setSubMenuCollapse(0, 1)
                  }}
                >
                  <i className="las la-user-friends" />
                  <span>User</span>
                </Link>
              </li>
            )}

            {/* Video Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_SINGLE_VIDEOS) && (
              <li className={subMenu.navId == 2 ? 'active' : ''}>
                <a
                  className={
                    subMenu.navId == 2
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 2 ? 'true' : 'false'}
                  onClick={() => setSubMenuCollapse(0, 2)}
                >
                  <i className="las la-video"></i>
                  <span>Video</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="movie"
                  className={
                    subMenu.navId == 2
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                  // data-parent="#iq-sidebar-toggle"
                >
                  <li
                    className={
                      subMenu.navId == 2 && subMenu.subNavId === 1
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/multi-video"
                      className=""
                      onClick={() => setSubMenuCollapse(1, 2)}
                    >
                      <i className="la la-film" />
                      Multi Video List
                    </Link>
                  </li>
                  <li
                    className={
                      subMenu.navId == 2 && subMenu.subNavId === 2
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/single-video"
                      onClick={() => setSubMenuCollapse(2, 2)}
                    >
                      <i className="la la-film" />
                      Single Videos
                    </Link>
                  </li>
                  <li
                    className={
                      subMenu.navId == 2 && subMenu.subNavId === 3
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/video-playlist"
                      onClick={() => setSubMenuCollapse(3, 2)}
                    >
                      <i className="la la-film" />
                      Video Playlist List
                    </Link>
                  </li>
                  {getPermission(PERMISSIONS.HANDLE_VIDEO_TRANSCODING) && (
                    <li
                      className={
                        subMenu.navId == 2 && subMenu.subNavId === 4
                          ? 'active'
                          : ''
                      }
                    >
                      <Link
                        to="/transcoding"
                        onClick={() => setSubMenuCollapse(4, 2)}
                      >
                        <i className="la la-file-video-o" />
                        Transcoding
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {/* Audio Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_ALBUM) && (
              <li className={subMenu.navId == 3 ? 'active' : ''}>
                <a
                  className={
                    subMenu.navId == 3
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 3 ? 'true' : 'false'}
                  onClick={() => setSubMenuCollapse(0, 3)}
                >
                  <i className="la la-music" />
                  <span>Audio</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="podcast"
                  className={
                    subMenu.navId == 3
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                  // data-parent="#iq-sidebar-toggle"
                >
                  <li
                    className={
                      subMenu.navId == 3 && subMenu.subNavId === 1
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/single-audio"
                      onClick={() => setSubMenuCollapse(1, 3)}
                    >
                      <i className="la la-play-circle" />
                      Single Audio List
                    </Link>
                  </li>
                  <li
                    className={
                      subMenu.navId == 3 && subMenu.subNavId === 2
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/multi-audio"
                      onClick={() => setSubMenuCollapse(2, 3)}
                    >
                      <i className="la la-play-circle" />
                      Multi Audio List
                    </Link>
                  </li>
                  <li
                    className={
                      subMenu.navId == 3 && subMenu.subNavId === 3
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/audio-transcoding"
                      onClick={() => setSubMenuCollapse(3, 3)}
                    >
                      <i className="la la-file-audio-o" />
                      Transcoding
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {/* Magazine Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_MAGAZINES) && (
              <li className={subMenu.navId == 4 ? 'active' : ''}>
                <a
                  className={
                    subMenu.navId == 4
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 4 ? 'true' : 'false'}
                  onClick={() => setSubMenuCollapse(0, 4)}
                >
                  <i className="las la-file-pdf"></i>
                  <span>Magazine</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="magazine"
                  // data-parent="#iq-sidebar-toggle"
                  className={
                    subMenu.navId == 4
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                >
                  {getPermission(PERMISSIONS.VIEW_ALL_AUTHORS) && (
                    <li
                      className={
                        subMenu.navId == 4 && subMenu.subNavId === 1
                          ? 'active'
                          : ''
                      }
                    >
                      <Link
                        to="/author"
                        onClick={() => setSubMenuCollapse(1, 4)}
                      >
                        <i className="la la-group" />
                        Author List
                      </Link>
                    </li>
                  )}
                  <li
                    className={
                      subMenu.navId == 4 && subMenu.subNavId === 2
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/magazine"
                      onClick={() => setSubMenuCollapse(2, 4)}
                    >
                      <i className="las la-file-pdf" />
                      Magazine List
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {/* Artist Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_ARTIST) && (
              <li className={subMenu.navId == 5 ? 'active' : ''}>
                <a
                  // href="#artist"
                  className={
                    subMenu.navId == 5
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 5 ? 'true' : 'false'}
                  onClick={() => setSubMenuCollapse(0, 5)}
                >
                  <i className="la la-headphones" />
                  <span>Artist</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="artist"
                  // className="iq-submenu collapse"
                  className={
                    subMenu.navId == 5
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                  data-parent="#iq-sidebar-toggle"
                >
                  <li
                    className={
                      subMenu.navId == 5 && subMenu.subNavId === 1
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/artists"
                      onClick={() => setSubMenuCollapse(1, 5)}
                    >
                      <i className="la la-group" />
                      Artist List
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {/* Web Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_BANNERS) && (
              <li className={subMenu.navId == 6 ? 'active' : ''}>
                <a
                  className={
                    subMenu.navId == 6
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 6 ? 'true' : 'false'}
                  onClick={() =>
                    subMenu.navId == 6
                      ? setSubMenuCollapse(0, 0)
                      : setSubMenuCollapse(0, 6)
                  }
                >
                  <i className="la la-desktop" />
                  <span>Web Management</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="settings"
                  // className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                  className={
                    subMenu.navId == 6
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                >
                  {/* <li
                  className={
                    subMenu.navId == 6 && subMenu.subNavId === 1 ? 'active' : ''
                  }
                >
                  <Link
                    to="/settings/categories"
                    onClick={() => setSubMenuCollapse(1, 6)}
                  >
                    <i className="las la-stream"></i>
                    Categories
                  </Link>
                </li> */}
                  <li
                    className={
                      subMenu.navId == 6 && subMenu.subNavId === 2
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/banners"
                      onClick={() => setSubMenuCollapse(2, 6)}
                    >
                      <i className="la la-image"></i>
                      Banners
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {/* Radio Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_RADIO_SHOWS) && (
              <li className={subMenu.navId == 7 ? 'active' : ''}>
                <a
                  className={
                    subMenu.navId == 7
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 7 ? 'true' : 'false'}
                  onClick={() =>
                    subMenu.navId == 7
                      ? setSubMenuCollapse(0, 0)
                      : setSubMenuCollapse(0, 7)
                  }
                >
                  <i className="la la-rss" />
                  <span>Radio</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="settings"
                  // className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                  className={
                    subMenu.navId == 7
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                >
                  <li
                    className={
                      subMenu.navId == 7 && subMenu.subNavId === 1
                        ? 'active'
                        : ''
                    }
                  >
                    <Link to="/hosts" onClick={() => setSubMenuCollapse(1, 7)}>
                      <i className="la la-headphones"></i>
                      Hosts
                    </Link>
                  </li>
                  <li
                    className={
                      subMenu.navId == 7 && subMenu.subNavId === 2
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/radio-shows"
                      onClick={() => setSubMenuCollapse(2, 7)}
                    >
                      <i className="la la-twitch"></i>
                      Shows
                    </Link>
                  </li>

                  <li
                    className={
                      subMenu.navId == 7 && subMenu.subNavId === 2
                        ? 'active'
                        : ''
                    }
                  ></li>
                </ul>
              </li>
            )}

            {/* Sponsors Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_SPONSORS) && (
              <li className={subMenu.navId == 8 ? 'active' : ''}>
                <a
                  className={
                    subMenu.navId == 7
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 8 ? 'true' : 'false'}
                  onClick={() =>
                    subMenu.navId == 7
                      ? setSubMenuCollapse(0, 0)
                      : setSubMenuCollapse(0, 8)
                  }
                >
                  <i className="la la-ioxhost" />
                  <span>Sponsors</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="settings"
                  // className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                  className={
                    subMenu.navId == 8
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                >
                  <li
                    className={
                      subMenu.navId == 8 && subMenu.subNavId === 1
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/sponsors"
                      onClick={() => setSubMenuCollapse(1, 8)}
                    >
                      <i className="la la-money"></i>
                      Sponsors
                    </Link>
                  </li>
                  {/* <li
                  className={
                    subMenu.navId == 7 && subMenu.subNavId === 2 ? 'active' : ''
                  }
                >
                  <Link
                    to="/radio-shows"
                    onClick={() => setSubMenuCollapse(2, 8)}
                  >
                    <i className="las la-paperclip"></i>
                    Sponsors
                  </Link>
                </li> */}

                  <li
                    className={
                      subMenu.navId == 8 && subMenu.subNavId === 2
                        ? 'active'
                        : ''
                    }
                  ></li>
                </ul>
              </li>
            )}

            {getIsRoot() &&
              (getPermission(PERMISSIONS.VIEW_ALL_ROLES) ||
                getPermission(PERMISSIONS.VIEW_ALL_TENANTS) ||
                getPermission(PERMISSIONS.VIEW_ALL_CONTENT_CATEGORIES) ||
                getPermission(PERMISSIONS.VIEW_ALL_GENRES)) && (
                <li className={subMenu.navId == 9 ? 'active' : ''}>
                  <a
                    className={
                      subMenu.navId == 9
                        ? 'iq-waves-effect'
                        : 'iq-waves-effect collapsed'
                    }
                    aria-expanded={subMenu.navId == 9 ? 'true' : 'false'}
                    onClick={() => setSubMenuCollapse(0, 9)}
                  >
                    <i className="ri-settings-5-fill" />
                    <span>Settings</span>
                    <i className="ri-arrow-right-s-line iq-arrow-right" />
                  </a>

                  <ul
                    id="settings"
                    // className="iq-submenu collapse"
                    data-parent="#iq-sidebar-toggle"
                    className={
                      subMenu.navId == 9
                        ? 'iq-submenu collapse show'
                        : 'iq-submenu collapse'
                    }
                  >
                    {getPermission(PERMISSIONS.VIEW_ALL_CONTENT_CATEGORIES) && (
                      <li
                        className={
                          subMenu.navId == 9 && subMenu.subNavId === 1
                            ? 'active'
                            : ''
                        }
                      >
                        <Link
                          to="/settings/categories"
                          onClick={() => setSubMenuCollapse(1, 9)}
                        >
                          <i className="la la-asterisk"></i>
                          Content Categories
                        </Link>
                      </li>
                    )}
                    {getPermission(PERMISSIONS.VIEW_ALL_GENRES) && (
                      <li
                        className={
                          subMenu.navId == 9 && subMenu.subNavId === 2
                            ? 'active'
                            : ''
                        }
                      >
                        <Link
                          to="/settings/genres"
                          onClick={() => setSubMenuCollapse(2, 9)}
                        >
                          <i className="las la-paperclip"></i>
                          Genres
                        </Link>
                      </li>
                    )}
                    {getPermission(PERMISSIONS.VIEW_ALL_TENANTS) && (
                      <li
                        className={
                          subMenu.navId == 9 && subMenu.subNavId === 3
                            ? 'active'
                            : ''
                        }
                      >
                        <Link
                          to="/settings/tenant"
                          onClick={() => setSubMenuCollapse(3, 9)}
                        >
                          <i className="las la-user-tag"></i>
                          Tenant
                        </Link>
                      </li>
                    )}
                    {getPermission(PERMISSIONS.VIEW_ROLE) &&
                      getPermission(PERMISSIONS.VIEW_PERMISSION) && (
                        <li
                          className={
                            subMenu.navId == 7 && subMenu.subNavId === 4
                              ? 'active'
                              : ''
                          }
                        >
                          <Link
                            to="/settings/roles"
                            onClick={() => setSubMenuCollapse(4, 7)}
                          >
                            <i className="las la-user-check"></i>
                            Access & IAM
                          </Link>
                        </li>
                      )}
                  </ul>
                </li>
              )}
            {/* Career Management Menus */}
            {getPermission(PERMISSIONS.VIEW_ALL_CAREER_JOBS) && (
              <li className={subMenu.navId == 10 ? 'active' : ''}>
                <a
                  className={
                    subMenu.navId == 10
                      ? 'iq-waves-effect'
                      : 'iq-waves-effect collapsed'
                  }
                  aria-expanded={subMenu.navId == 10 ? 'true' : 'false'}
                  onClick={() =>
                    subMenu.navId == 9
                      ? setSubMenuCollapse(0, 0)
                      : setSubMenuCollapse(0, 10)
                  }
                >
                  <i className="la la-briefcase" />
                  <span>careers</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul
                  id="settings"
                  // className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                  className={
                    subMenu.navId == 10
                      ? 'iq-submenu collapse show'
                      : 'iq-submenu collapse'
                  }
                >
                  <li
                    className={
                      subMenu.navId == 10 && subMenu.subNavId === 1
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to="/career"
                      onClick={() => setSubMenuCollapse(2, 10)}
                    >
                      <i className="la la-inbox"></i>
                      Jobs
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
