import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Artist } from './types'

// eslint-disable-next-line
const getArtists = createAction<any>('cms/getArtists')
const deleteArtists = createAction<any>('cms/deleteArtists')
const updateArtists = createAction<any>('cms/updateArtists')
const createArtists = createAction<any>('cms/createArtists')
const getAuthors = createAction<any>('cms/getAuthors')
const deleteAuthors = createAction<any>('cms/deleteAuthors')

export const initialState: Artist = {
  artists: [],
}

const ArtistSlice = createSlice({
  name: 'Artist',
  initialState: initialState,
  reducers: {
    setArtists(state, action: PayloadAction<any>) {
      state.artists = action.payload
    },
  },
})

export {
  getArtists,
  deleteArtists,
  updateArtists,
  createArtists,
  getAuthors,
  deleteAuthors,
}

export const { actions, reducer, name: sliceKey } = ArtistSlice
