/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CustomHeader from '../../../components/CustomHeader'
import { usePdf } from '@mikecousins/react-pdf'

const ViewMagazine = () => {
  const location = useLocation()
  const [magazineData, setMagazineData] = useState(location.state.data)
  const [authorDetail, setAuthorDetail]: any = useState(null)
  const [page, setPage] = useState(1)
  const canvasRef = useRef(null)

  useEffect(() => {
    if (magazineData != null) {
      baseAxios
        .get(apiEndPoints.cms.authorsWithId(magazineData.authorId))
        .then(response => {
          setAuthorDetail(response)
        })
        .catch((err: any) => {
          console.log(err)
          setAuthorDetail(undefined)
        })
    }
  }, [magazineData])
  const getUrl = (url: any) => {
    const newUrl = url.replace(apiEndPoints.s3.base, '')
    return apiEndPoints.s3.pdf + newUrl
  }

  const { pdfDocument, pdfPage } = usePdf({
    file: getUrl(magazineData.fileUrl),
    page,
    canvasRef,
  })

  return (
    <CustomHeader pageName={'Movie View'}>
      <div id="content-page" className="content-page ">
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-md-12">
              <div className="iq-card" style={{ padding: '0px !important' }}>
                <div className="iq-card-body w-100 p-0">
                  <div className="card bg-dark text-white">
                    <img
                      src={s3Url + magazineData.imageUrl}
                      className="card-img"
                      style={{ height: '450px' }}
                      alt="Movie Poster"
                    />
                    <div className="card-img-overlay d-flex align-items-center justify-content-center">
                      <div
                        className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                        }}
                        data-toggle="modal"
                        data-target="#VideoPlayer"
                      >
                        <i
                          className="fas fa-play fs-1"
                          style={{ fontSize: '1.3rem' }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-4">
              <div className="iq-card" style={{ padding: '0px !important' }}>
                <div className="iq-card-body w-100 p-0">
                  <div className="card bg-dark text-white">
                    <img
                      src={magazineData.imageUrl}
                      className="card-img"
                      alt="Movie Poster"
                    />
                    <div className="card-img-overlay d-flex align-items-center justify-content-center">
                      <div
                        className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                        }}
                        data-toggle="modal"
                        data-target="#VideoPlayer"
                      >
                        <i
                          className="fas fa-eye fs-1"
                          style={{ fontSize: '1.3rem' }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="iq-card">
                <div className="iq-card-body">
                  <h5 className="iq-card-title">{magazineData.name}</h5>
                  {/* <p className="iq-card-text p-0 m-0">
                    <strong>Rating:</strong> 4.5/5{' '}
                    <i className="fas fa-star text-warning"></i>
                  </p> */}
                  <p className="iq-card-text p-0 m-0">
                    <strong>Release Date:</strong>{' '}
                    {moment(magazineData.releaseDate).format('DD/MM/YYYY')}
                  </p>
                  <p className="iq-card-text">{magazineData.description}</p>

                  {authorDetail != null && authorDetail != undefined && (
                    <div>
                      <h6 className="card-subtitle mt-4 mb-2 text-muted">
                        Author
                      </h6>
                      <div className="row" style={{ marginTop: '25px' }}>
                        <div className="col-md-2">
                          <div
                            className="container p-0 rounded-circle bg-primary d-flex justify-content-center align-items-center"
                            style={{ width: '85px', height: '85px' }}
                          >
                            <img
                              src={authorDetail.profileImageUrl}
                              className="img-fluid avatar-80 rounded-circle"
                              alt="Profile picture"
                              style={{ border: '2px solid #333;' }}
                            />
                          </div>
                        </div>
                        <div className="col-md-10">
                          <h6>{authorDetail.name}</h6>
                          <p style={{ fontSize: '13px' }}>
                            {authorDetail.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="VideoPlayer"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content ">
            <div className="container">
              <div className="row">
                <div className="col-md 12 d-flex align-items-center justify-content-center">
                  {!pdfDocument && <span>Loading...</span>}
                  <canvas ref={canvasRef} />
                </div>
                <div className="col-md-12">
                  {Boolean(pdfDocument && pdfDocument.numPages) && (
                    <div className="container d-flex align-items-center justify-content-center mt-3 mb-4">
                      <button
                        className="btn btn-primary"
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                      >
                        <i className="fas fa-duotone fa-arrow-left"></i>
                      </button>
                      <button
                        className="btn btn-primary ml-2"
                        // disabled={page === pdfDocument?.numPages}
                        onClick={() => setPage(page + 1)}
                      >
                        <i className="fas fa-duotone fa-arrow-right"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default ViewMagazine
