import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Transcoding } from './types'

// eslint-disable-next-line
const getTranscodingVideos = createAction<any>('cms/getTranscodingVideos')
const beginTranscoding = createAction<any>('cms/beginTranscoding')
const stopTranscoding = createAction<any>('cms/stopTranscoding')
const searchVideos = createAction<any>('cms/searchAllVideos')

export const initialState: Transcoding = {
  transcodedVideos: [],
  transcodingLoading: false,
  transcodingStartLoading: false,
  videosSearchData: [],
}

const TranscodingSlice = createSlice({
  name: 'Transcoding',
  initialState: initialState,
  reducers: {
    setTranscodingVideos(state, action: PayloadAction<any>) {
      if (action?.payload?.skip > 0) {
        state.transcodedVideos = state.transcodedVideos.concat(
          action.payload?.data,
        )
      } else {
        state.transcodedVideos = action.payload?.data
      }
    },
    setTranscodingVideoSearch(state, action: PayloadAction<any>) {
      state.videosSearchData = action.payload
    },
    setTranscodingStartLoading(state, action: PayloadAction<any>) {
      state.transcodingStartLoading = action.payload
    },
    setTranscodingLoading(state, action: PayloadAction<any>) {
      state.transcodingLoading = action.payload
    },
    setStartTranscodingRowLoader(state, action: PayloadAction<any>) {
      const { transcodingId, loading, transcodingStatus } = action.payload
      const videoIndex = state.videosSearchData.length
        ? state.videosSearchData.findIndex((x: any) => x._id === transcodingId)
        : state.transcodedVideos.findIndex((x: any) => x._id === transcodingId)
      if (state.videosSearchData.length) {
        state.videosSearchData[videoIndex] = {
          ...state.videosSearchData[videoIndex],
          loading,
          transcodingStatus,
        }
      } else {
        state.transcodedVideos[videoIndex] = {
          ...state.transcodedVideos[videoIndex],
          loading,
        }
      }
    },
  },
})

export { getTranscodingVideos, beginTranscoding, stopTranscoding, searchVideos }

export const { actions, reducer, name: sliceKey } = TranscodingSlice
