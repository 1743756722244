/* eslint-disable no-unused-vars */
export enum PERMISSIONS {
  // ALBUMS
  VIEW_ALBUM = 'view_album',
  CREATE_ALBUM = 'create_album',
  UPDATE_ALBUM = 'update_album',
  DELETE_ALBUM = 'delete_album',
  VIEW_ALL_ALBUM = 'view_all_albums',
  APPROVE_ALBUM = 'approve_album',
  PUBLISH_ALBUM = 'publish_album',

  // ARTISTS
  VIEW_ARTIST = 'view_artist',
  CREATE_ARTIST = 'create_artist',
  UPDATE_ARTIST = 'update_artist',
  DELETE_ARTIST = 'delete_artist',
  VIEW_ALL_ARTIST = 'view_all_artists',

  // AUTHORS
  VIEW_AUTHOR = 'view_author',
  CREATE_AUTHOR = 'create_author',
  UPDATE_AUTHOR = 'update_author',
  DELETE_AUTHOR = 'delete_author',
  VIEW_ALL_AUTHORS = 'view_all_authors',

  // SINGLE VIDEO
  VIEW_SINGLE_VIDEO = 'view_single_video',
  CREATE_SINGLE_VIDEO = 'create_single_video',
  UPDATE_SINGLE_VIDEO = 'update_single_video',
  DELETE_SINGLE_VIDEO = 'delete_single_video',
  VIEW_ALL_SINGLE_VIDEOS = 'view_all_single_videos',
  APPROVE_SINGLE_VIDEO = 'approve_single_video',
  PUBLISH_SINGLE_VIDEO = 'publish_single_video',
  HANDLE_VIDEO_TRANSCODING = 'handle_video_transcoding',

  // PLAYLIST VIDEO
  VIEW_PLAYLIST_VIDEO = 'view_playlist_video',
  CREATE_PLAYLIST_VIDEO = 'create_playlist_video',
  UPDATE_PLAYLIST_VIDEO = 'update_playlist_video',
  DELETE_PLAYLIST_VIDEO = 'delete_playlist_video',
  VIEW_ALL_PLAYLIST_VIDEOS = 'view_all_playlist_videos',
  APPROVE_PLAYLIST_VIDEO = 'approve_playlist_video',
  PUBLISH_PLAYLIST_VIDEO = 'publish_playlist_video',

  // MAGAZINES
  VIEW_MAGAZINE = 'view_magazine',
  CREATE_MAGAZINE = 'create_magazine',
  UPDATE_MAGAZINE = 'update_magazine',
  DELETE_MAGAZINE = 'delete_magazine',
  VIEW_ALL_MAGAZINES = 'view_all_magazines',
  APPROVE_MAGAZINES = 'approve_magazines',
  PUBLISH_MAGAZINES = 'publish_magazines',

  // ROLES
  VIEW_ROLE = 'view_role',
  CREATE_ROLE = 'create_role',
  UPDATE_ROLE = 'update_role',
  DELETE_ROLE = 'delete_role',
  VIEW_ALL_ROLES = 'view_all_roles',

  // TENANTS
  VIEW_TENANT = 'view_tenant',
  CREATE_TENANT = 'create_tenant',
  UPDATE_TENANT = 'update_tenant',
  DELETE_TENANT = 'delete_tenant',
  VIEW_ALL_TENANTS = 'view_all_tenants',

  // USERS
  VIEW_USER = 'view_user',
  CREATE_USER = 'create_user',
  UPDATE_USER = 'update_user',
  DELETE_USER = 'delete_user',
  VIEW_ALL_USERS = 'view_all_users',

  // PERMISSIONS
  VIEW_PERMISSION = 'view_permission',
  CREATE_PERMISSION = 'create_permission',
  UPDATE_PERMISSION = 'update_permission',
  DELETE_PERMISSION = 'delete_permission',
  VIEW_ALL_PERMISSIONS = 'view_all_permissions',

  // BANNER
  VIEW_BANNER = 'view_banner',
  CREATE_BANNER = 'create_banner',
  UPDATE_BANNER = 'update_banner',
  DELETE_BANNER = 'delete_banner',
  VIEW_ALL_BANNERS = 'view_all_banners',
  APPROVE_BANNER = 'approve_banner',
  PUBLISH_BANNER = 'publish_banner',

  // HOSTS
  VIEW_HOST = 'view_host',
  CREATE_HOST = 'create_host',
  UPDATE_HOST = 'update_host',
  DELETE_HOST = 'delete_host',
  VIEW_ALL_HOSTS = 'view_all_hosts',

  // RADIO SHOWS
  VIEW_RADIO_SHOW = 'view_radio_show',
  CREATE_RADIO_SHOW = 'create_radio_show',
  UPDATE_RADIO_SHOW = 'update_radio_show',
  DELETE_RADIO_SHOW = 'delete_radio_show',
  VIEW_ALL_RADIO_SHOWS = 'view_all_radio_shows',

  // SPONSORS
  VIEW_SPONSOR = 'view_sponsor',
  CREATE_SPONSOR = 'create_sponsor',
  UPDATE_SPONSOR = 'update_sponsor',
  DELETE_SPONSOR = 'delete_sponsor',
  VIEW_ALL_SPONSORS = 'view_all_sponsors',

  // CONTENT CATEGORIES
  VIEW_CONTENT_CATEGORY = 'view_content_category',
  CREATE_CONTENT_CATEGORY = 'create_content_category',
  UPDATE_CONTENT_CATEGORY = 'update_content_category',
  DELETE_CONTENT_CATEGORY = 'delete_content_category',
  VIEW_ALL_CONTENT_CATEGORIES = 'view_all_content_categories',

  // GENRES
  VIEW_GENRE = 'view_genre',
  CREATE_GENRE = 'create_genre',
  UPDATE_GENRE = 'update_genre',
  DELETE_GENRE = 'delete_genre',
  VIEW_ALL_GENRES = 'view_all_genres',

  // CAREER JOBS
  VIEW_CAREER_JOB = 'view_career_job',
  CREATE_CAREER_JOB = 'create_career_job',
  UPDATE_CAREER_JOB = 'update_career_job',
  DELETE_CAREER_JOB = 'delete_career_job',
  VIEW_ALL_CAREER_JOBS = 'view_all_career_jobs',
  VIEW_ALL_CAREER_JOB_APPLICANTSS = 'view_all_career_job_applicants',
}

export interface User {
  firstName: string
  lastName: string
}
