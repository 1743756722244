import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectCurrentActiveUserAnalyticState = (state: RootState) =>
  state.Dashboard || initialState

export const dashboardSelector = createSelector(
  [selectCurrentActiveUserAnalyticState],
  Dashboard => Dashboard,
)
