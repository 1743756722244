// import { Cookies } from 'react-cookie'
import { put, takeLatest, all } from 'redux-saga/effects'
import { actions, setMenu, setSubMenu } from './slice'

function* setSideMenu({ payload }: ReturnType<typeof setMenu>): any {
  try {
    const selectedMenu = payload.selectedMenu
    // const response = yield call(baseAxios.get, apiEndPoints.users.getRolesData)
    yield put(actions.setSideMenu(selectedMenu))
  } catch (err) {
    console.log(err)
  }
}

function* setSideSubMenu({ payload }: ReturnType<typeof setMenu>): any {
  try {
    const selectedSubMenu = payload.selectedSubMenu
    // const response = yield call(baseAxios.get, apiEndPoints.users.getRolesData)
    yield put(actions.setSideSubMenu(selectedSubMenu))
  } catch (err) {
    console.log(err)
  }
}

export function* menuSaga(): any {
  yield all([yield takeLatest(setMenu, setSideMenu)])
  yield all([yield takeLatest(setSubMenu, setSideSubMenu)])
}
