import CustomHeader from 'components/CustomHeader'
import React from 'react'
import { Link } from 'react-router-dom'
import { accessDenied } from './../../utils/imageUtils'

function AccessDenied() {
  return (
    <CustomHeader pageName={'AccessDenied'} baseHeader={true}>
      <div className="container pt-5">
        <div className="row no-gutters height-self-center">
          <div className="col-sm-12 text-center align-self-center">
            <div className="iq-error position-relative">
              <img
                src={accessDenied}
                className="img-fluid iq-error-img"
                alt=""
              />
              <h2 className="mb-0 mt-4">Oops! This Page is Restricted.</h2>
              <p>The requested page is restricted.</p>
              <Link className="btn btn-primary mt-3" to="/">
                <i className="ri-home-4-line" />
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default AccessDenied
