import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import {
  actions,
  getTranscodingVideos,
  beginTranscoding,
  stopTranscoding,
  searchVideos,
} from './slice'
import { TranscoderJobStatusType } from 'utils/enum'

function* getAllTranscodingVideos({ payload }: any): any {
  try {
    // yield put(actions.setTranscodingLoading(true))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.transcoding.getAllTranscodingVideos(
        payload?.skip,
        payload?.limit,
      ),
    )
    yield put(actions.setTranscodingLoading(false))
    yield put(
      actions.setTranscodingVideos({ data: response, skip: payload?.skip }),
    )
  } catch (err) {
    console.log(err)
  } finally {
    yield put(actions.setTranscodingLoading(false))
  }
}

function* startTranscoding({ payload }: any): any {
  try {
    yield put(actions.setTranscodingLoading(true))

    yield put(
      actions.setStartTranscodingRowLoader({
        loading: true,
        transcodingId: payload?.videoId,
      }),
    )
    yield call(
      baseAxios.post,
      apiEndPoints.transcoding.startTranscoding,
      payload,
    )
    yield put(
      actions.setStartTranscodingRowLoader({
        loading: false,
        transcodingStatus: TranscoderJobStatusType.InProgress,
        transcodingId: payload?.videoId,
      }),
    )
    yield put(actions.setTranscodingLoading(true))

    yield getAllTranscodingVideos({})
  } catch (err) {
    console.log(err)
  } finally {
    yield put(actions.setTranscodingStartLoading(false))
  }
}

function* cancelTranscoding({ payload }: any): any {
  try {
    yield put(actions.setTranscodingLoading(true))

    yield call(
      baseAxios.post,
      apiEndPoints.transcoding.cancelTranscoding,
      payload,
    )
    yield getAllTranscodingVideos({})
    yield put(actions.setTranscodingLoading(false))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(actions.setTranscodingLoading(false))
  }
}

function* searchAllVideos({ payload }: any): any {
  try {
    yield put(actions.setTranscodingLoading(true))

    const response = yield call(
      baseAxios.get,
      apiEndPoints.transcoding.searchVideos(payload?.searchValue),
    )
    yield put(actions.setTranscodingVideoSearch(response))
    yield put(actions.setTranscodingLoading(false))
  } catch (err) {
    console.log(err)
  }
}

export function* transcodingSaga(): any {
  yield all([yield takeLatest(getTranscodingVideos, getAllTranscodingVideos)])
  yield all([yield takeLatest(beginTranscoding, startTranscoding)])
  yield all([yield takeLatest(stopTranscoding, cancelTranscoding)])
  yield all([yield takeLatest(searchVideos, searchAllVideos)])
}
