/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import CustomHeader from 'components/CustomHeader'
import moment from 'moment'
import { Country, State } from 'country-state-city'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { getCertification } from 'store/tmdbManagement/slice'
import { tmdbSelector } from 'store/tmdbManagement/selectors'
import { FieldArray } from 'formik'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getIsRoot,
  getPermission,
  getStatus,
  getTenantId,
  matchYoutubeUrl,
} from 'utils/helpers/systemHelpers'
import PublishedVideos from '../Published'
import { Uploader } from 'utils/helpers/uploader'
import { getArtists } from 'store/artistManagement/slice'
import { artistSelector } from 'store/artistManagement/selector'
import { getVideoById, getVideos } from 'store/videoManagement/slice'
import { videoSelector } from 'store/videoManagement/selectors'
import ApprovedVideos from '../Approved'
import { PERMISSIONS } from 'model/auth'
import { getTenants } from 'store/roleManagement/slice'
import {
  getCategories,
  getGenres,
  setUploadStatus,
} from 'store/basicManagement/slice'
import { basicSelector } from 'store/basicManagement/selector'
import { roleSelector } from 'store/roleManagement/selectors'
import { MediaContentStatusType, UploadStatusType } from 'utils/enum'
import Swal from 'sweetalert2'
import SelectBox from 'components/Select'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'

const videoValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().optional(),
  // movieThumbnail: Yup.string().required('Movie Thumbnail is required'),
  releaseDate: Yup.date().required('Release Date is required'),
  genreId: Yup.string().required('Genre is required'),
  // tenantId: Yup.string().required('Tenant is required'),
  categoryId: Yup.string().required('Category is required'),
  contentType: Yup.string().required('Video Content Type is required'),
  sensorBoard: Yup.object()
    .shape({
      name: Yup.string().optional(),
      description: Yup.string().optional(),
    })
    .optional(),
  geoConfig: Yup.object()
    .shape({
      restrict: Yup.string().optional(),
      longitude: Yup.string().optional(),
      radius: Yup.string().optional(),
      allowedGeoLocations: Yup.object()
        .shape({
          country: Yup.string().optional(),
          state: Yup.string().optional(),
        })
        .optional(),
    })
    .optional(),
  enableDrm: Yup.boolean().optional(),
  enableOffline: Yup.boolean().optional(),
  videoType: Yup.string().required('Select Type'),
  duration: Yup.number().required('Duration is required'),
})

const SingleVideoList = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [countries, setCountries]: any = useState([])
  const [states, setStates]: any = useState([])
  const [selectedCountry, setSelectedCountry]: any = useState(null)
  const singleVideoCloseRef = useRef<HTMLButtonElement>(null)
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const inputFileSelectBanner = useRef<HTMLInputElement>(null)
  const inputFileSelectVideo = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedVideoFile, setSelectedVideoFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const [selectedFileBanner, setSelectedFileBanner]: any = useState(null)
  const [uploader, setUploader] = useState<any>(undefined)
  const [progress, setProgress] = useState(101)
  const [selectedFileBannerDataURI, setSelectedFileBannerDataURI]: any =
    useState(null)
  const [geoConfig, setGeoConfig]: any = useState(false)
  // const singleVideo = useSelector(singleVideoSelector)
  const basic = useSelector(basicSelector)
  const role = useSelector(roleSelector)
  const tmdb = useSelector(tmdbSelector)
  const [uploadType, setUploadType] = useState('youtube')
  const movieModalRef = useRef<any>(null)
  const artist = useSelector(artistSelector)
  const video = useSelector(videoSelector)
  const [modalImageThumb, setModalImageThumb]: any = useState(null)
  const [modalImageBanner, setModalImageBanner]: any = useState(null)
  const [singleVideoID, setSingleVideoId]: any = useState(null)
  const [videoID, setVideoId]: any = useState(null)
  const [certificationDetail, setCertificationDetail]: any = useState(null)
  const [videoUrl, setVideoUrl]: any = useState(null)
  const [selectedSingleVideo, setSelectedSingleVideo]: any = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const approveButtonRef = useRef<HTMLButtonElement>(null)
  const [selectedBtnId, setSelectedBtnId] = useState(-1)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const publishButtonRef = useRef<HTMLButtonElement>(null)
  const [singleVideo, setSingleVideo]: any = useState(null)
  const [tenantID, setTenantID] = useState(getTenantId())

  // const [uploadStatus, setUploadStatus]: any = useState([{ id: 0, status: 0 }])
  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.singleVideoByStatus(MediaContentStatusType.Draft),
    )
    if (response) {
      setSingleVideo(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    const country: any = Country.getAllCountries()
    setCountries(country)
    fetchData()
    dispatch(getArtists({}))
    dispatch(getCertification({}))
    dispatch(getTenants({}))
    dispatch(getCategories({}))
    dispatch(getGenres({}))
  }, [])

  const artistList = artist.artists
  const certification = tmdb.movieCertificates
  const tenants = role.tenants
  const categories = basic.categories
  const genres = basic.genres
  let status = basic.uploadStatus

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Type', field: 'type', sortable: false },
    { name: 'Release Date', field: 'release_date', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Upload Status', field: 'status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  const singleVideosData = useMemo(() => {
    const tempDataDraft: any = []
    singleVideo &&
      singleVideo.length > 0 &&
      singleVideo.map((videoPo: any) => {
        tempDataDraft.push(videoPo)
      })
    let computedSingleVideos: any = tempDataDraft

    if (search) {
      computedSingleVideos = computedSingleVideos.filter(
        (singleVideo: { name: string }) =>
          singleVideo.name.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedSingleVideos.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedSingleVideos.slice(firstPageIndex, lastPageIndex)
  }, [singleVideo, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    description: '',
    releaseDate: '',
    genreId: '',
    contentType: '',
    sensorBoard: {
      name: '',
      description: '',
    },
    geoConfig: {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    },
    enableDrm: false,
    enableOffline: false,
    videoType: '',
    movieThumbnail: '',
    castConfig: [
      {
        reelName: '',
        realName: '',
        artistId: '',
        role: '',
        artistProfileImageUrl: '',
      },
    ],
    categoryId: '',
    tenantId: tenantID,
    tags: [],
    duration: '',
  })

  const setSelectedVideo = async (value: any) => {
    await clearModal()
    setModalOpenType('edit')
    dispatch(getVideoById({ videoId: value.videoId }))
    await baseAxios
      .get(apiEndPoints.cms.videoWithId(value.videoId))
      .then(async (data: any) => {
        setSingleVideoId(value._id)
        setVideoId(value.videoId)
        const videoDataSingle = data
        initialValues.name = value.name
        initialValues.description = value.description
        initialValues.releaseDate = moment(value.releaseDate).format(
          'YYYY-MM-DD',
        )
        initialValues.sensorBoard = value.sensorBoardConfig
        const tagData: any = []
        if (value.tags && value.tags.length > 0) {
          value.tags.forEach((tag: any) => {
            tagData.push({ label: tag, value: tag })
          })
        }
        initialValues.tags = tagData
        initialValues.genreId = value.genreId
        initialValues.contentType = value.contentType
        initialValues.castConfig = value.cast.data
        initialValues.enableDrm = videoDataSingle.enableDrm
        initialValues.enableOffline = videoDataSingle.enableOffline
        setVideoUrl(videoDataSingle.videoUrl)
        setModalImageThumb(value.titleImageUrl)
        setModalImageBanner(value.bannerImageUrl)
        initialValues.geoConfig.restrict = videoDataSingle.geoConfig[0].restrict
        initialValues.geoConfig.longitude =
          videoDataSingle.geoConfig[0].longitude
        initialValues.geoConfig.radius = videoDataSingle.geoConfig[0].radius
        initialValues.videoType = videoDataSingle.accessType
        initialValues.categoryId = value.categoryId
        initialValues.tenantId = value.tenantId
        initialValues.duration = videoDataSingle.duration
        if (
          videoDataSingle.geoConfig[0].allowedGeoLocations &&
          videoDataSingle.geoConfig[0].allowedGeoLocations.length > 0
        ) {
          initialValues.geoConfig.allowedGeoLocations =
            videoDataSingle.geoConfig[0].allowedGeoLocations[0]
        }

        if (
          videoDataSingle.geoConfig[0] &&
          videoDataSingle.geoConfig[0].allowedGeoLocations &&
          videoDataSingle.geoConfig[0].allowedGeoLocations[0] &&
          videoDataSingle.geoConfig[0].allowedGeoLocations[0].country
        ) {
          handleCountrySelectSetValue(
            videoDataSingle.geoConfig[0].allowedGeoLocations[0].country,
          )
        }

        if (matchYoutubeUrl(videoDataSingle.videoUrl)) {
          setUploadType('youtube')
        } else {
          setUploadType('upload')
        }
      })
  }

  const handleSubmit = async (value: any, resetForm: any) => {
    if (!submitButtonRef.current) {
      return
    }

    // short gap fix for uploadStatus issue
    let uploadStatusOptions: any = {}
    if (uploadType == 'youtube' || uploadType == 'AWS Media Tailor') {
      uploadStatusOptions = { uploadStatus: UploadStatusType.Completed }
    }

    if (selectedFileDataURI && selectedFileBannerDataURI) {
      if (selectedVideoFile || value.youtubeUrl) {
        //Disable button
        submitButtonRef.current?.setAttribute('disabled', 'true')
        // Activate indicator
        setLoading(true)
        const geoData: any = {
          restrict: false,
          longitude: '',
          radius: '',
          allowedGeoLocations: [
            {
              country: '',
              state: '',
            },
          ],
        }
        if (selectedCountry) {
          // value.geoConfig.allowedGeoLocations.country = selectedCountry
          geoData.allowedGeoLocations[0].country = selectedCountry
        }
        geoData.allowedGeoLocations[0].state =
          value.geoConfig.allowedGeoLocations.state
        geoData.restrict = value.geoConfig.restrict
        const videoData = {
          title: value.name,
          description: value.description,
          videoUrl: selectedVideoFile
            ? `${apiEndPoints.s3.base}movies/${selectedVideoFile.name}`
            : value.youtubeUrl,
          accessType: value.videoType,
          enableDrm: value.enableDrm,
          enableOffline: value.enableOffline,
          geoConfig: [geoData],
          duration: value.duration,
          ...uploadStatusOptions,
        }
        baseAxios
          .post(apiEndPoints.cms.videos, videoData)
          .then((resp: any) => {
            value.sensorBoard.description = value.sensorBoard.name
            const tagsData: any = []
            if (value.tags.length > 0) {
              value.tags.forEach((tag: any) => {
                tagsData.push(tag.value)
              })
            }
            const singleVideoData = {
              name: value.name,
              description: value.description,
              releaseDate: moment(
                value.releaseDate,
                'YYYY-MM-DD HH:SS',
              ).valueOf(),
              genreId: value.genreId,
              contentType: value.contentType,
              videoId: resp._id,
              titleImageUrl: selectedFileDataURI,
              bannerImageUrl: selectedFileBannerDataURI,
              sensorBoardConfig: value.sensorBoard,
              cast:
                value.castConfig.length > 0 ? { data: value.castConfig } : {},
              status: MediaContentStatusType.Draft,
              tenantId: value.tenantId,
              categoryId: value.categoryId,
              tags: tagsData,
              ...uploadStatusOptions,
            }
            baseAxios
              .post(apiEndPoints.cms.singleVideo, singleVideoData)
              .then(async (singleResp: any) => {
                //Enable button

                await fetchData()
                // dispatch(getSingleVideos({}))
                toast.success('Single Video added successfully! ')
                if (selectedVideoFile) uploadFile(singleResp)
                submitButtonRef.current?.removeAttribute('disabled')
                // Disable indicator
                setLoading(false)
                singleVideoCloseRef.current?.click()
                resetForm()
                setModalOpenType('add')
                setSingleVideoId(null)
                setVideoId(null)
                setVideoUrl(null)
                setModalImageThumb(null)
                setModalImageBanner(null)
                setSelectedCountry(null)
              })
              .catch((err: any) => {
                submitButtonRef.current?.removeAttribute('disabled')
                // Disable indicator
                setLoading(false)
              })
          })
          .catch((err: any) => {
            submitButtonRef.current?.removeAttribute('disabled')
            // Disable indicator
            setLoading(false)
          })
      } else {
        toast.error('Please select video')
      }
    } else {
      toast.error('Please select Banner & Thumbnail Image')
    }
  }

  const handleUpdate = async (
    value: any,
    singlevideoId: string,
    resetForm: any,
  ) => {
    const geoData: any = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: [
        {
          country: '',
          state: '',
        },
      ],
    }
    //Disable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    if (selectedCountry) {
      // value.geoConfig.allowedGeoLocations.country = selectedCountry
      geoData.allowedGeoLocations[0].country = selectedCountry
    }
    geoData.allowedGeoLocations[0].state =
      value.geoConfig.allowedGeoLocations.state
    geoData.restrict = value.geoConfig.restrict
    const videoData = {
      title: value.name,
      description: value.description,
      videoUrl: selectedVideoFile
        ? `${apiEndPoints.s3.base}movies/${selectedVideoFile.name}`
        : value.youtubeUrl
        ? value.youtubeUrl
        : videoUrl,
      accessType: value.videoType,
      enableDrm: value.enableDrm,
      enableOffline: value.enableOffline,
      geoConfig: [geoData],
      duration: value.duration,
    }
    await baseAxios
      .put(apiEndPoints.cms.videoWithId(videoID), videoData)
      .then(async (resp: any) => {
        // const sensorBoard = value.sensorBoard.name
        // value.sensorBoard.description = sensorBoard
        const tagsData: any = []
        if (value.tags.length > 0) {
          value.tags.forEach((tag: any) => {
            tagsData.push(tag.value)
          })
        }
        const singleVideoData = {
          name: value.name,
          description: value.description,
          releaseDate: moment(value.releaseDate, 'YYYY-MM-DD HH:SS').valueOf(),
          genreId: value.genreId,
          contentType: value.contentType,
          videoId: resp._id,
          titleImageUrl:
            selectedFileDataURI != null ? selectedFileDataURI : modalImageThumb,
          bannerImageUrl:
            selectedFileBannerDataURI != null
              ? selectedFileBannerDataURI
              : modalImageBanner,
          sensorBoardConfig: value.sensorBoard,
          cast: value.castConfig.length > 0 ? { data: value.castConfig } : {},
          status: MediaContentStatusType.Draft,
          tenantId: value.tenantId,
          categoryId: value.categoryId,
          tags: tagsData,
          uploadStatus: selectedVideoFile
            ? UploadStatusType.Processing
            : value.youtubeUrl
            ? UploadStatusType.Completed
            : UploadStatusType.Completed,
        }
        await baseAxios
          .put(
            apiEndPoints.cms.singleVideoWithId(singlevideoId),
            singleVideoData,
          )
          .then(async (singleResp: any) => {
            await fetchData()

            if (selectedVideoFile) uploadFile(singleResp)
            submitButtonRef.current?.removeAttribute('disabled')
            // Disable indicator
            setLoading(false)
            singleVideoCloseRef.current?.click()
            resetForm()

            toast.success('Single Video added successfully! ')
          })
          .catch(er => {
            //Disable button
            submitButtonRef.current?.removeAttribute('disabled')
            // Activate indicator
            setLoading(false)
          })
      })
      .catch(er => {
        console.log(er)
        //Disable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
      })
  }

  const changePublish = async (value: any, type: string) => {
    //Disable button
    publishButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoadingPublish(true)
    setSelectedBtnId(value._id)
    const data = Object.assign({}, value)
    data.status = type
    data.releaseDate = moment(value.releaseDate, 'YYYY-MM-DD HH:SS').valueOf()
    baseAxios
      .put(apiEndPoints.cms.singleVideoWithId(value._id), data)
      .then(async (singleResp: any) => {
        //Enable button
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
        // dispatch(getSingleVideos({}))
        await fetchData()
        toast.success('Status updated successfully! ')
      })
      .catch((err: any) => {
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
      })
  }

  const changeStatus = async (value: any, type: string) => {
    //Disable button
    approveButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoadingApprove(true)
    setSelectedBtnId(value._id)
    const data = Object.assign({}, value)
    data.status = type
    data.releaseDate = moment(value.releaseDate, 'YYYY-MM-DD HH:SS').valueOf()
    baseAxios
      .put(apiEndPoints.cms.singleVideoWithId(value._id), data)
      .then(async (singleResp: any) => {
        //Enable button
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
        // dispatch(getSingleVideos({}))
        await fetchData()
        toast.success('Status updated successfully! ')
        singleVideo.singleVideos
        singleVideoCloseRef.current?.click()
      })
      .catch((err: any) => {
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
      })
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.singleVideoWithId(value))
            .then(async resp => {
              await fetchData()
              // dispatch(getSingleVideos({}))
              // singleVideoData = singleVideo.singleVideos
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const openPicker = (type: string) => {
    if (type === 'banner') {
      inputFileSelectBanner.current?.click()
    } else if (type === 'thumbnail') {
      inputFileSelect.current?.click()
    } else {
      inputFileSelectVideo.current?.click()
    }
  }

  const handleFileSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const handleFileBannerSelect = (e: any) => {
    const files: any = e.target.files[0]
    setSelectedFileBanner(files)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setSelectedFileBannerDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(files)
  }

  const handleVideoSelect = (e: any) => {
    const file: any = e.target.files[0]
    setSelectedVideoFile(file)
  }

  const handleCountrySelect = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event.target.value)
    initialValues.geoConfig.allowedGeoLocations.country = event.target.value
    setSelectedCountry(event.target.value)
    setStates(statesArr)
  }

  const handleCountrySelectSetValue = (event: any) => {
    const statesArr: any = State.getStatesOfCountry(event)
    setSelectedCountry(event)
    setStates(statesArr)
  }

  const handleGeoChange = (event: any) => {
    setGeoConfig(event.target.value)
  }

  const onLinkChange = (e: any) => {
    setUploadType(e.currentTarget.value)
  }

  const onUploadChange = (e: any) => {
    setUploadType(e.currentTarget.value)
  }

  const clearModal = async () => {
    // setTimeout(() => innerModalClear(), 100)

    // const innerModalClear = () => {
    initialValues.name = ''
    initialValues.description = ''
    initialValues.releaseDate = ''
    initialValues.genreId = ''
    initialValues.contentType = ''
    initialValues.castConfig = [
      {
        reelName: '',
        realName: '',
        artistId: '',
        role: '',
        artistProfileImageUrl: '',
      },
    ]
    initialValues.enableDrm = false
    initialValues.enableOffline = false
    initialValues.geoConfig = {
      restrict: false,
      longitude: '',
      radius: '',
      allowedGeoLocations: {
        country: '',
        state: '',
      },
    }
    initialValues.sensorBoard = {
      name: '',
      description: '',
    }
    initialValues.videoType = ''
    initialValues.categoryId = ''
    initialValues.tenantId = tenantID
    initialValues.tags = []
    initialValues.duration = ''
    setModalOpenType('add')
    setSingleVideoId(null)
    setVideoId(null)
    setVideoUrl(null)
    setModalImageThumb(null)
    setModalImageBanner(null)
    setSelectedCountry(null)
    clearFieldOnClose()
    // }
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    if (inputFileSelectVideo.current) {
      inputFileSelectVideo.current.value = ''
      inputFileSelectVideo.current.files = null
    }

    if (inputFileSelectBanner.current) {
      inputFileSelectBanner.current.value = ''
      inputFileSelectBanner.current.files = null
    }

    setSelectedFile(null)
    setSelectedVideoFile(null)
    setSelectedFileBanner(null)
  }

  const uploadFile = async (data: any) => {
    dispatch(setUploadStatus({ id: data._id, status: 0 }))
    status = basic.uploadStatus
    if (selectedVideoFile) {
      let percentage: any = undefined
      const videoUploaderOptions = {
        fileName: `movies/${selectedVideoFile.name}`,
        file: selectedVideoFile,
      }

      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)
      uploader
        .onProgress(async ({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setProgress(percentage)
            dispatch(setUploadStatus({ id: data._id, status: percentage }))
            status = basic.uploadStatus
            if (percentage == 100) {
              setTimeout(() => {
                dispatch(setUploadStatus({ id: data._id, status: 100 }))
                status = basic.uploadStatus
                setProgress(101)
                handleUploadStatus(data, UploadStatusType.Completed)
              }, 8000)
            }
          }
        })
        .onError((error: any) => {
          handleUploadStatus(data, UploadStatusType.Failed)
          selectedVideoFile(undefined)
        })
      uploader.start()
    }
  }

  const handleUploadStatus = async (updateData: any, status: string) => {
    const data = Object.assign({}, updateData)
    data.uploadStatus = status
    data.releaseDate = moment(
      updateData.releaseDate,
      'YYYY-MM-DD HH:SS',
    ).valueOf()
    await baseAxios.put(
      apiEndPoints.cms.singleVideoWithId(updateData._id),
      data,
    )
    // await fetchData()
    toast.success('SingleVideo Uploaded Successfully!')
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <ul className="nav nav-tabs" id="myTab-two" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab-two"
                data-toggle="tab"
                href="#role-two"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                In Review
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-three"
                data-toggle="tab"
                href="#permission-three"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-two"
                data-toggle="tab"
                href="#permission-two"
                role="tab"
                aria-controls="permission"
                aria-selected="false"
              >
                Published
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="role-two"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Video Lists</h4>
                      </div>
                      {getPermission(PERMISSIONS.CREATE_SINGLE_VIDEO) && (
                        <div className="sign-info">
                          <a
                            data-toggle="modal"
                            data-target="#AddMovieModel"
                            className="btn btn-primary"
                            onClick={() => clearModal()}
                          >
                            Add Video
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="iq-card-body">
                      <div className="table-view">
                        <TableLength
                          handleLengthChange={(v: any) =>
                            setPageSize(parseInt(v))
                          }
                          handleSearchValue={(searchVal: any) =>
                            setSearch(searchVal)
                          }
                        />
                        {pageLoading && (
                          <div
                            className="container d-flex justify-content-center align-items-center"
                            style={{ height: '200px' }}
                          >
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          </div>
                        )}
                        {!pageLoading &&
                          singleVideo &&
                          singleVideo.length > 0 && (
                            <table
                              className="data-tables table movie_table"
                              style={{ width: '100%' }}
                            >
                              <TableHeader
                                headers={headers}
                                onSorting={(field: any, order: any) =>
                                  setSorting({ field, order })
                                }
                              />
                              <tbody>
                                {singleVideosData.map(
                                  (videoContent: any, index: number) => (
                                    <tr key={`single_video_list_${index}`}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <img
                                          src={videoContent.titleImageUrl}
                                          width={50}
                                          height={70}
                                        />
                                      </td>
                                      <td>{videoContent.name}</td>
                                      <td>{videoContent.contentType}</td>
                                      <td>
                                        {moment(
                                          videoContent.releaseDate,
                                        ).format('YYYY-MM-DD')}
                                      </td>
                                      <td>
                                        {getPermission(
                                          PERMISSIONS.UPDATE_SINGLE_VIDEO,
                                        ) == false
                                          ? getStatus(videoContent.status)
                                          : videoContent.uploadStatus &&
                                            videoContent.uploadStatus ===
                                              UploadStatusType.Completed && (
                                              <button
                                                type="button"
                                                className="btn btn-primary "
                                                ref={approveButtonRef}
                                                onClick={() => {
                                                  setSelectedSingleVideo(
                                                    videoContent,
                                                  )
                                                  changeStatus(
                                                    videoContent,
                                                    MediaContentStatusType.Approved,
                                                  )
                                                }}
                                              >
                                                {/* {loadingApprove == false &&
                                          selectedBtnId ==
                                            videoContent._id(
                                              <span className="indicator-label">
                                                Approve
                                              </span>, */}
                                                {/* )} */}
                                                {loadingApprove == true &&
                                                selectedBtnId ==
                                                  videoContent._id ? (
                                                  <span className="indicator-progress">
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                                  </span>
                                                ) : (
                                                  <span className="indicator-label">
                                                    Approve
                                                  </span>
                                                )}
                                              </button>
                                            )}
                                      </td>
                                      {/* {status ? (
                                    <td>
                                      <span className="badge iq-bg-success mx-1">
                                        Done
                                      </span>
                                    </td>
                                  ) : ( */}
                                      {videoContent.uploadStatus &&
                                      videoContent.uploadStatus ===
                                        UploadStatusType.Processing ? (
                                        <td>
                                          {status.some(
                                            (item: any) =>
                                              item.id === videoContent._id,
                                          )
                                            ? status.find(
                                                (item: any) =>
                                                  item.id === videoContent._id,
                                              )?.status + '%'
                                            : UploadStatusType.Interrupted}
                                        </td>
                                      ) : (
                                        <td>
                                          {videoContent.uploadStatus
                                            ? videoContent.uploadStatus.toUpperCase()
                                            : 'NA'}
                                        </td>
                                      )}
                                      {/* )} */}
                                      <td>
                                        <div className="flex align-items-center list-user-action">
                                          {getPermission(
                                            PERMISSIONS.VIEW_SINGLE_VIDEO,
                                          ) && (
                                            <Link
                                              className="iq-bg-warning"
                                              data-placement="top"
                                              to="/single-video/view"
                                              state={{ data: videoContent }}
                                            >
                                              <i className="ri-eye-line" />
                                            </Link>
                                          )}
                                          {getPermission(
                                            PERMISSIONS.UPDATE_SINGLE_VIDEO,
                                          ) && (
                                            <a
                                              className="iq-bg-success"
                                              data-placement="top"
                                              title=""
                                              data-toggle="modal"
                                              data-target="#AddMovieModel"
                                              data-original-title="Edit"
                                              onClick={() =>
                                                setSelectedVideo(videoContent)
                                              }
                                            >
                                              <i className="ri-pencil-line" />
                                            </a>
                                          )}
                                          {getPermission(
                                            PERMISSIONS.DELETE_SINGLE_VIDEO,
                                          ) && (
                                            <a
                                              className="iq-bg-primary"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title=""
                                              data-original-title="Delete"
                                              onClick={() =>
                                                handleDelete(videoContent._id)
                                              }
                                            >
                                              <i className="ri-delete-bin-line" />
                                            </a>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </table>
                          )}
                        {!pageLoading &&
                          singleVideo &&
                          singleVideo.length == 0 && <NoData />}
                        <Pagination
                          currentPage={currentPage}
                          totalCount={totalItems}
                          pageSize={pageSize}
                          onPageChange={(page: React.SetStateAction<number>) =>
                            setCurrentPage(page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-three"
              id="permission-three"
            >
              <ApprovedVideos
                data={singleVideo}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => setSelectedVideo(value)}
                handlePublish={(value: any) =>
                  changePublish(value, MediaContentStatusType.Publish)
                }
                publishButtonRef={publishButtonRef}
                selectedBtnId={selectedBtnId}
                loading={loadingPublish}
                // handleChange={(value: any) => changeStatus(value, MediaContentStatusType.Publish)}
              />
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="permission-two"
            >
              <PublishedVideos
                data={singleVideo}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => setSelectedVideo(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddMovieModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
        ref={movieModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={videoValidationSchema}
            onSubmit={async (
              values: {
                name: string
                description: string
                movieThumbnail: string
                releaseDate: number
                genreId: string
                contentType: string
                sensorBoard: object
                geoConfig: object
                tenantId: string
                categoryId: string
              },
              { resetForm }: any,
            ) => {
              if (modalOpenType == 'add') {
                handleSubmit(values, resetForm)
              } else {
                handleUpdate(values, singleVideoID, resetForm)
              }
            }}
          >
            {(formik: any) => {
              const { errors, touched, values, setFieldValue } = formik

              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'edit'
                        ? 'Edit Video Content'
                        : 'Add Video Content'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={singleVideoCloseRef}
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className=" form-group">
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="textarea"
                              id="text"
                              name="description"
                              rows={5}
                              className="form-control"
                              placeholder="Description"
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {modalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <img
                                src={modalImageThumb}
                                width={100}
                                height={100}
                              />
                            </div>
                          )}
                          <div className="form_gallery form-group">
                            <label
                              onClick={() => openPicker('thumbnail')}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedFile != null
                                ? selectedFile.name
                                : 'Movie thumbnail'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelect}
                              name="movieThumbnail"
                              onChange={e => handleFileSelect(e)}
                              accept=".png, .jpg, .jpeg, .webp"
                            />
                            <ErrorMessage
                              name="movieThumbnail"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {modalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <img
                                src={modalImageBanner}
                                width={300}
                                height={100}
                              />
                            </div>
                          )}
                          <div className="form_gallery form-group">
                            <label
                              onClick={() => openPicker('banner')}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedFileBanner != null
                                ? selectedFileBanner.name
                                : 'Banner Image'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelectBanner}
                              name="movieThumbnailBanner"
                              onChange={e => handleFileBannerSelect(e)}
                              accept=".png, .jpg, .jpeg, .webp"
                            />
                            <ErrorMessage
                              name="movieThumbnailBanner"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="row ">
                            <div className="col-md-6 form-group">
                              <label htmlFor="form_gallery-upload">
                                Release Date
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                data-placeholder="Release date"
                                name="releaseDate"
                              />
                              <ErrorMessage
                                name="releaseDate"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <label htmlFor="form_gallery-upload">Genre</label>
                              <Field
                                as="select"
                                name="genreId"
                                className="form-control"
                              >
                                <option value="">Select Genre</option>
                                {genres.map((genre: any) => {
                                  return (
                                    <option value={genre._id} key={genre._id}>
                                      {genre.name}
                                    </option>
                                  )
                                })}
                              </Field>
                              <ErrorMessage
                                name="genreId"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6  form-group">
                              <Field
                                as="select"
                                className="form-control"
                                name="contentType"
                              >
                                <option value="">Select Content Type</option>
                                <option value="movie">Movie</option>
                                <option value="concert">Concert</option>
                                <option value="vlog">Vlog</option>
                                <option value="documentary">Documentary</option>
                                <option value="movie_video_song">
                                  Movie Video Song
                                </option>
                                <option value="trailer">Trailer</option>
                                <option value="private_video_song">
                                  Private Video Song
                                </option>
                                <option value="short_film">Short Film</option>
                              </Field>
                              <ErrorMessage
                                name="contentType"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                as="select"
                                name="videoType"
                                className="form-control"
                              >
                                <option value="">Select Type</option>
                                <option value="free">Free</option>
                                <option value="pay_per_view">
                                  Pay Per View
                                </option>
                              </Field>
                              <ErrorMessage
                                name="videoType"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <Field
                                name="tags"
                                id="tags"
                                placeholder="Tags"
                                isMulti={true}
                                component={SelectBox}
                                // options={lyricistList}
                              />
                              <ErrorMessage
                                name="tags"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p>CENSOR BOARD CERTIFICATION</p>
                          <div className="form-group">
                            <Field
                              as="select"
                              name="sensorBoard.name"
                              className="form-control"
                            >
                              <option value="">Select Certification</option>
                              {certification.map((certificate: any) => {
                                return (
                                  <option
                                    key={certificate.certification}
                                    value={certificate.certification}
                                  >
                                    {certificate.certification}
                                  </option>
                                )
                              })}
                            </Field>
                            <ErrorMessage
                              name="sensorBoard.name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <div className="row m-0">
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableDrm"
                                  name="enableDrm"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableDrm"
                                >
                                  Enable DRM availability
                                </label>
                                <ErrorMessage
                                  name="enableDrm"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className=" col-md-6 custom-switch">
                                <Field
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="enableOffline"
                                  name="enableOffline"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enableOffline"
                                >
                                  Offline access
                                </label>
                                <ErrorMessage
                                  name="enableOffline"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>

                          <p className="mt-2">GEO CONFIG</p>

                          <div className="form-group custom-switch">
                            <Field
                              type="checkbox"
                              className="custom-control-input"
                              id="geoConfig.restrict"
                              name="geoConfig.restrict"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="geoConfig.restrict"
                            >
                              Allow geo configure
                            </label>
                          </div>

                          {/* {geoConfig == true && ( */}
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.country"
                                  className="form-control"
                                  onChange={(e: any) => handleCountrySelect(e)}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country: any) => {
                                    return (
                                      <option
                                        value={country.isoCode}
                                        key={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.country"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                              <div className="col-md-6 form-group">
                                <Field
                                  as="select"
                                  name="geoConfig.allowedGeoLocations.state"
                                  className="form-control"
                                >
                                  <option value="">Select State</option>
                                  {states.length > 0 &&
                                    states.map((state: any) => {
                                      return (
                                        <option
                                          value={state.isoCode}
                                          key={state.isoCode}
                                        >
                                          {state.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="geoConfig.allowedGeoLocations.state"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>

                          {/* )} */}
                          <div className="form-group">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio6"
                                name="customRadio-1"
                                value="youtube"
                                checked={uploadType == 'youtube' ? true : false}
                                className="custom-control-input"
                                onChange={(e: any) => onLinkChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                {' '}
                                Youtube Link{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio7"
                                name="customRadio-1"
                                value="upload"
                                checked={uploadType == 'upload' ? true : false}
                                className="custom-control-input"
                                onChange={(e: any) => onUploadChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio7"
                              >
                                {' '}
                                Upload File{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadio8"
                                name="customRadio-1"
                                value="AWS Media Tailor"
                                checked={
                                  uploadType == 'AWS Media Tailor'
                                    ? true
                                    : false
                                }
                                className="custom-control-input"
                                onChange={(e: any) => onLinkChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio8"
                              >
                                {' '}
                                AWS Media Tailor{' '}
                              </label>
                            </div>
                          </div>
                          {modalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              {videoUrl}&nbsp;
                              <a href={videoUrl}>Open Video</a>
                            </div>
                          )}
                          {uploadType == 'youtube' ||
                          uploadType == 'AWS Media Tailor' ? (
                            <div className="form-group">
                              <Field
                                type="text"
                                className="form-control"
                                placeholder={
                                  modalOpenType == 'edit'
                                    ? uploadType == 'AWS Media Tailor'
                                      ? 'New AWS Mediatailor URL'
                                      : 'New Youtube URL'
                                    : uploadType == 'AWS Media Tailor'
                                    ? 'AWS Mediatailor URL'
                                    : 'Youtube Url'
                                }
                                name="youtubeUrl"
                              />
                              <ErrorMessage
                                name="youtubeUrl"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          ) : (
                            <div className="form_gallery form-group">
                              <label
                                onClick={() => openPicker('video')}
                                htmlFor="form_gallery-upload"
                              >
                                {selectedVideoFile != null
                                  ? selectedVideoFile.name
                                  : 'Video File'}
                              </label>
                              <input
                                type="file"
                                ref={inputFileSelectVideo}
                                name="videoFile"
                                onChange={e => handleVideoSelect(e)}
                                accept=".mp4, .mov, .mkv, .avi"
                              />
                              <ErrorMessage
                                name="videoFile"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          )}

                          <div className="form-group">
                            <div className="row">
                              {getIsRoot() && (
                                <div className="col-md-6 form-group">
                                  <Field
                                    as="select"
                                    name="tenantId"
                                    className="form-control"
                                  >
                                    <option value="">Select Tenant</option>
                                    {tenants.length > 0 &&
                                      tenants.map((tenant: any) => {
                                        return (
                                          <option
                                            value={tenant._id}
                                            key={tenant._id}
                                          >
                                            {tenant.name}
                                          </option>
                                        )
                                      })}
                                  </Field>
                                  <ErrorMessage
                                    name="tenantId"
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                              )}
                              <div
                                className={
                                  getIsRoot()
                                    ? 'col-md-6 form-group'
                                    : 'col-md-12 form-group'
                                }
                              >
                                <Field
                                  as="select"
                                  name="categoryId"
                                  className="form-control"
                                >
                                  <option value="">Select Category</option>
                                  {categories.length > 0 &&
                                    categories.map((category: any) => {
                                      return (
                                        <option
                                          value={category._id}
                                          key={category._id}
                                        >
                                          {category.name}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="categoryId"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12 form-group">
                                <Field
                                  type="number"
                                  name="duration"
                                  className="form-control"
                                  placeholder="Enter duration in seconds"
                                />

                                <ErrorMessage
                                  name="duration"
                                  component="span"
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="mt-2">Cast</p>
                      {/* <div className="form-control"> */}
                      <FieldArray
                        name="castConfig"
                        render={(arrayHelpers: any) => (
                          <div>
                            {values.castConfig.map((caste: any, index: any) => (
                              <div className="row mb-5" key={index}>
                                <div className="col-md-4">
                                  <Field
                                    type="text"
                                    className="form-control"
                                    placeholder="Reel Name"
                                    name={`castConfig[${index}].reelName`}
                                  />
                                  <ErrorMessage
                                    name={`castConfig[${index}].reelName`}
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Field
                                    as="select"
                                    className="form-control"
                                    name={`castConfig[${index}].realName`}
                                  >
                                    <option value="">Real Name</option>
                                    {artistList.length > 0 &&
                                      artistList.map((artistData: any) => {
                                        return (
                                          <option
                                            value={artistData._id}
                                            key={artistData._id}
                                          >
                                            {artistData.name}
                                          </option>
                                        )
                                      })}
                                  </Field>
                                  <ErrorMessage
                                    name={`castConfig[${index}].realName`}
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Field
                                    type="text"
                                    className="form-control"
                                    placeholder="Role"
                                    name={`castConfig[${index}].role`}
                                  />
                                  <ErrorMessage
                                    name={`castConfig[${index}].role`}
                                    component="span"
                                    className="text-primary"
                                  />
                                </div>
                                <div className="col-md-1 d-flex align-items-center justify-content-center p-0 m-0 w-100">
                                  <div className="row d-flex align-items-center justify-content-start w-100 p-1">
                                    <div className="col-md-6 align-items-start justify-content-center p-0 m-0">
                                      <div
                                        className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          borderRadius: '50%',
                                        }}
                                        onClick={() =>
                                          arrayHelpers.push({
                                            reelName: '',
                                            realName: '',
                                            role: '',
                                          })
                                        }
                                      >
                                        {/* <i className="fa-sharp fa-solid fa-circle-plus p-0"></i> */}
                                        <i className="fa-solid fa-plus p-0 m-0"></i>
                                      </div>
                                    </div>
                                    {index != 0 && (
                                      <div className="col-md-6 align-items-start justify-content-center p-0 m-0">
                                        <div
                                          className="btn-primary p-0 m-0 d-flex align-items-center justify-content-center"
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                          }}
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          {/* <i className="fa-solid fa-circle-xmark"></i> */}
                                          <i className="fa-solid fa-xmark p-0 m-0"></i>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      />
                      {/* {castConfig.map((cast: any, index: any) => {
                        return (
                          
                        )
                      })} */}

                      {/* </div> */}
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default SingleVideoList
