import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MultiVideo } from './types'

// eslint-disable-next-line
const getMultiVideos = createAction<any>('cms/getMultiVideos')
const deleteMultiVideos = createAction<any>('cms/deleteMultiVideos')
const updateMultiVideos = createAction<any>('cms/updateMultiVideos')
const createMultiVideos = createAction<any>('cms/createMultiVideos')
const getSeasons = createAction<any>('cms/getSeasons')
const getAllMultiVideosByStatus = createAction<any>(
  'cms/getAllMultiVideosByStatus',
)

export const initialState: MultiVideo = {
  multiVideos: [],
  seasons: [],
  multiVideosOptions: [],
}

const MultiVideoSlice = createSlice({
  name: 'MultiVideo',
  initialState: initialState,
  reducers: {
    setMultiVideos(state, action: PayloadAction<any>) {
      state.multiVideos = action.payload
    },
    setSeasons(state, action: PayloadAction<any>) {
      state.seasons = action.payload
    },
    setOptionsFormat(state, action: PayloadAction<any>) {
      state.multiVideosOptions = action.payload.map((val: any) => {
        return {
          value: val._id,
          label: val.name,
          imageUrl: val.titleImageUrl,
        }
      })
    },
  },
})

export {
  getMultiVideos,
  deleteMultiVideos,
  updateMultiVideos,
  createMultiVideos,
  getSeasons,
  getAllMultiVideosByStatus,
}

export const { actions, reducer, name: sliceKey } = MultiVideoSlice
