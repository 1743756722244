import React, { useState } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// eslint-disable-next-line react/prop-types
const Header = ({ headers, onSorting }: any) => {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')

  const onSortingChange = (field: React.SetStateAction<string>) => {
    const order =
      field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    onSorting(field, order)
  }

  return (
    <thead>
      <tr role="row">
        {headers.map(({ name, field, sortable }: any) => (
          <th
            className="sorting"
            aria-controls="new-cons"
            style={{ width: '234.172px' }}
            aria-sort="ascending"
            key={name}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            {name}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default Header
