import React from 'react'
import { Link } from 'react-router-dom'
import CustomHeader from '../../components/CustomHeader'
import { notFound } from './../../utils/imageUtils'

const NotFound = () => {
  return (
    <CustomHeader pageName={'Movie List'} baseHeader={true}>
      <div className="container pt-5">
        <div className="row no-gutters height-self-center">
          <div className="col-sm-12 text-center align-self-center">
            <div className="iq-error position-relative">
              <img src={notFound} className="img-fluid iq-error-img" alt="" />
              <h2 className="mb-0 mt-4">Oops! This Page is Not Found.</h2>
              <p>The requested page dose not exist.</p>
              <Link className="btn btn-primary mt-3" to="/">
                <i className="ri-home-4-line" />
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </CustomHeader>
  )
}

export default NotFound
