import React, { useState, useRef, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { MediaContentStatusType, bannerTypes } from 'utils/enum'
import { getAllSingleVideosByStatus } from 'store/singleVideoManagement/slice'
import { singleVideoSelector } from 'store/singleVideoManagement/selectors'
import { getAllMultiVideosByStatus } from 'store/multiVideoManagement/slice'
import { multiVideoSelector } from 'store/multiVideoManagement/selectors'
import {
  getAllPodcastsByStatus,
  getAllSingleAlbumsByStatus,
} from 'store/albumManagement/slice'
import { albumSelector } from 'store/albumManagement/selector'
import { getAllMagazinesByStatus } from 'store/magazineManagement/slice'
import { magazineSelector } from 'store/magazineManagement/selector'
import { categorySelector } from 'store/categoryManagement/selector'
import { formatedBannerTypes } from 'utils/helpers/systemHelpers'
import { bannerSelector } from 'store/bannnerManagement/selector'
import {
  manageBannerErrorMessage,
  manageSelectedBanner,
} from 'store/bannnerManagement/slice'

const AddBanner = (props: any) => {
  const { onSubmit } = props

  const bannerValidationSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    bannerType: Yup.string().required('Banner Type is required'),
    order: Yup.string().required('Order is required'),
    externalUrl: Yup.string().optional(),
  })

  const [bannerFile, setBannerFile] = useState<any>(null)
  const [bannerFileURI, setBannerFileURI] = useState<any>(null)
  const bannerImageRef = useRef<HTMLInputElement>(null)
  const bannerCloseRef = useRef<HTMLButtonElement>(null)
  const [content, setContent] = useState<any>(null)

  const dispatch = useDispatch()
  const { webCategories } = useSelector(categorySelector)
  const { singleVideosOptions } = useSelector(singleVideoSelector)
  const { multiVideosOptions } = useSelector(multiVideoSelector)
  const { podcastsAudioOptions, singleAlbumsOptions } =
    useSelector(albumSelector)
  const { magazineOptions } = useSelector(magazineSelector)
  const {
    selectedBannerInfo,
    activeCategory,
    modalOpenType,
    bannerErrorMessage,
    banners,
    bannersLoading,
  } = useSelector(bannerSelector)

  const openPicker = () => {
    bannerImageRef.current?.click()
  }

  const formikRef: any = useRef()

  const dispatchSpecificContentRequest = useCallback(
    (value: any) => {
      if (value == bannerTypes.Movie) {
        dispatch(
          getAllSingleVideosByStatus({
            status: MediaContentStatusType.Publish,
            formatType: 'banner_screen',
          }),
        )
      }
      if (
        [
          bannerTypes.Interviews,
          bannerTypes.TalkShows,
          bannerTypes.WebSeries,
        ].includes(value)
      ) {
        dispatch(
          getAllMultiVideosByStatus({
            status: MediaContentStatusType.Publish,
            formatType: 'banner_screen',
          }),
        )
      }
      if (value == bannerTypes.Podcast) {
        dispatch(
          getAllPodcastsByStatus({
            status: MediaContentStatusType.Publish,
            formatType: 'banner_screen',
          }),
        )
      }
      if (value == bannerTypes.Music) {
        dispatch(
          getAllSingleAlbumsByStatus({
            status: MediaContentStatusType.Publish,
            formatType: 'banner_screen',
          }),
        )
      }
      if (value == bannerTypes.Magazine) {
        dispatch(
          getAllMagazinesByStatus({
            status: MediaContentStatusType.Publish,
            formatType: 'banner_screen',
          }),
        )
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (formikRef.current && activeCategory) {
      formikRef?.current?.resetForm()
      dispatchSpecificContentRequest(selectedBannerInfo?.type)
      formikRef.current.setFieldValue(
        'category',
        selectedBannerInfo?.webCategoryId,
      )
      formikRef.current.setFieldValue('bannerType', selectedBannerInfo?.type)
      formikRef.current.setFieldValue('order', selectedBannerInfo?.order)
      formikRef.current.setFieldValue(
        'externalUrl',
        selectedBannerInfo?.metaInfo?.externalUrl,
      )

      setContent({
        label: selectedBannerInfo?.metaInfo?.name,
        value: selectedBannerInfo?.metaInfo?._id,
      })
    }
  }, [activeCategory, dispatchSpecificContentRequest, selectedBannerInfo])

  useEffect(() => {
    clearFieldOnClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banners])

  const handleFileBannerSelect = (e: any) => {
    dispatch(manageBannerErrorMessage(''))
    const files: any = e.target.files[0]
    setBannerFile(files)
    const reader: any = new FileReader()
    reader.onloadend = () => {
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        setBannerFileURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(files)
  }

  const clearFieldOnClose = () => {
    //cleanup
    setBannerFile(null)
    setBannerFileURI(null)
    dispatch(manageSelectedBanner(null))
    setContent(null)
    formikRef.current.setFieldValue('bannerType', 'Select Banner Type')
    formikRef.current.setFieldValue('category', activeCategory?._id)
    formikRef.current.setFieldValue('order', 0)
    formikRef.current.setFieldValue('externalUrl', '')
    dispatch(manageBannerErrorMessage(''))
  }

  const CustomOption = ({ innerProps, label, data }: any) => (
    <div
      {...innerProps}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 10,
      }}
    >
      <img src={data.imageUrl} style={{ width: 80, height: 80 }} />
      <p style={{ padding: 5 }}> {label}</p>
    </div>
  )

  const CustomComponents = {
    Option: CustomOption,
  }

  const getOptionsBasedOnBannerType = (bannerType: any) => {
    if (bannerType == bannerTypes.Movie) {
      return singleVideosOptions
    }
    if (
      [
        bannerTypes.Interviews,
        bannerTypes.TalkShows,
        bannerTypes.WebSeries,
      ].includes(bannerType)
    ) {
      return multiVideosOptions
    }
    if (bannerType == bannerTypes.Music) {
      return singleAlbumsOptions
    }
    if (bannerType == bannerTypes.Podcast) {
      return podcastsAudioOptions
    }
    if (bannerType == bannerTypes.Magazine) {
      return magazineOptions
    }

    return []
  }

  const handleSubmit = (values: any) => {
    dispatch(manageBannerErrorMessage(''))

    if (!bannerFileURI && modalOpenType != 'edit') {
      dispatch(manageBannerErrorMessage('Banner Image is required'))
      return
    }
    if (values.bannerType != bannerTypes.external && !content) {
      dispatch(manageBannerErrorMessage('Please Select the Content'))
      return
    }
    const payload: any = {}
    payload.webCategoryId = values.category
    payload.type = values.bannerType
    payload.order = 1
    payload.imageUrl = bannerFileURI || selectedBannerInfo?.imageUrl
    payload.buttonRef = bannerCloseRef
    payload.order = values.order
    if (content && values.bannerType != bannerTypes.external) {
      payload.metaInfo = {
        name: content.label,
        _id: content.value,
      }
      payload.contentId =
        values.bannerType == bannerTypes.external ? null : content?.value || ''
    }
    if (values.bannerType == bannerTypes.external) {
      payload.metaInfo = {
        externalUrl: values?.externalUrl,
      }
    }
    if (selectedBannerInfo) {
      payload.bannerId = selectedBannerInfo?._id
    }
    onSubmit(payload)
  }

  return (
    <div
      className="modal fade"
      id="ModalCenter"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="ModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <Formik
          validationSchema={bannerValidationSchema}
          initialValues={{}}
          innerRef={formikRef}
          onSubmit={async (values: {
            category: string
            bannerType: string
            order: number
            externalUrl: string
          }) => {
            handleSubmit(values)
          }}
        >
          {(formik: any) => {
            const { values, setFieldValue } = formik
            return (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="ModalCenterTitle">
                    {modalOpenType == 'edit' ? 'Edit Banner' : 'Add Banner'}
                  </h5>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={bannerCloseRef}
                    onClick={clearFieldOnClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form>
                    <div className="row ">
                      <div className="col-md-6">
                        <div className="form_gallery form-group">
                          <label
                            onClick={openPicker}
                            htmlFor="form_gallery-upload"
                          >
                            {bannerFile != null
                              ? bannerFile.name
                              : 'Banner image'}
                          </label>
                          <input
                            type="file"
                            ref={bannerImageRef}
                            onChange={e => handleFileBannerSelect(e)}
                            accept=".png, .jpg, .jpeg, .webp"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Field
                            as="select"
                            name="category"
                            className="form-control"
                          >
                            <option value="">Select Category</option>
                            {webCategories?.length &&
                              webCategories.map(
                                (category: any, index: number) => {
                                  return (
                                    <option key={index} value={category._id}>
                                      {category.name}
                                    </option>
                                  )
                                },
                              )}
                          </Field>
                          <ErrorMessage
                            name="category"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Field
                            as="select"
                            name="bannerType"
                            className="form-control"
                            onBlur={(e: any) => {
                              const selectedValue = e.target.value
                              setFieldValue('bannerType', selectedValue)
                              setContent(null)
                              dispatchSpecificContentRequest(selectedValue)
                            }}
                          >
                            <option value="">Select Banner Type</option>
                            {formatedBannerTypes().map((banner: any) => {
                              return (
                                <option key={banner.key} value={banner.value}>
                                  {banner.label}
                                </option>
                              )
                            })}
                          </Field>
                          <ErrorMessage
                            name="bannerType"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          {values?.bannerType == bannerTypes.external ? (
                            <>
                              {' '}
                              <Field
                                type="text"
                                name="externalUrl"
                                className="form-control  mb-0"
                                placeholder="Enter Banner Redirect URL"
                              />
                              <ErrorMessage
                                name="externalUrl"
                                component="span"
                                className="text-primary"
                              />
                            </>
                          ) : (
                            <Select
                              options={getOptionsBasedOnBannerType(
                                values?.bannerType,
                              )}
                              name="content"
                              components={CustomComponents}
                              placeholder={'Select Content to redirect'}
                              value={content}
                              onChange={(e: any) => {
                                // setFieldValue('content', e)
                                setContent(e)
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          {' '}
                          <Field
                            type="number"
                            name="order"
                            className="form-control  mb-0"
                            placeholder="Enter order of the banner"
                          />
                          <ErrorMessage
                            name="order"
                            component="span"
                            className="text-primary"
                          />
                        </div>
                      </div>
                      {(bannerFileURI || selectedBannerInfo?.imageUrl) && (
                        <div className="modal-header">
                          <h5 className="modal-title" id="ModalCenterTitle">
                            Preview
                          </h5>
                        </div>
                      )}
                      {(bannerFileURI || selectedBannerInfo?.imageUrl) && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <img
                            src={bannerFileURI || selectedBannerInfo?.imageUrl}
                            style={{ width: '80%', height: 500 }}
                          />
                        </div>
                      )}

                      <div className="col-md-12 d-flex justify-content-end align-items-center mt-2">
                        <button type="submit" className="btn btn-primary">
                          {!bannersLoading && (
                            <span className="indicator-label">
                              {modalOpenType == 'edit' ? 'Update' : 'Submit'}
                            </span>
                          )}

                          {bannersLoading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className="col-md-12 d-flex justify-content-end align-items-center">
                        <p
                          style={{
                            color: 'red',
                            alignSelf: 'flex-end',
                            justifySelf: 'flex-end',
                          }}
                        >
                          {bannerErrorMessage}
                        </p>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default AddBanner
