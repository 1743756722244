/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pagination, TableHeader } from 'components/DataTable'
import TableLength from 'components/DataTable/TableLength'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomHeader from 'components/CustomHeader'
import moment from 'moment'
import { getAuthors, getMagazines } from 'store/magazineManagement/slice'
import { magazineSelector } from 'store/magazineManagement/selector'
import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { Link } from 'react-router-dom'
import {
  getIsRoot,
  getPermission,
  getStatus,
  getTenantId,
} from 'utils/helpers/systemHelpers'
import { PERMISSIONS } from 'model/auth'
import { basicSelector } from 'store/basicManagement/selector'
import { getCategories, setUploadStatus } from 'store/basicManagement/slice'
import { Uploader } from 'utils/helpers/uploader'
import { roleSelector } from 'store/roleManagement/selectors'
import { getTenants } from 'store/roleManagement/slice'
import Published from '../Published'
import Approved from '../Approved'
import { toast } from 'react-toastify'
import { MediaContentStatusType, UploadStatusType } from 'utils/enum'
import Swal from 'sweetalert2'
import { FormikHelpers } from 'formik/dist/types'
import { ThreeDots } from 'react-loader-spinner'
import NoData from 'components/NoData/nodata'
import SelectBox from 'components/Select'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  releaseDate: Yup.date().required('Date is Required'),
  type: Yup.string().required('Magazine Type is Required'),
  author: Yup.string().required('Author is Required'),
  categoryId: Yup.string().required('Category is required'),
})

const MagazineList = () => {
  const [pageLoading, setPageLoading]: any = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const [modalOpenType, setModalOpenType] = useState('add')
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const magazineCloseRef = useRef<HTMLButtonElement>(null)
  const inputFileSelect = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile]: any = useState(null)
  const [selectedFileDataURI, setSelectedFileDataURI]: any = useState(null)
  const inputFileSelectPdf = useRef<HTMLInputElement>(null)
  const [selectedFilePdf, setSelectedFilePdf]: any = useState(null)
  const [selectedFileDataURIPdf, setSelectedFileDataURIPdf]: any =
    useState(null)
  const dispatch = useDispatch()
  const magazineStore = useSelector(magazineSelector)
  const basic = useSelector(basicSelector)
  const [modalImage, setModalImage]: any = useState(null)
  const [modalPdf, setModalPdf]: any = useState(null)
  const [loading, setLoading] = useState(false)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const [uploader, setUploader] = useState<any>(undefined)
  const [progress, setProgress] = useState(101)
  const role = useSelector(roleSelector)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const publishButtonRef = useRef<HTMLButtonElement>(null)
  const approveButtonRef = useRef<HTMLButtonElement>(null)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [selectedBtnId, setSelectedBtnId] = useState(null)
  const [magazine, setMagazine]: any = useState(null)
  const [tenantID, setTenantID] = useState(getTenantId())

  const fetchData = async () => {
    setPageLoading(true)
    const response: any = await baseAxios.get(
      apiEndPoints.cms.magazineByStatus(MediaContentStatusType.Draft),
    )
    if (response) {
      setMagazine(response)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    fetchData()
    dispatch(getAuthors({}))
    dispatch(getTenants({}))
    dispatch(getCategories({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authorsData = magazineStore.authors
  let status = basic.uploadStatus
  const categories = basic.categories
  const tenants = role.tenants

  const headers = [
    { name: 'No.', field: 'number', sortable: false },
    { name: 'Thumbnail', field: 'thumbnail', sortable: false },
    { name: 'Name', field: 'name', sortable: false },
    { name: 'Type', field: 'type', sortable: false },
    { name: 'Release Date', field: 'release_date', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
    { name: 'Upload Status', field: 'status', sortable: false },
    { name: 'Action', field: 'action', sortable: false },
  ]

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const handleBeforeUnload = (e: any) => {
    e.preventDefault()
    const message =
      'Are you sure you want to leave? All provided data will be lost.'
    e.returnValue = message
    // return swal({
    //   title: 'Are you sure?',
    //   text: 'Once deleted, you will not be able to recover this imaginary file!',
    //   icon: 'warning',
    //   // buttons: true,
    //   dangerMode: true,
    // }).then(willDelete => {
    //   if (willDelete) {
    //     swal('Poof! Your imaginary file has been deleted!', {
    //       icon: 'success',
    //     })
    //   } else {
    //     swal('Your imaginary file is safe!')
    //   }
    // })
    return message
  }

  const magazinesData = useMemo(() => {
    const tempDataDraft: any = []
    magazine &&
      magazine.length > 0 &&
      magazine.map((videoPo: any) => {
        // if (videoPo.status == MediaContentStatusType.Draft) {
        tempDataDraft.push(videoPo)
        // }
      })
    let computedMagazines: any = tempDataDraft

    if (search) {
      computedMagazines = computedMagazines.filter(
        (magazine: {
          name: string
          description: string
          //   rating: string
        }) =>
          magazine.name.toLowerCase().includes(search.toLowerCase()) ||
          magazine.description.toLowerCase().includes(search.toLowerCase()),
        //   magazine.rating.includes(search),
      )
    }

    setTotalItems(computedMagazines.length)

    //Sorting scrolls
    // if (sorting.field) {
    //   const reversed = sorting.order === 'asc' ? 1 : -1
    //   computedMovies = computedMovies.sort(
    //     (a: any, b: any) => {
    //       console.log('reversed - ', reversed * a[sorting.field])
    //       console.log('a - ', a)
    //       console.log('b - ', b)
    //     },
    //     // reversed * a[sorting.field].localeCompare(b[sorting.field]),
    //   )
    // }

    //Current Page slice
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return computedMagazines.slice(firstPageIndex, lastPageIndex)
  }, [magazine, search, currentPage, pageSize])

  const [initialValues] = useState({
    name: '',
    description: '',
    releaseDate: '',
    type: '',
    author: '',
    categoryId: '',
    tenantId: tenantID,
    tags: [],
  })

  const setSelectedTenant = async (value: any) => {
    await clearModal()
    setModalOpenType('edit')
    initialValues.name = value.name
    initialValues.description = value.description
    initialValues.releaseDate = moment(value.releaseDate).format('YYYY-MM-DD')
    initialValues.type = value.type
    initialValues.author = value.authorId
    initialValues.tenantId = value.tenantId
    initialValues.categoryId = value.categoryId
    const tagData: any = []
    if (value.tags && value.tags.length > 0) {
      value.tags.forEach((tag: any) => {
        tagData.push({ label: tag, value: tag })
      })
    }
    initialValues.tags = tagData
    setModalImage(value.imageUrl)
    setModalPdf(value.fileUrl)
    setSelectedTenantId(value._id)
  }

  const clearModal = async () => {
    initialValues.name = ''
    initialValues.description = ''
    initialValues.releaseDate = ''
    initialValues.type = ''
    initialValues.author = ''
    initialValues.tenantId = tenantID
    initialValues.categoryId = ''
    initialValues.tags = []
    setModalOpenType('add')
    setModalImage(null)
    setModalPdf(null)
    setSelectedTenantId('')
    setSelectedFile(null)
    setSelectedFileDataURIPdf(null)

    clearFieldOnClose()
  }

  const handleFileSelect = (e: any) => {
    setSelectedFile(null)
    const file: any = e.target.files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      // console.log(reader.result)
      if (
        reader.result != null &&
        reader.result != undefined &&
        reader.result != ''
      ) {
        // initialValues.movieThumbnail = reader.result!.toString()
        setSelectedFileDataURI(reader.result!.toString())
      }
    }
    reader.readAsDataURL(file)
  }

  const handleFileSelectPdf = (e: any) => {
    setSelectedFileDataURIPdf(null)
    setSelectedFilePdf(null)
    const file: any = e.target.files[0]
    setSelectedFilePdf(file)
    if (file.size / 1048576 < 2) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (
          reader.result != null &&
          reader.result != undefined &&
          reader.result != ''
        ) {
          // initialValues.movieThumbnail = reader.result!.toString()
          setSelectedFileDataURIPdf(reader.result!.toString())
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const openPicker = () => {
    inputFileSelect.current?.click()
  }

  const openPickerPdf = () => {
    inputFileSelectPdf.current?.click()
  }

  const handleSubmitMagazine = (
    values: {
      name: string
      description: string
      releaseDate: number
      type: string
      author: string
      tenantId: string
      categoryId: string
      tags: any
    },
    resetForm: any,
  ) => {
    if (!submitButtonRef.current) {
      return
    }
    if (selectedFileDataURI) {
      if (selectedFileDataURIPdf || selectedFilePdf) {
        //Disable button
        submitButtonRef.current?.setAttribute('disabled', 'true')
        // Activate indicator
        setLoading(true)
        const tagsData: any = []
        if (values.tags.length > 0) {
          values.tags.forEach((tag: any) => {
            tagsData.push(tag.value)
          })
        }
        const magazineDatas = {
          name: values.name,
          description: values.description,
          releaseDate: moment(values.releaseDate, 'YYYY-MM-DD').valueOf(),
          type: values.type,
          authorId: values.author,
          imageUrl: selectedFileDataURI,
          tenantId: values.tenantId,
          categoryId: values.categoryId,
          tags: tagsData,
          status: MediaContentStatusType.Draft,
          fileUrl: selectedFileDataURIPdf
            ? selectedFileDataURIPdf
            : `${apiEndPoints.s3.base}magazine/${selectedFilePdf.name}`,
        }
        baseAxios
          .post(apiEndPoints.cms.magazine, magazineDatas)
          .then(async (resp: any) => {
            await fetchData()

            if (selectedFilePdf) uploadFile(resp)
            //Disable button
            submitButtonRef.current?.removeAttribute('disabled')
            // Activate indicator
            setLoading(false)
            magazineCloseRef.current?.click()
            toast.success('Magazine Created Successfully!')
            resetForm()
          })
          .catch((err: any) => {
            submitButtonRef.current?.removeAttribute('disabled')
            // Activate indicator
            setLoading(false)
          })
      } else {
        toast.error('Please select PDF!')
      }
    } else {
      toast.error('Please select thumbnail!')
    }
  }

  const handleUpdate = async (
    values: {
      name: string
      description: string
      releaseDate: any
      type: any
      author: string
      tenantId: string
      categoryId: string
      tags: any
    },
    magazineId: string,
    resetForm: any,
  ) => {
    if (!submitButtonRef.current) {
      return
    }
    //Disable button
    submitButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoading(true)
    const tagsData: any = []
    if (values.tags.length > 0) {
      values.tags.forEach((tag: any) => {
        tagsData.push(tag.value)
      })
    }
    const magazineDataPut = {
      name: values.name,
      description: values.description,
      releaseDate: moment(values.releaseDate, 'YYYY-MM-DD').valueOf(),
      type: values.type,
      authorId: values.author,
      imageUrl: selectedFileDataURI != null ? selectedFileDataURI : modalImage,
      tenantId: values.tenantId,
      categoryId: values.categoryId,
      status: MediaContentStatusType.Draft,
      tags: tagsData,
      fileUrl: selectedFilePdf
        ? `${apiEndPoints.s3.base}magazine/${selectedFilePdf.name}`
        : selectedFileDataURIPdf
        ? selectedFileDataURIPdf
        : modalPdf,
      uploadStatus: selectedFilePdf
        ? UploadStatusType.Processing
        : UploadStatusType.Completed,
    }
    baseAxios
      .put(apiEndPoints.cms.magazineWithId(magazineId), magazineDataPut)
      .then(async (resp: any) => {
        await fetchData()
        if (selectedFilePdf) uploadFile(resp)
        //Disable button
        submitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
        magazineCloseRef.current?.click()
        toast.success('Magazine Updated Successfully!')
      })
      .catch((err: any) => {
        submitButtonRef.current?.removeAttribute('disabled')
        // Activate indicator
        setLoading(false)
      })
  }

  const uploadFile = async (data: any) => {
    dispatch(setUploadStatus({ id: data._id, status: 0 }))
    status = basic.uploadStatus
    if (selectedFilePdf) {
      let percentage: any = undefined

      const videoUploaderOptions = {
        fileName: `magazine/${selectedFilePdf.name}`,
        file: selectedFilePdf,
      }
      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)

      uploader
        .onProgress(async ({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setProgress(percentage)
            dispatch(setUploadStatus({ id: data._id, status: percentage }))
            status = basic.uploadStatus
            if (percentage == 100) {
              setTimeout(async () => {
                dispatch(setUploadStatus({ id: data._id, status: 100 }))
                status = basic.uploadStatus
                setProgress(101)
                await handleUploadStatus(data, UploadStatusType.Completed)
              }, 8000)
            }
          }
        })
        .onError((error: any) => {
          setSelectedFilePdf(undefined)
        })
      uploader.start()
    }
  }

  const handleUploadStatus = async (updateData: any, status: string) => {
    const data = Object.assign({}, updateData)
    data.uploadStatus = status
    data.releaseDate = moment(
      updateData.releaseDate,
      'YYYY-MM-DD HH:SS',
    ).valueOf()
    await baseAxios.put(apiEndPoints.cms.magazineWithId(updateData._id), data)
    await fetchData()
    toast.success('Magazine Uploaded Successfully!')
  }

  const handleDelete = (value: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        toast.promise(
          baseAxios
            .delete(apiEndPoints.cms.magazineWithId(value))
            .then(async resp => {
              // dispatch(getMagazines({}))
              await fetchData()
              // magazineData = magazine.magazines
            }),
          {
            pending: 'Please wait...',
            success: 'Successfully deleted ',
            error: 'error deleting',
          },
        )
      }
    })
  }

  const changePublish = async (value: any, type: string) => {
    //Disable button
    publishButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoadingPublish(true)
    setSelectedBtnId(value._id)
    const data = Object.assign({}, value)
    data.status = type
    data.releaseDate = moment(value.releaseDate, 'YYYY-MM-DD HH:SS').valueOf()
    baseAxios
      .put(apiEndPoints.cms.magazineWithId(value._id), data)
      .then(async () => {
        //Enable button
        publishButtonRef.current?.removeAttribute('disabled')
        setLoadingPublish(false)
        await fetchData()
        toast.success('Status updated successfully! ')
        setSelectedBtnId(null)
      })
      .catch((err: any) => {
        publishButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingPublish(false)
      })
  }

  const changeStatus = async (value: any, type: string) => {
    //Disable button
    approveButtonRef.current?.setAttribute('disabled', 'true')
    // Activate indicator
    setLoadingApprove(true)
    setSelectedBtnId(value._id)
    const data = Object.assign({}, value)
    data.status = type
    data.releaseDate = moment(value.releaseDate, 'YYYY-MM-DD HH:SS').valueOf()
    baseAxios
      .put(apiEndPoints.cms.magazineWithId(value._id), data)
      .then(async () => {
        //Enable button
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
        await fetchData()
        toast.success('Status updated successfully! ')
        setSelectedBtnId(null)
      })
      .catch((err: any) => {
        approveButtonRef.current?.removeAttribute('disabled')
        // Disable indicator
        setLoadingApprove(false)
      })
  }

  const clearFieldOnClose = () => {
    if (inputFileSelect.current) {
      inputFileSelect.current.value = ''
      inputFileSelect.current.files = null
    }

    if (inputFileSelectPdf.current) {
      inputFileSelectPdf.current.value = ''
      inputFileSelectPdf.current.files = null
    }
    setSelectedFile(null)
    setSelectedFilePdf(null)
  }

  return (
    <CustomHeader pageName={'Movie List'}>
      <div id="content-page" className="content-page">
        <div className="container-fluid ">
          <ul className="nav nav-tabs" id="myTab-two" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab-two"
                data-toggle="tab"
                href="#role-two"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                In Review
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-three"
                data-toggle="tab"
                href="#permission-three"
                role="tab"
                aria-controls="role"
                aria-selected="true"
              >
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-two"
                data-toggle="tab"
                href="#permission-two"
                role="tab"
                aria-controls="permission"
                aria-selected="false"
              >
                Published
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="role-two"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Magazines List</h4>
                      </div>
                      {getPermission(PERMISSIONS.CREATE_MAGAZINE) && (
                        <a
                          data-toggle="modal"
                          data-target="#AddMagazineModel"
                          className="btn btn-primary m-1"
                          onClick={() => clearModal()}
                        >
                          Add Magazine
                        </a>
                      )}
                    </div>
                    <div className="iq-card-body">
                      <div className="table-view">
                        <TableLength
                          handleLengthChange={(v: any) =>
                            setPageSize(parseInt(v))
                          }
                          handleSearchValue={(searchVal: any) =>
                            setSearch(searchVal)
                          }
                        />
                        {pageLoading && (
                          <div
                            className="container d-flex justify-content-center align-items-center"
                            style={{ height: '200px' }}
                          >
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#e20e02"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          </div>
                        )}
                        {!pageLoading && magazine && magazine.length > 0 && (
                          <table
                            className="data-tables table movie_table"
                            style={{ width: '100%' }}
                          >
                            <TableHeader
                              headers={headers}
                              onSorting={(field: any, order: any) =>
                                setSorting({ field, order })
                              }
                            />
                            <tbody>
                              {magazinesData.map(
                                (magazine: any, index: number) => (
                                  <tr key={magazine._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <img
                                        src={magazine.imageUrl}
                                        width={70}
                                        height={100}
                                      />
                                    </td>
                                    <td>{magazine.name}</td>
                                    <td>
                                      {magazine.type.charAt(0).toUpperCase() +
                                        magazine.type.slice(1)}
                                    </td>
                                    <td>
                                      {moment(magazine.releaseDate).format(
                                        'YYYY-MM-DD',
                                      )}
                                    </td>
                                    <td>
                                      {getPermission(
                                        PERMISSIONS.APPROVE_MAGAZINES,
                                      ) == false
                                        ? getStatus(magazine.status)
                                        : magazine.uploadStatus &&
                                          magazine.uploadStatus ===
                                            UploadStatusType.Completed && (
                                            <button
                                              type="button"
                                              className="btn btn-primary "
                                              ref={approveButtonRef}
                                              onClick={() => {
                                                setSelectedTenant(magazine)
                                                changeStatus(
                                                  magazine,
                                                  MediaContentStatusType.Approved,
                                                )
                                              }}
                                            >
                                              {loadingApprove &&
                                              selectedBtnId == magazine._id ? (
                                                <span className="indicator-progress">
                                                  Please wait...
                                                  <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                                                </span>
                                              ) : (
                                                <span className="indicator-label">
                                                  Approve
                                                </span>
                                              )}
                                            </button>
                                          )}
                                    </td>
                                    {magazine.uploadStatus ===
                                    UploadStatusType.Processing ? (
                                      <td>
                                        {status.some(
                                          (item: any) =>
                                            item.id === magazine._id,
                                        )
                                          ? status.find(
                                              (item: any) =>
                                                item.id === magazine._id,
                                            )?.status + '%'
                                          : UploadStatusType.Interrupted}
                                      </td>
                                    ) : (
                                      <td>
                                        {magazine.uploadStatus
                                          ? magazine.uploadStatus.toUpperCase()
                                          : 'NA'}
                                      </td>
                                    )}
                                    <td>
                                      <div className="flex align-items-center list-user-action">
                                        {getPermission(
                                          PERMISSIONS.VIEW_MAGAZINE,
                                        ) && (
                                          <Link
                                            className="iq-bg-warning"
                                            data-placement="top"
                                            to="/magazine/view"
                                            state={{ data: magazine }}
                                            // title=""
                                            // data-toggle="modal"
                                            // data-target="#VideoPlayer"
                                            // data-original-title="Edit"
                                          >
                                            <i className="ri-eye-line" />
                                          </Link>
                                        )}
                                        {getPermission(
                                          PERMISSIONS.UPDATE_MAGAZINE,
                                        ) && (
                                          <a
                                            className="iq-bg-success"
                                            data-placement="top"
                                            title=""
                                            data-toggle="modal"
                                            data-target="#AddMagazineModel"
                                            data-original-title="Edit"
                                            onClick={() =>
                                              setSelectedTenant(magazine)
                                            }
                                          >
                                            <i className="ri-pencil-line" />
                                          </a>
                                        )}
                                        {getPermission(
                                          PERMISSIONS.DELETE_MAGAZINE,
                                        ) && (
                                          <a
                                            className="iq-bg-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            data-original-title="Delete"
                                            onClick={() =>
                                              handleDelete(magazine._id)
                                            }
                                          >
                                            <i className="ri-delete-bin-line" />
                                          </a>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        )}
                        {!pageLoading && magazine && magazine.length == 0 && (
                          <NoData />
                        )}
                        <Pagination
                          currentPage={currentPage}
                          totalCount={totalItems}
                          pageSize={pageSize}
                          onPageChange={(page: React.SetStateAction<number>) =>
                            setCurrentPage(page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-three"
              id="permission-three"
            >
              <Approved
                data={magazine}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => setSelectedTenant(value)}
                handlePublish={(value: any) =>
                  changePublish(value, MediaContentStatusType.Publish)
                }
                publishButtonRef={publishButtonRef}
                selectedBtnId={selectedBtnId}
                loading={loadingPublish}
                // handleChange={(value: any) => changeStatus(value, MediaContentStatusType.Publish)}
              />
            </div>
            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="profile-tab-two"
              id="permission-two"
            >
              <Published
                data={magazine}
                handleDeletePress={(value: any) => handleDelete(value)}
                handleUpdatePress={(value: any) => setSelectedTenant(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="AddMagazineModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          {' '}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
              values: {
                name: string
                description: string
                releaseDate: number
                type: string
                author: string
                tenantId: string
                categoryId: string
                tags: any
              },
              {
                resetForm,
              }: FormikHelpers<{
                name: string
                description: string
                releaseDate: number
                genreId: string
                contentType: string
                sensorBoard: object
                tenantId: string
                categoryId: string
                movieThumbnail: string
                tags: any
              }>,
            ) => {
              if (modalOpenType == 'add') {
                handleSubmitMagazine(values, resetForm)
              } else {
                handleUpdate(values, selectedTenantId, resetForm)
              }
            }}
          >
            {(formik: any) => {
              return (
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ModalCenterTitle">
                      {modalOpenType == 'add'
                        ? 'Add Magazine'
                        : 'Edit Magazine'}
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={magazineCloseRef}
                      onClick={() => {
                        clearFieldOnClose()
                        formik.resetForm()
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body ">
                    <Form>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className=" form-group">
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              as="textarea"
                              id="text"
                              name="description"
                              rows={5}
                              className="form-control"
                              placeholder="Description"
                              defaultValue={''}
                            />
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {modalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <img src={modalImage} width={100} height={100} />
                            </div>
                          )}
                          <div className="form_gallery form-group">
                            <label
                              onClick={() => openPicker()}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedFile != null
                                ? selectedFile.name
                                : modalOpenType == 'add'
                                ? 'Upload Thumbnail'
                                : 'Upload New Thumbnail'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelect}
                              name="thumbnail"
                              onChange={e => handleFileSelect(e)}
                              accept=".png, .jpg, .jpeg, .webp"
                            />
                            <ErrorMessage
                              name="thumbnail"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="row ">
                            <div className="col-md-6 form-group">
                              <Field
                                type="date"
                                className="form-control"
                                placeholder="Release date"
                                name="releaseDate"
                              />
                              <ErrorMessage
                                name="releaseDate"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                as="select"
                                name="type"
                                className="form-control"
                              >
                                <option value="">Frequency</option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="yearly">Yearly</option>
                              </Field>
                              <ErrorMessage
                                name="type"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className=" form-group">
                            <Field
                              as="select"
                              className="form-control"
                              name="author"
                            >
                              <option value="">Select Author</option>
                              {authorsData.map((author: any) => {
                                return (
                                  <option value={author._id} key={author._id}>
                                    {author.name}
                                  </option>
                                )
                              })}
                            </Field>
                            <ErrorMessage
                              name="author"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {modalOpenType == 'edit' && (
                            <div className="form-group col-md-12">
                              <a href={modalPdf}>{modalPdf}</a>
                            </div>
                          )}
                          <div className="form_gallery form-group">
                            <label
                              onClick={() => openPickerPdf()}
                              htmlFor="form_gallery-upload"
                            >
                              {selectedFilePdf != null
                                ? selectedFilePdf.name
                                : modalOpenType == 'add'
                                ? 'Upload PDF'
                                : 'Upload New PDF'}
                            </label>
                            <input
                              type="file"
                              ref={inputFileSelectPdf}
                              name="pdf"
                              onChange={e => handleFileSelectPdf(e)}
                              accept=".pdf"
                            />
                            <ErrorMessage
                              name="pdf"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          {getIsRoot() && (
                            <div className="form-group">
                              <Field
                                as="select"
                                name="tenantId"
                                className="form-control"
                              >
                                <option value="">Select Tenant</option>
                                {tenants.length > 0 &&
                                  tenants.map((tenant: any) => {
                                    return (
                                      <option
                                        value={tenant._id}
                                        key={tenant._id}
                                      >
                                        {tenant.name}
                                      </option>
                                    )
                                  })}
                              </Field>
                              <ErrorMessage
                                name="tenantId"
                                component="span"
                                className="text-primary"
                              />
                            </div>
                          )}
                          <div className="form-group">
                            <Field
                              as="select"
                              name="categoryId"
                              className="form-control"
                            >
                              <option value="">Select Category</option>
                              {categories.length > 0 &&
                                categories.map((category: any) => {
                                  return (
                                    <option
                                      value={category._id}
                                      key={category._id}
                                    >
                                      {category.name}
                                    </option>
                                  )
                                })}
                            </Field>
                            <ErrorMessage
                              name="categoryId"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="tags"
                              id="tags"
                              placeholder="Tags"
                              isMulti={true}
                              component={SelectBox}
                              // options={lyricistList}
                            />
                            <ErrorMessage
                              name="tags"
                              component="span"
                              className="text-primary"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end ">
                        <button
                          type="submit"
                          className="btn btn-primary "
                          ref={submitButtonRef}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              {modalOpenType == 'add' ? 'Submit' : 'Update'}
                            </span>
                          )}
                          {loading && (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </CustomHeader>
  )
}

export default MagazineList
